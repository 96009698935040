import React, { Component } from 'react'
import { Modal } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import { formatShortDate } from '../utils';

const Transaction = ({t}) => (<>
    <div>
        {t.typeDescription}
        <span style={{float: 'right'}}>€{t.cost.toFixed(2)}</span>
    </div>
    <div style={{clean: 'both'}}>
        {t.users.map(u => <div key={u.id} style={{marginLeft: '30px', color: '#aaa'}}>{u.name}</div>)}
    </div>
</>)

export default class ProlongateModal extends Component {    
    render() {
        const {
            missingModal, 
            handleChange, 
            handleBuyMissingMemberships, 
            renewalDate, 
            missing
        } = this.props;

        return (
            <Modal
                visible={missingModal}
                title="Pay for missing periods"
                closable={false}
                onCancel={() => handleChange(false, "missingModal")}
                onOk={handleBuyMissingMemberships}
                okText="Pay with PayPal"
                width={700}
        >
            Extend all memberships of members who have missing periods up to the renewal date. 

            Missing periods are calculated on the basis of a pro-rata of 135 Euros divided by 12 months, i.e. 11.25 Euros per month.
            {missing ? (
                <div style={{ marginTop: 15 }}>
                    <>
                        Membership will be valid up to: {formatShortDate(renewalDate)}
                        <br /><br />
                        <h4>Payment details</h4>
                        <div>
                            {missing.missing.transactions.map((t, i) => <Transaction key={i} t={t}></Transaction>)}
                        </div>

                        <div>Total: <strong style={{float: 'right'}}>€{missing.missing.missingCost.toFixed(2)}</strong></div>
                    </>
                </div>
            ) : (
                <LoadingOutlined />
            )}
        </Modal>
        )
    }
}
