import { EVENT_TYPE, EVENT_FORM } from "../constants/enums";
export function parseEventTypeIdToEventTypeName(id) {
  switch (id) {
    case EVENT_TYPE.ARBITRALWOMEN_FREE:
      return "ArbitralWomen Event Free";
    case EVENT_TYPE.ARBITRALWOMEN_PAID:
      return "ArbitralWomen Event Paid";
    case EVENT_TYPE.YAWP_FREE:
      return "ArbitralWomen YAWP Event Free";
    case EVENT_TYPE.YAWP_PAID:
      return "ArbitralWomen YAWP Event Paid";
    case EVENT_TYPE.NON_ARBITRALWOMEN_FREE:
      return "Non-ArbitralWomen Event Free";
    case EVENT_TYPE.NON_ARBITRALWOMEN_PAID:
      return "Non-ArbitralWomen Event Paid";
    case EVENT_TYPE.NON_YAWP_FREE:
      return "Non-ArbitralWomen YAWP Event Free";
    case EVENT_TYPE.NON_YAWP_PAID:
      return "Non-ArbitralWomen YAWP Event Paid";
    case EVENT_TYPE.SPONSOR_ONLY:
      return "Sponsor Only";
    default:
      return "";
  }
}

export function parseEventTypeIdToEventTypeShortName(id) {
  switch (id) {
    case EVENT_TYPE.ARBITRALWOMEN_FREE:
      return "AW Free";
    case EVENT_TYPE.ARBITRALWOMEN_PAID:
      return "AW Paid";
    case EVENT_TYPE.YAWP_FREE:
      return "YAWP Free";
    case EVENT_TYPE.YAWP_PAID:
      return "YAWP Paid";
    case EVENT_TYPE.NON_ARBITRALWOMEN_FREE:
      return "Non-AW Free";
    case EVENT_TYPE.NON_ARBITRALWOMEN_PAID:
      return "Non-AW Paid";
    case EVENT_TYPE.NON_YAWP_FREE:
      return "Non-AW YAWP Free";
    case EVENT_TYPE.NON_YAWP_PAID:
      return "Non-AW YAWP Paid";
    case EVENT_TYPE.SPONSOR_ONLY:
      return "Sponsor Only";
    default:
      return "";
  }
}

export function isEventTypeIdPaid(id) {
  switch (id) {
    case EVENT_TYPE.ARBITRALWOMEN_FREE:
      return false;
    case EVENT_TYPE.ARBITRALWOMEN_PAID:
      return true;
    case EVENT_TYPE.YAWP_FREE:
      return false;
    case EVENT_TYPE.YAWP_PAID:
      return true;
    case EVENT_TYPE.NON_ARBITRALWOMEN_FREE:
      return false;
    case EVENT_TYPE.NON_ARBITRALWOMEN_PAID:
      return true;
    case EVENT_TYPE.NON_YAWP_FREE:
      return false;
    case EVENT_TYPE.NON_YAWP_PAID:
      return true;
    case EVENT_TYPE.SPONSOR_ONLY:
      return false;
    default:
      return false;
  }
}

export function isEventTypeIdAnArbitralWomenEvent(id) {
  switch (id) {
    case EVENT_TYPE.ARBITRALWOMEN_FREE:
      return true;
    case EVENT_TYPE.ARBITRALWOMEN_PAID:
      return true;
    case EVENT_TYPE.YAWP_FREE:
      return true;
    case EVENT_TYPE.YAWP_PAID:
      return true;
    case EVENT_TYPE.NON_ARBITRALWOMEN_FREE:
      return false;
    case EVENT_TYPE.NON_ARBITRALWOMEN_PAID:
      return false;
    case EVENT_TYPE.NON_YAWP_FREE:
      return false;
    case EVENT_TYPE.NON_YAWP_PAID:
      return false;
    case EVENT_TYPE.SPONSOR_ONLY:
      return false;
    default:
      return false;
  }
}

export function allEventTypes() {
  var allEventTypes = [];
  Object.values(EVENT_TYPE).map(value =>
    allEventTypes.push({ type: value, label: parseEventTypeIdToEventTypeShortName(value) })
  );
  return allEventTypes;
}

export function parseEventFormIdToEventFormName(id) {
  switch (id) {
    case EVENT_FORM.IN_PERSON:
      return "Event in Person";
    case EVENT_FORM.VIRTUAL:
      return "Virtual Event";
    case EVENT_FORM.HYBRID:
      return "Hybrid Event";
    default:
      return "";
  }
}
