import React from "react";
import { Modal } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";

class SubscriptionModal extends React.Component {
   
    handlePayment = e =>{
        this.props.getEstimatedPrice(e);
        this.props.handleChange(e,"slots")
    }

    componentDidMount() {
        const {
            members,
            inactiveMembers,
            avaliableSlots
        } = this.props;

        const totalSlots = (members ? members.length : 0) + inactiveMembers + avaliableSlots;

        this.handlePayment(totalSlots);
    }

    render(){
        const {
            subscription,
            estimatedCost,
            renewalDate,
            slots,
            handleSubscription,
            loadingEstimatedCost, 
            handleChange,
            members,
            inactiveMembers,
            avaliableSlots,
            prolongate
        } = this.props;
        
        let period;

        const renewalMonthDiff = moment(renewalDate).diff(moment(), 'months') ;
        const renewalDaysDiff = moment(renewalDate).diff(moment(), 'days') ;

        if(renewalMonthDiff > 0) {
           period = renewalMonthDiff;
        } else {
            if(renewalDaysDiff > 0 ) {
                period = 1;
            } else {
                period = 12;
            }
        }

        const totalSlots = (members ? members.length : 0) + inactiveMembers + avaliableSlots;

        return (
            <Modal
                visible={subscription}
                title="Subscribe membership slots"
                closable={false}
                okText="Subscribe with PayPal"
                onCancel={() => handleChange(false, "subscription")}
                onOk={ e => handleSubscription(e, estimatedCost,slots)}
                width={700}
            >
                You can subscribe memberships and autopay for them once for year. With your current plan you wil subscribe {totalSlots} slot(s). Payment price may be diffrent in the next years, if you will add some new members to your corporate.
                <div style={{ marginTop: 15 }}>
                    Estimated cost: 
                    {loadingEstimatedCost
                        ? <LoadingOutlined />
                        : ` €${(prolongate && prolongate.prolongationCost) || 0} `
                    }
                    each year until cancel
            </div>
            </Modal>
        )
    }
}
export default SubscriptionModal;