import React, { Component } from 'react';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Card, Input, Button, Checkbox, Row, Col, DatePicker, AutoComplete, Select, Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import UserSelect from './UserSelect';
import { Container, Draggable } from 'react-smooth-dnd';
import moment from 'moment';
import { shortDateFormat } from '../utils/formatShortDate'

const { Option } = Select;

export default class BoardSection extends Component {
    constructor(props) {
        super(props);
        this.refsCollection = {};

        this.state = {
            id: uuid(),
            level: this.props.level,
            title: this.props.data.title,
            displayPhotos: this.props.data.displayPhotos,
            children: this.props.data.children,
            members: this.props.data.members,
            options: [],
        };
    }

    render() {
        const { title, level, displayPhotos, children, members } = this.state;
        const { handleDelete, options, boardsTitles, sectionsTitles, roles, isYamp } = this.props;

        return (
            <Card
                className="custom-card"
                title={
                    <>
                        {!isYamp ?
                            <AutoComplete
                                className="title"
                                placeholder={level === 0 ? "Main Title" : "Comittee"}
                                value={title}
                                onChange={e => this.handleTitleChange(e)}
                                size={level === 0 ? "large" : "middle"}
                                filterOption={(inputValue, option) =>
                                    option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            >
                                <>
                                    {level === 0 && boardsTitles.map(option =>
                                        <AutoComplete.Option key={option.id} value={option.name}>
                                            {option.name}
                                        </AutoComplete.Option>
                                    )}
                                    {level !== 0 && sectionsTitles.map(option =>
                                        <AutoComplete.Option key={option.id} value={option.name}>
                                            {option.name}
                                        </AutoComplete.Option>
                                    )}
                                </>
                            </AutoComplete>
                            : <></>
                        }
                    </>
                }
                extra={
                    <>
                        {level === 0 && (
                            <Checkbox
                                style={{ margin: '15px 0px' }}
                                checked={displayPhotos}
                                onChange={e =>
                                    this.handleChangeDisplayPhotos(e.target.checked)
                                }
                            >
                                Display photos
                            </Checkbox>
                        )}
                        {!isYamp && (
                            <Tooltip title="Drag to reorder section position">
                                <DragOutlined
                                    className={
                                        level === 0
                                            ? "drag-handler-section"
                                            : "drag-handler-children"
                                    }
                                    style={{ marginLeft: 10 }}
                                />
                            </Tooltip>
                        )}
                        {!isYamp && (
                            <DeleteOutlined type="link" onClick={() => handleDelete()} style={{ marginLeft: 10 }} />
                        )}
                    </>
                }
            >
                <Container onDrop={result => this.reorderChildrens(result)} dragHandleSelector=".drag-handler-children">
                    {children.map((child, id) => (
                        <Draggable key={child.id}>
                            <BoardSection
                                key={child.id}
                                data={child}
                                level={this.state.level + 1}
                                handleDelete={() => this.removeSection(child.id)}
                                ref={instance => {
                                    this.refsCollection[id] = instance;
                                }}
                                options={options}
                                boardsTitles={boardsTitles}
                                sectionsTitles={sectionsTitles}
                                roles={roles}
                                isYamp={false}
                            />
                        </Draggable>
                    ))}
                </Container>
                <Container onDrop={result => this.reorderMembers(result)} dragHandleSelector=".drag-handler">
                    {members.map((member, id) => (
                        <Draggable key={member.id}>
                            <Row>
                                <Col span={5}>
                                    <UserSelect
                                        member={member}
                                        changeMember={selectedmember =>
                                            this.handleMemberChange(member.id, selectedmember)
                                        }
                                        options={options}
                                    />
                                </Col>
                                <Col span={4} offset={1}>
                                    <Select
                                        mode="multiple"
                                        placeholder="Member roles"
                                        value={member.position && member.position.filter(x => x)}
                                        onChange={e => this.handleMemberChangeValue(member.id, "position", e)}
                                        style={{ width: '100%' }}
                                    >
                                        {roles && roles.map(role => (
                                            <Option key={role.name}>{role.name}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={4} offset={1}>
                                    <Input
                                        value={member.subPosition}
                                        onChange={e => this.handleMemberChangeValue(member.id, "subPosition", e.target.value)}
                                        style={{ width: '100%' }}
                                        placeholder="Member Subposition"
                                    />
                                </Col>
                                <Col span={6} offset={1}>
                                    {member.memberLeft &&
                                        <DatePicker
                                            placeholder="Left date"
                                            value={member.leftDate && moment(member.leftDate)}
                                            onChange={e => this.handleMemberChangeValue(member.id, "leftDate", e && e.format(shortDateFormat))}
                                            format={shortDateFormat}
                                        />
                                    }
                                    <Checkbox
                                        style={{ marginTop: 5, marginLeft: member.memberLeft ? 25 : 0 }}
                                        checked={member.memberLeft}
                                        onChange={e => this.handleMemberChangeValue(member.id, "memberLeft", e.target.checked)}
                                    /> Member left
                                </Col>
                                <Col span={1} offset={1} style={{ textAlign: "right" }}>
                                    <Tooltip title="Drag to reorder member position">
                                        <DragOutlined className="drag-handler" />
                                    </Tooltip>
                                    <DeleteOutlined onClick={() => this.removeMember(id)} style={{ marginLeft: 10 }} />
                                </Col>
                            </Row>
                        </Draggable>
                    ))}
                </Container>
                <Row>
                    {!isYamp && (
                        level < 1 && members.length === 0 && (
                            <Col span={5}>
                                <Button
                                    type="dashed"
                                    style={{ width: "100%" }}
                                    onClick={() => this.addSection()}
                                >
                                    + Add section
                                </Button>
                            </Col>
                        )
                    )}
                    {isYamp ? (
                        children.length === 0 && (
                            <Col span={5}>
                                <Button
                                    type="dashed"
                                    style={{ width: "100%" }}
                                    onClick={() => this.addMember()}
                                >
                                    + Add member
                                </Button>
                            </Col>
                        )
                    ) : (
                        children.length === 0 && (
                            <Col span={5} offset={level === 1 || members.length > 0 ? 0 : 1}>
                                <Button
                                    type="dashed"
                                    style={{ width: "100%" }}
                                    onClick={() => this.addMember()}
                                >
                                    + Add member
                                </Button>
                            </Col>
                        )
                    )}

                </Row>
            </Card>
        );
    }

    handleTitleChange = title => {
        this.setState({ title });
    };

    handleMemberChange = (id, value) => {
        this.setState(prevState => {
            let members = prevState.members.map(member => {
                if (member.id !== id)
                    return member;

                return {
                    id: member.id,
                    position: member.position,
                    subPosition: member.subPosition,
                    leftDate: member.leftDate,
                    memberLeft: member.memberLeft,
                    memberId: value.id,
                    first_name: value.first_name,
                    last_name: value.last_name,
                    img: value.img,
                    country: value.address_country,
                    jurisdiction: value.jurisdiction,
                    profile: value.profile
                }
            })

            return { members }
        });
    };

    addSection() {
        this.setState(state => {
            let children = state.children.concat({
                id: uuid(),
                title: '',
                displayPhotos: false,
                members: [],
                children: []
            })

            return { children }
        });
    }

    removeSection(id) {
        this.setState(state => {
            const children = state.children.filter(child => child.id !== id);
            return { children };
        });
    }

    addMember() {
        this.setState(state => {
            const members = state.members.concat({
                id: uuid(),
                first_name: '',
                last_name: '',
                position: [],
                subPosition: '',
                memberLeft: false
            });

            return { members }
        });
    }

    removeMember(id) {
        this.setState(state => {
            const members = state.members.filter((member, i) => id !== i);

            return { members };
        });
    }

    saveData() {
        let data = [];

        const { refsCollection } = this;

        for (var ref in refsCollection) {
            if (refsCollection[ref]) {
                data[ref] = refsCollection[ref].saveData();
            }
        }

        const { id, title, displayPhotos, members, position, memberLeft } = this.state;

        return {
            id,
            title,
            displayPhotos,
            members,
            children: data.filter(x => x !== null),
            position,
            memberLeft
        };
    }

    handleChangeDisplayPhotos(value) {
        this.setState({
            displayPhotos: value,
        });
    }

    handleMemberChangeValue(id, property, value) {
        this.setState(prevState => {
            const members = prevState.members.map(member => {
                if (member.id !== id)
                    return member;

                return {
                    ...member,
                    [property]: value
                }
            })

            return { members }
        });
    }

    reorderMembers(result) {
        const { removedIndex, addedIndex } = result;
        const { members } = this.state;

        const deletedItem = members[removedIndex];

        this.setState(prevState => {
            const members = prevState.members.filter((_, index) => index !== removedIndex);
            members.splice(addedIndex, 0, deletedItem)

            return { members }
        })
    }

    reorderChildrens(result) {
        const { removedIndex, addedIndex } = result;
        const { children } = this.state;

        const deletedItem = children[removedIndex];

        this.setState(prevState => {
            const children = prevState.children.filter((_, index) => index !== removedIndex);
            children.splice(addedIndex, 0, deletedItem)

            return { children }
        })
    }
}
