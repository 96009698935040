import React, { Component } from 'react';
import { Card, Button,Statistic, Row, Col, Spin, Modal } from 'antd';
import { validateEmail } from '../utils/validateEmail';
import { api } from '../api';
import ProlongateModal from "./ProlongateModal";
import MissingMembershipsModal from "./MissingMembershipsModal";
import SubscriptionModal from "./SubscriptionModal";
import AddMemberModal from "./AddMemberModal";
import SubscriptionDetailsCard from "./SubscriptionDetailsCard";
import moment from "moment"
import "../styles/CorporationMembershipsInfo.css";
import { formatShortDate } from '../utils';

export default class CorporationMembershipsInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            visible: false,
            subscription: false,
            prolongateModal: false,
            loadingEstimatedCost: false,
            loadingProlongateCost: true,
            prolongate: null,
            estimatedCost: 0,
            slots: 0,
            prolongateStatus: false,
            renewalDaysLeft: 0,
            loading:false,
            months: 0,
            missingButtonVisible: false,
            missingModal: false,
            missing: null
        };
    }

    componentDidMount() {
        const {
            renewalDate,
            members
        } = this.props;

        const countRenewalDaysLeft = renewalDate && moment(renewalDate).diff(moment(), 'days');
        
        this.setState({
            renewalDaysLeft: countRenewalDaysLeft
        })
        if (renewalDate && countRenewalDaysLeft <= 31) {
            this.setState({
                renewalDaysLeft: countRenewalDaysLeft,
                prolongateStatus: true,
            });
        }

        this.showOrHideMissingButton(members, renewalDate);
    }
    
    showOrHideMissingButton = (members, renewalDate) => {
        if (this.isAnyMembershipMissing(members, renewalDate)) {
            this.setState({
                missingButtonVisible: true
            })
        };
    }

    handleSubscription = (e, cost, slots) => {
        e.preventDefault();
        this.setState({ 
            loading: true,
            subscription: false
        }, () => {
            api.post(`/payments/subscribe`,{
                userId: this.props.user.id,
                corporationId: this.props.user.corporationId,
                slots: slots,
                type: 'corporate',
            })
            .then(res=> res.data)
            .then(res => {
                this.setState({ isLoading: true });
                window.location.replace(`${res.link}`);
            })
        })
    }

    clearMember = () => {
        const { name, email } = this.state;
        if (name === '' || !validateEmail(email)) {
            Modal.warning({
                content: "Please enter correct user credentials"
            })
        } else {
            this.props.onAdd(name, email);
            this.setState({
                email: '',
                name: '',
                visible: false,
            });
        }
    }
    
    getEstimatedPrice = async slots => {
        const { corporationId } = this.props;

        await api
            .get(`/payments/estimated-payment/${corporationId}/${slots || 0}`)
            .then(res => {
                this.setState({
                    slots: slots,
                    loading: false,
                    estimatedCost: res.data.price,
                    months: res.data.months
                });
            });
    }

    getProlongatePrice = async () => {
        const { corporationId, selectedMembers } = this.props;

        api
            .post(`/payments/prolongate-cost/${corporationId}`, { selectedMembers })
            .then(res => res.data)
            .then(data => this.setState({
                prolongate: {
                    newRenewalDate: data.newRenewalDate,
                    prolongationCost: data.prolongateCost,
                    transactions: data.transactions
                },
                loadingProlongateCost: false,
            }))
    };

    handleChange = (e,property) => {
        this.setState({[property]:e})
    }
    
    handleProlongate = () => {
        this.props.handleTabChange(3);
        this.props.handleProlongateRequest(this.state.prolongate, this.props.selectedMembers);
        this.setState({ prolongateModal: false });
    };

    isAnyMembershipMissing = (members, renewalDateStr) => {
        const renewalDate = moment(renewalDateStr);
        for (const member of members) {
            const validTo = moment(member.valid_to);
            if (validTo < renewalDate || member.is_payment_deferred) {
                return true;
            }
        }
        return false;
    }

    prepareBuyMissingMembershipsModal = async () => {
        this.setState({ missingModal: true });
        
        const priceObject = await this.getMissingMembershipsPrice();
        
        this.setState({
            missing: priceObject
        });        
    }

    getMissingMembershipsPrice = async () => {
        const { corporationId, selectedMembers } = this.props;
        
        const resp = await api.post(`/payments/missing-cost/${corporationId}`, { selectedMembers });
        const missingObj = {
            missing: {
                missingCost: resp.data.missingCost,
                transactions: resp.data.transactions
            },
        };
        
        return missingObj;
    }

    handleBuyMissingMemberships = () => {
        this.props.handleTabChange(3);
        this.props.handleMissingRequest(this.state.missing);
        this.setState({ missingModal: false });
    }

    render() {
        const {
            name,
            email,
            visible,
            loading,
            missingButtonVisible
        } = this.state;

        const {
            members,
            inactiveMembers,
            renewalDate,
            user,
            avaliableSlots,
            subscription,
            fetchSubscription,
            selectedMembers
        } = this.props;

        return (
            <Spin
                spinning={loading}
                size="large"
                tip="We're redirecting you to PayPal"
            >
                <AddMemberModal
                    clearMember={this.clearMember}
                    handleChange={this.handleChange}
                    name={name}
                    email={email}
                    visible={visible}
                />            
                <SubscriptionModal 
                    user={user} 
                    getEstimatedPrice={this.getProlongatePrice} 
                    handleSubscription={this.handleSubscription} 
                    handleChange={this.handleChange} 
                    renewalDate={renewalDate}
                    avaliableSlots={avaliableSlots}
                    inactiveMembers={parseInt(inactiveMembers)}
                    members={members}
                    {...this.state}
                />
                <ProlongateModal 
                    user={user}
                    renewalDate={renewalDate}
                    handleChange={this.handleChange} 
                    handleProlongate={this.handleProlongate} 
                    {...this.state} 
                />
                <MissingMembershipsModal 
                    user={user}
                    renewalDate={renewalDate}
                    handleChange={this.handleChange} 
                    handleBuyMissingMemberships={this.handleBuyMissingMemberships} 
                    {...this.state} 
                />
                <div className="membership-info-card-container">
                <Card
                    title={
                        <div className="custom-card-header blue-underline">
                            Membership
                        </div>
                    }
                    className="custom-card"
                >

                    <Row gutter={[16, 16]}>
                        <Col md={6} span={24}>
                            <Statistic  title={<div style={{color: '#000000'}}>Corporate Members</div>} value={members && members.length} />
                        </Col>
                        <Col md={6} span={24}>
                            <Statistic  title={<div style={{color: '#000000'}}>Renewal Date</div>} value={ (renewalDate && formatShortDate(renewalDate)) || 'Not set'} />
                        </Col>
                        <Col md={6} span={24}>
                            <Statistic  title={<div style={{color: '#000000'}}>Available membership slots</div>} value={avaliableSlots || 0} />
                        </Col>
                    </Row>
                    {!user.isAdmin() &&
                        <>  
                            <Button
                                onClick={() => {
                                    this.setState({ prolongateModal: true });
                                    this.getProlongatePrice();
                                }}
                                className="button-no-padding-no-border"
                                style={{
                                    paddingLeft: 15
                                }}
                                disabled={selectedMembers.length < 5}
                            >
                                Extend corporate renewal date (please select at least 5 members)
                            </Button>
                               
                            {missingButtonVisible && 
                                <Button
                                    onClick={this.prepareBuyMissingMembershipsModal}
                                    style={{
                                        paddingLeft: 15,
                                        borderLeft: '1px solid #DDD',
                                    }}
                                    className="button-no-padding-no-border"
                                >
                                    Pay for missing periods
                                </Button>
                            }
                        </>
                    }
                </Card>
               
                </div>
                <div className="membership-info-card-container" >
                    <SubscriptionDetailsCard 
                        slots={avaliableSlots} 
                        inactiveMembers={inactiveMembers} 
                        members={members} 
                        subscription={subscription} 
                        loading={loading} 
                        user={user} 
                        fetchSubscription={fetchSubscription} 
                        handleChange={this.handleChange}/>
                </div>
            </Spin>
        );
    }
}
