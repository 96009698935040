import React, { Component } from 'react'
import { api } from '../../api'
import CustomTable from '../CustomTable'
import { Card, DatePicker, Form, Button, Input, InputNumber, Col, Row } from 'antd'
import { renderDate, shortDateFormat } from '../../utils'

function disabledDate(current) {
    return current && current.date() !== 1;
}

export default class CorporationPayment extends Component {
    columns = [
        {
            title: "Name",
            dataIndex: 'purchase_name',
            key: "purchase_name"
        },
        {
            title: "Payment Date",
            dataIndex: 'create_time',
            key: "create_time",
            render: renderDate
        },
        {
            title: "Payment Status",
            dataIndex: "paypal_status",
            key: "paypal_status",
            render: status => {
                switch (status) {
                    case "success": return <span style={{ color: '#52C41A' }}>Successful</span>;
                    case "UNCLAIMED": return <span style={{ color: 'gold' }}>Unclaimed</span>;
                    default: return <span style={{ color: '#E80003' }}>Canceled</span>;
                }
            }
        },
        {
            title: "Slots Amount",
            dataIndex: 'number_of_users',
            key: "number_of_users"
        },
        {
            title: "Amount To Pay",
            dataIndex: 'amount_to_pay',
            key: "amount_to_pay",
            render: amount => `€${amount}`
        },
    ]

    formRef = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            payments: []
        }
    }

    handleAddPayment = (values) => {
        this.formRef.current.resetFields();
    }

    componentDidMount() {
        api
            .get(`/corporations/${this.props.corporation.id}/payments`)
            .then(response => {
                this.setState({
                    payments: response.data.payments
                })
            })
    }

    render() {
        return (
            <>
                <CustomTable
                    dataSource={this.state.payments}
                    columns={this.columns}
                    pagination={{ pageSize: 10 }}
                    rowKey="id"
                />

                <Card size="small" title="Add corporate payment" hidden={true}>
                    <Form ref={this.formRef} layout="vertical" onFinish={this.handleAddPayment}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="Payment Date"
                                    name="paymentDate"
                                    rules={[{ required: true, message: 'Required field' }]}>
                                    <DatePicker style={{ width: '100%' }} format={shortDateFormat} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Amount Payed"
                                    name="amount"
                                    rules={[{ required: true, message: 'Required field' }]}>
                                    <Input prefix="€" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Payment Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Required field' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Expiry date"
                                    name="membership_expiration"
                                    rules={[{ required: true, message: 'Required field' }]}>
                                    <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} format={shortDateFormat} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Membership Slot Count"
                                    name="slots"
                                    rules={[{ required: true, message: 'Required field' }]}>
                                    <InputNumber />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Add corporate payment
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        )
    }
}
