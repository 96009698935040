import React from 'react'

import { Row, Col, Select, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';

import { FIELDS_TYPE } from '../../../constants/enums';
import { addArrayElement, updateArrayElement, removeArrayElement } from '../common/array';

const { Option } = Select;

export function AdditionalFieldsContent({ field }) {
    return (
        <>
            {field.label}
            {field.options && field.options.length > 0 &&
                <div style={{ padding: "5px 0px" }}>
                    {(field.field_type_id === FIELDS_TYPE.DROPDOWN || field.field_type_id === FIELDS_TYPE.MULTI_SELECT_LIST) &&
                        <>
                            <p style={{ margin: "10px 0px 0px 0px" }}>Options:</p>
                            {field.options.map((option, key) => (
                                <span key={key} style={{ color: "#999" }}>{option.label}{key < field.options.length - 1 && ','}{" "}</span>
                            ))}
                        </>
                    }
                </div>
            }
        </>
    )
}

export function AdditionalFieldsModal({ editedField, onUpdate }) {
    return (
        <>
            <p style={{ marginBottom: "5px" }}>Select field type</p>
            <Row>
                <Col span={24}>
                    <Select onChange={e => onUpdate("field_type_id", e)} value={editedField.field_type_id} style={{ width: "100%" }}>
                        {Object.keys(FIELDS_TYPE).map(key => (
                            <Option
                                key={FIELDS_TYPE[key]} 
                                value={FIELDS_TYPE[key]}
                            >
                                {key.toLocaleLowerCase().split("_").join(" ")}
                            </Option>
                        ))}
                    </Select>
                </Col> 
            </Row>

            <p style={{ marginBottom: "5px" }} className="margin-top-20">Field name</p>
            <Input value={editedField.label} onChange={e => onUpdate("label", e.target.value)}/>

            {(editedField.field_type_id === FIELDS_TYPE.MULTI_SELECT_LIST || editedField.field_type_id === FIELDS_TYPE.DROPDOWN) &&
                <>
                    <p style={{ marginBottom: "5px" }} className="margin-top-20">Available options</p>
                    {editedField.options.map(option => (
                        <Row key={option.id}>
                            <Col span={22}>
                                <Input
                                    style={{ marginBottom: "5px" }} 
                                    value={option.label}
                                    onChange={e => onUpdate("options", updateArrayElement(editedField.options, { ...option, label: e.target.value }, option.id))}
                                />
                            </Col>
                            <Col span={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <DeleteOutlined 
                                    style={{ cursor: "pointer" }} 
                                    onClick={() => onUpdate("options", removeArrayElement(editedField.options, option.id))}
                                />
                            </Col>
                        </Row>
                    ))}
                    <button 
                        className="add-event-element-button light" 
                        type="link" 
                        onClick={() => onUpdate("options", addArrayElement(editedField.options, { id: uuid(), label: "" }))}
                    >
                        <PlusOutlined/> Add option
                    </button>
                </>
            }
        </>
    )
}
