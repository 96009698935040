import React from 'react'
import { Input, Form, Select } from 'antd'
import { roles } from '../utils/userRoles';

const { Option } = Select;

export default function CategoryDetails({ editedCategory, onSubmit }) {
    return (
        <Form
            id="category-details"
            onFinish={onSubmit}
            initialValues={editedCategory}
        >
            Name
            <Form.Item
                name="name"
                rules={[
                    {required: true, message: "Category name is required"}
                ]}
            >
                <Input/>
            </Form.Item>
            Description
            <Form.Item
                name="description"
            >
                <Input.TextArea/>
            </Form.Item>
            Access granted to
            <Form.Item
                name="access_granted_for"
             
            >
                <Select
                    mode="multiple"
                    style={{marginBottom:"20px"}}
                >
                    <Option value={roles.INDIVIDUAL_MEMBER}>Member</Option>
                    <Option value={roles.BOARD_MEMBER}>Board member</Option>
                </Select>
            </Form.Item>
        </Form>
    )
}
