import React from 'react';
import { ArticlesTable } from '../../components/ArticlesTable';

export class ArticlesAdminView extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Articles');
    }

    render() {
        return (
            <>
                <ArticlesTable isAdmin={true} />
            </>
        );
    }
}
