import { Button, Checkbox, Col, DatePicker, Divider, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { api } from '../../api';
import _ from 'lodash';
import moment from 'moment';
import { shortDateFormat } from '../../utils/formatShortDate'
import CustomTable from '../CustomTable';

export default function NewMemberModal({ corporationId, visible, onCancel, onSubmit }) {
    const [newMember, setNewMember] = useState({});
    const [corporation, setCorporation] = useState([]);
    const [memberEmail, setMemberEmail] = useState("");
    const [selectedMembership, selectMembership] = useState(null);
    const [membershipType, selectMembershipType] = useState("attach");
    const [createNewMembership, setCreateNewMembership] = useState(false);
    const [validFrom, setValidFrom] = useState(null);
    const [validTo, setValidTo] = useState(null);
    const [isPaymentDeferred, setIsPaymentDeferred] = useState(false);
    const [newMemberMethod, setNewMemberMethod] = useState("create");

    const membershipColumns = [
        {
            title: "Membership Valid From",
            key: "valid_from",
            dataIndex: "valid_from",
            render: (data) => data ? moment(data).format(shortDateFormat) : ""
        },
        {
            title: "Membership Valid To",
            key: "valid_to",
            dataIndex: "valid_to",
            render: (data) => data ? moment(data).format(shortDateFormat) : ""
        },
        {
            title: "Is Payment Deferred",
            key: "is_payment_deferred",
            dataIndex: "is_payment_deferred",
            render: (is_payment_deferred) => is_payment_deferred ? "Yes" : "No"
        },
        {
            title: "Action",
            render: (membership) => (
                <Button
                    type={membership && membership.id === selectedMembership
                        ? "primary"
                        : "default"
                    }
                    onClick={() => selectMembership(
                        membership && membership.id === selectedMembership
                            ? null
                            : membership.id)
                    }
                >
                    {membership && membership.id === selectedMembership
                        ? "Assigned (click to unassign)"
                        : "Assign"
                    }
                </Button>
            )
        }
    ];

    useEffect(() => {
        api
            .get(`/corporations/${corporationId}/details`)
            .then(res => res.data)
            .then(data => setCorporation(data.corporation))
    }, [corporationId])

    const getMemberInformation = (member) => {
        api
            .get(`/users/details?email=${member}`)
            .then(res => res.data)
            .then(data => setNewMember(newMember => ({ ...newMember, memberData: data.user ? data.user : {} })))
    }

    const addMemberToCorporate = () => {
        onSubmit({ newMember, memberEmail, membershipType, createNewMembership, selectedMembership, validFrom, validTo, isPaymentDeferred, newMemberMethod })
    }

    const updateNewMemberData = (key, value) => {
        setNewMember(newMember => ({ ...newMember, [key]: value }))
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={() => addMemberToCorporate()}
            title="Add new member to corporate"
            width={900}
            okText="Add new member"
        >
            Please enter member email address:
            <Row>
                <Col md={18} span={24}>
                    <Input
                        value={memberEmail}
                        onChange={e => setMemberEmail(e.target.value)}
                    />
                </Col>
                <Col md={6} span={24}>
                    <Button
                        onClick={() => getMemberInformation(memberEmail)}
                        type="primary"
                        style={{
                            display: "block",
                            marginLeft: "auto"
                        }}
                    >
                        Check member
                    </Button>
                </Col>
            </Row>
            {newMember.memberData &&
                <>
                    <Divider />
                    {!_.isEmpty(newMember.memberData)
                        ?
                        <>
                            <div>User {newMember.memberData.first_name} {newMember.memberData.last_name} exists in the system. Membership valid from {(newMember.memberData.valid_from && moment(newMember.memberData.valid_from).format(shortDateFormat)) || "-"} to {(newMember.memberData.valid_to && moment(newMember.memberData.valid_to).format(shortDateFormat)) || "-"}</div>
                            <Divider />
                            <div>
                                <Checkbox
                                    style={{
                                        marginRight: 5
                                    }}
                                    checked={createNewMembership}
                                    onChange={e => setCreateNewMembership(e.target.checked)}
                                >
                                    Would you like to add corporate membership to this user?
                                </Checkbox>
                            </div>
                            {createNewMembership &&
                                <>
                                    <Select
                                        onChange={selectMembershipType}
                                        value={membershipType}
                                        style={{
                                            margin: "25px 0px"
                                        }}
                                    >
                                        <Select.Option value={"attach"}>Assign to existing memberships</Select.Option>
                                        <Select.Option value={"add"}>Create new corporate membership</Select.Option>
                                    </Select>
                                    {membershipType === "attach" &&
                                        <CustomTable
                                            columns={membershipColumns}
                                            dataSource={corporation.filter(membership => !membership.user_id)}
                                            style={{
                                                marginTop: 25,
                                                marginBottom: 25
                                            }}
                                            pagination={false}
                                        />
                                    }
                                    {membershipType === "add" &&
                                        <Row gutter={[25, 25]}>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Membership valid from:</div>
                                                <DatePicker style={{ width: "100%" }} onChange={setValidFrom} value={validFrom} format={shortDateFormat} />
                                            </Col>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Membership valid to:</div>
                                                <DatePicker style={{ width: "100%" }} onChange={setValidTo} value={validTo} format={shortDateFormat} />
                                            </Col>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Corporate will pay for this membership</div>
                                                <Checkbox onChange={e => setIsPaymentDeferred(e.target.checked)} checked={isPaymentDeferred} />
                                            </Col>
                                        </Row>
                                    }
                                </>
                            }
                        </>
                        :
                        <>
                            <div>User doesn't exists in the system. You can create new member and assign her to the corporate.</div>
                            <Select value={newMemberMethod} onChange={setNewMemberMethod}>
                                <Select.Option value="create">Create new account</Select.Option>
                                <Select.Option value="invite">Send invitation to the corproation</Select.Option>
                            </Select>
                            <Divider />
                            {newMemberMethod === "create" &&
                                <>
                                    <div>Please enter new member basic informations</div>
                                    <Row gutter={[100, 25]} style={{ marginTop: 25 }}>
                                        <Col md={12} span={24} style={{ borderRight: "1px solid #ddd" }}>
                                            <div style={{ marginBottom: 15 }}>Member information</div>
                                            <Input
                                                placeholder="First name"
                                                value={newMember.firstName}
                                                onChange={e => updateNewMemberData('firstName', e.target.value)}
                                            />
                                            <Input
                                                placeholder="Last name"
                                                style={{
                                                    marginTop: 10
                                                }}
                                                value={newMember.lastName}
                                                onChange={e => updateNewMemberData('lastName', e.target.value)}
                                            />
                                        </Col>
                                        <Col md={12} span={24}>
                                            <div style={{ marginBottom: 15 }}>System information</div>
                                            <Checkbox
                                                checked={newMember.approveProfile}
                                                onChange={e => updateNewMemberData('approveProfile', e.target.checked)}
                                                style={{ marginRight: 5 }}
                                            >
                                                Check the box to auto-approve member profile
                                            </Checkbox>
                                            <Checkbox
                                                checked={newMember.confirmEmailAddress}
                                                onChange={e => updateNewMemberData('confirmEmailAddress', e.target.checked)}
                                                style={{ marginRight: 5, marginLeft: 0, marginTop: 5 }}
                                            >
                                                Check the box to auto-confirm email address
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <div>
                                        <Checkbox
                                            style={{
                                                marginRight: 5
                                            }}
                                            checked={createNewMembership}
                                            onChange={e => setCreateNewMembership(e.target.checked)}
                                        >
                                            Would you like to add corporate membership to this user?
                                        </Checkbox>
                                    </div>
                                </>
                            }
                            {newMemberMethod === "invite" &&
                                <>
                                    <div>Email with invitation to the corproation will be sent to {memberEmail}. User will create member profile on by own.</div>
                                </>
                            }
                            {createNewMembership &&
                                <>
                                    <Select
                                        onChange={selectMembershipType}
                                        value={membershipType}
                                        style={{
                                            margin: "25px 0px"
                                        }}
                                    >
                                        <Select.Option value={"attach"}>Assign to existing memberships</Select.Option>
                                        <Select.Option value={"add"}>Create new corporate membership</Select.Option>
                                    </Select>
                                    {membershipType === "attach" &&
                                        <CustomTable
                                            columns={membershipColumns}
                                            dataSource={corporation.filter(membership => !membership.user_id)}
                                            style={{
                                                marginTop: 25,
                                                marginBottom: 25
                                            }}
                                            pagination={false}
                                        />
                                    }
                                    {membershipType === "add" &&
                                        <Row gutter={[25, 25]}>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Membership valid from:</div>
                                                <DatePicker style={{ width: "100%" }} onChange={setValidFrom} value={validFrom} format={shortDateFormat} />
                                            </Col>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Membership valid to:</div>
                                                <DatePicker style={{ width: "100%" }} onChange={setValidTo} value={validTo} format={shortDateFormat} />
                                            </Col>
                                            <Col md={8} span={24}>
                                                <div style={{ marginTop: 15 }}>Corporate will pay for this membership</div>
                                                <Checkbox onChange={e => setIsPaymentDeferred(e.target.checked)} checked={isPaymentDeferred} />
                                            </Col>
                                        </Row>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }

        </Modal>
    )
}
