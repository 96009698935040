import React from 'react';
import CustomTable from './CustomTable';
import { renderDate } from '../utils';

const SubscriptionPaymentsTable = props => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'create_time',
            key: 'create_time',
            render: renderDate,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (obj, row) =>
                row.amount.total
                    ? `${parseFloat(row.amount.total).toFixed(2)} ${row.amount.currency}`
                    : '',
        }, 
         {
            title: 'Transation fee',
            dataIndex: 'transation_fee',
            key: 'transation_fee',
            render: (obj, row) =>
                row.transation_fee
                    ? `${parseFloat(row.transation_fee.value).toFixed(2)} ${row.transation_fee.currency}`
                    : '',
        },
        {
            title: 'Order details',
            dataIndex: 'order_details',
            key: 'order_details',
            render: (obj, row) => {
                return row.summary ? row.summary : '';
            },
        },
        {
            title: 'Payment status',
            dataIndex: 'paypal_status',
            key: 'paypal_status',
            render: (obj,row) => (
                <>
                    {row.status &&
                        (
                            row.status === 'COMPLETED' && (<span style={{ color: '#52C41A' }}>Successful</span> )
                            ||
                            row.status === 'cancel' && (<span style={{ color: '#E80003' }}>Cancelled</span>)) 
                        || 
                        row.status === 'UNCLAIMED' && <span style={{ color: 'gold' }}>Unclaimed</span>
                    }
                </>
            ),
        },
        {
            title: 'Subscription ID',
            dataIndex: 'subscription_id',
            key: 'subscription_id',
        },{
            title: 'Transation ID',
            dataIndex: 'transation_id',
            key: 'transation_id',
        }
    ];

    const { rowKey, dataSource } = props;

    return (
        <CustomTable
            dataSource={dataSource}
            columns={columns}
            rowKey={rowKey}
            pagination={{ pageSize: 10 }}
        /> 
    )
}
export default SubscriptionPaymentsTable;