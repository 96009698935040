import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Spin, Popconfirm, Layout, Card, Modal, Input, Select } from 'antd';

import '../../styles/BoardsView.css';

import { api } from '../../api';
import CustomTable from '../../components/CustomTable';
import { renderDate, shortDateFormat } from '../../utils';

const { Content } = Layout;

export class BoardsView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.refsCollection = {};

        this.state = {
            id: 0,
            loading: true,
            boards: [],
            displayRoleModal: false,
            titles: [],
            roles: [],
            modalVisible: false,
            newRoleName: '',
            modalTitlesVisible: false,
            newTitleName: '',
            titleType: "MAIN",
            displayTitleModal: false,
        };

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'position',
                key: 'position',
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate',
                key: 'startDate',
                render: renderDate
            },
            {
                title: 'End Date',
                dataIndex: 'endDate',
                key: 'endDate',
                render: renderDate
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: id => (
                    <>
                        <Link className="hide-mobile" to={`/boards/${id}`}>Edit</Link>
                        <Button onClick={() => this.duplicateBoard(id)} type="link">Duplicate</Button>
                        <Popconfirm
                            title="Are you sure want to delete this board?"
                            onConfirm={() => this.deleteBoard(id)}
                            okText="Delete"
                            cancelText="Cancel"
                        >
                            <Button
                                style={{ marginLeft: 0 }}
                                type="link"
                                className="deleteButton"
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </>
                )
            }
        ];

        this.roleColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 400
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (_, role) => (
                    <Popconfirm onConfirm={() => this.deleteRole(role.id)} title={`Are you sure want to delete role "${role.name}"`}>
                        <Button style={{ paddingLeft: 0 }} type="link">Delete</Button>
                    </Popconfirm>
                )
            }
        ]

        this.titleColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 400
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (_, title) => (
                    <Popconfirm onConfirm={() => this.deleteTitle(title.id)} title={`Are you sure want to delete "${title.name}"`}>
                        <Button style={{ paddingLeft: 0 }} type="link">Delete</Button>
                    </Popconfirm>
                )
            }
        ]
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Board');

        this.fetchBoards();
        this.fetchBoardRoles();
        this.fetchBoardTitles();
    }

    fetchBoards() {
        this.setState({
            loading: true
        }, () => {
            api.get('/boards').then(res => {
                let data = this.parseDataToTableView(res.data.boards);

                this.setState({
                    boards: data,
                    loading: false,
                });
            });
        })
    }

    fetchBoardTitles = () => {
        api
            .get('/boards/titles')
            .then(response => this.setState({ titles: response.data.titles }));
    }

    changeModalVisibility = (visibility) => {
        this.setState({
            modalVisible: visibility
        })
    }

    changeModalTitleVisibility = (visibility) => {
        this.setState({
            modalTitlesVisible: visibility
        })
    }

    displayRoleModal() {
        this.setState({
            displayRoleModal: true,
            newRoleName: ""
        })
    }

    displayTitleModal() {
        this.setState({
            displayTitleModal: true,
            newTitleName: "",
            titleType: "MAIN TITLE"
        })
    }

    fetchBoardRoles = () => {
        api
            .get('/boards_roles')
            .then(res => this.setState({ roles: res.data }))
    }

    addRole = () => {
        const { newRoleName } = this.state;

        if (newRoleName !== '') {
            api
                .post('/boards_roles', { role: newRoleName })
                .then(() => {
                    this.fetchBoardRoles();
                    this.setState({
                        newRoleName: '',
                        displayRoleModal: false
                    })
                })
        }
    }

    addTitle = () => {
        const { newTitleName, titleType } = this.state;

        if (newTitleName) {
            api
                .post('/boards/titles', { role: newTitleName, type: titleType })
                .then(() => {
                    this.fetchBoardTitles();
                    this.setState({
                        newTitleName: '',
                        titleType: "MAIN",
                        displayTitleModal: false
                    })
                })
        }
    }

    deleteRole = (id) => {
        api
            .delete(`/boards_roles/${id}`)
            .then(() => this.fetchBoardRoles())
    }

    deleteTitle = (id) => {
        api
            .delete(`/boards/titles/${id}`)
            .then(() => this.fetchBoardTitles())
    }

    render() {
        const { modalVisible, displayRoleModal, displayTitleModal, modalTitlesVisible } = this.state;

        return (
            <>
                <Content className="content">
                    <Modal
                        visible={modalVisible}
                        bodyStyle={{ padding: 0, borderBottom: "unset" }}
                        closable={false}
                        footer={null}
                        width={600}
                    >
                        <Card
                            title='Manage board roles'
                            extra={
                                <Button type="primary" onClick={() => this.displayRoleModal()}><PlusCircleOutlined />Add role</Button>
                            }
                            onCancel={() => this.changeModalVisibility(false)}
                        >
                            <CustomTable
                                columns={this.roleColumns}
                                dataSource={this.state.roles}
                                pagination={{ pageSize: 10 }}
                            />
                            <Button style={{ display: "block", marginLeft: "auto", marginTop: 25 }} type="primary" onClick={() => this.changeModalVisibility(false)}>OK</Button>
                            <Modal
                                visible={displayRoleModal}
                                onOk={() => this.addRole()}
                                onCancel={() => this.setState({ displayRoleModal: false })}
                                title="Add role"
                                width={450}
                            >
                                Role name
                                <Input
                                    value={this.state.newRoleName}
                                    onChange={(e) => {
                                        this.setState({
                                            newRoleName: e.target.value
                                        })
                                    }}
                                />
                            </Modal>
                        </Card>
                    </Modal>
                    <Modal
                        visible={modalTitlesVisible}
                        bodyStyle={{ padding: 0, borderBottom: "unset" }}
                        closable={false}
                        footer={null}
                        width={700}
                        onCancel={() => this.changeModalTitleVisibility(false)}
                    >
                        <Card
                            title='Manage board titles and committees'
                            extra={
                                <Button type="primary" onClick={() => this.displayTitleModal()}><PlusCircleOutlined />Add title/committe</Button>
                            }
                        >
                            <CustomTable
                                columns={this.titleColumns}
                                dataSource={this.state.titles}
                                pagination={{ pageSize: 10 }}
                            />
                            <Button style={{ display: "block", marginLeft: "auto", marginTop: 25 }} type="primary" onClick={() => this.changeModalTitleVisibility(false)}>OK</Button>
                            <Modal
                                visible={displayTitleModal}
                                onOk={() => this.addTitle()}
                                onCancel={() => this.setState({ displayTitleModal: false })}
                                title="Add title/committee"
                                width={450}
                            >
                                Title Name
                                <Input
                                    value={this.state.newTitleName}
                                    onChange={(e) => this.setState({ newTitleName: e.target.value })}
                                />
                                <div style={{ marginTop: 25 }}>Title Type</div>
                                <Select
                                    value={this.state.titleType}
                                    onChange={type => this.setState({ titleType: type })}
                                >
                                    <Select.Option value="MAIN TITLE">Main Title</Select.Option>
                                    <Select.Option value="COMMITTEE">Committee</Select.Option>
                                </Select>
                            </Modal>
                        </Card>
                    </Modal>
                    <Spin spinning={this.state.loading}>
                        <Card
                            className="custom-card"
                            title={
                                <div className="custom-card-header blue-underline">
                                    Board
                                </div>
                            }
                            extra={
                                <>
                                    <Button
                                        onClick={() => this.changeModalVisibility(true)}
                                        type="link"
                                        style={{
                                            marginTop: -10,
                                            marginRight: 10
                                        }}
                                    >
                                        Manage roles
                                    </Button>
                                    <Button
                                        onClick={() => this.changeModalTitleVisibility(true)}
                                        type="link"
                                        style={{
                                            marginTop: -10,
                                            marginRight: 10
                                        }}
                                    >
                                        Manage titles/committees
                                    </Button>
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        type="primary"
                                        style={{ marginBottom: '16px' }}
                                        onClick={() => this.addBoard()}
                                        className="hide-mobile"
                                    >
                                        Add board
                                    </Button>
                                </>
                            }
                        >
                            <CustomTable
                                dataSource={this.state.boards}
                                columns={this.columns}
                                className="table"
                                pagination={{ hideOnSinglePage: true, pageSize: 10 }}
                            />
                        </Card>
                    </Spin>
                </Content>
            </>
        );
    }

    parseDataToTableView(data) {
        let table = [];

        for (let i in data) {
            table[i] = {
                key: data[i].id,
                position: parseInt(i) + 1,
                startDate:
                    data[i].start_date !== null
                        ? moment(data[i].start_date).format(shortDateFormat)
                        : '---',
                endDate:
                    data[i].end_date !== null
                        ? moment(data[i].end_date).format(shortDateFormat)
                        : '---',
                action: data[i].id,
            };
        }

        return table;
    }

    duplicateBoard(id) {
        api.post(`/boards/${id}/duplicate`)
            .then(response => {
                if (response.data.success) {
                    this.fetchBoards();
                    Modal.success({
                        content: "Board has been duplicated"
                    })
                }
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    addBoard() {
        let id = uuid();

        let board = {
            id: id,
            startDate: null,
            endDate: null,
            hierarchy: [],
        };

        api.post('/boards', { board }).then(res => {
            if (res.status === 200) {
                this.setState(state => {
                    let boards = state.boards.concat({
                        ...board,
                        key: id,
                        action: id,
                        position: state.boards.length + 1,
                    });
                    return { boards };
                });
            }
        });
    }

    deleteBoard(id) {
        api.delete(`/boards/${id}`).then(res => {
            if (res.status === 200) {
                this.setState(
                    state => {
                        const boards = state.boards.filter(
                            board => board.key !== id
                        );
                        return { boards };
                    },
                    () => {
                        this.reorderBoardsPosition();
                    }
                );
            }
        });
    }

    reorderBoardsPosition() {
        this.setState(state => {
            let boards = state.boards.map((board, i) => {
                return {
                    ...board,
                    position: i + 1,
                };
            });
            return { boards };
        });
    }
}
