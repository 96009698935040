import { CloseOutlined } from '@ant-design/icons'
import React from 'react'
import { Menu } from './Menu'

export default function MobileMenu({ hideMobileMenu }) {
    return (
        <div className="mobile-responsive-menu">
            <CloseOutlined
                onClick={hideMobileMenu}
                style={{ 
                    fontSize: "16px", 
                    color: "#0072DC",
                    padding: 15
                }}
            />
            <Menu onClick={hideMobileMenu}/>
        </div>
    )
}
