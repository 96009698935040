import React from 'react';

import { Container, Draggable } from 'react-smooth-dnd';
import { Modal } from 'antd';

import { v4 as uuid } from 'uuid';

import EventListElement from './EventListElement';
import EventListAddButton from './EventListAddButton';

import { AddonsContent, AddonsModal } from "./common/Addons";

const defaultField = {}

export default class Sponsorships extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayModal: false,
            editedFieldId: null,
            editedField: defaultField
        }
    }

    addNewField() {
        this.setState({
            displayModal: true,
            editedFieldId: null,
            editedField: {
                ...defaultField,
                id: uuid()
            }
        })
    }
    
    editField(field) {
        this.setState({
            displayModal: true,
            editedFieldId: field.id,
            editedField: field
        })
    }

    updateField(property, value) {
        const { editedField } = this.state;

        this.setState({
            editedField: {
                ...editedField,
                [property]: value
            }
        })
    }

    submitFieldForm() {
        const { onAddField, onUpdateField } = this.props;
        const { editedFieldId, editedField } = this.state;

        if(editedFieldId) {
            onUpdateField(editedField, editedFieldId);
        } else {
            onAddField(editedField);
        }

        this.setState({ displayModal: false })
    }

    render() {
        const { addons, onDeleteField, onDropList } = this.props;
        const { displayModal, editedField, editedFieldId } = this.state;

        return (
            <div>
                <Modal
                    visible={displayModal}
                    onCancel={() => this.setState({ displayModal: false })}
                    onOk={() => this.submitFieldForm()}
                    title={editedFieldId ? "Edit addon" : "Add addon"}
                >
                    <AddonsModal
                        editedField={editedField}
                        onUpdate={(property, value) => this.updateField(property, value)}
                    />
                </Modal>
                <Container onDrop={onDropList} dragHandleSelector=".drag-handler">
                    {addons.map(addon => 
                        <Draggable key={addon.id}>
                            <EventListElement
                                onDelete={() => onDeleteField(addon.id)}
                                onUpdate={() => this.editField(addon)}
                                label={addon.name}
                                draggable
                            >
                                <AddonsContent
                                    addon={addon}
                                />
                            </EventListElement>
                        </Draggable>
                    )}
                </Container>
                <EventListAddButton onClick={() => this.addNewField()} title="Add addon"/>
            </div>
        );
    }
}
