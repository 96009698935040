import React from 'react';
import { PaymentMemberHistory } from '../components/PaymentMemberHistory';
import { withUser } from '../components/UserContext';

class MembershipViewInner extends React.PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Payments');
    }

    render() {
        return <PaymentMemberHistory />;
    }
}

export const MembershipView = withUser(MembershipViewInner);
