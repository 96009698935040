import React from 'react';
import { Spin, Result, Modal } from 'antd';
import { RegisterUserForm } from '../components/RegisterUserForm';
import '../styles/RegisterUserView.css';
import { api } from '../api';
import { FIELDS_TYPE, EVENT_PRICE_TYPES } from '../constants/enums';
import moment from 'moment';
import { shortDateFormat } from '../utils';
import { withUser } from '../components/UserContext';
import { InputSuffix } from '../utils/currencySuffix';

class RegisterUserViewInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalFields: [],
            sponsorships: [],
            choosenSponsorship: null,
            addons: [],
            choosenAddons: [],
            coupons: [],
            isProfileDataLoading: true,
            event: {},
            eventPriceData: {},
            redirectTo: false,
            registeredEmail: '',
            couponCode: '',
            baseCost: 0,
            appliedCoupons: [],
            totalCost: 0,
            discountValue: 0,
            addonsCost: 0,
            isPaypalLoading: false,
            isEarlyBird: false,
        };

        this.handleAddData = this.handleAddData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const {
            match: {
                params: { eventId },
            },
        } = this.props;
        const context = this;
        api.get(`/events/${eventId}`).then(result => {
            if (result.data !== null) {
                this.setState({ event: result.data });
                api.get(`/eventPrices/${eventId}`).then(result => {
                    if (result.data.length > 0) {
                        if (result.data) {
                            this.setState({
                                eventPriceData: context.mapToEventPriceData(
                                    result.data
                                ),
                            }, () => {
                                const { eventPriceData } = this.state;
                                const { user } = this.props;
                                let baseCost;

                                if (user && user.id && user.membershipStatus && !user.membershipStatus.is_suspended && !this.isMembershipInactive()) {

                                    if (moment().diff(eventPriceData.early_bird_end_date) > 0 || !eventPriceData.early_bird_end_date) {
                                        baseCost = eventPriceData.regular_price_member;
                                    } else {
                                        baseCost = eventPriceData.early_bird_price_member;
                                    }
                                } else {
                                    if (moment().diff(eventPriceData.early_bird_end_date) > 0 || !eventPriceData.early_bird_end_date) {
                                        baseCost = eventPriceData.regular_price_non_member;
                                    } else {
                                        baseCost = eventPriceData.early_bird_price_non_member;
                                    }
                                }

                                this.setState({
                                    baseCost,
                                    totalCost: baseCost
                                })
                            });
                        }
                    }
                });
                api.get(`/eventAdditionalFields/${eventId}`).then(result => {
                    this.setState({
                        additionalFields: result.data,
                        isProfileDataLoading: false,
                    });
                });
                api.get(`/eventSponsorships/${eventId}`).then(result => {
                    this.setState({
                        sponsorships: result.data,
                    });
                });
                api.get(`/eventAddons/${eventId}`).then(result => {
                    this.setState({
                        addons: result.data,
                    });
                });

            }
        });
    }

    isEarlyBird = () => {
        return moment().diff(this.state.eventPriceData.early_bird_end_date) <= 0
    }

    isMembershipInactive() {
        const { user } = this.props;
        return (!user.membershipStatus.expires_at) || (moment(user.membershipStatus.expires_at).diff(moment().startOf('day')) < 0);
    }

    handleAddData(registrationObj, additionalFieldsObj) {
        const { appliedCoupons, choosenSponsorship, choosenAddons } = this.state;

        api.post('/registeredUsers', {
            ...registrationObj,
            additionalFields: additionalFieldsObj,
            choosenSponsorship: choosenSponsorship,
            choosenAddons: choosenAddons,
            appliedCoupons: appliedCoupons
        }).then((res) => {
            if (res.data && res.data.success && res.data.paid) {
                this.setState({
                    isPaypalLoading: true
                }, () => window.location.replace(`${process.env.REACT_APP_EVENT_PAYMENT_URL}/${res.data.paymentId}`))
            }
            else if (res.data && res.data.success && !res.data.paid) {
                window.location.replace(`${process.env.REACT_APP_API_URL}event/after-payment?success=true&ticket=${res.data.ticket_id}`)
            } else {
                window.location.replace(`${process.env.REACT_APP_API_URL}event/after-payment?success=false`);
            }
        }
        );
    }

    changeSponsorship = (sponsorshipObj) => {
        this.setState(prevState => {
            return {
                choosenSponsorship: prevState.choosenSponsorship !== sponsorshipObj ? sponsorshipObj : null,
                totalCost: prevState.choosenSponsorship !== sponsorshipObj
                    ? parseFloat(prevState.baseCost) + parseFloat(sponsorshipObj.price) + parseFloat(prevState.addonsCost) - parseFloat(prevState.discountValue)
                    : parseFloat(prevState.baseCost) + parseFloat(prevState.addonsCost) - parseFloat(prevState.discountValue)
            }
        })
    }
    changeAddons = (e, addonObj) => {
        if (e.target.checked) {
            this.setState(prevState => {
                prevState.choosenAddons.push(addonObj);
                prevState.totalCost += parseFloat(addonObj.price);
                prevState.addonsCost += parseFloat(addonObj.price);
                return prevState
            })
        }
        else {
            this.setState(prevState => {
                return {
                    choosenAddons: prevState.choosenAddons.filter(a => a !== addonObj),
                    totalCost: prevState.totalCost -= parseFloat(addonObj.price),
                    addonsCost: prevState.addonsCost -= parseFloat(addonObj.price)
                }
            })
        }
    }

    onSubmit(values) {
        const registrationObj = this.mapRegistration(values);
        const additionalFieldsObj = this.mapAdditionalFields(values);
        this.handleAddData(registrationObj, additionalFieldsObj);
    }

    mapRegistration(values) {
        const {
            match: {
                params: { eventId },
            },
        } = this.props;

        return {
            title: values.title,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            organization_name: values.organization_name,
            city: values.city,
            country: values.country,
            sponsorship_info: values.sponsorship_info,
            event_id: eventId,
        };
    }

    mapAdditionalFields(values) {
        const result = [];

        for (let i = 0; i < this.state.additionalFields.length; i++) {
            const additionalField = this.state.additionalFields[i];
            const value = values[additionalField.id];

            if (value !== undefined) {
                const parsedValue = this.parseValueByFieldType(
                    value,
                    additionalField.field_type_id
                );
                result.push({
                    additional_field_id: additionalField.id,
                    value: parsedValue,
                });
            }
        }

        return result;
    }

    parseValueByFieldType(value, field_type_id) {
        switch (field_type_id) {
            case FIELDS_TYPE.CHECKBOX:
                return JSON.stringify(value);
            case FIELDS_TYPE.MULTI_SELECT_LIST:
                return value && value.join(", ");
            case FIELDS_TYPE.DATEPICKER:
                return value.format(shortDateFormat);
            default:
                return value;
        }
    }

    mapToEventPriceData(data) {
        let regular_price_member = null,
            regular_price_non_member = null,
            early_bird_price_member = null,
            early_bird_price_non_member = null,
            early_bird_end_date = null;

        for (let i = 0; i < data.length; i++) {
            switch (data[i].price_type_id) {
                case EVENT_PRICE_TYPES.REGULAR_MEMBER:
                    regular_price_member = data[i].price;
                    break;
                case EVENT_PRICE_TYPES.REGULAR_NON_MEMBER:
                    regular_price_non_member = data[i].price;
                    break;
                case EVENT_PRICE_TYPES.EARLY_BIRD_MEMBER:
                    early_bird_price_member = data[i].price;
                    early_bird_end_date = data[i].end_date;
                    break;
                case EVENT_PRICE_TYPES.EARLY_BIRD_NON_MEMBER:
                    early_bird_price_non_member = data[i].price;
                    break;
                default:
                    break;
            }
        }

        return {
            regular_price_member: regular_price_member,
            regular_price_non_member: regular_price_non_member,
            early_bird_price_non_member: early_bird_price_non_member,
            early_bird_price_member: early_bird_price_member,
            early_bird_end_date: early_bird_end_date,
        };
    }

    updateCouponCode(value) {
        this.setState({
            couponCode: value
        });
    }

    applyCouponCode() {
        const { event: { id, currency }, couponCode, appliedCoupons } = this.state;
        api.post(`/events/${id}/apply-coupon`, { couponCode })
            .then(response => {
                if (response.data.coupon) {
                    const { coupon } = response.data;

                    if (appliedCoupons.includes(coupon.name)) {
                        Modal.warning({
                            title: "Coupon error",
                            content: "You have already applied this coupon"
                        })
                    } else {
                        const couponAmount = coupon.type === "percentage"
                            ? (this.state.baseCost * coupon.amount / 100)
                            : coupon.amount;

                        Modal.success({ content: `Valid coupon code. You got ${InputSuffix("amount", currency)}${couponAmount} discount` });

                        this.setState(prevState => {
                            const appliedCoupons = prevState.appliedCoupons.concat(coupon.name);
                            const discountValue = prevState.discountValue + parseFloat(couponAmount);
                            let totalCost = prevState.totalCost - parseFloat(couponAmount);

                            return {
                                appliedCoupons,
                                totalCost,
                                discountValue
                            }
                        });
                    }
                } else {
                    Modal.warning({
                        title: "Coupon error",
                        content: "Invalid coupon code"
                    })
                }
            })
    }

    render() {
        const {
            additionalFields,
            sponsorships,
            choosenSponsorship,
            addons,
            choosenAddons,
            isProfileDataLoading,
            isPaypalLoading,
            event,
            eventPriceData,
            couponCode,
            baseCost,
            totalCost,
            appliedCoupons
        } = this.state;
        if (this.state.redirectTo) {
            return (
                <Result
                    status="success"
                    title="You have successfully registered!"
                    subTitle={
                        'A confirmation mail has been sent to ' +
                        this.state.registeredEmail
                    }
                />
            );
        }
        return (
            <div className="container">
                <Spin
                    spinning={isProfileDataLoading || isPaypalLoading}
                    size="large"
                    tip={isPaypalLoading ? "We're redirecting you to PayPal" : ""}
                >

                    <RegisterUserForm
                        onSubmit={this.onSubmit}
                        additionalFields={additionalFields}
                        sponsorships={sponsorships}
                        addons={addons}
                        onChangeAddons={this.changeAddons}
                        choosenAddons={choosenAddons}
                        onChangeSponsorship={this.changeSponsorship}
                        choosenSponsorship={choosenSponsorship}
                        event={event}
                        eventPriceData={eventPriceData}
                        couponCode={couponCode}
                        onUpdateCouponCode={value => this.updateCouponCode(value)}
                        applyCouponCode={() => this.applyCouponCode()}
                        baseCost={baseCost}
                        totalCost={totalCost}
                        appliedCoupons={appliedCoupons}
                        isEarlyBird={this.isEarlyBird}
                    />
                </Spin>
            </div>
        );
    }
}

export const RegisterUserView = withUser(RegisterUserViewInner)