import React from 'react';
import { api } from '../../api';
import { Statistic, Card, Spin, Row, Col, Typography } from 'antd';
import moment from 'moment';
import { HomeOutlined, ReadOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import SupportMessage from '../utils/SupportMessage';

import { Link } from 'react-router-dom';
import { formatShortDate, shortDateFormat } from '../../utils';

const { Title } = Typography;

export class CorporateMemberDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: 0,
            expires_date: "",
            corporation_info: "",
            approval_info: "",
            renewal_date: "",
            isMemberProfileCreated: false,
            isLoading: true,
        };
    }
    componentDidMount = async () => {
        try {
            const resp = await api.get(`statistics/corporation-member`);
            const memberResp = await api.get('/members/is-member');
            const data = resp.data && resp.data.data;

            this.setState({
                articles: data && data.articles,
                expires_date: data && data.expires_date,
                corporation_info: data && data.corporation,
                approval_info: data && data.approval_info,
                renewal_date: data && data.renewal_date,
                isMemberProfileCreated: memberResp.data && memberResp.data.isMember,
                isLoading: false,
            });
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const {
            expires_date,
            corporation_info,
            approval_info,
            renewal_date,
            articles,
            isMemberProfileCreated,
            isLoading
        } = this.state;

        const { user } = this.props;

        const expiration_date_info = expires_date
            ? moment(this.state.expires_date).format(shortDateFormat)
            : 'No expiration date';

        const corporation_name = corporation_info
            ? corporation_info.name
            : 'No corporate info';

        const approval_text_info = approval_info
            ? 'Approved'
            : 'Awaiting approval';
        const approval_color = approval_info ? '#3f8600' : '#e81a1a';

        const articles_number = articles ? articles[0].count : 'No data';

        const overviewStyle = !isMemberProfileCreated ? { marginTop: 30 } : {};

        return (
            <Spin spinning={isLoading}>
                <Row style={overviewStyle}>
                    <Title level={3}>Overview</Title>
                </Row>

                <Row gutter={16}>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{ color: '#000000' }}>Your corporate</div>}
                                style={{ textAlign: 'left' }}
                                value={corporation_name}
                            />
                        </Card>
                    </Col>
                    {!user.corporationId &&
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Expiry date</div>}
                                    style={{ textAlign: 'left' }}
                                    value={expiration_date_info}
                                />
                            </Card>
                        </Col>
                    }
                    {!user.corporationId &&
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Approval status</div>}
                                    style={{ textAlign: 'left' }}
                                    value={approval_text_info}
                                    valueStyle={{ color: approval_color }}
                                />
                            </Card>
                        </Col>
                    }
                    {user.corporationId &&
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Renewal date</div>}
                                    style={{ textAlign: 'left' }}
                                    value={moment(renewal_date).isValid() ? formatShortDate(renewal_date) : 'No renewal date'}
                                />
                            </Card>
                        </Col>
                    }
                </Row>

                {(user.corporationId && user.hasMemberProfile) &&
                    <Row gutter={16}>
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Expiry date</div>}
                                    style={{ textAlign: 'left' }}
                                    value={expiration_date_info}
                                />
                            </Card>
                        </Col>
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Approval status</div>}
                                    style={{ textAlign: 'left' }}
                                    value={approval_text_info}
                                    valueStyle={{ color: approval_color }}
                                />
                            </Card>
                        </Col>
                        <Col md={6} span={12}>
                            <Card className="dashboard-info-card">
                                <Statistic
                                    title={<div style={{ color: '#000000' }}>Your articles</div>}
                                    style={{ textAlign: 'left' }}
                                    value={articles_number}
                                />
                            </Card>
                        </Col>
                    </Row>
                }

                <Row style={{ marginTop: '30px' }}>
                    <Title level={3}>Quick Access</Title>
                </Row>
                <Row gutter={16}>
                    {(user.corporationId && user.hasMemberProfile) &&
                        <Col md={6} span={12}>
                            <Link to="/profile">
                                <Card className="quick-access-card">
                                    <Row justify="left">
                                        <Col span={24}>
                                            <Row
                                                justify="flex-start"
                                                align="middle"
                                            >
                                                <UserOutlined />
                                                <span className="nav-text">
                                                    Profile
                                                </span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    }
                    <Col md={6} span={12}>
                        <Link to="/my-corporation">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <HomeOutlined />
                                            <span className="nav-text">
                                                Corporate
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                    {(user.corporationId && user.hasMemberProfile) &&
                        <Col md={6} span={12}>
                            <Link to="/articles">
                                <Card className="quick-access-card">
                                    <Row justify="left">
                                        <Col span={24}>
                                            <Row
                                                justify="flex-start"
                                                align="middle"
                                            >
                                                <ReadOutlined />
                                                <span className="nav-text">
                                                    Articles
                                                </span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    }
                    {(user.corporationId && user.hasMemberProfile) &&
                        <Col md={6} span={12}>
                            <Link to="/events">
                                <Card className="quick-access-card">
                                    <Row justify="left">
                                        <Col span={24}>
                                            <Row
                                                justify="flex-start"
                                                align="middle"
                                            >
                                                <TeamOutlined />
                                                <span className="nav-text">
                                                    Events
                                                </span>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    }
                </Row>
                <Row style={{ textAlign: 'left' }}>
                    <Col>
                        <SupportMessage />
                    </Col>
                </Row>
            </Spin>
        );
    }
}
