import React from 'react';

import { Container, Draggable } from 'react-smooth-dnd';
import { Modal } from 'antd';

import { v4 as uuid } from 'uuid';

import EventListElement from './EventListElement';
import EventListAddButton from './EventListAddButton';

import { AdditionalFieldsContent, AdditionalFieldsModal } from "./common/AdditionalFields";

import { FIELDS_TYPE } from '../../constants/enums';

const defaultField = {
    options: [],
    field_type_id: FIELDS_TYPE.TEXTBOX
}

export default class AdditionalFields extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayModal: false,
            editedFieldId: null,
            editedField: defaultField
        }
    }

    addNewField() {
        this.setState({
            displayModal: true,
            editedFieldId: null,
            editedField: {
                ...defaultField,
                id: uuid()
            }
        })
    }
    
    editField(field) {
        this.setState({
            displayModal: true,
            editedFieldId: field.id,
            editedField: {
                ...field,
                options: field.options.map((f, i) => ({ ...f, id: i }))
            }
        })
    }

    updateField(property, value) {
        const { editedField } = this.state;

        this.setState({
            editedField: {
                ...editedField,
                [property]: value
            }
        })
    }

    submitFieldForm() {
        const { onAddField, onUpdateField } = this.props;
        const { editedFieldId, editedField } = this.state;

        if(editedFieldId) {
            onUpdateField(editedField, editedFieldId);
        } else {
            onAddField(editedField);
        }

        this.setState({ displayModal: false })
    }

    render() {
        const { fields, onDeleteField, onDropList } = this.props;
        const { displayModal, editedField, editedFieldId } = this.state;

        return (
            <div>
                <Modal
                    visible={displayModal}
                    onCancel={() => this.setState({ displayModal: false })}
                    onOk={() => this.submitFieldForm()}
                    title={editedFieldId ? "Edit additional field" : "Add additional field"}
                >
                    <AdditionalFieldsModal
                        editedField={editedField}
                        onUpdate={(property, value) => this.updateField(property, value)}
                    />
                </Modal>
                <Container onDrop={onDropList} dragHandleSelector=".drag-handler">
                    {fields.map(field => 
                        <Draggable key={field.id}>
                            <EventListElement
                                onDelete={() => onDeleteField(field.id)}
                                onUpdate={() => this.editField(field)}
                                label={field.label}
                                draggable
                            >
                                <AdditionalFieldsContent 
                                    field={field}
                                />
                            </EventListElement>
                        </Draggable>
                    )}
                </Container>
                <EventListAddButton onClick={() => this.addNewField()} title="Add field"/>
            </div>
        );
    }
}
