import React from 'react';
import { Steps, Row, Col, Button } from 'antd';
import { LogoutOutlined } from "@ant-design/icons";
import logo from '../../logo.svg';
import { withUser } from '../UserContext';

const { Step } = Steps;

class SetupStepsInner extends React.Component {
    logout() {
        this.props.user && this.props.user.logout();
    }

    render() {
        const { selectedStep } = this.props;

        return (
            <Row className="setup-header">
                <Col md={6} span={24} className="logo">
                    <img src={logo} alt="ArbitralWomen" />
                </Col>
                <Col md={12} span={24}>
                    <Steps current={selectedStep}>
                        <Step 
                            title="1. Setup" 
                            icon={<></>} 
                        />
                        <Step 
                            title="2. Payment"
                            icon={<></>} 
                        />
                        <Step 
                            title="3. Confirmation" 
                            icon={<></>} 
                        />
                    </Steps>
                </Col>
                <Col md={6} span={24} style={{ textAlign: "center" }}>
                    <Button type="link" onClick={() => this.logout()}>
                        <LogoutOutlined/> Log out
                    </Button>
                </Col>
            </Row>
        )
    }
}

export const SetupSteps = withUser(SetupStepsInner);