import React from "react";
import { Link } from "react-router-dom";
import {
    Spin,
    Button,
    Menu,
    Dropdown,
    Popover,
    Tag
} from "antd";
import {
    DownOutlined,
    CommentOutlined
} from "@ant-design/icons";
import moment from "moment";
import CustomTable from "../CustomTable";
import { MembershipDetailsModal } from "./MembershipDetailsModal";
import { renderDate } from "../../utils";

export class MembersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberId: null,
            isModalVisible: false,
            isMembershipModalVisible: false,
            isLoading: true,
            description: "",
            requestCorporationId: null,
            valid_from: null,
            valid_to: null,
            value: null,
            purchaseName: "",
            memberProfileDetails: null,
        };

        this.columns = [
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (_, member) => {
                    if (member.suspended) {
                        return (
                            <Tag color="#ffa940">Suspended</Tag>
                        );
                    }
                    if (
                        !member.expires_at ||
                        moment(member.expires_at).diff(moment()) < 0
                    ) {
                        return <Tag color="#ff4d4f">Expired</Tag>;
                    }
                    if (member.approval_status === 'rejected') {
                        return <Tag color="#8c8c8c">Rejected</Tag>;
                    }
                    return <Tag color="#237804">Active</Tag>;
                },
            },
            {
                title: "",
                dataIndex: "approval_comment",
                key: "approval_comment",
                render: (_, member) => (
                    member.approval_comment && <Popover content={member.approval_comment} title={member.approval_status === 'rejected' ? 'Rejection comment' : 'Approval comment'}>
                        <CommentOutlined />
                    </Popover>
                )
            },
            {
                title: "First name",
                dataIndex: "first_name",
                key: "first_name",
            },
            {
                title: "Last name",
                dataIndex: "last_name",
                key: "last_name",
            },
            {
                title: "Email",
                dataIndex: "personal_email",
                key: "personal_email",
                render: (_, row) =>
                    row.personal_email ? (
                        <a href={`mailto:${row.personal_email}`}>
                            {row.personal_email}
                        </a>
                    ) : (
                            ""
                        ),
            },
            {
                title: "Corporate",
                dataIndex: "corporation_name",
                key: "corporation_name"
            },
            {
                title: "Registration date",
                dataIndex: "created_at",
                key: "created_at",
                render: renderDate
            },
            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (_, row) => (
                    <Dropdown overlay={this.menu(row)}>
                        <a href="#" className="ant-dropdown-link" style={{ whiteSpace: 'nowrap' }} onClick={e => e.preventDefault()}>
                            Actions <DownOutlined />
                        </a>
                    </Dropdown>
                ),
            },
        ];
    }

    menu = (row) => (
        <Menu>
            <Menu.Item>
                <Link to={`/profile/${row.id}`}>
                    <Button style={{ padding: 0 }} type="link">
                        Edit profile
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ padding: 0 }} type="link" onClick={() => this.showMembershipDetails(row)}>
                    Payments
                </Button>
            </Menu.Item>
        </Menu>
    );

    showMembershipDetails = (row) => {
        this.setState({
            isMembershipDetailsModalVisible: true,
            userId: row.id
        })
    }

    closeModal = () => {
        this.setState({
            isMembershipDetailsModalVisible: false,
            memberId: null,
            description: "",
            value: null,
            valid_from: null,
            valid_to: null,
            purchaseName: "",
        });
    };

    render() {
        const {
            isMembershipDetailsModalVisible,
            userId
        } = this.state;

        return (
            <>
                <MembershipDetailsModal
                    isVisible={isMembershipDetailsModalVisible}
                    userId={userId}
                    closeModal={this.closeModal}
                    fetchMembers={t => this.props.fetchMembers(t)}
                />

                <Spin spinning={this.props.isLoading} size="large">
                    <CustomTable
                        dataSource={this.props.members}
                        columns={this.columns}
                        rowKey="id"
                        pagination={false}
                    />
                </Spin>

                {!this.props.members && (
                    <Button
                        type="primary"
                        onClick={this.toggleModal}
                        className="u--push-right"
                    >
                        Approve
                    </Button>
                )}
            </>
        );
    }
}
