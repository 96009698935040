import React, { Component } from 'react'

import Form from 'antd/lib/form/Form';
import { Spin, Modal, Collapse } from 'antd';
import moment from 'moment';
import { longDateFormat } from '../../utils/formatShortDate'
import _ from "lodash";

import { v4 as uuid } from 'uuid';

import Coupons from './Coupons';
import Sponsorships from './Sponsorships';
import Addons from './Addons';
import AdditionalFields from './AdditionalFields';
import EventDetailsForm from './EventDetailsForm';

import { EventBanner } from './EventBanner';
import { EventLogos } from './EventLogos';
import { api } from '../../api';

import { EVENT_PRICE_TYPES } from '../../constants/enums';

const { Panel } = Collapse;

export default class EventsModalContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEventType: null,
            selectedEventForm: null,
            loading: false,
            uploadingBanner: false,
            uploadingLogo: false,
            event: {
                banner_logo: "",
                logo_images: [],
                additionalFields: [],
                coupons: [],
                sponsorships: [],
                addons: [],
                flyer: null,
            }
        }
    }

    componentDidMount() {
        const { editedId } = this.props;

        if (editedId) {
            this.setState({
                loading: true
            }, () => {
                this.getEventInfo();
            })
        }
    }

    getEventInfo = async () => {
        const { editedId } = this.props;
        const prices = await this.getEventPrices();

        api
            .get(`/events/${editedId}`)
            .then(result => {
                const { data } = result;
                this.setState({
                    loading: false,
                    event: {
                        ...data,
                        logo_images: data.logo_images ? JSON.parse(data.logo_images) : [],
                        additionalFields: data.additional_fields ? this.mapAdditionalFields(data.additional_fields) : [],
                        coupons: data.coupons || [],
                        addons: data.addons || [],
                        sponsorships: data.sponsorships || []
                    },
                    selectedEventType: data.eventtypeid,
                    selectedEventForm: data.eventformid,
                    flyer: data.flyer_url,
                    fields: [
                        {
                            name: ['eventtypeid'],
                            value: data.eventtypeid,
                        },
                        {
                            name: ['eventformid'],
                            value: data.eventformid,
                        },
                        {
                            name: ['name'],
                            value: data.name,
                        },
                        {
                            name: ['description'],
                            value: data.description,
                        },
                        {
                            name: ['country'],
                            value: data.country,
                        },
                        {
                            name: ['location'],
                            value: data.location,
                        },
                        {
                            name: ['range_date'],
                            value: [
                                moment(data.startdate),
                                moment(data.enddate)
                            ],
                        },
                        {
                            name: ['arbitralwomenparticipation'],
                            value: data.arbitralwomenparticipation ? data.arbitralwomenparticipation : [],
                        },
                        {
                            name: ['currency'],
                            value: data.currency,
                        },
                        {
                            name: ['registration_link'],
                            value: data.registration_link,
                        },
                        {
                            name: ['registration_email'],
                            value: data.registration_email,
                        },
                        {
                            name: ['early_bird_date'],
                            value: data.early_bird_date && moment(data.early_bird_date),
                        }, {
                            name: ['time_zone'],
                            value: data.time_zone,
                        },
                        {
                            name: ['price', 'member'],
                            value: _.isEmpty(prices) || (prices.length && prices.find(el => el.price_type_id === EVENT_PRICE_TYPES.REGULAR_MEMBER).price)
                        },
                        {
                            name: ['price', 'non_member'],
                            value: _.isEmpty(prices) || prices.find(el => el.price_type_id === EVENT_PRICE_TYPES.REGULAR_NON_MEMBER).price
                        },
                        {
                            name: ['price', 'member_early_date'],
                            value: _.isEmpty(prices) || prices.find(el => el.price_type_id === EVENT_PRICE_TYPES.EARLY_BIRD_MEMBER).price
                        },
                        {
                            name: ['price', 'non_member_early_date'],
                            value: _.isEmpty(prices) || prices.find(el => el.price_type_id === EVENT_PRICE_TYPES.EARLY_BIRD_NON_MEMBER).price
                        },
                        {
                            name: ['notes'],
                            value: data.notes
                        }
                    ]
                });
            })
    }

    getEventPrices = async () => {
        const { editedId } = this.props;
        const resp = await api.get(`/eventPrices/${editedId}`);
        return resp.data;
    }

    mapAdditionalFields(fields) {
        return fields.map(field => ({ ...field, options: JSON.parse(field.options) }));
    }

    onUpdateFlyer(flyer) {
        this.setState({ flyer })
    }

    changeState = (field, value) => {
        this.setState({
            [field]: value
        })
    }

    downloadFlyer() {
        window.location.replace(this.state.flyer);
    }

    deleteFlyer() {
        this.setState({ flyer: "" })
    }

    render() {
        const { selectedEventType, selectedEventForm, loading, fields, event, flyer, uploadingBanner, uploadingLogo } = this.state;
        const { editedId, user } = this.props;

        return (
            <Modal
                visible={true}
                title={editedId ? "Edit event" : "Add event"}
                onCancel={() => this.props.closeEventModal()}
                width={1000}
                okButtonProps={{ form: 'event-form', key: 'submit', htmlType: 'submit' }}
            >
                <Spin spinning={loading}>
                    <Form
                        id='event-form'
                        onFinish={values => this.submitForm(values)}
                        fields={fields}
                        onFieldsChange={this.onChangeFields.bind(this)}
                    >
                        <EventDetailsForm
                            user={user}
                            selectedEventType={selectedEventType}
                            selectedEventForm={selectedEventForm}
                            onUpdateFlyer={flyer => this.onUpdateFlyer(flyer)}
                            flyer={flyer}
                            isBeingEdited={Boolean(editedId)}
                            onDownloadFlyer={() => this.downloadFlyer()}
                            onDeleteFlyer={() => this.deleteFlyer()}
                        />
                        {user.isAdmin() &&
                            <Collapse>

                                <Panel header="Banner" key="1">
                                    <Spin spinning={uploadingBanner} tip='Your banner is being uploaded...'>
                                        <EventBanner
                                            bannerImg={event.banner_img}
                                            onUpload={img => this.onBannerUpload(img)}
                                            changeState={this.changeState}
                                        />
                                    </Spin>
                                </Panel>
                                <Panel header="Logos" key="2">
                                    <Spin spinning={uploadingLogo} tip='Your logo is being uploaded...'>
                                        <EventLogos
                                            onUpload={img => this.onLogoUpload(img)}
                                            logos={event.logo_images}
                                            onRemove={img => this.onLogoRemove(img)}
                                            changeState={this.changeState}
                                        />
                                    </Spin>
                                </Panel>

                                <Panel header="Additional fields" key="3">
                                    <AdditionalFields
                                        fields={event.additionalFields || []}
                                        onAddField={field => this.addEventArrayElement("additionalFields", field)}
                                        onDeleteField={id => this.removeEventArrayElement("additionalFields", id)}
                                        onUpdateField={(field, id) => this.updateEventArrayElement("additionalFields", id, field)}
                                        onDropList={result => this.reorderEventList("additionalFields", result)}
                                    />
                                </Panel>

                                <Panel header="Coupons" key="4">
                                    <Coupons
                                        currency={event.currency}
                                        coupons={event.coupons}
                                        onAddField={field => this.addEventArrayElement("coupons", field)}
                                        onDeleteField={id => this.removeEventArrayElement("coupons", id)}
                                        onUpdateField={(field, id) => this.updateEventArrayElement("coupons", id, field)}
                                    />
                                </Panel>

                                <Panel header="Sponsorships" key="5">
                                    <Sponsorships
                                        sponsorships={event.sponsorships}
                                        onAddField={field => this.addEventArrayElement("sponsorships", field)}
                                        onDeleteField={id => this.removeEventArrayElement("sponsorships", id)}
                                        onUpdateField={(field, id) => this.updateEventArrayElement("sponsorships", id, field)}
                                        onDropList={result => this.reorderEventList("sponsorships", result)}
                                    />
                                </Panel>

                                <Panel header="Addons" key="6">
                                    <Addons
                                        addons={event.addons}
                                        onAddField={field => this.addEventArrayElement("addons", field)}
                                        onDeleteField={id => this.removeEventArrayElement("addons", id)}
                                        onUpdateField={(field, id) => this.updateEventArrayElement("addons", id, field)}
                                        onDropList={result => this.reorderEventList("addons", result)}
                                    />
                                </Panel>

                            </Collapse>
                        }
                    </Form>
                </Spin>
            </Modal>
        )
    }

    onChangeFields(data) {
        if (data && data.length > 0 && data[0].name[0] === 'eventtypeid') {
            this.setState({
                selectedEventType: data[0].value
            })
        }
        if (data && data.length > 0 && data[0].name[0] === 'eventformid') {
            this.setState({
                selectedEventForm: data[0].value
            })
        }
    }

    submitForm(values) {
        const { editedId } = this.props;
        const { arbitralwomenparticipation } = values;
        const arbitralwomenparticipationParsed = JSON.stringify(arbitralwomenparticipation);
        const currentValues = { ...values, arbitralwomenparticipation: arbitralwomenparticipationParsed };
        this.setState({
            loading: true
        }, () => {
            if (editedId) {
                this.updateEvent(currentValues)
            } else {
                this.addEvent(values);
            }
        })
    }

    addEvent(values) {
        const { event } = this.state;
        const { flyer } = this.state;

        api
            .post('/events', {
                ...event,
                ...values,
                flyer,
                startdate: values.range_date[0].format(longDateFormat),
                enddate: values.range_date[1].format(longDateFormat),
                early_bird_date: values.early_bird_date && values.early_bird_date.format(longDateFormat)
            })
            .then(() => {
                this.closeFormAndRefreshData();
            })
    }

    updateEvent(values) {
        const { editedId } = this.props;
        const { event, flyer } = this.state;
        delete event['created_at'];
        api
            .patch(`/events/${editedId}`, {
                ...event,
                ...values,
                flyer,
                startdate: values.range_date[0].format(longDateFormat),
                enddate: values.range_date[1].format(longDateFormat),
                early_bird_date: values.early_bird_date && values.early_bird_date.format(longDateFormat),
                logo_images: JSON.stringify(event.logo_images)
            })
            .then(() => {
                this.closeFormAndRefreshData();
            })
    }

    closeFormAndRefreshData() {
        this.setState({
            loading: false
        })
        this.props.closeEventModal();
        this.props.refreshData();
    }

    onBannerUpload(img) {
        const { event } = this.state;

        this.setState({
            event: {
                ...event,
                banner_img: img
            }
        })
    }

    onLogoUpload(img) {
        const { event } = this.state;
        const logoImages = Array.isArray(event.logo_images) ? event.logo_images : [];
        const name = img.split('/');

        this.setState({
            event: {
                ...event,
                logo_images: logoImages.concat({
                    name: name[name.length - 1],
                    status: 'done',
                    uid: uuid(),
                    url: img
                })
            }
        });
    }

    onLogoRemove(img) {
        this.setState(prevState => {
            const logo_images = prevState.event.logo_images.filter(x => x.uid !== img.uid)

            return {
                event: {
                    ...prevState.event,
                    logo_images
                }
            }
        })
    }

    addEventArrayElement(arrayName, element) {
        this.setState(prevState => {
            const array = prevState.event[arrayName]
                .concat({
                    ...element,
                    position: prevState.event[arrayName].length
                });

            return {
                event: {
                    ...prevState.event,
                    [arrayName]: array
                }
            }
        })
    }

    updateEventArrayElement(arrayName, id, element) {
        this.setState(prevState => {
            const array = prevState.event[arrayName].map(field => {
                if (field.id === id) {
                    return { ...element }
                } else {
                    return field
                }
            })

            return {
                event: {
                    ...prevState.event,
                    [arrayName]: array
                }
            }
        })
    }

    removeEventArrayElement(arrayName, id) {
        this.setState(prevState => {
            const array = prevState.event[arrayName].filter(e => e.id !== id)

            return {
                event: {
                    ...prevState.event,
                    [arrayName]: array
                }
            }
        })
    }

    reorderEventList(arrayName, result) {
        const { removedIndex, addedIndex } = result;
        const { event } = this.state;

        const deletedItem = event[arrayName][removedIndex];

        this.setState(prevState => {
            const array = prevState.event[arrayName].filter((_, index) => index !== removedIndex);
            array.splice(addedIndex, 0, deletedItem)

            const sorted_array = array.map((element, position) => ({ ...element, position }))

            return {
                event: {
                    ...prevState.event,
                    [arrayName]: sorted_array
                }
            }
        })
    }
}
