import React from 'react';
import { Button, Modal, Tooltip, Spin, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { api } from '../../api';
import { formatShortDate } from '../../utils';

export class DetachMember extends React.PureComponent {

    state = {
        isDeleteModalVisible: false,
        id: null,
        name: '',
        email: '',
        valid_to: null,
        isLoading: false
    };

    openDeleteModal = () => {
        api
            .get(`/users/details?email=${this.props.member.member_email}`)
            .then(res => res.data)
            .then(res => this.setState({
                membershipInfo: res.user || {},
                isDeleteModalVisible: true,
                id: this.props.member.id,
                name: this.props.member.user_name,
                email: this.props.member.member_email,
                valid_to: this.props.member.valid_to,
                userId: this.props.member.user_id,
                type: this.props.member.type
            }));

        // console.log('Fetching memberships for user', this.props.member.user_id);
        // TODO: Get current membership here.
    }

    closeModal = (e, modal) => {
        this.setState({
            [modal]: false,
            name: '',
            email: ''
        })
    }

    handleDeleteMember = (e, withMembership) => {
        this.setState({ isLoading: true });

        const url = this.state.type === 'member'
            ? `/corporations/${this.props.member.corporation_id}/members/${this.state.id}?removeMembership=${withMembership}`
            : `/corporations/${this.props.member.corporation_id}/associates/${this.state.id}`;
        api.delete(url).then(() => {
            this.closeModal(e, 'isDeleteModalVisible')
            this.setState({ isLoading: false });
            this.props.onDelete();
        });
    };

    render() {
        const {
            name,
            email,
            valid_to,
            licenseOwner,
            isLoading,
            membershipInfo
        } = this.state;

        return (
            <Spin spinning={isLoading}>
                <Button type="link" onClick={this.openDeleteModal}>Detach</Button>
                {membershipInfo && <Modal
                    title={
                        <div className='delete-title'>
                            <ExclamationCircleOutlined />
                            <h3>Confirm member removal action</h3>
                        </div>
                    }
                    visible={this.state.isDeleteModalVisible}
                    onCancel={e => this.closeModal(e, 'isDeleteModalVisible')}
                    footer={null}
                >
                    <div className='delete-modal'>
                        <div className='delete-item'>
                            <h4>Name:</h4>
                            <p>{name}</p>
                        </div>
                        <div className='delete-item'>
                            <h4>Email:</h4>
                            <p>{email}</p>
                        </div>
                        {valid_to && (
                            <div className='delete-item'>
                                <h4>Expiry date:</h4>
                                <p>{formatShortDate(valid_to)}</p>
                            </div>
                        )}
                        <Space>
                            <Tooltip title='Member will be unassigned from corporation. '>
                                <Button
                                    type="link"
                                    onClick={e => {
                                        this.handleDeleteMember(e, true);
                                    }}
                                >Detach member</Button>
                            </Tooltip>
                            {membershipInfo.membership_is_valid && membershipInfo.membership_corporation_id && (
                                <Tooltip title="Member will be unassigned from corporation. Membership will be transfered to the member">
                                    <Button
                                        type="link"
                                        onClick={e => {
                                            this.handleDeleteMember(e, false);
                                        }}
                                        style={licenseOwner && this.transferStyle}
                                    >Detach member and gift her membership</Button>
                                </Tooltip>
                            )}
                        </Space>
                    </div>
                </Modal>}
            </Spin>
        );
    }
}
