import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

export default function MobileFilters({ children, onClearFilters, onHideFilters, onFinish }) {
    return (
        <div className="mobile-filters-content">
            <div className="mobile-header">
                <CloseOutlined style={{ color: "#0072dc" }} onClick={onHideFilters}/> Filters
            </div>
            <div className="filter-content">
                {children}
                <div className="buttons">
                    <Button
                        type="primary"
                        htmlType="submit"
                        ghost
                        onClick={onFinish}
                        size="large"
                    >
                        Search
                    </Button>
                    <Button onClick={onClearFilters} size="large">
                        Clear Filters
                    </Button>
                </div>
            </div>
        </div>
    )
}
