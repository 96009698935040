import _ from "lodash";

const InputSuffix = (type, currency) => {
    if (_.isUndefined(type)) {
        return "";
    }
    switch (type) {
        case "amount":
            if(currency === "USD")
                return "$";
            else
                return "€"
        case "percentage":
            return "%";
        default:
            return "";
    }
};

export {InputSuffix}