import React, { useState } from 'react';
import { Card, Button } from 'antd';

import '../../styles/Filters.css';
import { SearchOutlined } from '@ant-design/icons';
import MobileFilters from './MobileFilters';

export default function Filters({ children, onClearFilters, onFinish }) {
    const [displayFilters, setDisplayFilters] = useState(false);

    const handleSubmitMobileFilters = () => {
        setDisplayFilters(false);
        onFinish();
    }

    return (
        <>
            <div className="mobile-filters">
                <Button type="primary" ghost onClick={() => setDisplayFilters(true)}>
                    <SearchOutlined /> Filters
                </Button>
                {displayFilters && 
                    <MobileFilters 
                        onClearFilters={onClearFilters} 
                        onHideFilters={() => setDisplayFilters(false)} 
                        onFinish={() => handleSubmitMobileFilters()}>
                        {children}
                    </MobileFilters>
                }
            </div>
            <Card
                className="filter-card-header"
                title={
                    <span className="filters-header blue-underline">Search</span>
                }
            >
                <form onSubmit={(e) => {e.preventDefault(); onFinish(e);}}>
                    <div className="filters">
                        {children}
                        <Button
                            type="primary"
                            htmlType="submit">
                            Search
                        </Button>
                        <Button 
                            style={{ marginLeft: 25 }} 
                            type="primary" 
                            onClick={onClearFilters}
                            ghost
                        >
                            Clear search
                        </Button>
                    </div>
                </form>
            </Card>
        </>
    );
}
