import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Modal,
    Upload,
    Select,
    Avatar,
    Spin,
    Row,
    Col,
    Checkbox
} from "antd";
import { api } from "../../api";
import {
    LoadingOutlined,
    PaperClipOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { Link } from 'react-router-dom';
import _ from "lodash";
import { countries } from "../../utils/dictionaries";
import moment from "moment";
import { shortDateFormat } from "../../utils";

const { Option } = Select;

export const SetupCard = props => {
    const [isImgLoading, setIsImgLoading] = useState(false);
    const [isCvLoading, setIsCvLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [warning, setWarning] = useState("");
    const [img, setImg] = useState(null);
    const [cv, setCv] = useState(null);
    const profile = props.user;


    const handleContinue = () => {
        props.handleChange("step", 1);
    };

    const onFinish = values => {
        if (img) {
            api
                .patch('/members', {
                    user: profile,
                    first_name: values.firstname,
                    last_name: values.lastname,
                    residence: values.countryOfResidence,
                    why_join: values.whyJoin,
                    who_recommended: values.whoRecommended,
                    cv,
                    img,
                    id: null,
                })
                .then(() => handleContinue());
        } else {
            setWarning("Please upload your profile photo.")
        }
    };

    const onFinishFailed = errorInfo => {
        console.log("Failed:", errorInfo);
    };

    const uploadButton = (
        <div>
            {isImgLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload your photo</div>
        </div>
    );

    const beforeImgUpload = file => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isJpgOrPng) {
            Modal.warning({
                title: "Setup error",
                content: "You can only upload JPG/PNG file!"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Setup error",
                content: "Image must smaller than 30MB!"
            })
        }

        // if correct format and size read resolution
        if (isJpgOrPng && isLt30M) {
            const reader = new FileReader();
            const image = new Image();

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                image.src = e.target.result;
            };

            image.addEventListener("load", function () {
                setIsImgLoading(true);
                const formData = new FormData();
                formData.append("avatar", file);
                formData.append("id", profile.id ? profile.id : "");

                api
                    .post(`/users/upload-img/${props.user.id}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(({ data }) => {
                        setIsImgLoading(false);
                        setIsModalVisible(false);
                        setImg(data.data.image)
                    })
                    .catch(error => console.log(error));
            });
        }

        return false; // always return false to upload manually
    };

    function beforeCvUpload(file) {
        const isPdf = file.type === "application/pdf";
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isPdf) {
            Modal.warning({
                title: "Setup error",
                content: "You can only upload PDF file!"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Setup error",
                content: "CV must smaller than 30MB!"
            })
        }

        if (isPdf && isLt30M) {
            setIsCvLoading(true);
            const formData = new FormData();
            formData.append("cv", file);
            formData.append("id", profile.id ? profile.id : "");

            api
                .post(`/members/upload-cv/${profile.id || props.user.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(({ data }) => {
                    setIsCvLoading(false);
                    setCv(data.data.cv)
                })
                .catch(error => console.log(error));
        }

        return false; // always return false to upload manually
    }

    const cvFile = (cv || "").split('/').pop();
    return (
        <>
            <Card
                className="setup-card"
                bordered={false}
            >
                <h1>Setup your account</h1>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="m-l-auto m-r-auto"
                >
                    <Row gutter={[50, 0]}>
                        <Col md={8} span={24}>
                            <Form.Item
                                name="img"
                            >
                                <ImgCrop
                                    aspect={215 / 290}
                                    grid
                                >
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={beforeImgUpload}
                                    >
                                        {img
                                            ?
                                            <img
                                                src={img}
                                                alt="Profile photo"
                                                className="setup-img"
                                                onClick={() => setIsModalVisible(true)}
                                            />
                                            :
                                            uploadButton
                                        }
                                        <div className="warning" style={{ display: "flex", color: "#f44242" }}>
                                            {!img && warning}
                                        </div>
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                        </Col>
                        <Col md={16} span={24}>
                            <Form.Item
                                name="firstname"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                                style={{ display: "block", marginBottom: 0 }}
                            >
                                <Input placeholder="First name" size="large" />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                                style={{ display: "block", marginTop: 16, marginBottom: 0 }}
                            >
                                <Input placeholder="Last name" size="large" />
                            </Form.Item>
                            <Form.Item
                                name="cv"
                                rules={[
                                    { required: true, message: "Please upload your cv" },
                                ]}
                                style={{ display: "block", marginTop: 16, marginBottom: 0 }}
                            >
                                <Upload
                                    showUploadList={false}
                                    className="cv-upload"
                                    beforeUpload={beforeCvUpload}
                                    style={{ backgroundColor: 'red' }}
                                >
                                    <Input
                                        placeholder="Upload you CV"
                                        suffix={<PaperClipOutlined />}
                                        value={cvFile}
                                        size="large"
                                        prefix={isCvLoading && <LoadingOutlined />}
                                    />
                                </Upload>
                            </Form.Item>

                            <Form.Item name="countryOfResidence" rules={[{ required: true, message: "Required" }]}>
                                <Select
                                    id="setup-select"
                                    placeholder="Country of residence"
                                    allowClear
                                    size="large"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    mode="multiple"
                                    style={{ display: "block", marginTop: 16, marginBottom: 0 }}
                                >
                                    {countries &&
                                        countries.map(country => (
                                            <Option key={country.name} className="setup-select-option" value={`${country.name}`}>{country.name}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ display: "block", marginTop: 16, marginBottom: 0 }}>
                                Who recommended you or how did you learn about ArbitralWomen?
                            </Form.Item>
                            <Form.Item
                                name="whoRecommended"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                                style={{ display: "block", marginTop: 16, marginBottom: 0 }}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item style={{ display: "block", marginTop: 16, marginBottom: 0 }}>
                                Why would you like to join ArbitralWomen or any comments you wish to share?
                            </Form.Item>
                            <Form.Item
                                name="whyJoin"
                                rules={[
                                    { required: true, message: "Required" },
                                ]}
                                style={{ display: "block", marginTop: 16, marginBottom: 0, whiteSpace: "normal" }}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item
                                className="setup-button-container"
                                style={{ display: "block", marginTop: 16, marginBottom: 0 }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="setup-button"
                                    size="large"
                                >
                                    Continue
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export const PaymentCard = props => {
    const [visible, setVisible] = useState(false);
    const [corporationId, setCorporationId] = useState(null);
    const [corporationName, setCorporationName] = useState("");
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [autoPay, setAutoPay] = useState();
    const [isAssociate, setIsAssociate] = useState(false);

    useEffect(() => {
        api
            .get("/corporations?limit=0")
            .then(res => res.data)
            .then(res => setCompanies(res.corporations));

        api
            .get('/corporation-associates')
            .then(res => setIsAssociate(res.data.length > 0))
    }, []);

    const handleOk = () => {
        api.post(`/corporationJoinRequest/corporations/${corporationId}/user/${props.user.id}`);
        setVisible(false);
        props.hasUserJoinedToTheCorporation();
        props.handleChangeStep(2);

    }

    const handlePayment = e => {
        setLoading(true);
        if (autoPay) {
            handleSubscription(e);
        } else {
            handleCheckout(e);
        }
    }

    const showErrorModal = () => {
        Modal.error({
            title: "PayPal payment error",
            content: "An error occured during payment. Please try again later. If the problem persists please contact our IT support at awadministrator@arbitralwomen.org."
        });
    };

    const handleSubscription = e => {
        e.preventDefault();
        api.post(`/payments/subscribe`, {
            userId: props.user.id,
            type: 'individual'
        }).then(
            res => {
                setLoading(true);
                window.location.replace(`${res.data.link}`);
            }
        ).catch(() => {
            setLoading(false);
            showErrorModal();
        });


    }
    const handleCheckout = e => {
        e.preventDefault();
        api.post('/payments/new-order', { userId: props.user.id, type: "individual" }).then(
            res => {
                const { id } = res.data.data;
                setLoading(true);
                window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`)
            }
        ).catch(() => {
            setLoading(false);
            showErrorModal();
        });
    }

    const handleCancel = () => {
        setVisible(false);
    }

    const changeCorporationName = (e) => {
        const corporation = companies && companies.find(c => parseInt(c.id) === parseInt(e));
        setCorporationId(e);
        setCorporationName(corporation && corporation.name);
    }

    return (
        <Spin spinning={loading} tip="We're redirecting you to PayPal">
            <Modal
                className="payment-modal"
                visible={visible}
                footer={null}
            >
                <h3>
                    You are requesting to join {corporationName}. Your request will be sent to {corporationName}, who will review and accept or reject your request.

                </h3>
                <div style={{ paddingTop: 25 }} className="payment-modal-buttons">
                    <Button style={{ paddingLeft: 0, color: "#737373" }} type="link" size="large" className="button-cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" size="large" className="button-confirm" onClick={handleOk}>
                        Confirm
                    </Button>
                </div>
            </Modal>

            <Card
                className="setup-card payment"
                bordered={false}
            >
                <div className="payment-text-container">
                    <h1>Individual membership</h1>
                    <div className="payment-card-text">
                        Your membership will be valid one year from the date of payment of the membership fee of 150 Euros.
                        It will expire on the first day of the next month, i.e
                        <b> {moment().add(1, 'y').add(1, 'month').startOf('month').format(shortDateFormat)}</b>.
                    </div>

                    <div className="payment-card-text">

                    </div>
                    <div className="autopay">
                        <label>
                            <Checkbox checked={autoPay} style={{ marginRight: 5 }} onChange={e => setAutoPay(e.target.checked)} /> Auto-Pay
                        </label>
                        <p>
                            By Checking the “Auto-Pay” box above, you will subscribe to automatic payments and your membership
                            fee will automatically be debited from your card on file on your membership’s annual renewal date.
                            You may update the automatic payments settings in your profile anytime.
                        </p>
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col md={17} span={24}>
                            <Input
                                value={`€${isAssociate && !autoPay ? '135' : '150'}`}
                                size="large"
                            />
                        </Col>

                        <Col md={7} span={24}>
                            <Button
                                style={{ width: "100%" }}
                                size="large"
                                type="primary"
                                htmlType="submit"
                                onClick={e => handlePayment(e)}
                            >
                                Pay
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>
            <Card
                className="setup-card payment"
                bordered={false}
                style={{ marginTop: 25 }}
            >
                <div className="payment-text-container">
                    <h1>Corporate membership</h1>
                    <div className="payment-card-text">
                        You have the option to join an ArbitralWomen Corporate Membership if your firm is already subscribed as a Corporate Member.
                    </div>

                    <div className="payment-card-text">

                    </div>
                    <div className="payment-card-text" style={{ marginBottom: 25 }}>
                        Verify in the dropdown menu if your firm is among the Corporate Subscribers:
                    </div>
                    <Row gutter={[16, 16]}>
                        <Col md={17} span={24}>
                            <Select
                                className="company-request-select"
                                placeholder="Select company"
                                optionFilterProp="children"
                                onChange={e => changeCorporationName(e)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                size="large"
                                style={{ width: "100%" }}
                                showSearch
                            >
                                {companies &&
                                    companies.map(company => (
                                        <Option value={company.id}>{company.name}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                        <Col md={7} span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => setVisible(true)}
                                size="large"
                                style={{ width: "100%" }}
                            >
                                Send a request
                            </Button>
                        </Col>
                    </Row>
                    <div className="autopay">
                        <p>
                            A request will be sent to your firm. You must wait until your firm accepts your joining request.
                            If your firm accepts, it will pay your membership and you will receive an email with notification of acceptance.
                            If the request is rejected, you will nevertheless be able to benefit from the discounted rate of 135 Euros.
                        </p>
                    </div>
                </div>
            </Card>
        </Spin>
    );
};

export const ThankYouCard = props => {

    return (
        <div className="thank-you-container">
            <Card
                style={{ width: "670px", maxWidth: "90%", margin: "0px auto" }}
                className="thank-you-card"
                bordered={false}
            >
                <div className="thank-you-body">
                    <Avatar
                        size={95}
                        className="thank-you-card-avatar"
                        icon={
                            <svg
                                className="thank-you-bird"
                                width="50"
                                height="50"
                                viewBox="-10 -25 75 75"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M59.937 4.1903C59.7841 3.76471 59.3799 3.47921 58.9276 3.47921H32.1649C31.8948 3.47921 31.6352 3.58084 31.4372 3.76361L22.4622 12.0522L11.3 0.889764C11.2879 0.87841 11.2721 0.871451 11.2602 0.859914C11.2216 0.824387 11.1775 0.795453 11.1341 0.765053C11.0874 0.734288 11.0434 0.704438 10.9941 0.681913C10.9795 0.675137 10.9685 0.663417 10.9533 0.65719C10.9224 0.644188 10.8905 0.64309 10.859 0.63375C10.8044 0.616536 10.7517 0.600237 10.6951 0.592912C10.6438 0.585038 10.594 0.585037 10.5427 0.585037C10.4915 0.585037 10.4422 0.585038 10.3904 0.592912C10.3338 0.601336 10.2799 0.617635 10.2256 0.634849C10.1957 0.644372 10.1633 0.64547 10.1334 0.658472C10.1182 0.664699 10.1078 0.67532 10.0937 0.683195C10.0435 0.706635 9.99737 0.737584 9.95122 0.769082C9.90837 0.797833 9.86534 0.826584 9.8267 0.861196C9.81369 0.872733 9.79904 0.879509 9.78586 0.891046L0.314429 10.3623C0.00768848 10.669 -0.0846085 11.1298 0.0818557 11.5308C0.247771 11.9319 0.639483 12.1927 1.0724 12.1927H9.47143V24.5237C9.47143 24.5268 9.47252 24.5288 9.47252 24.531C9.47252 24.5332 9.47143 24.5352 9.47143 24.5372C9.47143 24.5456 9.47674 24.5541 9.47784 24.5636C9.48351 24.6901 9.51336 24.8097 9.55951 24.9216C9.57361 24.9542 9.58826 24.9855 9.60456 25.017C9.6595 25.1196 9.72707 25.2139 9.81131 25.2933C9.8223 25.304 9.82651 25.3186 9.8375 25.3291L21.4528 35.5156C21.4618 35.5227 21.4733 35.526 21.4832 35.5345C21.5334 35.6987 21.611 35.8548 21.7361 35.9806L30.8556 45.1011C31.0609 45.3053 31.3357 45.4154 31.6145 45.4154C31.7527 45.4154 31.8919 45.389 32.0249 45.3337C32.4257 45.1683 32.6865 44.7766 32.6865 44.3432V27.4634L59.6092 5.38137C59.9581 5.09404 60.0899 4.61717 59.937 4.1903ZM11.614 21.934V11.1192V4.23645L20.4634 13.0858L11.614 21.934ZM3.65891 10.0469L9.46941 4.23645V10.0469H3.65891ZM30.5393 41.7543L24.0565 35.2704L30.5393 29.5374V41.7543ZM22.1802 33.3038L12.1438 24.5017L32.5849 5.62401H55.9287L22.1802 33.3038Z"
                                    fill="#0072DC"
                                />
                            </svg>
                        }
                    />
                    <h1>Thank you!</h1>
                    <div className="thank-you-divider">
                        <svg width="45" height="4" viewBox="0 0 45 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="4" fill="#0072DC" />
                        </svg>
                    </div>
                    <div className="thank-you-text">
                        {props.corporationSetup &&
                            <div>
                                <p>We have received your payment. The members you have indicated, will receive an email inviting them to complete their account.
                                </p>

                                <Link to="/profile" onClick={() => window.location.replace('/')}>
                                    <Button style={{ marginTop: 20 }} size="large" type="primary" >Continue</Button>
                                </Link>
                            </div>

                        }

                        {!props.corporationSetup &&
                            <>
                                <p>
                                    {!_.isEmpty(props.joinRequest) ? <>
                                        We have received your request to join  {props.joinRequest[0].name}.{" "}
                                        Your request will be sent to {props.joinRequest[0].name} , who will review and accept or reject your request.
                                        <p>
                                            Once your firm accepts or rejects your joining request, we will notify you.
                                        </p>

                                    </>

                                        : <>
                                            We have received your payment.
                                            Your application is now being reviewed by ArbitralWomen.
                                            <p>We will notify you once your application has been approved.</p>  </>}

                                </p>

                            </>
                        }

                    </div>

                </div>
            </Card>
        </div>
    );
};
