import React from 'react';
import {
    Card,
    Spin,
    Popconfirm,
    Button,
    Modal,
    Select,
    Form,
    DatePicker,
    Tag,
    InputNumber
} from 'antd';
import moment from 'moment';
import CustomTable from '../CustomTable';
import { api } from '../../api';
import { formatShortDate, shortDateFormat } from '../../utils';

function disabledDate(current) {
    return current && current.date() !== 1;
}

export class MembershipsTable extends React.Component {

    state = {};
    formRef = React.createRef();
    columns = [
        {
            title: 'Membership ID',
            dataIndex: 'id',
            key: 'id',
            render: (id, row) => <>
                <div style={{ whiteSpace: 'nowrap' }}>{id}</div>
                {row.is_payment_deferred && (<>
                    <Tag color="warning">Payment deferred</Tag>
                    {this.props.user.isAdmin() && <Popconfirm title="Are you sure you want to waive the payment for this membership?" onConfirm={() => this.handleCancelPayment(id)}><Button type="link">Waive payment</Button></Popconfirm>}
                </>)}
            </>
        },
        {
            title: 'Valid From',
            dataIndex: 'valid_from',
            key: 'valid_from',
            render: date => <div style={{ whiteSpace: 'nowrap' }}>{formatShortDate(date)}</div>
        },
        {
            title: 'Valid To',
            dataIndex: 'valid_to',
            key: 'valid_to',
            render: date => <div style={{ whiteSpace: 'nowrap' }}>{formatShortDate(date)}</div>
        },
        {
            title: 'Member Name',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (_, row) => <>{row.first_name} {row.last_name}</>
        },
        {
            title: 'Member Email',
            dataIndex: 'personal_email',
            key: 'personal_email'
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, row) => (row.user_id
                ? <Popconfirm
                    title="Do you want to unassign this member from a membership?"
                    okText="Unassign"
                    onConfirm={() => this.handleUnassign(row.id)}>
                    <Button type="link">Unassign a member</Button>
                </Popconfirm>
                : <Button onClick={() => this.handleAssign(row.id)} type="link">Assign a member</Button>
            )
        },
        {
            title: '',
            className: this.props.user.isAdmin() ? 'show' : 'hide',
            render: (_, row) => <Popconfirm
                title="Do you want to remove this membership?"
                okText="Remove membership"
                onConfirm={() => this.handleRemoveMembership(row.id)}>
                <Button type="link">Remove</Button>
            </Popconfirm>
        }
    ]

    handleCancelPayment(id) {
        this.setState({ isLoading: true });
        api
            .patch(`/memberships/${id}/cancel-payment`)
            .then(() => this.fetchMemberships())
    }

    handleUnassign(id) {
        this.setState({ isLoading: true });
        api
            .delete(`/memberships/${id}/user`)
            .then(() => this.fetchMemberships())
    }

    handleRemoveMembership(id) {
        this.setState({ isLoading: true });
        api
            .delete(`/memberships/${id}`)
            .then(() => this.fetchMemberships())
    }

    handleAssign(id) {
        this.fetchMembers()
            .then(() => {
                this.setState({ assigningTo: id })
            })
    }

    assignSelected() {
        const { assigningTo, userIdForAssignment } = this.state;

        if (!userIdForAssignment)
            return;

        api
            .patch(`/memberships/${assigningTo}/user/${userIdForAssignment}`)
            .then(() => this.fetchMemberships())
            .then(() => this.setState({
                userIdForAssignment: null,
                assigningTo: null
            }));
    }

    fetchMemberships() {
        const { corporationId } = this.props;
        this.setState({ isLoading: true });
        return api.get(`/corporations/${corporationId}/memberships`)
            .then(result => result.data)
            .then(({ memberships }) => this.setState({ memberships, isLoading: false }))
    }

    fetchMembers() {
        const { corporationId } = this.props;
        this.setState({ isLoading: true });
        return api.get(`/corporations/${corporationId}/members/list`)
            .then(result => result.data)
            .then(({ members }) => this.setState({ members, isLoading: false }))
    }

    componentDidMount() {
        this.fetchMemberships();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reloadKey !== this.props.reloadKey) {
            this.fetchMemberships();
        }
    }

    handleAddMembership = (values) => {
        const { corporationId } = this.props;
        this.setState({ isLoading: true });
        return api.post(`/memberships`, {
            corporationId,
            validFrom: moment(values.valid_from).format(shortDateFormat),
            validTo: moment(values.valid_to).format(shortDateFormat),
            count: values.count
        })
            .then(result => result.data)
            .then(() => this.fetchMemberships())
            .then(() => this.setState({ isLoading: false }))
            .then(() => this.formRef.current.resetFields())
    }

    render() {
        const {
            memberships,
            members = [],
            isLoading,
            assigningTo
        } = this.state;

        const {
            showTableOnly,
            showWhenEmpty
        } = this.props;

        if (!memberships && !showWhenEmpty)
            return null;

        const table = (
            <>
                <Modal
                    visible={assigningTo}
                    title="Assign member"
                    destroyOnClose={true}
                    onCancel={() => this.setState({ assigningTo: null })}
                    onOk={() => this.assignSelected()}>
                    <Select options={members.map(m => ({
                        label: m.member_name,
                        value: m.user_id
                    }))} style={{ width: '100%' }} onChange={id => this.setState({ userIdForAssignment: id })}></Select>
                </Modal>
                <CustomTable
                    dataSource={memberships}
                    columns={this.columns}
                    rowKey='id'
                    pagination={{ pageSize: 10 }}
                />
                {this.props.user.isAdmin() && <Card size="small" title="Add membership" style={{ width: "75%" }}>
                    <Form ref={this.formRef} layout="inline" onFinish={this.handleAddMembership}>
                        <Form.Item
                            label="Valid From"
                            name="valid_from"
                            rules={[{ required: true, message: 'Required field' }]}>
                            <DatePicker format={shortDateFormat} />
                        </Form.Item>
                        <Form.Item
                            label="Valid To"
                            name="valid_to"
                            rules={[{ required: true, message: 'Required field' }]}>
                            <DatePicker disabledDate={disabledDate} format={shortDateFormat} />
                        </Form.Item>
                        <Form.Item
                            label="Count"
                            name="count"
                            rules={[{ required: true, message: 'Required field' }]}>
                            <InputNumber min={1} defaultValue={1} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Add membership
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>}
            </>
        );

        return showTableOnly ? table : (
            <Card
                className='custom-card'
                title={<div className='custom-card-header blue-underline'>Memberships</div>}
            >
                <Spin spinning={isLoading}>
                    {table}
                </Spin>
            </Card>
        );
    }
}