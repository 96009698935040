import React from 'react';
import moment from 'moment';
import { shortDateFormat } from '../utils/formatShortDate'
import ReactQuill from 'react-quill';

import { Card, Spin, Modal, Pagination, Popconfirm, Button, Input, DatePicker, Form, Empty, Row, Col, Menu, Dropdown, Radio, Tooltip, Popover } from 'antd';
import { PlusCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone, WarningOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, CommentOutlined } from '@ant-design/icons';
import { Layout, Tag, Upload, Select } from 'antd';
import { DownOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

import Filters from './utils/Filters';
import Filter from './utils/Filter';
import CustomTable from '../components/CustomTable';

import buildQueryStringFromFilters from '../utils/buildQueryStringFromFilters';

import { api } from '../api';
import parse from "html-react-parser";
import { withUser } from './UserContext';

import '../styles/ArticleView.css';
import '../styles/Filters.css';
import 'react-quill/dist/quill.snow.css';
import { renderDate, formatLongDate } from '../utils';

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;

class ArticlesTableInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            articles: [],
            title: '',
            link: '',
            publication: '',
            date: '',
            description: "",
            kluwer: null,
            visible: false,
            updateModalVisible: false,
            page: 1,
            total: 0,
            limit: 50,
            cellMaxLength: 24,
            editedArticleId: null,
            editedArticleIndex: null,
            published: false,
            filterStartDate: null,
            filterEndDate: null,
            filterTitle: '',
            filterAuthor: '',
            filterPublication: '',
            validateErrors: {},
            publications: [],
            uploadingFile: false,
            displayPublicationModal: false,
            modalVisible: false,
            publicationText: "",
            author: "",
            authors: [],
            isPublicationEditModalVisible: false,
            publicationTextToEdit: "",
            publicationIdToEdit: "",
            namePublication: "",
            totalCountPublication: 0,
            limitPublication: 10,
            co_author: '',
            authors_id: "",
            author_id: ""
        };
    }
    componentDidMount() {
        this.fetchData();
        this.fetchPublications();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.kluwer && this.state.publication) {
            this.setState({ publication: '' })
        }
    }

    fetchData = (p, l) => {
        const {
            limit,
            page,
            filterTitle,
            filterAuthor,
            filterPublication,
            filterStartDate,
            filterEndDate,
        } = this.state;

        const filters = {
            limit: l || limit,
            page: p || page,
            title: filterTitle,
            author: filterAuthor,
            publication: filterPublication,
            start: filterStartDate,
            end: filterEndDate,
        };

        const params = buildQueryStringFromFilters(filters);

        api.get(`/articles${params}`)
            .then(result => result.data)
            .then(result => {
                this.setState({
                    articles: result.data,
                    total: parseInt(result.total_count),
                    loading: false,
                });
            });

        api
            .get('/articles/publications')
            .then(response => {
                this.setState({
                    publications: response.data.publications
                })
            })
        this.fetchAuthors();

    };

    showModal = () => {
        this.setState({
            visible: true,
            editedArticleId: null,
            editedArticleIndex: null,
            title: '',
            link: '',
            publication: '',
            date: '',
            published: false,
            updateModalVisible: false,
            validateErrors: {},
            description: "",
            author: ''
        });
    };

    showUpdateModal = (article, index) => {
        console.log(article);
        this.setState({
            editedArticleId: article.id,
            editedArticleIndex: index,
            title: article.title,
            link: article.link,
            publication: article.publication,
            description: article.description,
            kluwer: article.kluwer,
            date: moment(article.date).startOf('day').format(),
            published: true,
            updateModalVisible: true,
            author: article.author,
            co_author: article.co_author,
            authors_id: article.authors_id,
        });
    };

    clearUpdateState = () => {
        this.setState({
            editedArticleId: null,
            editedArticleIndex: null,
            title: '',
            link: '',
            publication: '',
            date: '',
            published: false,
            updateModalVisible: false,
            validateErrors: {},
            author: "",
            co_author: ''
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            validateErrors: {}
        });
    };

    handleUpdateCancel = () => {
        this.clearUpdateState();
        this.setState({
            updateModalVisible: false,
        });
    };

    fetchAuthors = async () => {
        const requestAuthors = await api.get(`articles/authors/public`);
        this.setState({ authors: requestAuthors.data.authors });
    }
    isBoolean(n) {
        return typeof n === 'boolean';
    }

    handleSubmit = () => {
        const { title, link, publication, date, description, kluwer, author, co_author, authors_id } = this.state;
        if (title && link && date && this.isBoolean(kluwer) && (kluwer && !publication || !kluwer && publication) && description) {
            this.addNewArticle({ title, link, publication, date, description, kluwer, author, co_author, authors_id: `[${authors_id}]` });

            this.setState({
                visible: false,
                title: '',
                link: '',
                publication: '',
                date: '',
                description: '',
                kluwer: false,
                validateErrors: {},
                co_author: '',
                authors_id: ''
            });
        } else {
            this.setState({
                validateErrors: {
                    title: title ? false : true,
                    link: link ? false : true,
                    date: date ? false : true,
                    publication: publication ? false : true,
                    kluwer: kluwer !== true && kluwer !== false ? true : false,
                    description: description ? false : true
                }
            })
        }
    };

    handleUpdateSubmit = (user) => {
        const {
            editedArticleId,
            editedArticleIndex,
            title,
            link,
            publication,
            date,
            description,
            kluwer,
            author,
            co_author,
            authors_id,
        } = this.state;
        console.log(this.state);
        if (title && link && date && this.isBoolean(kluwer) && (kluwer && !publication || !kluwer && publication) && description) {
            const data = { title, link, publication, date, description, kluwer, author, co_author, authors_id: `[${authors_id}]`, published: true }
            console.log(data);
            this.updateArticle(editedArticleId, data, editedArticleIndex);
            this.clearUpdateState();
        } else {
            this.setState({
                validateErrors: {
                    title: title ? false : true,
                    link: link ? false : true,
                    date: date ? false : true,
                    publication: publication ? false : true,
                    kluwer: kluwer !== true && kluwer !== false ? true : false,
                    description: description ? false : true
                }
            })
        }
    };

    updateArticle = (id, data, index) => {
        api
            .patch(`/articles/${id}`, data)
            .then(() => this.fetchData(null, null));
    };

    addNewArticle = data => {
        this.setState({ loading: true })

        api
            .post(`/articles`, data)
            .then(() => this.clearFilters())
            .catch(() => this.setState({ loading: false }));
    }

    deleteArticle = (id, index) => {
        api
            .delete(`/articles/${id}`)
            .then(() => this.fetchData(null, null));
    }

    handlePaginationChange = (page, limit) => {
        window.scrollTo(0, 0);
        this.setState(
            {
                limit: limit,
                page: page,
            },
            () => this.fetchData(page, limit)
        );
    }

    clearFilters = () => {
        this.setState({
            filterStartDate: null,
            filterEndDate: null,
            filterPublication: '',
            filterTitle: '',
            filterAuthor: '',
        }, () => this.fetchData(null, null));
    }

    handleRangeChange = dates => {
        this.setState({
            filterStartDate:
                dates != null && dates.length === 2 ? dates[0] : null,
            filterEndDate:
                dates != null && dates.length === 2 ? dates[1] : null,
        });
    }

    handlePublishedChange(articleId, shouldBePublished, index) {
        api.patch(`/articles/${articleId}/publish`, {
            published: shouldBePublished,
        }).then(res => {
            if (res.status === 200) {
                this.setState(prevState => {
                    const articles = prevState.articles.map((article, i) => {
                        if (i === index) {
                            article.published = shouldBePublished;
                        }
                        return article;
                    });
                    return articles;
                });
            } else {
                Modal.warning({
                    title: "Authorization error",
                    content: "Authorization error. Only admin can change article's published status"
                })
            }
        });
    }

    columns = [
        {
            title: <div style={{ whiteSpace: 'nowrap' }}>Date</div>,
            dataIndex: 'date',
            key: 'date',
            render: renderDate
        },
        {
            title: 'Article',
            dataIndex: 'article',
            key: 'article',
            render: (_, article) => (
              <>
                <Tooltip
                  title={article.title}
                  block>
                  <p><b>Title: </b><a href={article.link} target="_blank" rel="noopener noreferrer">{article.title ? article.title.substring(0, article.title.length > 75 ? 75 : article.title.length) : ""}{article.title.length > 75 ? "..." : ""}</a></p>
                </Tooltip>
                <Tooltip
                  title={article.author}
                  block>
                  <p><b>Author: </b>{article.author ? article.author.substring(0, article.author.length > 75 ? 75 : article.author.length) : ""}{article.author.length > 75 ? "..." : ""}</p>
                </Tooltip>
                <Tooltip
                  title={article.co_author}
                  block>
                  <p><b>Co-author(s): </b>{article.co_author ? article.co_author.substring(0, article.co_author.length > 75 ? 75 : article.co_author.length) : ""}{article.co_author.length > 75 ? "..." : ""}</p>
                </Tooltip>
                <Tooltip
                  title={article.publication}
                  block>
                  <p><b>Publication: </b>{article.publication ? article.publication.substring(0, article.publication.length > 75 ? 75 : article.publication.length) : ""}{article.publication.length > 75 ? "..." : ""}</p>
                </Tooltip>                
                <p><b>Kluwer: </b>{article.kluwer ? 'Yes' : 'No'}
                </p>
              </>
            )
        },
        {
            title: <div style={{ whiteSpace: 'nowrap' }}>Upload Date</div>,
            dataIndex: 'upload_date',
            key: 'upload_date',
            render: renderDate
        },
        {

            title: <div style={{ whiteSpace: 'nowrap' }}>Published</div>,
            dataIndex: 'published',
            key: 'published',
            render: (_, row) => (
                row.published
                    ? <CheckCircleTwoTone twoToneColor="#52c41a" />
                    : <CloseCircleTwoTone twoToneColor="#f44242" />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            width: 150,
            render: (_, row, index) => {
                const menu = (
                    <Menu>
                        <Menu.Item>
                            <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={() => this.showUpdateModal(row, index)}
                            >
                                Edit
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                type="link"
                                className="link-btn"
                                style={{ padding: 0, marginTop: "0px !important" }}
                            >
                                <a href={row.link} target="_blank" rel="noopener noreferrer">
                                    View
                                </a>
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Popconfirm
                                title="Are you sure want to delete this article?"
                                onConfirm={() => this.deleteArticle(row.id, index)}
                                okText="Delete"
                                cancelText="Cancel"
                            >
                                <Button type="link" style={{ padding: 0 }}>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Menu.Item>
                        {row.published && this.props.isAdmin && (
                            <Menu.Item>
                                <Button
                                    type="link"
                                    onClick={() =>
                                        this.handlePublishedChange(row.id, false, index)
                                    }
                                    style={{ padding: 0 }}
                                >
                                    Unpublish
                                </Button>
                            </Menu.Item>

                        )}
                        {!row.published && this.props.isAdmin && (
                            <Menu.Item>
                                <Button
                                    type="link"
                                    onClick={() =>
                                        this.handlePublishedChange(row.id, true, index)
                                    }
                                    style={{ padding: 0 }}
                                >
                                    Publish
                                </Button>
                            </Menu.Item>
                        )}
                    </Menu>
                );
                return (
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            Actions <DownOutlined />
                        </a>
                    </Dropdown>
                );
            }
        },
    ];

    publicationColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 500
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, publication) => (
                <>
                    <Button onClick={async () => {
                        this.setState({
                            publicationTextToEdit: publication.name,
                            publicationIdToEdit: publication.id
                        })
                        this.handleToggleModalPublication()
                    }} style={{ paddingLeft: 0 }} type="link">Edit</Button>
                    <Popconfirm onConfirm={() => this.deletePublication(publication.id)} title={`Are you sure want to delete "${publication.name}"`}>
                        <Button style={{ paddingLeft: 0 }} type="link">Delete</Button>
                    </Popconfirm>
                </>
            )
        }
    ]

    beforeCvUpload(file) {
        const isPdf = file.type === "application/pdf";
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isPdf) {
            Modal.warning({
                title: "Uploading error",
                content: "You can only upload PDF file"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Uploading error",
                content: "CV must smaller than 30MB!"
            })
        }

        if (isPdf && isLt30M) {
            this.setState({
                uploadingFile: true
            }, () => {
                const formData = new FormData();
                formData.append("pdf", file);

                api
                    .post(`/articles/pdf`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => {
                        this.setState({
                            uploadingFile: false,
                            link: response.data.pdf
                        });
                    })
            })

            return false;
        }
    }

    displayPublicationModal() {
        this.setState({
            displayPublicationModal: true,
            publicationText: ""
        })
    }

    addPublication() {
        const { publicationText } = this.state;

        if (publicationText) {
            api
                .post('/articles/publications', { name: publicationText })
                .then(() => {
                    this.fetchPublications();
                    this.setState({
                        displayPublicationModal: false
                    })
                })
        }
    }

    deletePublication(id) {
        api
            .delete(`/articles/publications/${id}`)
            .then(() => this.fetchPublications())
    }

    fetchPublications(namePublication, page, limit) {
        api
            .get('/articles/publications', {
                params: {
                    name: namePublication,
                    page,
                    limit,
                }
            })
            .then(response => {
                this.setState({
                    publications: response.data.publications,
                    totalCountPublication: response.data.publications[0] ? response.data.publications[0].total_count : 0,
                    limitPublication: response.data.limit
                })
            })
    }

    handleToggleModalPublication = () => {
        this.setState(prevState => ({
            isPublicationEditModalVisible: !prevState.isPublicationEditModalVisible
        }));
    }
    handleEditPublication = (id, name) => {
        api
            .patch(`/articles/publications/${id}`, { name: name })
            .then(() => this.fetchPublications())
    }
    handleSearchPublications = async (namePublication) => {
        this.fetchPublications(namePublication);
    }
    handleChangePaginationPublication = (page, limit) => {
        const { namePublication } = this.state;
        window.scrollTo(0, 0);
        this.setState(
            {
                limit: page.pageSize,
                page: page.current,
            },
            () => this.fetchPublications(namePublication, page.current, page.pageSize)
        );
    }
    render() {
        const {
            loading,
            articles,
            filterStartDate,
            filterEndDate,
            filterTitle,
            filterAuthor,
            filterPublication,
            validateErrors,
            author,
            co_author,
            author_id,
        } = this.state;

        const {
            page,
            total,
            totalCountPublication,
            limit,
            title,
            link,
            publication,
            date,
            updateModalVisible,
            visible,
            publications,
            description,
            kluwer,
            uploadingFile,
            authors,
            isPublicationEditModalVisible,
            publicationTextToEdit,
            publicationIdToEdit,
            namePublication,
            limitPublication
        } = this.state;
        const { user } = this.props;

        const isAccoutSuspeded = user && user.membershipStatus && user.membershipStatus.is_suspended;
        const isAccountExpired = moment(user && user.membershipStatus && user.membershipStatus.expires_at).diff(moment().startOf('day'), 'days') < 0

        const isAccountNotActive = isAccoutSuspeded || isAccountExpired;

        const { modalVisible, displayPublicationModal, publicationText } = this.state;

        return (
            <>
                <Modal
                    visible={modalVisible}
                    bodyStyle={{ padding: 0, borderBottom: "unset" }}
                    closable={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({ modalVisible: false, namePublication: '' })
                        this.fetchPublications();
                    }}
                    maskClosable
                    width={900}
                >
                    <Card
                        title='Manage publications'
                        extra={
                            <Button type="primary" onClick={() => this.displayPublicationModal()}><PlusCircleOutlined />Add publication</Button>
                        }
                    >
                        <Form
                            style={{ marginBottom: 16 }}
                            name="horizontal_login"
                            layout="inline"
                            onFinish={() => this.handleSearchPublications(namePublication)}
                        >
                            <Form.Item
                                name="username"
                            >
                                {console.log(namePublication)}
                                <Input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.handleSearchPublications(e.target.value)
                                        }
                                    }}
                                    placeholder="Publication"
                                    value={namePublication}
                                    onChange={(e) => this.setState({ namePublication: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        <SearchOutlined />
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                        <CustomTable
                            columns={this.publicationColumns}
                            dataSource={publications}
                            pagination={{
                                pageSize: limitPublication,
                                total: totalCountPublication,
                            }}
                            onChangePaginationPublication={this.handleChangePaginationPublication}
                        />
                        <Button style={{ display: "block", marginLeft: "auto", marginTop: 25 }} type="primary" onClick={() => this.setState({ modalVisible: false })}>OK</Button>
                    </Card>
                </Modal>
                <Modal
                    visible={isPublicationEditModalVisible}
                    title="Edit Publication"
                    onCancel={() => this.handleToggleModalPublication}
                    onOk={() => {
                        this.handleEditPublication(publicationIdToEdit, publicationTextToEdit)
                        this.handleToggleModalPublication();
                    }}
                >
                    <Form
                        name="basic"
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Publication"
                        >
                            <Input
                                name="publication"
                                value={publicationTextToEdit}
                                onChange={(e) => this.setState({
                                    publicationTextToEdit: e.target.value
                                })}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <Content className="content">
                    {isAccountNotActive && (
                        <Tag
                            color="red"
                            style={{ width: '100%', padding: 15, fontSize: 14 }}
                        >
                            <WarningOutlined style={{ marginRight: 5 }} /> Your
                            account is inactive. You can't add new articles.
                            {isAccoutSuspeded && ' Please contact the admin to resolve this.'}
                            {!isAccoutSuspeded && isAccountExpired && <Link to="/payments" className="membership-link">{" "}Purchase membership here.</Link>}
                        </Tag>
                    )}
                    <div className="article-view-container">
                        <Spin spinning={loading}>
                            <Filters onClearFilters={this.clearFilters} onFinish={() => this.fetchData(null, null)}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div className="filter-title">
                                        Articles Date Range
                                    </div>
                                    <RangePicker
                                        value={(filterStartDate && filterEndDate) ? [
                                            moment(filterStartDate),
                                            moment(filterEndDate),
                                        ] : []}
                                        onCalendarChange={this.handleRangeChange}
                                        className="hide-mobile"
                                        format={shortDateFormat}
                                    />
                                    <div className="display-mobile">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <DatePicker
                                                value={filterStartDate ? moment(filterStartDate) : null}
                                                onChange={date => this.setState({
                                                    filterStartDate: date && moment(date).startOf('day').format(shortDateFormat)
                                                })}
                                                format={shortDateFormat}
                                            />
                                            <DatePicker
                                                value={filterEndDate ? moment(filterEndDate) : null}
                                                onChange={date => this.setState({
                                                    filterEndDate: date && moment(date).endOf('day').format(shortDateFormat)
                                                })}
                                                format={shortDateFormat}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Filter
                                    title="Title"
                                    placeholder="Title"
                                    value={filterTitle}
                                    onChange={e =>
                                        this.setState({ filterTitle: e })
                                    }
                                />
                                <Filter
                                    title="Author"
                                    placeholder="Author"
                                    value={filterAuthor}
                                    onChange={e =>
                                        this.setState({ filterAuthor: e })
                                    }
                                />
                                <Filter
                                    title="Publication"
                                    placeholder="Publication"
                                    value={filterPublication}
                                    type="select"
                                    onChange={e =>
                                        this.setState({ filterPublication: e })
                                    }
                                    options={publications}
                                    optionProperty={{
                                        key: "name",
                                        id: "name",
                                        display: "name"
                                    }}
                                />
                            </Filters>
                            <Card
                                className="custom-card"
                                title={
                                    <div className="custom-card-header blue-underline">
                                        Articles
                                    </div>
                                }
                                extra={
                                    <>
                                        {user.isAdmin() &&
                                            <Button type="link" onClick={() => this.setState({ modalVisible: true })}>
                                                Manage publications
                                            </Button>
                                        }
                                        <Button
                                            disabled={isAccountNotActive}
                                            icon={<PlusCircleOutlined />}
                                            type="primary"
                                            onClick={() => this.showModal()}
                                        >
                                            Add article
                                        </Button>
                                    </>
                                }
                            >
                                <CustomTable
                                    dataSource={articles}
                                    columns={this.columns}
                                    rowKey="id"
                                    pagination={false}
                                    style={{ wordBreak: 'break-all' }}
                                    locale={{ emptyText: <Empty /> }}
                                />

                                {articles.length === 0 ? null : (
                                    <Pagination
                                        showSizeChanger={false}
                                        style={{
                                            textAlign: 'center',
                                            padding: 10,
                                        }}
                                        defaultCurrent={1}
                                        current={page}
                                        total={total}
                                        pageSize={limit}
                                        onChange={(e, f) =>
                                            this.handlePaginationChange(e, f)
                                        }
                                    />
                                )}
                            </Card>
                            <Modal
                                visible={visible}
                                onCancel={this.handleCancel}
                                onOk={this.handleSubmit}
                                title="Add article"
                                destroyOnClose={true}
                                width={800}
                                className='add-article-modal'
                            >
                                <Form layout="vertical">
                                    <Form.Item label="Article Date" validateStatus={validateErrors.date && "error"} help={validateErrors.date && "Article Date is required"}>
                                        <DatePicker
                                            value={date && moment(date)}
                                            style={{ width: '100%' }}
                                            onChange={(e, date) =>
                                                this.setState({ date: date })
                                            }
                                            format={shortDateFormat}
                                        />
                                    </Form.Item>
                                    {!user.isAdmin() && (
                                        <Form.Item label="Author">
                                            <Select
                                                showSearch
                                                placeholder="Select Author"
                                                optionFilterProp="children"
                                                value={user.id}
                                                disabled
                                                onChange={(e, option) => {
                                                    const authorName = option.children.join();
                                                    this.setState({
                                                        author: authorName,
                                                        authors_id: e,
                                                        author_id: e
                                                    })
                                                }
                                                }
                                            >
                                                <Option value="">Select Author</Option>
                                                {authors.map(author => {
                                                    if (author.first_name || author.last_name) {
                                                        return <Option key={author.id} value={author.id}>
                                                            {author.first_name} {author.last_name}
                                                        </Option>
                                                    }
                                                }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {user.isAdmin() && (
                                        <Form.Item label="Author">
                                            <Select
                                                showSearch
                                                placeholder="Select Author"
                                                optionFilterProp="children"
                                                onChange={(e, option) => {
                                                    const authorName = option.children.join().replaceAll(',', '');
                                                    this.setState({
                                                        author: authorName,
                                                        authors_id: e,
                                                        author_id: e
                                                    })
                                                }
                                                }
                                                value={author_id}
                                            >
                                                <Option value="">Select Author</Option>
                                                {authors.map(author => {
                                                    if (author.first_name || author.last_name) {
                                                        return <Option key={author.id} value={author.id}>
                                                            {author.first_name} {author.last_name}
                                                        </Option>
                                                    }
                                                }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    <Form.Item label="Co-author(s) (Separate names with a semi-colon)">
                                        <Input
                                            value={co_author}
                                            style={{ width: '100%' }}
                                            onChange={e =>
                                                this.setState({ co_author: e.target.value })
                                            }
                                            placeholder="Enter co-author(s) separated with a semi-colon"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Title" validateStatus={validateErrors.title && "error"} help={validateErrors.title && "Title is required"}>
                                        <Input
                                            placeholder="Enter article title"
                                            value={title}
                                            onChange={e =>
                                                this.setState({
                                                    title: e.target.value,
                                                })
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <div>
                                                Kluwer Arbitration or Mediation Blog {" "}
                                                <Tooltip title="Kluwer blogs are listed on the AW-Kluwer page here https://www.arbitralwomen.org/arbitralwomen-kluwer-arbitration-blog/">
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }
                                        validateStatus={validateErrors.kluwer && "error"}
                                        help={validateErrors.kluwer && "Kluwer is required"}
                                    >
                                        <Radio.Group
                                            onChange={e => {
                                                this.setState({ kluwer: e.target.value })
                                                const authorFind = authors.find(author => author.id === user.id);
                                                const authorName = `${authorFind.author_detail.first_name} ${authorFind.author_detail.last_name}`;
                                                if (!user.isAdmin()) {
                                                    this.setState({
                                                        author: authorName, authors_id: user.id,
                                                        author_id: user.id
                                                    })
                                                }
                                            }}
                                        >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="Publication"
                                        validateStatus={!kluwer && validateErrors.publication && "error"}
                                        help={!kluwer && validateErrors.publication && "Publication is required"}
                                    >
                                        <Select
                                            disabled={kluwer}
                                            value={publication && !kluwer ? publication : null}
                                            onChange={e =>
                                                this.setState({
                                                    publication: e,
                                                })
                                            }
                                            filterOption={(inputValue, option) =>
                                                option && option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            showSearch
                                            placeholder="Select publication"
                                            notFoundContent={
                                                <Row>
                                                    <Col span={24} offset={10} >
                                                        <Button type="primary" onClick={() => this.displayPublicationModal()}>Add publication</Button>
                                                    </Col>
                                                </Row>

                                            }
                                        >
                                            {publications.map(p => (
                                                <Select.Option key={p.name} value={p.name}>{p.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Link (You can add a link and/or upload a PDF file)"
                                        validateStatus={validateErrors.link && "error"}
                                        help={validateErrors.link && "Link is required"}
                                    >
                                        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
                                            <Input
                                                value={link}
                                                placeholder="Enter article's URL address"
                                                onChange={e =>
                                                    this.setState({
                                                        link: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label="PDF Upload (You can add a link and/or upload a PDF file)"
                                        validateStatus={validateErrors.link && "error"}
                                        help={validateErrors.link && "Link is required"}
                                    >
                                        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
                                            <Upload
                                                fileList={[]}
                                                beforeUpload={this.beforeCvUpload.bind(this)}
                                            >
                                                <Button>{uploadingFile && <LoadingOutlined />}Upload PDF</Button>
                                            </Upload>
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        validateStatus={validateErrors.description && "error"}
                                        help={validateErrors.description && "Description is required"}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Description is required',
                                            },
                                        ]}
                                        label="Abstract">
                                        <ReactQuill
                                            theme="snow"
                                            value={description || ""}
                                            style={{ height: '150px' }}
                                            onChange={e =>
                                                this.setState({
                                                    description: e,
                                                })
                                            }
                                        />
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Modal
                                visible={updateModalVisible}
                                onCancel={this.handleUpdateCancel}
                                onOk={() => this.handleUpdateSubmit(user)}
                                title="Edit article"
                                destroyOnClose={true}
                                width={800}
                                className='edit-article-modal'
                            >
                                <Form layout="vertical">
                                    <Form.Item label="Article Date" validateStatus={validateErrors.date && "error"} help={validateErrors.date && "Article Date is required"}>
                                        <DatePicker
                                            value={date ? moment(date) : null}
                                            style={{ width: '100%' }}
                                            onChange={(e, date) =>
                                                this.setState({ date })
                                            }
                                            format={shortDateFormat}
                                        />
                                    </Form.Item>
                                    {!user.isAdmin() && (
                                        <Form.Item label="Author">
                                            <Select
                                                showSearch
                                                placeholder="Select Author"
                                                optionFilterProp="children"
                                                value={user.id}
                                                disabled
                                                onChange={(e, option) => {
                                                    const authorName = option.children.join();
                                                    this.setState({
                                                        author: authorName,
                                                        authors_id: e,
                                                        author_id: e
                                                    })
                                                }
                                                }
                                            >
                                                <Option value="">Select Author</Option>
                                                {authors.map(author => {
                                                    if (author.first_name || author.last_name) {
                                                        return <Option key={author.id} value={author.id}>
                                                            {author.first_name} {author.last_name}
                                                        </Option>
                                                    }
                                                }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    {user.isAdmin() && (
                                        <Form.Item label="Author">
                                            <Select
                                                showSearch
                                                placeholder="Select Author"
                                                optionFilterProp="children"
                                                onChange={(e, option) => {
                                                    const authorName = option.children.join().replaceAll(',', '');
                                                    this.setState({
                                                        author: authorName,
                                                        authors_id: e,
                                                        author_id: e
                                                    })
                                                }
                                                }
                                                value={author}
                                            >
                                                <Option value="">Select Author</Option>
                                                {authors.map(author => {
                                                    if (author.first_name || author.last_name) {
                                                        return <Option key={author.id} value={author.id}>
                                                            {author.first_name} {author.last_name}
                                                        </Option>
                                                    }
                                                }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    )}
                                    <Form.Item label="Co-author(s) (Separate names with a semi-colon)">
                                        <Input
                                            value={co_author}
                                            style={{ width: '100%' }}
                                            onChange={e =>
                                                this.setState({ co_author: e.target.value })
                                            }
                                            placeholder="Enter co-author(s) separated with a semi-colon"
                                        />
                                    </Form.Item>
                                    <Form.Item label="Title" validateStatus={validateErrors.title && "error"} help={validateErrors.title && "Title is required"}>
                                        <Input
                                            placeholder="Enter article title"
                                            value={title}
                                            onChange={e =>
                                                this.setState({
                                                    title: e.target.value,
                                                })
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <div>
                                                Kluwer {" "}
                                                <Tooltip title="Check this box if your article was published on the Kluwer Arbitration Blog">
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }
                                        validateStatus={validateErrors.kluwer && "error"}
                                        help={validateErrors.kluwer && "Kluwer is required"}
                                    >
                                        <Radio.Group
                                            defaultValue={kluwer}
                                            onChange={e => this.setState({ kluwer: e.target.value })}
                                        >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="Publication"
                                        validateStatus={!kluwer && validateErrors.publication && "error"}
                                        help={!kluwer && validateErrors.publication && "Publication is required"}
                                    >
                                        <Select
                                            disabled={kluwer}
                                            value={publication ? publication : undefined}
                                            onChange={e =>
                                                this.setState({
                                                    publication: e,
                                                })
                                            }
                                            filterOption={(inputValue, option) =>
                                                option && option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            showSearch
                                            placeholder="Publication"
                                        >
                                            {publications.map(p => (
                                                <Select.Option key={p.name} value={p.name}>{p.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Link"
                                        validateStatus={validateErrors.link && "error"}
                                        help={validateErrors.link && "Link is required"}
                                    >
                                        <div style={{ display: "flex", flexFlow: "row nowrap" }}>
                                            <Input
                                                value={link}
                                                placeholder="Enter article's URL address"
                                                onChange={e =>
                                                    this.setState({
                                                        link: e.target.value,
                                                    })
                                                }
                                            />
                                            <Upload
                                                beforeUpload={this.beforeCvUpload.bind(this)}
                                                fileList={[]}
                                            >
                                                <Button>{uploadingFile && <LoadingOutlined />}Upload PDF</Button>
                                            </Upload>
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        validateStatus={validateErrors.description && "error"}
                                        help={validateErrors.description && "Description is required"}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Description is required',
                                            },
                                        ]}
                                        label="Abstract">
                                        <ReactQuill
                                            theme="snow"
                                            value={description || ""}
                                            style={{ height: '150px' }}
                                            onChange={e =>
                                                this.setState({
                                                    description: e,
                                                })
                                            }
                                        />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Spin>
                    </div>
                </Content>
                <Modal
                    visible={displayPublicationModal}
                    onOk={() => this.addPublication()}
                    onCancel={() => this.setState({ displayPublicationModal: false })}
                    title="Add publication"
                    width={450}
                >
                    <Input
                        placeholder='New publication name'
                        value={publicationText}
                        onChange={(e) => {
                            this.setState({
                                publicationText: e.target.value
                            })
                        }}
                    />
                </Modal>
            </>
        );
    }
}

export const ArticlesTable = withUser(ArticlesTableInner);
