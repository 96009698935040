import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Spin,
  Card,
  Button,
  Popconfirm,
  Table,
  Checkbox,
  Popover,
  Space,
  Modal,
  List,
  Typography,
  Tooltip
} from "antd";
import { api } from "../../api";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { parseEventTypeIdToEventTypeName } from "../../utils/event";
import parse from "html-react-parser";
import { formatLongDate } from "../../utils";

export const EventsApprovation = forwardRef(({ fetchEvents, editEvent }, ref) => {
  const [eventsPending, setEventsPending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalConfirmIsVisibile, setModalConfirmIsVisible] = useState(false);
  const [action, setAction] = useState("");
  const [eventsSelected, setEventsSelected] = useState([]);
  const [confirmModalLoading, setConfirmModalLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    getEvents
  }));

  function handleToggleModal(modalType) {
    setModalConfirmIsVisible((prevState) => !prevState);
    setAction(modalType);
  }

  function handleEditEvent(id) {
    editEvent(id);
  }

  function handleSelectEvent(eventReceived) {
    let eventsUpdated;
    if (eventsSelected.find(event => event.id === eventReceived.id)) {
      eventsUpdated = eventsSelected.filter(
        (event) => eventReceived.id !== event.id
      );
      setEventsSelected(eventsUpdated);
      setEventsPending((prevState) => prevState.map(event => {
        console.log(prevState);
        if (event.id === eventReceived.id) {
          return {
            ...event,
            selected: false
          }
        }
        return event;
      }))
    } else {
      setEventsSelected((prevState) => [...prevState, { ...eventReceived, selected: true }]);
      setEventsPending((prevState) => prevState.map(event => {
        if (event.id === eventReceived.id) {
          return {
            ...event,
            selected: true
          }
        }
        return event;
      }))
    }

  }

  async function handleUpdateEvents(statusToUpdate) {
    setConfirmModalLoading(true);
    const requests = eventsSelected.map((event) => {
      return updateEvent(event, statusToUpdate);
    });
    await Promise.all(requests);
    await fetchEvents();
    await getEvents();
    setConfirmModalLoading(false);
    setModalConfirmIsVisible(false);
    setEventsSelected([]);
  }

  async function updateEvent(event, statusToUpdate) {
    const request = await api.patch(`/events/${event.id}`, {
      status: statusToUpdate,
    });
    if (request.status) {
      const eventsPendingUpdated = eventsPending.filter(
        (oldEvent) => !eventsSelected.includes(oldEvent)
      );
      setEventsPending(eventsPendingUpdated);
    }
  }
  function handleDeleteEvent(id) {
    api.delete(`/events/${id}`).then((response) => {
      if (response.data.success) {
        const eventsPendingUpdated = eventsPending.filter(
          (event) => event.id !== id
        );
        setEventsPending(eventsPendingUpdated);
      }
    });
  }
  async function getEvents() {
    const request = await api.get(`/events?status=pending&sort_by=start_date&order_by=asc`);
    const { events } = request.data;
    const eventsFormatted = events.map((event) => {
      return {
        key: event.id,
        id: event.id,
        currency: event.currency,
        name: event.name,
        location: event.location,
        created_at: event.created_at
          ? formatLongDate(event.created_at)
          : event.created_at,
        startdate: event.startdate
          ? formatLongDate(event.startdate)
          : event.startdate,
        enddate: event.enddate
          ? formatLongDate(event.enddate)
          : event.enddate,
        arbitralwomenparticipation: event.arbitralwomenparticipation,
        registered_users: event.registered_users,
        eventtype: parseEventTypeIdToEventTypeName(event.eventtypeid),
        registration_email: event.registration_email,
        registration_link: event.registration_link,
        flyer_url: event.flyer_url,
        status: event.status,
        notes: event.notes,
        user: event.user,
        selected: false
      };
    });
    setEventsPending(eventsFormatted);
    setLoading(false);
  }
  useEffect(() => {
    getEvents();
  }, [])
  useEffect(() => {
  }, [eventsPending])
  const COLUMNS_TABLE = [
    {
      title: "Event",
      dataIndex: "name",
      key: "name",
      render: (_, event) => (
        <>
          <Tooltip
            title={event.name}
            block>
            <p><b>Title: </b>{event.name ? event.name.substring(0, event.name.length > 75 ? 75 : event.name.length) : ""}{event.name.length > 75 ? "..." : ""}</p>
          </Tooltip>
          <p><b>Type: </b>{event.eventtype}</p>
          <p><b>From </b>{formatLongDate(event.startdate)} <b>to</b> {formatLongDate(event.enddate)}</p>

          {event.location &&
            <Tooltip title={event.location}>
              <p><b>Location: </b>{event.location ? event.location.substring(0, event.location.length > 75 ? 75 : event.location.length) : ""}{event.location.length > 75 ? "..." : ""}</p>
            </Tooltip>
          }

          {Array.isArray(event.arbitralwomenparticipation) &&
            <p><b>AW Speakers: </b>
              {event.arbitralwomenparticipation.map((participant, index) => (
                <span key={participant}>{participant}{(index + 1) % 2 === 0 ? <br /> : (index + 1) < event.arbitralwomenparticipation.length ? ", " : ""}</span>
              ))}
            </p>
          }

          {event.registration_link &&
            <Tooltip title={event.registration_link}>
              <p><b>Registration: </b><a href={event.registration_link} target="_blank" rel="noopener noreferrer">{event.registration_link ? event.registration_link.substring(0, event.registration_link.length > 75 ? 75 : event.registration_link.length) : ""}{event.registration_link.length > 75 ? "..." : ""}</a></p>
            </Tooltip>
          }

          {event.registration_email &&
            <Tooltip title={event.registration_email}>
              <p><b>Registration Email: </b>{event.registration_email ? event.registration_email.substring(0, event.registration_email.length > 75 ? 75 : event.registration_email.length) : ""}{event.registration_email.length > 75 ? "..." : ""}</p>
            </Tooltip>
          }

          {event.notes &&
            <p><b>Notes: </b>
              <Popover content={parse(event.notes)} title="Notes">
                <CommentOutlined />
              </Popover>
            </p>
          }
        </>
      )
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      render: (_, { user }) => (
        <p>{`${user.first_name} ${user.last_name}`}</p>
      ),
      width: 150,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      key: "startdate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, event) => (
        <>
          <Button
            type="link"
            style={{ display: "block" }}
            onClick={() => handleEditEvent(event.id)}
            className="hide-mobile"
          >
            Edit
          </Button>
          {event.eventtype !== "Non-ArbitralWomen Event" &&
            event.eventtype !== "YAWP Event" && (
              <Button
                type="link"
                onClick={() => this.viewParticipants(event.id, event)}
              >
                View participants
              </Button>
            )}
          {event.flyer_url && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={event.flyer_url}
              style={{ fontSize: 15 }}
            >
              <Button type="link">View flyer</Button>
            </a>
          )}
          <Popconfirm
            title={`Are you sure want to delete event "${event.name}"?`}
            onConfirm={() => handleDeleteEvent(event.id)}
          >
            <Button type="link" block style={{ textAlign: "left" }}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
    {
      title: "Approve/Reject",
      dataIndex: "approve",
      key: "approve",
      render: (_, event) => (
        <Checkbox checked={event.selected} onChange={() => handleSelectEvent(event)}></Checkbox>
      ),
      width: 50,
    },
  ];
  return (
    <Spin spinning={loading}>
      <Content className="content">
        <Card
          className="filter-card-header"
          title={
            <span className="filters-header blue-underline">
              New event(s) created by member(s) pending approval
            </span>
          }
          extra={
            <Space>
              <Button
                disabled={eventsSelected.length === 0 ? true : false}
                type="primary"
                icon={<CheckCircleOutlined />}
                onClick={() => handleToggleModal("approve")}
              >
                Approve
              </Button>
              <Button
                disabled={eventsSelected.length === 0 ? true : false}
                type="default"
                icon={<CloseCircleOutlined />}
                onClick={() => handleToggleModal("reject")}
              >
                Reject
              </Button>
            </Space>
          }
        >
          <Table
            scroll={{ x: "max-content" }}
            dataSource={eventsPending}
            columns={COLUMNS_TABLE}
          />
        </Card>
        <Modal
          title="Comment"
          visible={modalConfirmIsVisibile}
          onOk={() =>
            handleUpdateEvents(action === "approve" ? "approved" : "rejected")
          }
          onCancel={handleToggleModal}
          confirmLoading={confirmModalLoading}
        >
          Do you really want to{" "}
          {action === "approve" ? "approve" : "reject"} the event below ?
          <List
            style={{ marginTop: "1rem" }}
            bordered
            dataSource={eventsSelected}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text mark></Typography.Text> {item.name}
              </List.Item>
            )}
          />
        </Modal>
      </Content>
    </Spin>
  );
});
