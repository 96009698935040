import React from 'react';
import { Input } from 'antd';

export function SponsorshipContent({ sponsorship }) {
    return (
        <>
            <p>{sponsorship.name} €{sponsorship.price}</p>
            <p style={{ marginBottom: 0 }}>Description:</p>
            <span style={{ color: "#999" }}>{sponsorship.description}</span>
        </>
    )
}

export function SponsorshipModal({ editedField, onUpdate }) {
    return (
        <>
            <p style={{ marginBottom: "5px" }}>Name</p>
            <Input value={editedField.name} onChange={e => onUpdate("name", e.target.value)}/>

            <p className="margin-top-20" style={{ marginBottom: "5px" }}>Price</p>
            <Input type="number" value={editedField.price} onChange={e => onUpdate("price", e.target.value)}/>

            <p className="margin-top-20" style={{ marginBottom: "5px" }}>Description</p>
            <Input.TextArea value={editedField.description} onChange={e => onUpdate("description", e.target.value)}/>
        </>
    )
}