import React, { Component } from 'react'
import { Modal } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import { formatShortDate } from '../utils';

const Transaction = ({t}) => (<>
    <div>
        {t.typeDescription}
        <span style={{float: 'right'}}>€{(t.cost || 0).toFixed(2)}</span>
    </div>
    <div style={{clean: 'both'}}>
        {t.users.map(u => <div key={u.id} style={{marginLeft: '30px', color: '#aaa'}}>{u.name}</div>)}
    </div>
</>)

export default class ProlongateModal extends Component {    
    render() {
        const {
            prolongateModal, 
            handleChange, 
            loadingProlongateCost, 
            handleProlongate, 
            prolongate
        } = this.props;

        return (
            <Modal
                visible={prolongateModal}
                title="Extend current memberships for one year"
                closable={false}
                onCancel={() => handleChange(false,"prolongateModal")}
                onOk={handleProlongate}
                okText="Pay with PayPal"
                width={700}
        >
            Extend all memberships which have been purchased by your firm. Individual memberships will not be extended. After succesfull payment you can purchase missing individual memberships which will be valid up to the next renewal date. All deferred payments will be included in this extension.
            {!loadingProlongateCost ? (
                <div style={{ marginTop: 15 }}>
                    <>
                        Membership will be valid up to: {formatShortDate(prolongate.newRenewalDate)}
                        <br /><br />
                        <h4>Payment details</h4>
                        <div>
                            {prolongate && prolongate.transactions.map((t, i) => <Transaction key={i} t={t}></Transaction>)}
                        </div>

                        <div>Total: <strong style={{float: 'right'}}>€{prolongate.prolongationCost.toFixed(2)}</strong></div>

                        

                    </>
                </div>
            ) : (
                <LoadingOutlined />
            )}
        </Modal>
        )
    }
}
