import React, { useState, useEffect } from 'react'
import { Card, Select, Modal, Button, Statistic, Row, Col } from "antd";
import { api } from '../api';
import _ from "lodash"
import { formatShortDate } from '../utils';

const { Option } = Select;

const CorporationJoinRequestCard = ({user}) => {
    const [corporationJoinRequests,setCorporationJoinRequests] = useState([]);
    const [corporations,setCorporations] = useState([]);
    const [corporationId,setCorporationId] = useState(null);
    const [corporationName,setCorporationName] = useState(null);
    const [visible,setVisible] = useState(false);
    const [isCorporateMember, setIsCorporateMember] = useState(null);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const result = await Promise.all(
            [
                api.get(`/corporationJoinRequest/user/${user.id}`),
                api.get(`/corporations?limit=0`),
            ]
        )
        
        const joinRequest = await filterJoinRequest((result && result[0] && result[0].data && result[0].data.result) || []);
        setCorporations((result && result[1] && result[1].data && result[1].data.corporations) || []);
        const corporateProfile = !_.isEmpty(joinRequest) &&  await api.get(`/corporations/${joinRequest.corporation_id}/user/${user.id}`)
                                          .then(res=>res.data)
                                          .then(res=>res.corporateProfile)

        setIsCorporateMember(!_.isEmpty(corporateProfile) && corporateProfile.corporation_id === joinRequest.corporation_id)
    }

    const handleWithdraw = e => {
        e.preventDefault();
        api.delete(`/corporationJoinRequest/${corporationJoinRequests.requestid}`).then(
            () =>{fetchData()}
        )
    }
    const filterJoinRequest = async  joinRequests => {

        const filtered = _.chain(joinRequests).orderBy(['created_at'],['desc']).head().value();
        setCorporationJoinRequests(filtered);
        return filtered;

    }
    const changeCorporationName = e => {
        const corporation = corporations && corporations.find(c => parseInt(c.id) === parseInt(e));
        setCorporationId(e);
        setCorporationName(corporation && corporation.name);
    }
    const handleJoinRequest = e => {
        e.preventDefault();
        api.post(`/corporationJoinRequest/corporations/${corporationId}/user/${user.id}`).then(
            () =>{fetchData()}
        )
        setVisible(false);
    }

    const handleStatus = () => {
        const status = !_.isEmpty(corporationJoinRequests) && corporationJoinRequests.approval_status; 
        const value = (_.isNull(status) && "Waiting for approval") || (status === false ? 'Rejected' 
            :((status === true && isCorporateMember) ? "Approved" : "Removed" ));
        const color =(_.isNull(status) && {color: ""} ) 
                                       || (status === false ? {color :'#cf1322'} 
                                                          : ((status === true && isCorporateMember) ? {color:'#3f8600'} 
                                                                                    :{color :'#cf1322'}));
                  
        return { value, color };
    }

    return (
      <>
        <Modal
          className="payment-modal"
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <h3>
            You are requesting to join {corporationName}. Your
            request will be sent to {corporationName}.
          </h3>
          <div style={{ paddingTop: 25 }} className="payment-modal-buttons">
            <Button
              style={{ paddingLeft: 0, color: "#737373" }}
              type="link"
              size="large"
              className="button-cancel"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="button-confirm"
              onClick={handleJoinRequest}
            >
              Confirm
            </Button>
          </div>
        </Modal>
        <Card
          className="custom-card"
          extra={  !isCorporateMember && !_.isEmpty(corporationJoinRequests)  &&  
            <Row gutter={16}>
                <Button onClick={e => handleWithdraw(e)} type="link">Withdraw request</Button>
             </Row> }
          title={
            <>
              <div className="custom-card-header blue-underline">
                Existing corporate membership
              </div>
            </>
          }
        >
          {_.isEmpty(corporationJoinRequests) && (
            <>
              <p>
                If you would like to join to corporate, please request to join in the dropdown below
              </p>
              <Row gutter={[8,0]}>
                <Col md={6} span={24}>
                  <Select
                    className="company-request-select"
                    placeholder="Select company"
                    optionFilterProp="children"
                    onChange={e => changeCorporationName(e)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    allowClear
                  >
                    {corporations &&
                      corporations.map(company => (
                        <Option value={company.id}>
                          {company.name}
                        </Option>
                      ))}
                  </Select>
                </Col>
                <Col md={6} span={24}>
                  <Button
                    type="primary"
                    disabled={!corporationId}
                    ghost
                    onClick={() => setVisible(true)}
                  >
                    Send a request to join
                  </Button>
                </Col>
              </Row>
              

              
            </>
          )}
          {isCorporateMember  && !_.isEmpty(corporationJoinRequests)  &&<>
            <Row gutter={16}>
              
                <Col span={12}>
                  <Statistic 
                    title="Corporate name" 
                    value={corporationJoinRequests.name} />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Request status"
                    value={handleStatus().value}
                    valueStyle={handleStatus().color}
                  />
                </Col>
              </Row>
            </>
        }      
          {!isCorporateMember && !_.isEmpty(corporationJoinRequests) && (
            <>
              <Row gutter={[16,16]}>
                <Col md={8} span={24}>
                  <Statistic 
                    title="Corporate name" 
                    value={corporationJoinRequests.name} />
                </Col>
                <Col md={8} span={24}>
                  <Statistic
                    title="Request date"
                    value={formatShortDate(corporationJoinRequests.created_at)}
                  />
                </Col>   
                <Col md={8} span={24}>
                  <Statistic
                    title="Request status"
                    value={handleStatus().value}
                    valueStyle={handleStatus().color}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card>
      </>
    );
}
export default CorporationJoinRequestCard;