import React from 'react';
import { Tabs, Spin, Layout, Modal, Card } from 'antd';
import { PaymentMemberHistory } from '../components/PaymentMemberHistory';
import { CorporationMembers } from '../components/CorporationMembers';
import { withUser } from '../components/UserContext';
import { CorporationsRequests } from '../components/CorporationsRequests';
import { CorporationDetails } from '../components/corporation/CorporationDetails';
import { api } from '../api';
import '../styles/ProfileView.css';
import CorporationMembershipsInfo from '../components/CorporationMembershipsInfo';
import { MembershipsTable } from '../components/corporation';

const { TabPane } = Tabs;
const { Content } = Layout;

class CorporationsMemberViewInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            unassignedSlots: null,
            isLoading: true,
            corporation: {},
            members: [],
            activeKey: '1',
            prolongation: null,
            membersPaymentCount: 0,
            isPurchaseRequested: false,
            refreshTable: false,
            inactiveMembers: null,
            subscription:null,
            triggerModal: false,
            recordMembershipStatus: null,
            record: null,
            estimatedCost: null,
            selectedMembers: []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Corporate');
        this.fetchCorporation();
    }

    getCorporationId = () => this.props.corporationId && this.props.user.isAdmin()
        ? this.props.corporationId
        : this.props.user.corporationId;

    fetchCorporation = async () => {
        this.setState({ isLoading: true });

        const corporationId = this.getCorporationId();

        try {
            const resp = await Promise.all([
                api.get(`/corporations/${corporationId}`),
                api.get(`/corporations/${corporationId}/members`),
                api.get(`/corporations/purchased-slots/${corporationId}`),
                api.get(`/corporations/${corporationId}/members-payment`), 
                api.get(`/payments/subscription/${this.props.user.id}`)
            ]);

            this.setState({
                corporation: resp[0] && resp[0].data && resp[0].data.data,
                members: resp[1] && resp[1].data && resp[1].data.members,
                unassignedSlots:
                    resp[2] && resp[2].data && resp[2].data.unassignedSlots,
                inactiveMembers:
                    resp[2] && resp[2].data && resp[2].data.inactiveMembers,
                activeMembers:
                    resp[2] && resp[2].data && resp[2].data.activeMembers,
                renewalDate:
                    resp[2] && resp[2].data && resp[2].data.renewaldate,
                membersPayment: resp[3] && resp[3].data && resp[3].data.data,
                subscription: resp[4] && resp[4].data && resp[4].data.subscriptions,
                isLoading: false,
                refreshTable: false
            });
        } catch (error) {
            console.log(error);
        }
    };

    fetchSubscription = async () => {
        this.setState({ isLoading: true });
        try {
            const resp = await api.get(
                `/payments/subscription/${this.props.user.id}`
            );

            this.setState({
                subscription: resp&& resp.data && resp.data.subscriptions,
                isLoading: false,
                refreshTable: false,
            });
        } catch (error) {
            console.log(error);
        }
    }

    fetchUserMembershipStatus = id => {
        api
            .post(`/users/${id}/membership-status`)
            .then(res => res.data)
            .then(res => res.data)
            .then(res => {
                this.setState({
                    recordMembershipStatus: res.membershipStatus
                })
            })
    }

    getEstimatedPrice = async id => {
        const corporationId = this.getCorporationId();

        await api
            .get(`/payments/estimated-payment/${corporationId}/user/${id}`)
            .then(res => {
                this.setState({
                    estimatedCost: res.data.price,
                });
            });
        };

    fetchPurchasedSlots = async () => {
        this.setState({ isLoading: true });

        const corporationId = this.getCorporationId();

        try {
            const resp = await api.get(
                `corporations/purchased-slots/${corporationId}`
            );

            this.setState({
                unassignedSlots: resp.data && resp.data.unassignedSlots,
                inactiveMembers: resp.data && resp.data.inactiveMembers,
                activeMembers: resp.data && resp.data.activeMembers,
                renewalDate: resp.data && resp.data.renewaldate,

                isLoading: false,
                refreshTable: false,
            });
        } catch (error) {
            console.log(error);
        }
    };

    handleEditData = values => {
        this.setState({ isLoading: true });
        const { corporation } = this.state;
        api.patch('/corporations', {
            ...values,
            id: corporation && corporation.id,
        }).then(() => this.setState({ isLoading: false }));
    };

    clearPurchaseRequest = () => {
        this.setState({ isPurchaseRequested: false });
    };

    handleEditNameDate = (name, establishDate) => {
        this.setState({ isLoading: true });
        const { corporation } = this.state;
        api.patch('/corporations/name-date', {
            name,
            establishDate,
            id: corporation && corporation.id,
        }).then(() => this.fetchCorporation());
    };

    deleteContactPerson = email => {
        this.setState({ isLoading: true });

        const corporationId = this.getCorporationId();

        api.delete(`/corporations/${corporationId}/contact-persons/${email}`).then(() => this.fetchCorporation());
    };
    
    handleAddMember = async (name, email, type) => {
        this.setState({ isLoading: true });

        const corporationId = this.getCorporationId();

        try {
            await api.post(`/corporations/${corporationId}/members`, { name, email, type });
            Modal.success({ content: type === "member" ? "Invitation has been sent" : "Affiliate has been added"});
        } catch (error) {
            if(error.response.data && error.response.data.error && typeof(error.response.data.error) === 'string') {
                Modal.warning({ content: error.response.data.error })
            } else {
                Modal.warning({ content: 'Unknown error' });
            }
        } finally {
            this.fetchCorporation();
        }
    };

    handleEditMember = async (id, name, email) => {
        this.setState({ isLoading: true });

        const corporationId = this.getCorporationId();

        try {
            await api.patch(
                `/corporations/${corporationId}/members/${id}`,
                { name, email }
            );
            this.fetchCorporation();
        } catch (error) {
            console.log(error);
        }
    };

    handleTabChange = k => {
        this.setState({ activeKey: `${k}` });
    };

    handleRefresh = () => {
        this.setState({ refreshTable: true }, () => this.fetchCorporation());
    };

    handleProlongateRequest = data => {
        this.setState({
            prolongation: data,
            isPurchaseRequested: true,
        });
    };

    handleMissingRequest = data => {
        this.setState({
            missing: data,
            isPurchaseRequested: true
        })
    }

    handleSlotsButtonPurchase = count => {
        this.setState({
            membersPaymentCount: count,
            isPurchaseRequested: true,
        });
    };

    handleDeleteMember = (id,withMembership) => {
        this.setState({ isLoading: true });
        
        if (!id) {
            return this.fetchCorporation();
        }

        const corporationId = this.getCorporationId();

        api
            .delete(`/corporations/${corporationId}/members/${id}?removeMembership=${withMembership}`)
            .then(() => this.fetchCorporation());
    }; 

    prepareModalForExistingUser = (user) => {
        this.fetchUserMembershipStatus(user.id);
        this.createNewJoinRequest(user.id);
        this.getEstimatedPrice(user.id);
        this.changeExistingMemberModalVisibility(true);
    };
    
    createNewJoinRequest = (userId) => {
        const corporationId = this.getCorporationId();

        api
            .post(`corporationJoinRequest/corporations/${corporationId}/user/${userId}`)
            .then(resp => this.handleChangeRecord(resp.data.newRequest));
    }

    changeExistingMemberModalVisibility = (shouldBeVisible) => {
        this.setState({
            triggerModal: shouldBeVisible
        })
    }

    handleChangeRecord = (newRecord) => {
        this.setState({
            record: newRecord
        })
    }

    render() {
        const {
            isLoading,
            members,
            unassignedSlots,
            renewalDate,
            membersPayment,
            activeKey,
            membersPaymentCount,
            isPurchaseRequested,
            inactiveMembers,
            prolongation,
            subscription,
            triggerModal,
            recordMembershipStatus,
            record,
            estimatedCost,
            missing,
            selectedMembers
        } = this.state;
        const { user } = this.props;

        const corporationId = this.getCorporationId();

        return (
            <div style={{backgroundColor: '#f4faff'}}>
                <Spin spinning={isLoading}>
                    <Tabs
                        defaultActiveKey="1"
                        activeKey={activeKey}
                        onChange={this.callback}
                        size="large"
                        animated={false}
                        tabBarStyle={{ margin: 0 }}
                        centered
                        onTabClick={(k, e) => this.handleTabChange(k, e)}
                    >
                        <TabPane
                            tab="Corporate info"
                            key="1"
                            className="profile__pane"
                        >
                            <Content className="content">
                                <CorporationDetails
                                    canEditName={user.isAdmin()}
                                    corporationId={corporationId}
                                />
                            </Content>
                        </TabPane>

                        <TabPane
                            tab="Corporate members"
                            key="2"
                            className="profile__pane"
                        >
                            <Content className="content">
                                <CorporationMembershipsInfo
                                    user={user}
                                    members={members}
                                    avaliableSlots={unassignedSlots}
                                    inactiveMembers={inactiveMembers}
                                    subscription={subscription}
                                    renewalDate={renewalDate}
                                    corporationId={corporationId}
                                    handleTabChange={this.handleTabChange}
                                    handleSlotsButtonPurchase={
                                        this.handleSlotsButtonPurchase
                                    }
                                    handleProlongateRequest={
                                        this.handleProlongateRequest
                                    }
                                    handleMissingRequest={this.handleMissingRequest}
                                    fetchSubscription={this.fetchSubscription}
                                    selectedMembers={selectedMembers}
                                />
                                <CorporationsRequests
                                    {...this.props}
                                    corporationId={corporationId}
                                    fetchCorporation={this.fetchCorporation}
                                    fetchUserMembershipStatus={this.fetchUserMembershipStatus}
                                    changeExistingMemberModalVisibility={this.changeExistingMemberModalVisibility}
                                    handleChangeRecord={this.handleChangeRecord}
                                    getEstimatedPrice={this.getEstimatedPrice}
                                    recordMembershipStatus={recordMembershipStatus}
                                    renewalDate={renewalDate}
                                    subscription={subscription}
                                    totalMembers={members.length}
                                    avaliableSlots={unassignedSlots}
                                    handleRefresh={this.handleRefresh}
                                    triggerModal={triggerModal}
                                    record={record}
                                    estimatedCost={estimatedCost}
                                />
                                <CorporationMembers
                                    membersPayment={membersPayment}
                                    members={members}
                                    onAdd={this.handleAddMember}
                                    user={user}
                                    renewalDate={renewalDate}
                                    handleTabChange={this.handleTabChange}
                                    onEdit={this.handleEditMember}
                                    onDelete={this.handleDeleteMember}
                                    refreshTable={this.state.refreshTable}
                                    fetchCorporation={this.fetchCorporation}
                                    prepareModalForExistingUser={this.prepareModalForExistingUser}
                                    availableMemberships={unassignedSlots}
                                    subscription={subscription}
                                    corporationId={corporationId}
                                    onChange={(members) => this.setState({ selectedMembers: members })}
                                />
                            </Content>
                        </TabPane>

                        <TabPane
                            tab="Payment history"
                            key="3"
                            className="profile__pane"
                        >
                            <Content className="content">
                                <PaymentMemberHistory
                                    membersPaymentCount={membersPaymentCount}
                                    prolongation={prolongation}
                                    missing={missing}
                                    isPurchaseRequested={isPurchaseRequested}
                                    corporationId={corporationId}
                                    clearPurchaseRequest={this.clearPurchaseRequest}
                                    refreshPurchasedSlots={this.fetchPurchasedSlots}
                                    selectedMembers={selectedMembers}
                                />
                            </Content>
                        </TabPane>
                        <TabPane
                            tab="Memberships"
                            key="4"
                            className="profile__pane">
                            <Content className="content">
                                <Card>
                                    <MembershipsTable user={user} showTableOnly corporationId={corporationId} />
                                </Card>
                            </Content>
                        </TabPane>
                    </Tabs>
                </Spin>
            </div>
        );
    }
}

export const CorporationsMemberView = withUser(CorporationsMemberViewInner);
