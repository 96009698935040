let filters = {

};

export function persist(name, value) {
    filters[name] = value;
}

export function retrieve(name) {
    return filters[name];
}