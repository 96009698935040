import React from 'react';

import { PlusOutlined } from '@ant-design/icons';

export default function EventListAddButton({ onClick, title }) {
    return (
        <div className="add-event-element-button" type="link" onClick={onClick}>
            <PlusOutlined/> {title}
        </div>
    )
}
