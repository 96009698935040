export const isPasswordLongEnough = password => {
    return /^.{10}/.test(password);
}

export const passwordContainsUppercaseLetter = password => {
    return /^(?=.*[A-Z])/.test(password);
}

export const passwordContainsAtLeastOneDigit = password => {
    return /^(?=.*[0-9])/.test(password);
}

export const validatePassword = password => {
    return isPasswordLongEnough(password) && passwordContainsUppercaseLetter(password) && passwordContainsAtLeastOneDigit(password);
};
