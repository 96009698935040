import React from 'react';
import {
    DeleteOutlined,
    PlusCircleOutlined,
    StopOutlined,
} from '@ant-design/icons';
import {
    Card,
    Button,
    Modal,
    Input,
    Spin,
    Popconfirm,
} from 'antd';
import '../styles/ProfileView.css';
import { validateEmail } from '../utils/validateEmail';
import { api } from '../api';
import { withUser } from '../components/UserContext';

class CorporationContactPersonsInner extends React.PureComponent {
    state = {
        email: '',
        isModalVisible: false,
        contactPersons: null,
        isLoading: true,
    };

    addUser = () => {
        if (validateEmail(this.state.email)) {
            this.toggleModal();
            this.onAdd(this.state.email);
        } else {
            Modal.warning({
                content: "Invalid email"
            })
        }
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible,
        }));
    };

    onAdd = async email => {
        api.post(`/corporations/${this.props.corporationId}/contact-persons`, { email })
            .then(result => result.data)
            .then(() => this.fetchData())
            .catch(err => Modal.warning({ content: err.response.data.message }));
    };

    onDelete = async email => {
        api.delete(`/corporations/${this.props.corporationId}/contact-persons/${email}`)
            .then(() => this.fetchData());
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.setState({ isLoading: true });
        api.get(`corporations/${this.props.corporationId}/contact-persons`)
            .then(result => result.data)
            .then(result =>
                this.setState({
                    contactPersons: result.data,
                    isLoading: false,
                })
            );
    }

    render() {
        const { isLoading, isModalVisible, contactPersons, email } = this.state;

        return (
            <Spin spinning={isLoading}>
                {isModalVisible && (
                    <Modal
                        title="Add new contact person"
                        visible={isModalVisible}
                        onOk={this.addUser}
                        onCancel={this.toggleModal}
                    >
                        Email
                        <Input
                            value={email}
                            onChange={e =>
                                this.setState({ email: e.target.value })
                            }
                            className="u--spacer-bottom-1x"
                        />
                    </Modal>
                )}
                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            Corporate representatives
                        </div>
                    }
                    extra={
                        <Button type="primary" onClick={this.toggleModal}>
                            <PlusCircleOutlined /> Add
                        </Button>
                    }
                >
                    {contactPersons &&
                        contactPersons.map(person => (
                            <div key={person.email} style={{ marginTop: 10 }}>
                                {person.canDelete ? (
                                            <Popconfirm
                                                title="Are you sure want to delete this company representative?"
                                                onConfirm={() =>
                                                    this.onDelete(person.email)
                                                }
                                            >
                                                <Button
                                                    type="link"
                                                    style={{
                                                        width: 100,
                                                        textAlign: 'left',
                                                    }}
                                                    size="small"
                                                >
                                                    <DeleteOutlined /> Delete
                                                </Button>
                                            </Popconfirm>
                                        ) : (
                                            <Button
                                                type="link"
                                                style={{
                                                    width: 100,
                                                    textAlign: 'left',
                                                }}
                                                size="small"
                                                disabled
                                            >
                                                <StopOutlined /> It's you
                                            </Button>
                                        )
                                }

                                <span className="u--secondary-text">
                                    Email:{' '}
                                </span>{' '}
                                <h4 className="inline-header">
                                    {person.email}
                                </h4>
                            </div>
                        ))}
                </Card>
            </Spin>
        );
    }
}

export const CorporationContactPersons = withUser(
    CorporationContactPersonsInner
);
