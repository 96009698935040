import React from 'react';
import { Spin } from 'antd';
import { CorporationDataForm } from '../CorporationDataForm';
import { CorporationData } from '../CorporationData';
import { CorporationContactPersons } from '../CorporationContactPersons';

import { api } from '../../api';

export class CorporationDetails extends React.Component {

    state = {
        corporation: null,
        isLoading: false
    }

    componentDidMount() {
        this.fetchCorporation();
    }

    fetchCorporation() {
        this.setState({ isLoading: true });
        api
            .get(`/corporations/${this.props.corporationId}`)
            .then(response => response.data.data)
            .then(corporation => this.setState({ corporation, isLoading: false }))
    }

    handleEditNameDate = (name, renewaldate) => {
        this.setState({ isLoading: true });
        const { corporation } = this.state;
        api.patch('/corporations/name-date', {
            name,
            renewaldate,
            id: corporation && corporation.id,
        }).then(() => this.fetchCorporation());
    };

    handleEditData = values => {
        this.setState({ isLoading: true });
        const { corporation } = this.state;
        api.patch('/corporations', {
            ...values,
            id: corporation && corporation.id,
        }).then(() => this.setState({ isLoading: false }));
    };

    render() {
        const { 
            corporation,
            isLoading
        } = this.state;

        if(!corporation)
            return null;

        return (
            <Spin spinning={isLoading}>
                <CorporationData
                    canEdit={this.props.canEditName}
                    corporation={corporation}
                    onSubmit={this.handleEditNameDate}
                />
                <CorporationContactPersons corporationId={corporation.id}/>
                <CorporationDataForm
                    onSubmit={this.handleEditData}
                    corporation={corporation}
                />
            </Spin>
        );
    }
}