import React, { Component } from "react";
import _ from "lodash"
import { Modal } from 'antd';

import "../../styles/IndividualSetupView.css";

import { 
    SetupCard, 
    SetupSteps,
    PaymentCard, 
    ThankYouCard
} from "../../components/registration";
import { withUser } from '../../components/UserContext';
import PageLoading from '../../components/PageLoading';

import { api } from '../../api';

class IndividualSetupViewInner extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 0,
            loading: true,
            corporationMemberData : null,
            isFromCorporation : false,
            joinRequest : null,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        
        const { user: { membershipStatus, id } } = this.props;

        const resp = await Promise.all([
            api.get(`/corporationJoinRequest/user/${id}`),
            api.get(`/members/${id}`)]
        )

        this.setState({joinRequest : (resp && resp[0] && resp[0].data.result ) || null})
        const { first_name = "", last_name = "", cv = "", img = "" } = (resp && resp[1] && resp[1].data && resp[1].data.data) || {};
        const isFromCorporation = await this.hasUserJoinedToTheCorporation();

        if (this.isMemberProfileCreated(resp[1]) && ((membershipStatus && membershipStatus.expires_at) || isFromCorporation || !_.isEmpty(resp[0].data.result))) {
            this.setState({ step: 2 })
        } else if (first_name && last_name && cv && img) {
            this.setState({ step: 1 })
        }

        this.setState({
          loading: false
        })
    }

    isMemberProfileCreated(memberResponse) {
        return memberResponse && memberResponse.data && memberResponse.data.data && memberResponse.data.data.id;
    }

    hasUserJoinedToTheCorporation = async () => {
        const { user } = this.props;
        const corporationMemberData = await api.get(`/users/${user.id}/corporation`);
        this.setState({corporationMemberData:corporationMemberData,
            isFromCorporation: Boolean(corporationMemberData && corporationMemberData.data.corporation_id)})
        return Boolean(corporationMemberData && corporationMemberData.data.corporation_id);
    }

    skipThePaymentProcess = () => {
        this.setState({
            step: 2
        })
    }

    sendCorporationJoinRequest = async () => {
        const { user } = this.props;
        const corporationMemberData = await api.get(`/users/${user.id}/corporation`);
        const corporationId = corporationMemberData && corporationMemberData.data.corporation_id;
        if (user.id && corporationId) {
            await api.post(`/corporationJoinRequest/corporations/${corporationId}/user/${user.id}`);
        } else {
            Modal.warning({
                title: "Setup error",
                content: "Not valid user or corporation!"
            })
        }

    }

    handleChange = async (property, value) => {
        const isFromCorporation = await this.hasUserJoinedToTheCorporation();
        if (isFromCorporation && property === 'step' && value === 1) {
            this.skipThePaymentProcess();
            this.sendCorporationJoinRequest();
        } else {
            this.setState({ [property]: value })
        }
    }

    handleChangeStep = step => {
        this.setState({ step: step })
    }

  render() {
    const { step, loading } = this.state;
    const { user: { membershipStatus } } = this.props;

    if(loading) {
      return (
        <PageLoading/>
      )
    }

    return(
        <div className="individual-step-view">
           <SetupSteps 
            selectedStep={step}
            handleChangeStep={this.handleChangeStep} 
            allowed={membershipStatus && !!membershipStatus.expires_at}
          />
            <div className="individual-tabs">
                {step === 0 && <SetupCard handleChange={this.handleChange} {...this.props} />}
                {step === 1 && <PaymentCard handleChange={this.handleChange} 
                hasUserJoinedToTheCorporation={this.hasUserJoinedToTheCorporation}
                handleChangeStep={this.handleChangeStep} {...this.props} />}
                
                {step === 2 && <ThankYouCard handleChange={this.handleChange} 
                joinRequest={this.state.joinRequest}
                corporationMemberData={this.state.corporationMemberData} 
                isFromCorporation={this.state.isFromCorporation} />}
            </div>
          </div>
        );
    }
}

export const IndividualSetupView = withUser(IndividualSetupViewInner);