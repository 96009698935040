import React from 'react'
import { Spin } from 'antd';

import '../styles/App.css';

export default function PageLoading() {
    return (
        <Spin spinning={true} style={{ maxHeight: "unset", height: "100vh" }}>
            <div className="page-loading">
                
            </div>
        </Spin>
    )
}
