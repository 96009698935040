import React from 'react';
import {
    Layout,
    Card,
    Spin,
    DatePicker,
    Pagination,
} from 'antd';
import Filters from '../../components/utils/Filters';
import Filter from '../../components/utils/Filter';
import { api } from '../../api';
import moment from 'moment';
import buildQueryStringFromFilters from '../../utils/buildQueryStringFromFilters';
import CustomTable from '../../components/CustomTable';
import { renderDate, shortDateFormat } from '../../utils';
import PaymentStatus from '../../components/PaymentStatus';

const { Content } = Layout;
const { RangePicker } = DatePicker;

function ucFirst(string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
}

const columns = [
    {
        title: 'Date',
        dataIndex: 'create_time',
        key: 'create_time',
        render: renderDate
    },
    {
        title: 'PayPal Transaction ID / Profile ID',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
        render: (_, row) => <>{row.transaction_id || ''}<br />{row.subscription_id || ''}</>,
    },
    {
        title: 'Corporate or Member Name',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) =>
            row.membership_type === 'individual' || row.membership_type === "Manual"
                ? `${row.pers_full_name}`
                : (row.corp_name ? row.corp_name : row.pers_full_name),
    },
    {
        title: 'Membership Type',
        dataIndex: 'membership_type',
        key: 'membership_type',
        render: ucFirst
    },
    {
        title: 'Amount',
        dataIndex: 'amount_to_pay',
        key: 'amount_to_pay',
        render: (_, row) => `€${row.amount_to_pay}`,
    },
    {
        title: 'Payment Status',
        dataIndex: 'paypal_status',
        key: 'paypal_status',
        render: status => <PaymentStatus status={status} />
    },
    {
        title: 'Comment',
        dataIndex: 'purchase_name',
        key: 'purchase_name',
        render: (obj, row) => {
            return row.purchase_name ? row.purchase_name : '';
        },
    }
];

export class PaymentsAdminView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            payments: null,
            filters: this.initialFilters,
            paymentsCount: 0,
        };
    }

    initialFilters = {
        page: 1,
        limit: 50,
        payment_status: '',
        name: '',
        type: '',
        start_date: moment().subtract(1, 'months').startOf('day').format(),
        end_date: moment().endOf('day').format(),
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Payments');
        this.fetchData();
    }

    fetchData = () => {
        const queryString = buildQueryStringFromFilters(this.state.filters);

        api.get(`/payments${queryString}`).then(res => {
            this.setState({
                payments: res.data.payments,
                paymentsCount: res.data.count,
                isLoading: false,
            });
        });
    };

    changeFilter = (filter_name, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [filter_name]: value,
            },
        });
    };

    handleRangeChange = dates => {
        this.setState({
            filters: {
                ...this.state.filters,
                start_date: dates && dates[0]
                    ? dates[0].startOf('day').format()
                    : null,
                end_date: dates && dates[1]
                    ? dates[1].endOf('day').format()
                    : null,
            },
        });
    };

    clearFilters = () => {
        this.setState({
            filters: this.initialFilters,
        }, () => this.fetchData());
    };

    handleChangePage = page => {
        this.setState({
            filters: {
                ...this.state.filters,
                page: page,
            },
        }, () => this.fetchData());
    };

    render() {
        const { payments, isLoading, filters, paymentsCount } = this.state;

        return (
            <Spin spinning={isLoading}>
                <Content className="content">
                    <Filters onClearFilters={() => this.clearFilters()} onFinish={() => this.fetchData()}>
                        <Filter
                            value={filters.name}
                            placeholder="Name"
                            title="Corporate or Member Name"
                            onChange={e => this.changeFilter('name', e)}
                        />
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div className="filter-title">
                                Payments Date Range
                            </div>
                            <RangePicker
                                value={filters.start_date && filters.end_date
                                    ? [moment(filters.start_date), moment(filters.end_date)]
                                    : []
                                }
                                onCalendarChange={this.handleRangeChange}
                                className="hide-mobile"
                                format={shortDateFormat}
                            />
                            <div className="display-mobile">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <DatePicker
                                        value={filters.start_date ? moment(filters.start_date) : null}
                                        onChange={date => this.setState(prevState => {
                                            return {
                                                filters: {
                                                    ...prevState.filters,
                                                    start_date: date && moment(date).startOf('day').format()
                                                }
                                            }
                                        })}
                                        format={shortDateFormat}
                                    />
                                    <DatePicker
                                        value={filters.end_date ? moment(filters.end_date) : null}
                                        onChange={date => this.setState(prevState => {
                                            return {
                                                filters: {
                                                    ...prevState.filters,
                                                    end_date: date && moment(date).endOf('day').format()
                                                }
                                            }
                                        })}
                                        format={shortDateFormat}
                                    />
                                </div>
                            </div>
                        </div>

                        <Filter
                            title="Type"
                            type="select"
                            options={[
                                { type: '', label: 'All' },
                                { type: 'individual', label: 'Individual' },
                                { type: 'corporate', label: 'Corporate' },
                            ]}
                            optionProperty={{
                                key: 'type',
                                value: 'type',
                                display: 'label',
                            }}
                            defaultValue=""
                            value={filters.type}
                            onChange={e => this.changeFilter('type', e)}
                        />
                        <Filter
                            title="Payment status"
                            type="select"
                            options={[
                                { status: '', label: 'All' },
                                { status: 'success', label: 'Success' },
                                { status: 'cancel', label: 'Cancelled' },
                            ]}
                            optionProperty={{
                                key: 'status',
                                value: 'status',
                                display: 'label',
                            }}
                            defaultValue=""
                            value={filters.payment_status}
                            onChange={e =>
                                this.changeFilter('payment_status', e)
                            }
                        />
                    </Filters>
                    <Card
                        className="custom-card"
                        title={
                            <div className="custom-card-header blue-underline">
                                Payments
                            </div>
                        }
                    >
                        <CustomTable
                            columns={columns}
                            dataSource={payments}
                            pagination={false}
                        />
                        <Pagination
                            current={filters.page}
                            pageSize={filters.limit}
                            total={paymentsCount}
                            onChange={this.handleChangePage}
                            showSizeChanger={false}
                        />
                    </Card>
                </Content>
            </Spin>
        );
    }
}
