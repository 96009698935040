import React from "react";
import {Modal,Input,Form} from "antd";

const AddMemberModal = ({visible,clearMember,handleChange,email,name}) => {
   

        return (
            <Modal
            visible={visible}
            onOk={clearMember}
            onCancel={() => handleChange(false,"visible")}
            closable={false}
            title="Add new member"
        >
            <Form onFinish={clearMember}>
                <h4 className="no-bold">Name</h4>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'User name is required',
                        },
                    ]}
                    name="name"
                >
                    <Input
                        placeholder="Name"
                        value={name}
                        onChange={e =>handleChange( e.target.value,"name")}
                    />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Email adress is required',
                        },
                        {
                            type: 'email',
                            message:
                                'Please enter a valid email address',
                        },
                    ]}
                    name="email"
                >
                    <h4 className="no-bold">Email</h4>
                    <Input
                        placeholder="Email"
                        value={email}
                        onChange={e =>handleChange( e.target.value,"email")}
                    />
                </Form.Item>
            </Form>
        </Modal>
        )
    
}
export default AddMemberModal;