import React, { useEffect, useState } from "react";
import { Modal, Button, Spin, Popconfirm, Badge, Row, Col } from "antd";
import { api } from "../../api";
import { Link } from "react-router-dom";
import CustomTable from "../CustomTable";
import { PlusOutlined } from "@ant-design/icons";
import NewMemberModal from "./NewMemberModal";
import EditMembershipModal from "./EditMembershipModal";
import { renderDate, formatShortDate } from '../../utils';
import moment from "moment";

const MonthDiffBadge = ({validTo, renewalDate}) => {
    const diffInMonth = moment(validTo).startOf('day').diff(moment(renewalDate).startOf('day'), 'months');

    if(Number.isNaN(diffInMonth) || diffInMonth === 0)
        return null;
    
    return <Badge count={`${diffInMonth}m`} style={{ backgroundColor: diffInMonth > 0 ? '#52C41A' : '#E80003' }} />;
}

export const CorporationMembersModal = ({ visible, setVisible, record: { id = null, name, renewaldate } }) => {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayNewMemberModal, setDisplayNewMemberModal] = useState(false);
    const [displayEditMemberModal, setDisplayEditMemberModal] = useState(false);
    const [editedMembership, setEditedMembership] = useState(null);

    useEffect(() => {
        fetchCorporatemembers();
    }, [id]);


    const fetchCorporatemembers = () => {
        if (id) {
            setLoading(true);

            api
                .get(`/corporations/${id}/members`)
                .then(res => res.data)
                .then(res => res.members)
                .then(res => {
                    setMembers(res);
                    setLoading(false);
                });
        }
    }

    const handleAddNewMember = ({
        newMember,
        memberEmail,
        membershipType,
        selectedMembership,
        validFrom,
        validTo,
        isPaymentDeferred,
        createNewMembership,
        newMemberMethod
    }) => {
        api
            .post(`/corporations/${id}/member`, {
                newMember,
                memberEmail,
                membershipType,
                selectedMembership,
                validFrom,
                validTo,
                isPaymentDeferred,
                createNewMembership,
                newMemberMethod
            })
            .then(() => {
                fetchCorporatemembers();
                Modal.success({
                    title: "Member has been added to the corproate"
                })
            })
            .then(() => setDisplayNewMemberModal(false))
    }

    const handleDeleteMember = (corporationId, memberId) => {
        api
            .delete(`/corporations/${corporationId}/member/${memberId}`)
            .then(() => {
                fetchCorporatemembers();
                Modal.success({
                    title: "Member has been detached"
                })
            })
            .catch(() => Modal.warning({
                title: "Cannot detached member",
                content: "Please try one more time"
            }))
    }

    const handleUpdateMembership = (membership_id, validFrom, validTo, isPaymentDeferred) => {
        api
            .patch(`/corporations/${id}/member/${membership_id}`, {
                validFrom,
                validTo,
                isPaymentDeferred
            })
            .then(() => {
                fetchCorporatemembers();
                Modal.success({
                    title: "Membership has been saved"
                })
                setDisplayEditMemberModal(false);
                setEditedMembership(null);
            })
            .catch(() => Modal.warning({
                title: "Cannot edit membership",
                content: "Please try one more time"
            }))
    }

    const columns = [
        {
            title: 'Member name',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: "Member Email",
            dataIndex: "member_email",
            key: "member_email",
        },
        {
            title: "Expiry date",
            dataIndex: "valid_to",
            key: "valid_to",
            render: d => (
                <div style={{ whiteSpace: 'nowrap' }}>
                    {renderDate(d)} <MonthDiffBadge validTo={d} renewalDate={renewaldate} />
                </div>
            ),
        },
        {
            title: "Action",
            render: profile => (
                <>
                    <Link to={`/profile/${profile && profile.user_id}`}>View profile</Link>
                    <Popconfirm
                        title="Are you sure want to detach this member?"
                        onConfirm={() => handleDeleteMember(profile && profile.corporation_id, profile && profile.id)}
                        okText="Detach"
                        cancelText="Cancel"
                    >
                        <Button type="link" style={{ padding: 0, paddingLeft: 5 }}>
                            Detach
                        </Button>
                    </Popconfirm>
                </>
            )
        }
    ];

    const handleClose = () => {
        setVisible(false);
    }

    return (
        <Modal
            width={1200}
            title={`${name} Members`}
            visible={visible}
            onCancel={handleClose}
            footer={<Button onClick={handleClose} type="primary">Ok</Button>}
        >
            <Row>
                <Col span="12">Renewal Date: {formatShortDate(renewaldate)}</Col>
                <Col span="12">
                    <Button
                        type="primary"
                        style={{
                            marginLeft: "auto",
                            marginBottom: 25,
                            display: "block"
                        }}
                        onClick={() => setDisplayNewMemberModal(true)}>
                        <PlusOutlined /> Add member
                    </Button>
                </Col>
            </Row>

            <Spin spinning={loading}>
                <CustomTable
                    columns={columns}
                    dataSource={members}
                    pagination={{ pageSize: 10 }}
                />
            </Spin>
            {displayNewMemberModal &&
                <NewMemberModal
                    visible={displayNewMemberModal}
                    corporationId={id}
                    onCancel={() => setDisplayNewMemberModal(false)}
                    onSubmit={handleAddNewMember}
                />
            }
            <Modal
                title="Edit user membership"
                visible={displayEditMemberModal}
                destroyOnClose={true}
                footer={null}
                width={900}
                onCancel={() => {
                    setDisplayEditMemberModal(false);
                    setEditedMembership(null);
                }}
            >
                <EditMembershipModal
                    membership={editedMembership}
                    onSubmit={handleUpdateMembership}
                    onCancel={() => {
                        setDisplayEditMemberModal(false);
                        setEditedMembership(null);
                    }}
                />
            </Modal>
        </Modal>
    );
};
