import axios from 'axios';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(config => {
    return config;
});

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if(error.response) {
            if(error.response.status === 401) {
                console.log('REDIRECT TO /', error.request.responseURL);
                window.location = "/";
            }
        }

        return Promise.reject(error);
    }
);

api.interceptors.response.use(response => {
    return response;
});
