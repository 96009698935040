import React from 'react';
import { Card, Row, Col, Input, InputNumber, Divider, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { validateEmail } from '../../utils/validateEmail'

export class PaymentCorporateForm extends React.Component {
    
    isRowFilled = (member) => {
        return  member.firstName !== '' && 
                member.lastName !== '' &&
                validateEmail(member.email);
    }

    calculatePrice = (slots) => {
        return slots >= 5 ? 650 + (slots - 5) * 135 : 'Error! Min. number of slots is 5';
    }

    render() {        
        const { slots, membersData } = this.props;

        return (
            <Card
                className="setup-card members"
                bordered={false}
                style={{ marginTop: 50, marginBottom: 50 }}
            >
                <Col span={23}>
                    <Row className="big-bold-text">
                        <p style={{ textAlign: "center" }}>Select the number of members you wish to add in the Corporate Membership</p>
                    </Row>
                    <Row className="margin-top-10 margin-bottom-20" style={{textAlign: 'left'}}>
                        <Col span={24}>
                       A Corporate Membership is for a minimum of 5 members. There is no limitation to any additional number. 
                       Indicate the names and emails of the members you wish to include in the Corporate Membership.
                       You can add other members any time later. Depending on the date on which you add new members, 
                       the payment of the membership for such members will be prorated to the period left before renewal of the Corporate Membership. 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8} offset={8} className='margin-bottom-20'>
                            <InputNumber min={5} max={100} value={slots} onChange={this.props.onChangeSlots} />
                        </Col>
                    </Row>
                </Col>
                {membersData && membersData.map((member, index) => 
                    <Row style={{marginTop: 10}} alignItems="middle" gutter={[8,8]}>
                        <Col md={1} span={24} style={{ color: member.addedBefore ? '#909090' : this.isRowFilled(member) ? '#6EA5E9' : '#000000' }}>
                            {index + 1}
                        </Col>
                        <Col md={7} span={24}>
                            <Input 
                                placeholder="First name" 
                                value={member.firstName}
                                disabled={member.addedBefore === true}
                                onChange={(e) => this.props.onChangeInput(e, 'firstName', index)}
                                />
                        </Col>
                        <Col md={7} span={24}>
                            <Input 
                                placeholder="Last name" 
                                value={member.lastName}
                                disabled={member.addedBefore === true}
                                onChange={(e) => this.props.onChangeInput(e, 'lastName', index)}
                                />
                        </Col>
                        <Col md={8} span={24}>
                            <Input 
                                placeholder="Email" 
                                value={member.email}
                                disabled={member.addedBefore === true}
                                onChange={(e) => this.props.onChangeInput(e, 'email', index)}
                            />
                        </Col>
                        {(this.isRowFilled(member) || member.addedBefore) && 
                            <>
                                <Col span={1} style={{ padding: 0, color: member.addedBefore ? '#909090' : '#6EA5E9' }}>
                                    <CheckCircleOutlined />
                                </Col>
                                <Col offset={1} style={{ color: member.addedBefore ? '#909090' : '#6EA5E9', fontSize: 10 }}>
                                    {member.addedBefore ? '* Member has already been added' : '* Invitation will be sent'}
                                </Col>    
                            </>
                        }
                    </Row>
                )}

                <Row justify="center" className="margin-top-20" gutter={[8,8]}>
                    <Col md={8} span={24} style={{textAlign: 'center', padding: "25px 0px"}}>
                        <Row justify="center">${this.calculatePrice(slots)}</Row>
                        <Divider style={{margin: 5}}/>
                        <Row className="small-grey-label" justify="center">{slots} members</Row>
                    </Col>
                </Row>

                <Row justify="center" className="margin-top-20" gutter={[8,8]}>
                    <Button 
                        size="large" 
                        className="submit-button" 
                        type="primary" 
                        onClick={this.props.handleClickPaymentButton} 
                        style={{ 
                            paddingLeft: 40, 
                            paddingRight: 40, 
                            textAlign: "center" 
                        }}
                    >
                        Pay
                    </Button>
                </Row>

            </Card>
        )
    }
}