import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';
import { api } from '../../api';

export const EventBanner = props => {
    const { bannerImg, onUpload } = props;

    function beforeImgUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isJpgOrPng) {
            Modal.warning({
                title: "Image uploading error",
                content: "You can only upload JPG/PNG file."
            });
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Image uploading error",
                content: "Image must smaller than 30MB!."
            })
        }

        // if correct format and size read resolution
        if (isJpgOrPng && isLt30M) {
            props.changeState('uploadingBanner', true);
            const reader = new FileReader();
            const image = new Image();

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                image.src = e.target.result;
            };

            image.addEventListener('load', function () {
                const height = this.naturalHeight;
                const width = this.naturalWidth;

                if (height < 300 || width < 300) {
                    Modal.warning({
                        title: "Image uploading error",
                        content: "The image dimensions are too small. Minimum image size is 300px by 300px."
                    })
                    props.changeState('uploadingBanner', false);
                } else {
                    // api logic necessary inside event listnere to get uploaded image resolution
                    const formData = new FormData();
                    formData.append('avatar', file);

                    api.post(`/events/upload-img`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(({ data }) => {
                            onUpload(data.data.image);
                        })
                        .catch(error => console.log(error))
                        .finally(() => props.changeState('uploadingBanner', false));
                }
            });
        }

        return false; // always return false to upload manually
    }

    return (
        <>
            <Upload
                showUploadList={false}
                className="avatar-uploader"
                listType="picture-card"
                beforeUpload={beforeImgUpload}
            >
                {bannerImg ? (
                    <img
                        src={bannerImg}
                        alt="avatar"
                        style={{ width: '100%' }}
                    />
                ) : (
                    <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Upload</div>
                    </div>
                )}
            </Upload>
            <i>Minimum image size is 300px by 300px. You can upload JPG or PNG files with a maximum size 2MB.</i>
        </>
    );
};
