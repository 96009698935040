import React from 'react';
import { Alert } from 'antd';

export default function SupportMessage() {
    const supportMessage = <div>
        If you need assistance, write to <a href="mailto:contact@arbitralwomen.org">contact@arbitralwomen.org</a>
    </div>;

    return <Alert
        className="large-alert"
        style={{ textAlign: 'left', marginTop: '30px' }}
        description={supportMessage}
        type="info"
        showIcon
    />
}