import React, { Component } from 'react';
import { Form, Input, Button, Result, Modal } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { api } from '../api';
import mailIcon from "../img/mail.png";

import { 
    validatePassword, 
    isPasswordLongEnough, 
    passwordContainsAtLeastOneDigit,
    passwordContainsUppercaseLetter
} from '../utils/validatePassword';

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendedEmail: false,
            passwordChanged: false,
            email: null,
            loading: false,
            isChanged: null,
            loadingContent: true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        const { token } = this.props.match.params;

        if(token) {
            api.get(`/users/reset-password/${token}`)
            .then(response => {
                this.setState({
                    isChanged: response.data.is_changed,
                    loadingContent: false
                });
            })
        }
    }

    sendResetPasswordRequest(values) {
        const { email } = values;

        this.setState({
            email,
            loading: true
        }, () => {
            api.post("/users/reset-password", {
                email
            })
            .then(response => {
                this.setState({
                    sendedEmail: response.data.success
                });
            })
            .catch(error => {
                if(error.response && error.response.status === 400) {
                    Modal.warning({ content: "This email does not exist" });
                }
                this.setState({
                    loading: false
                });
            })
        });
    }

    resetPassword(values) {
        const { token } = this.props.match.params;
        const { password, confirmPassword } = values;

        this.setState({
            loading: true
        }, () => {
            api.patch(`/users/reset-password/${token}`, {
                password,
                confirmPassword
            })
            .then(response => {
                if(response.data.success) {
                    this.setState({
                        passwordChanged: true
                    });
                }
            })
            .catch(error => {
                if(error.response && error.response.status === 400) {
                    Modal.warning({ content: error.response.data.message });
                }
                this.setState({
                    loading: false
                });
            })
        })
    }
    
    validateFormItemPassword = (rule, password, callback) => {
        if (validatePassword(password)) {
            callback();
        } else if (!isPasswordLongEnough(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsAtLeastOneDigit(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsUppercaseLetter(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        }
    }

    render() {
        if(this.state.isChanged) {
            return (
                <div className="wrapped-content message login-message-area">
                    <Result
                        status="warning"
                        title={`Reset link is expired`}
                        extra={
                            <Link to={'/login'}>Back to login page</Link>
                        }
                    />
                </div>
            )
        }

        if(this.state.passwordChanged) {
            return (
                <div className="wrapped-content message login-message-area">
                    <Result
                        status="success"
                        title={`Your password has been changed`}
                        extra={
                            <Link to={'/login'}>Back to login page</Link>
                        }
                    />
                </div>
            )
        }

        if(this.state.sendedEmail) {
            return (
                <div className="wrapped-content message login-message-area">
                    <img src={mailIcon} alt="" />
                    <h2>Check your email</h2>
                    <p>We’ve sent a message to <b>{this.state.email}</b> with a link to password reset form.</p>
                    <h3>Didn’t get an email?</h3>
                    <p>If you don’t see an email from us within a few minutes, a few things could have happened:</p>
                    <ul>
                        <li>The email is in your spam folder. (Sometimes things get lost in there.)</li>
                        <li>The email address you entered had a mistake or typo. (Happens to the best of us.)</li>
                        <li>You accidentally gave us another email address. (Usually a work or personal one instead of the one you meant.)</li>
                        <li>We can’t deliver the email to this address. (Usually because of corporate firewalls or filtering.)</li>
                    </ul>
                    <Link to={'/login'}>Back to login page</Link>
                </div>
            )
        }
        if(this.props.match.params && this.props.match.params.token) {
            return (
                <div className="wrapped-content">
                    <h1>Reset password</h1>
                    <Form onFinish={fields => this.resetPassword(fields)}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Password is required',
                                },
                                { validator: this.validateFormItemPassword }
                            ]}
                            style={{ marginBottom: 8 }}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                type="password"
                                placeholder="New password *"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue(
                                                'password'
                                            ) === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            'Passwords must be the same'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                type="password"
                                placeholder="Retype password *"
                                size="large"
                                style={{ marginBottom: 8 }}
                            />
                        </Form.Item>
                        <Button loading={this.state.loading} className="submit-button" type="primary" block style={{ maxWidth: 200, marginBottom: 20 }} htmlType="submit" size="large">
                            Reset password
                        </Button>
                    </Form>
                    <Link to={'/login'}>Back to login page</Link>
                </div>
            )
        }

        return (
            <div className="wrapped-content">
                <h1>Reset password</h1>
                <Form onFinish={fields => this.sendResetPasswordRequest(fields)}>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "This field is required" },
                            { type: "email", message: "Please enter correct email address" }
                        ]}
                    >
                        <Input
                            prefix={
                                <MailOutlined
                                    style={{
                                        color:
                                            'rgba(0,0,0,.25)',
                                    }}
                                />
                            }
                            type="text"
                            placeholder="Email address"
                            size="large"
                        />
                    </Form.Item>
                    <Button 
                        loading={this.state.loading} 
                        type="primary" 
                        htmlType="submit" 
                        size="large" 
                        className="submit-button" 
                        style={{ 
                            maxWidth: 200, 
                            marginBottom: 20,
                            marginTop: 10
                        }} 
                        block
                    >
                        Reset password
                    </Button>
                </Form>
                <Link to={'/login'}>Back to login page</Link>
            </div>
           
        )
    }
}
