import React, { Component } from "react";
import {
    Layout,
    Button,
    Pagination,
    Card,
    Modal,
    Tag,
    Input,
    Dropdown,
    Menu,
    Select
} from "antd";
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    ExclamationCircleOutlined,
    DownOutlined
} from "@ant-design/icons";
import { MembershipDetailsModal } from '../../components/admin/MembershipDetailsModal';

import { roles } from "../../constants/roles";

import Filters from "../../components/utils/Filters";
import Filter from "../../components/utils/Filter";

import buildQueryStringFromFilters from "../../utils/buildQueryStringFromFilters";
import { api } from "../../api";
import CustomTable from "../../components/CustomTable";
import { renderDate } from "../../utils";

const { Content } = Layout;
const { Option } = Select;

const allRoles = [
    { role: "admin", label: "Administrator" },
    { role: "member", label: "Member" },
    { role: "board_member", label: "Board Member" }
];

export class SystemUsersView extends Component {
    state = {
        filters: {},
        users: [],
        corporations: [],
        loading: false,
        page: 1,
        pageSize: 50,
        totalCount: 0,
        modalVisible: false,
        emailModalVisible: false,
        passwordModalVisible: false,
        isMembershipDetailsModalVisible: false,
        userId: null,
        modifiedUserData: {
            id: null,
            role: null,
            roles: null,
            name: null,
            index: null,
        },
        emailModifiedUserData: {
            id: null,
            email: null,
            name: null,
            index: null,
        },
        passwordModifiedUserData: {
            id: null,
            name: null,
            index: null,
        },
    };

    menu = (user, index) => (
        <Menu>
            <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.suspensionAction(user.id, !user.is_suspended, index)}>
                    {user.is_suspended ? "Unsuspend" : "Suspend"}
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.showPayments(user.id)}>
                    Payments
                </Button>
            </Menu.Item>
            {user.last_name && <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.changeRole(user, index)}>
                    Change Role
                </Button>
            </Menu.Item>}
            {user.last_name && <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.changeEmail(user, index)}>
                    Change Email
                </Button>
            </Menu.Item>}
            {user.last_name && <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.changePassword(user, index)}>
                    Change Password
                </Button>
            </Menu.Item>}
            <Menu.Item>
                <Button style={{ padding: "0px" }} type="link" onClick={() => this.deleteUser(user)}>
                    Delete
                </Button>
            </Menu.Item>
        </Menu>
    );

    columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (_, user) => (
                <div>
                    <div>
                        {user.first_name || ""} {user.last_name || ""}
                    </div>
                    <div>
                        {user.is_suspended && (
                            <Tag color="red">Membership suspended</Tag>
                        )}
                    </div>
                </div>
            ),
        },
        {
            title: "Registration Date",
            dataIndex: "created_at",
            key: "created_at",
            render: renderDate,
        },
        {
            title: "Roles",
            dataIndex: "roles",
            key: "roles",
            render: (_, user) => user.roles.map(r => roles[r]).join(', '),
        },
        {
            title: "Corporate",
            dataIndex: "corporation_name",
            key: "corporation_name",
        },
        {
            title: "Email",
            dataIndex: "personal_email_confirmed",
            key: "personal_email_confirmed",
            render: (_, user) => (
                <>
                    <div>
                        <a href={`mailto:${user.personal_email}`}>
                            <span style={{ display: "inline-block", width: 300 }}>
                                {user.personal_email}
                            </span>
                        </a>
                    </div>
                </>
            ),
        },
        {
            title: "Email Confirmed",
            dataIndex: "personal_email_confirmed",
            key: "personal_email_confirmed",
            render: (_, user) =>
                user.confirmed_personal_email
                    ? <CheckCircleTwoTone twoToneColor="#52c41a" />
                    : <CloseCircleTwoTone twoToneColor="#f44242" />
        },
        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            render: (_, user, index) => (
                <Dropdown overlay={this.menu(user, index)}>
                    <a href="#" className="ant-dropdown-link" style={{ whiteSpace: 'nowrap' }} onClick={e => e.preventDefault()}>
                        Actions <DownOutlined />
                    </a>
                </Dropdown>
            ),
        },
    ];

    updateUsers = index => {
        let updated_users = this.state.users;
        updated_users[index].is_suspended = updated_users[index].is_suspended
            ? false
            : true;
        this.setState({
            users: updated_users,
        });
    };

    suspensionAction = (user_id, should_suspend, index) => {
        if (should_suspend) {
            api.post(`suspensions`, { id: user_id })
                .then(res => {
                    if (res.data.success) {
                        this.updateUsers(index);
                    }
                })
                .catch(err => console.log(err));
        } else {
            api.delete(`suspensions/${user_id}`)
                .then(res => {
                    if (res.data.success) {
                        this.updateUsers(index);
                    }
                })
                .catch(err => console.log(err));
        }
    };

    showPayments = (userId) => {
        this.setState({
            isMembershipDetailsModalVisible: true,
            userId
        })
    }

    deleteUser = (user) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure that you want to delete user with login ${user.personal_email}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                this.setState({ loading: true });
                api.delete(`/users/${user.id}`)
                    .then(result => result.data)
                    .then(() => {
                        this.loadUsers();
                        this.setState({ loading: false });
                    });
            }
        });
    }

    changeRole(user, index) {
        this.setState({
            modalVisible: true,
            modifiedUserData: {
                id: user.id,
                roles: user.roles,
                name: `${user.first_name || ""} ${user.last_name || ""}`,
                index: index,
            },
        });
    }

    changeEmail(user, index) {
        this.setState({
            emailModalVisible: true,
            emailModifiedUserData: {
                id: user.id,
                email: user.personal_email,
                name: `${user.first_name || ""} ${user.last_name || ""}`,
                index: index,
            },
        });
    }

    changePassword(user, index) {
        this.setState({
            passwordModalVisible: true,
            passwordModifiedUserData: {
                id: user.id,
                name: `${user.first_name || ""} ${user.last_name || ""}`,
                index: index,
            },
        });
    }

    handleSelectChange = e => {
        this.setState({
            modifiedUserData: {
                ...this.state.modifiedUserData,
                role: e,
            },
        });
    };

    handleRolesChange = e => {
        this.setState({
            modifiedUserData: {
                ...this.state.modifiedUserData,
                roles: e,
            },
        });
    };

    handleEmailChange = email => {
        this.setState(prevState => ({
            emailModifiedUserData: {
                ...prevState.emailModifiedUserData,
                email,
            },
        }));
    }

    handlePasswordChange = password => {
        this.setState(prevState => ({
            passwordModifiedUserData: {
                ...prevState.passwordModifiedUserData,
                password,
            },
        }));
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
    };

    handleEmailCancel = () => {
        this.setState({
            emailModalVisible: false,
        });
    };

    handlePasswordCancel = () => {
        this.setState({
            passwordModalVisible: false,
        });
    };

    handleSubmit = () => {
        const data = {
            userId: this.state.modifiedUserData.id,
            roles: this.state.modifiedUserData.roles
        };
        api.post(`/users/change-role`, data).then(res => {
            if (res.data.success) {
                this.setState(prevState => {
                    const users = prevState.users.map((user, i) => {
                        if (i === prevState.modifiedUserData.index) {
                            user.roles = prevState.modifiedUserData.roles;
                        }
                        return user;
                    });
                    return {
                        users,
                        modalVisible: false,
                    };
                });
            }
        });
    };

    handleEmailSubmit = () => {
        const data = {
            userId: this.state.emailModifiedUserData.id,
            email: this.state.emailModifiedUserData.email
        };
        api.post(`/users/change-email`, data).then(res => {
            if (res.data.success) {
                this.setState(prevState => {
                    const users = prevState.users.map((user, i) => {
                        if (i === prevState.emailModifiedUserData.index) {
                            user.personal_email = prevState.emailModifiedUserData.email;
                        }
                        return user;
                    });
                    return {
                        users,
                        emailModalVisible: false,
                    };
                });
            }
        }).catch(err => {
            Modal.error({
                title: "Error",
                content: err.response.data.message
            });
        });
    };

    handlePasswordSubmit = () => {
        const data = {
            userId: this.state.passwordModifiedUserData.id,
            password: this.state.passwordModifiedUserData.password
        };
        api.post(`/users/change-password`, data).then(res => {
            if (res.data.success) {
                this.setState({ passwordModalVisible: false });
            }
        }).catch(err => {
            Modal.error({
                title: "Error",
                content: err.response.data.message
            });
        });
    };

    changeFilter(filter, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                [filter]: value,
            },
        });
    }

    clearFilters() {
        this.setState(
            {
                filters: {},
                page: 1,
            },
            () => {
                this.loadUsers();
            }
        );
    }

    loadUsers() {
        const { filters, page, pageSize } = this.state;

        const query = buildQueryStringFromFilters(filters);

        this.setState(
            {
                loading: true,
            },
            () => {
                api.get(`/users${query}&page=${page}&limit=${pageSize}`)
                    .then(response => {
                        this.setState({
                            users: response.data.users,
                            totalCount: response.data.totalCount,
                            loading: false,
                        });
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                        });
                    });
            }
        );
    }

    submitSearch() {
        this.setState(
            {
                page: 1,
            },
            () => {
                this.loadUsers();
            }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle("System users");
        this.loadUsers();
        this.loadCorporations();
    }

    loadCorporations() {
        api
            .get('/corporations?limit=0')
            .then(res => {
                this.setState({
                    corporations: res.data && res.data.corporations
                })
            });

    }

    changePageSize(page) {
        this.setState(
            {
                pageSize: page,
            },
            () => {
                this.loadUsers();
            }
        );
    }

    changePage(page) {
        this.setState(
            {
                page,
            },
            () => {
                this.loadUsers();
            }
        );
    }

    render() {
        const {
            filters,
            loading,
            users,
            corporations,
            totalCount,
            page,
            pageSize,
            isMembershipDetailsModalVisible,
            userId
        } = this.state;

        return (
            <>
                <Content className="content">
                    <Filters onClearFilters={() => this.clearFilters()} onFinish={() => this.submitSearch()}>
                        <Filter
                            title="Name:"
                            value={filters.name}
                            placeholder="Name"
                            onChange={e => this.changeFilter("name", e)}
                        />
                        <Filter
                            title="Role:"
                            type="select"
                            placeholder="Role"
                            options={[
                                { role: "", label: "All" },
                                { role: "admin", label: "Administrator" },
                                { role: "member", label: "Member" },
                                { role: "board_member", label: "Board Member" },
                            ]}
                            optionProperty={{
                                key: "role",
                                value: "role",
                                display: "label",
                            }}
                            defaultValue=""
                            value={this.state.filters["role"]}
                            onChange={e => this.changeFilter("role", e)}
                        />
                        <Filter
                            title="Corporate:"
                            type="select"
                            placeholder="Corporate"
                            options={
                                [{ id: null, label: 'All' }].concat(
                                    corporations.map(corp => ({
                                        id: corp.id,
                                        label: corp.name
                                    }))
                                )
                            }
                            optionProperty={{
                                key: "id",
                                value: "id",
                                display: "label",
                            }}
                            defaultValue={null}
                            value={filters.corporationId}
                            onChange={e => this.changeFilter("corporationId", e)}
                        />
                        <Filter
                            title="Email:"
                            value={filters.email}
                            placeholder="Email"
                            onChange={e => this.changeFilter("email", e)}
                        />
                    </Filters>
                    <Card
                        className="custom-card"
                        title={
                            <div className="custom-card-header blue-underline">
                                System users
                            </div>
                        }
                    >
                        <CustomTable
                            columns={this.columns}
                            dataSource={users}
                            loading={loading}
                            pagination={false}
                            rowKey="id"
                        />
                        <Pagination
                            current={page}
                            total={totalCount}
                            pageSize={pageSize}
                            onShowSizeChange={(c, s) => this.changePageSize(s)}
                            onChange={page => this.changePage(page)}
                            showSizeChanger={false}
                        />
                    </Card>
                </Content>
                <MembershipDetailsModal
                    isVisible={isMembershipDetailsModalVisible}
                    userId={userId}
                    closeModal={() => this.setState({ isMembershipDetailsModalVisible: false })}
                    fetchMembers={t => this.loadUsers()}
                />
                <Modal
                    visible={this.state.modalVisible}
                    onCancel={this.handleCancel}
                    onOk={this.handleSubmit}
                    title="Change role"
                >
                    <div>
                        {
                            this.state.modifiedUserData &&
                            <>
                                User: <b>{this.state.modifiedUserData.name}  </b>
                            </>
                        }

                    </div>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        value={this.state.modifiedUserData.roles}
                        onChange={e => this.handleRolesChange(e)}>
                        {allRoles.map(r => <Option key={r.role} value={r.role}>{r.label}</Option>)}
                    </Select>
                </Modal>
                <Modal
                    visible={this.state.emailModalVisible}
                    onCancel={this.handleEmailCancel}
                    onOk={this.handleEmailSubmit}
                    title="Change email"
                >
                    <div>
                        {
                            this.state.emailModifiedUserData &&
                            <>
                                User: <b>{this.state.emailModifiedUserData.name}  </b>
                            </>
                        }

                    </div>
                    <Input value={this.state.emailModifiedUserData.email} onChange={e => this.handleEmailChange(e.target.value)} />
                </Modal>
                <Modal
                    visible={this.state.passwordModalVisible}
                    onCancel={this.handlePasswordCancel}
                    onOk={this.handlePasswordSubmit}
                    title="Change password"
                >
                    <div>
                        {
                            this.state.passwordModifiedUserData &&
                            <>
                                User: <b>{this.state.passwordModifiedUserData.name}  </b>
                            </>
                        }

                    </div>
                    <Input.Password value={this.state.passwordModifiedUserData.password} onChange={e => this.handlePasswordChange(e.target.value)} />
                </Modal>
            </>
        );
    }
}
