import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { LockOutlined, MailOutlined, HomeOutlined } from '@ant-design/icons';
import { Input, Button, Form, Col, Checkbox, Modal } from 'antd';

import membershipTypes from '../constants/membershipTypes';
import {
    MembershipType,
    SuccesfulRegistration
} from '../components/registration';

import { api } from '../api';
import { membershipType } from '../utils/membershipType';
import { validatePassword, isPasswordLongEnough, passwordContainsAtLeastOneDigit, passwordContainsUppercaseLetter } from '../utils/validatePassword';
import { withUser } from '../components/UserContext';

class RegisterViewInner extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            inProgress: false,
            selectedMembershipType: membershipType.INDIVIDUAL,
            doesEmailExists: false,
            isFromInvitation: null,
            selectedCorporationId: null,
            invitationId: null,
            personalEmail: null,
            fields: [],
            recaptchaValue: null,
            acceptedTerms: false,
            registeredMessage: false,
            createdUser: null
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        localStorage.removeItem('token');
        if (this.props.location.search.indexOf('invitation') !== -1) {
            let invitationId = this.props.location.search.split('invitation=')[1] || 0;

            this.setState({
                isFromInvitation: true,
                membershipType: membershipTypes.CORPORATE_MEMBERSHIP,
            }, () => {
                api
                    .get(`/invitations/${invitationId}`)
                    .then(res => res.data)
                    .then(res => {
                        this.setState({
                            invitationId,
                            fields: [
                                {
                                    name: ['invitationId'],
                                    value: invitationId,
                                },
                                {
                                    name: ['selectedCorporationId'],
                                    value: res.data.corporation_id,
                                },
                                {
                                    name: ['personalEmail'],
                                    value: res.data.member_email,
                                },
                            ],
                        });
                    });
                }
            )
        }
    }

    registerUser(account) {
        const { password, confirmPassword, personalEmail, companyName } = account;
        const { selectedMembershipType, invitationId, recaptchaValue, acceptedTerms } = this.state;

        this.setState({
                personalEmail,
                invitationId,
        }, () => {
            api.post('/register', {
                password,
                confirmPassword,
                personalEmail,
                selectedMembershipType,
                invitationId,
                companyName,
                recaptchaValue,
                acceptedTerms
            })
            .then(response => response.data)
            .then(response => {
                if (response.success) {
                    this.setState({
                        redirectTo: '/',
                        inProgress: false,
                        registeredMessage: true,
                        createdUser: response.createdUser
                    })
                } else {
                    Modal.warning({ content: response.message });
                    this.setState({ inProgress: false });
                }
            })
            .catch(error => {
                Modal.warning({ content: error.response.data.message });
                
                this.setState({
                    inProgress: false,
                    doesEmailExists: error.response.data.doesEmailExists ? true : false,
                });
            })}
        );
    }

    changeCaptcha(value) {
        this.setState({
            recaptchaValue: value,
        });
    }

    validateFormItemPassword = (rule, password, callback) => {
        if (validatePassword(password)) {
            callback();
        } else if (!isPasswordLongEnough(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsAtLeastOneDigit(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsUppercaseLetter(password)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        }
    }


    render() {
        const { 
            fields, 
            selectedMembershipType, 
            recaptchaValue, 
            invitationId, 
            acceptedTerms, 
            registeredMessage, 
            personalEmail, 
            createdUser
        } = this.state;

        if(registeredMessage) {
            return (
                <SuccesfulRegistration
                    email={personalEmail}
                    isFromInvitation={invitationId ? true : false}
                    createdUser={createdUser}
                />
            );
        }

        return (
            <div className="wrapped-content">
                <Form
                    onFinish={e => this.registerUser(e)}
                    fields={fields}
                >
                    {invitationId 
                        ?   
                            <h1>Register</h1>
                        : 
                            <MembershipType
                                onChange={membershipType => this.setState({ selectedMembershipType: membershipType })}
                                selectedMembership={selectedMembershipType}
                            />

                    }
                    <Col style={{ marginTop: 25 }} md={24}>
                        {selectedMembershipType === membershipType.CORPORATE_REPRESENTATIVE && 
                            <Form.Item
                                name="companyName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Corporate name is required',
                                    },
                                    {
                                        validator: this
                                            .comparePasswords,
                                    },
                                ]}
                                style={{ display: "block", marginTop: 16 }}
                            >
                                <Input
                                    prefix={
                                        <HomeOutlined
                                            style={{
                                                color:
                                                    'rgba(0,0,0,.25)',
                                            }}
                                        />
                                    }
                                    type="text"
                                    placeholder={'Corporate name *'}
                                    size="large"
                                />
                            </Form.Item>
                        }
                        <Form.Item
                            name="personalEmail"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Email is required',
                                },
                                {
                                    type: 'email',
                                    message:
                                        'Please enter a valid email address',
                                },
                            ]}
                            style={{ marginTop: 16 }}
                        >
                            <Input
                                prefix={
                                    <MailOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                type="text"
                                placeholder="Email *"
                                disabled={
                                    invitationId ? true : false
                                }
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Password is required',
                                },
                                { validator: this.validateFormItemPassword }
                            ]}
                            style={{ marginTop: 16 }}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                type="password"
                                placeholder="Password *"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue(
                                                'password'
                                            ) === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            'Passwords must be the same'
                                        );
                                    },
                                }),
                            ]}
                            style={{ marginTop: 16 }}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined
                                        style={{
                                            color: 'rgba(0,0,0,.25)',
                                        }}
                                    />
                                }
                                type="password"
                                placeholder="Retype password *"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item style={{ marginTop: 16 }}>
                            <p style={{ display: "block", textAlign: "left", marginLeft: 30}}>
                                <Checkbox
                                    checked={acceptedTerms}
                                    onChange={e => this.setState({ acceptedTerms: e.target.checked })}
                                    style={{ marginLeft: -30, marginRight: 14 }}
                                />
                                By checking this box and clicking the "Submit" button you are creating an ArbitralWomen account and you agree to ArbitralWomen's <a href="https://www.arbitralwomen.org/disclaimer/" target="_blank" rel="noopener noreferrer">Privacy Policy and Terms of Use</a>
                            </p>
                        </Form.Item>
                        <ReCAPTCHA
                            hl="en"
                            sitekey="6LdOcrcZAAAAACeM0UgS2WZWwaTKFcJlpugaI5Qz"
                            onChange={e => this.changeCaptcha(e)}
                        />
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            style={{
                                marginBottom: 10,
                                marginTop: 20,
                            }}
                            disabled={recaptchaValue && acceptedTerms ? false : true}
                            className="submit-button"
                        >
                            Submit
                        </Button>
                        {invitationId
                            ?
                                <Link to={`/invitations/${invitationId}`} style={{ textAlign: "left", display: "block" }}>
                                    Back to invitation
                                </Link>
                            :
                                <Link to={`/login`} style={{ textAlign: "left", display: "block" }}>
                                    Back to login page
                                </Link>
                        }
                    </Col>
                    <p className="additional-info">
                        ©2005–2021 All Rights Reserved. ArbitralWomen® is a registered trademark.
                    </p>
                </Form>
            </div>
        );
    }
}

export const RegisterView = withUser(RegisterViewInner);