import React from 'react';
import { Modal } from 'antd';
import queryString from 'query-string';

import { withUser } from '../../components/UserContext';
import { SetupCorporateForm } from '../../components/SetupCorporateForm';
import { 
    SetupSteps,
    PaymentCorporateForm,
    ThankYouCard
} from '../../components/registration';
import PageLoading from '../../components/PageLoading';

import { validateEmail } from '../../utils/validateEmail';
import { api } from '../../api';

class InitializeCorporateViewInner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedStep: 0,
            slotsAmount: 5,
            membersData: [],
            membersToDelete: [],
            corporationName: null,
            loading: true
        }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        
        const { user } = this.props;
        
        api.get(`corporations/${user.corporationId}/setup-status`)
            .then(res => res.data)
            .then(res => {
                this.setState({
                    loading: false
                })
                const { status } = res;
                const params = queryString.parse(this.props.location.search)

                if ((params && params.success) || status.renewaldate) {
                    if (params.success === 'true' || status.renewaldate) {
                        this.handleChangeStep(2);
                    } else {
                        Modal.warning({
                            title: "Payment canceled",
                            content: "Your payment was canceled",
                        });
                        this.handleChangeStep(1);
                    }
                } else {
                    if(status.first_name && status.last_name) {
                        this.handleChangeStep(1);
                    } else {
                        this.handleChangeStep(0);
                    }
                }
                this.fetchCorporation();
                this.fetchAddedMembers();     
            }) 
    }
    
    fetchCorporation = async () => {
        const corporation = await api.get(`/corporations/${this.props.user.corporationId}`);
        this.setState({
            corporationName: corporation.data.data && corporation.data.data.name
        })
    }

    fetchAddedMembers = async () => {
        const { user } = this.props;
        const members = await api.get(`/corporations/${user.corporationId}/members`);

        const oldMembersData = [];
        members.data.members.forEach(member => {
            const name = member.user_name;
            const spaceIndex = name.indexOf(' ');
            const memberAddedBefore = {
                firstName: name.substring(0, spaceIndex),
                lastName: name.substring(spaceIndex+1, name.length),
                email: member.member_email,
                addedBefore: true,
                id: member.id
            }
            oldMembersData.push(memberAddedBefore)
        })
        this.setState({
            membersData: oldMembersData,
            slotsAmount: oldMembersData.length > 5 ? oldMembersData.length : 5
        }, () => {
            if (oldMembersData.length < 5) {
                this.extendWithEmptyMembers(5 - oldMembersData.length)
            }
        })
    }

    extendWithEmptyMembers = (numberOfEmptyMembers) => {
        const emptyMembersData = [];
        for (let i = 0; i < numberOfEmptyMembers; ++i) {
            const emptyObject = {
                firstName: '',
                lastName: '',
                email: ''
            }
            emptyMembersData.push(emptyObject);
        }
        
        this.setState(prevState => {
            prevState.membersData.push.apply(prevState.membersData, emptyMembersData);
            return prevState;
        })
    }

    
    handleClickContinue = (form) => {
        const { firstName, lastName } = form;

        this.updateUserFullName(firstName, lastName);
    }
    
    updateUserFullName = (firstName, lastName) => {
        const { user } = this.props;

        api.post(`/users/change-name`, {
            user: user,
            firstName,
            lastName,
            userId: user.id,
        })
        .then(res => {
            this.setState({
                selectedStep: 1
            })
        })
        .catch()
    }

    addMembers = () => {       
        this.state.membersData
            .filter(m => m.firstName && m.lastName && validateEmail(m.email) && !m.addedBefore)
            .forEach(m => {
                const name = m.firstName + ' ' + m.lastName;
                const email = m.email;

                api.post(
                    `/corporations/${this.props.user.corporationId}/members`,
                    { name, email, no_invitation: true }
                );
            })
    }

    deleteMembers = () => {
        const { corporationId } = this.props.user;
        this.state.membersToDelete.forEach(async (member) => {
            await api.delete(`/corporations/${corporationId}/members/${member.id}`);
        })
    }

    handlePayment = () => {
        this.addMembers();
        this.deleteMembers();
        
        if (!Number.isInteger(this.state.slotsAmount)) {
            return Modal.warning({
                title: "Setup error",
                content: "Incorrect value of slots."
            });
        }
        
        api.post(`/payments/new-order`, {
            userId: this.props.user.id,
            type: 'corporate',
            numberOfMembers: this.state.slotsAmount,
            corporation_id: this.props.user.corporationId,
        }).then(res => {
            const { id } = res.data.data;
            window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}?initialPayment=true`);
        });
        
    }

    changeSlotsAmount = (e) => {
        if (e && e >= 5) {
            this.setState(prevState => {
                if (e > prevState.slotsAmount) {
                    for (let i = 0; i < e - prevState.slotsAmount; ++i) {
                        const emptyObject = {
                            firstName: '',
                            lastName: '',
                            email: ''
                        }
                        prevState.membersData.push(emptyObject);
                    }
                }
                else {
                    prevState.membersToDelete = prevState.membersData.slice(e).filter(m => m.id);
                    prevState.membersData.splice(e);
                }
                prevState.slotsAmount = e;
                return prevState;
            })
        }
    } 

    handleChangeInput = (e, field, index) => {
        const newValue = e.target.value;
        this.setState(prevState => {
            prevState.membersData[index][field] = newValue;
            return prevState
        })
    }

    handleChangeStep = (newStep) => {
        this.setState({
            selectedStep: newStep
        })
    }

    render() {
        const { selectedStep, slotsAmount, membersData, corporationName, loading } = this.state;

        if(loading) {
            return (
                <PageLoading/>
            )
        }

        return (
            <>
                <SetupSteps 
                    selectedStep={selectedStep} 
                />
                {selectedStep === 0 && 
                    <SetupCorporateForm 
                        handleClickContinue={this.handleClickContinue}
                        corporationName={corporationName}
                    />
                }
                {selectedStep === 1 && 
                    <PaymentCorporateForm 
                        membersData={membersData}
                        slots={slotsAmount}
                        onChangeInput={this.handleChangeInput}
                        onChangeSlots={this.changeSlotsAmount} 
                        handleClickPaymentButton={this.handlePayment}
                    />
                }
                {selectedStep === 2 &&
                    <div style={{ maxWidth: 800, margin: "50px auto" }}>
                        <ThankYouCard corporationSetup={true}/>
                    </div>
                }
            </>
        )
    }
}

export const InitializeCorporateView = withUser(InitializeCorporateViewInner);