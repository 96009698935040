import React, { useState } from 'react';
import {
    DownloadOutlined,
    LoadingOutlined,
    UploadOutlined,
    CameraFilled
} from '@ant-design/icons';
import {
    Row,
    Col,
    Card,
    Button,
    Upload,
    Modal,
    Input,
    DatePicker,
    Tooltip
} from 'antd';
import * as moment from 'moment';
import { api } from '../api';
import '../styles/ProfileView.css';
import { withUser } from './UserContext';
import ImgCrop from 'antd-img-crop';
import { formatShortDate, shortDateFormat } from '../utils';

const ProfileDataInner = props => {
    const [isImgLoading, setIsImgLoading] = useState(false);
    const [isCvLoading, setIsCvLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { profile, onCVUpload, onImgUpload } = props;

    function beforeImgUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isJpgOrPng) {
            Modal.warning({
                title: "Setup error",
                content: "You can only upload JPG/PNG file!"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Setup error",
                content: "Image must smaller than 30MB!"
            })
        }

        // if correct format and size read resolution
        if (isJpgOrPng && isLt30M) {
            const reader = new FileReader();
            const image = new Image();

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                image.src = e.target.result;
            };

            image.addEventListener('load', function () {
                setIsImgLoading(true);
                const formData = new FormData();
                formData.append('avatar', file);
                formData.append('id', profile.id ? profile.id : '');

                api.post(`/users/upload-img/${props.user.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(({ data }) => {
                        setIsImgLoading(false);
                        setIsModalVisible(false);
                        onImgUpload(data.data.image);
                    })
                    .catch(error => console.log(error));
            });
        }

        return false; // always return false to upload manually
    }

    function beforeCvUpload(file) {
        const isPdf = file.type === 'application/pdf';
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isPdf) {
            Modal.warning({
                title: "Setup error",
                content: "You can only upload PDF file!"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Setup error",
                content: "CV must smaller than 30MB!"
            })
        }

        if (isPdf && isLt30M) {
            setIsCvLoading(true);
            const formData = new FormData();
            formData.append('cv', file);
            formData.append('id', profile.id ? profile.id : '');

            api.post(
                `/members/upload-cv/${profile.id || props.user.id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
                .then(({ data }) => {
                    setIsCvLoading(false);
                    onCVUpload(data.data.cv);
                })
                .catch(error => console.log(error));
        }

        return false; // always return false to upload manually
    }

    return (
        <>
            {isModalVisible && (
                <Modal
                    title="Upload image"
                    visible={isModalVisible}
                    onOk={() => setIsModalVisible(false)}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                >
                    <ImgCrop
                        aspect={215 / 290}
                        grid
                    >
                        <Upload
                            showUploadList={false}
                            beforeUpload={beforeImgUpload}
                        >
                            <ul>
                                <li>Upload jpg/png file.</li>
                                <li>File must be less than 2MB.</li>
                            </ul>

                            <Button>
                                {isImgLoading
                                    ? <LoadingOutlined />
                                    : <UploadOutlined />
                                } Click to Upload
                            </Button>
                        </Upload>
                    </ImgCrop>
                </Modal>
            )}
            <Row className="u--row-spacer">
                <Tooltip
                    title="Click to change your profile image"
                >
                    <Col md={6} span={24} style={{ position: "relative", display: "flex", alignItems: "center" }} onClick={() => setIsModalVisible(true)}>
                        <img
                            src={profile.img || 'https://via.placeholder.com/215x290'}
                            style={{ width: '100%', display: "block" }}
                            alt="Member"
                            className="profile-logo"
                        />
                        <CameraFilled className="profile-logo-icon" />
                    </Col>
                </Tooltip>
                <Col md={1} span={0} />
                <Col md={17} span={24} className="u--secondary-background profile-data-name">
                    <Card
                        title={
                            <div className="profile-data-user-name">
                                <div
                                    className="u--spacer-right-2x"
                                    style={{
                                        width: 200,
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span className="no-bold">First name</span>
                                    <Input
                                        className="input-profile"
                                        value={profile.first_name}
                                        onChange={e =>
                                            props.updateProfil(
                                                'first_name',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div
                                    className="u--spacer-right-2x"
                                    style={{
                                        width: 200,
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span className="no-bold">Last name</span>
                                    <Input
                                        className="input-profile"
                                        value={profile.last_name}
                                        onChange={e =>
                                            props.updateProfil(
                                                'last_name',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        }
                        bordered={false}
                    >
                        <div>
                            <div
                                className="profile-data"
                            >
                                <div
                                    className="u--spacer-right-2x"
                                    style={{
                                        width: 200,
                                        display: 'inline-flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span className="no-bold">Date of birth (optional)</span>
                                    <DatePicker
                                        style={{ marginTop: 10 }}
                                        value={
                                            profile.birthdate
                                                ? moment(profile.birthdate)
                                                : null
                                        }
                                        onChange={date =>
                                            props.updateProfil(
                                                'birthdate',
                                                date && date.format()
                                            )
                                        }
                                        format={shortDateFormat}
                                    />
                                </div>
                                <div className="u--spacer-right-2x">
                                    <span className="no-bold">
                                        Member created
                                    </span>
                                    <h4 style={{ marginTop: 10 }}>
                                        {profile.created_at && formatShortDate(profile.created_at)}
                                    </h4>
                                </div>
                                {profile.approval_timestamp && (
                                    <div className="u--spacer-right-2x">
                                        <span className="no-bold">
                                            Approved on
                                        </span>
                                        <h4 style={{ marginTop: 10 }}>
                                            {formatShortDate(profile.approval_timestamp)}
                                        </h4>
                                    </div>
                                )}
                            </div>

                            {profile && profile.cv && (
                                <a href={profile.cv}>
                                    <Button
                                        type="primary"
                                        className="u--spacer-top-1x u--spacer-right-1x"
                                    >
                                        <DownloadOutlined /> Download CV
                                    </Button>
                                </a>
                            )}

                            <Upload
                                showUploadList={false}
                                beforeUpload={beforeCvUpload}
                            >
                                <Button
                                    type="primary"
                                    loading={isCvLoading}
                                    className="u--spacer-top-1x"
                                    onClick={() => { }}
                                >
                                    {!isCvLoading && <UploadOutlined />} Upload
                                    CV
                                </Button>
                            </Upload>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export const ProfileData = withUser(ProfileDataInner);
