import { Button, Checkbox, Col, DatePicker, Row } from 'antd'
import moment from 'moment';
import { shortDateFormat } from '../../utils/formatShortDate'
import React, { useState } from 'react'

export default function EditMembershipModal({ membership = {}, onSubmit, onCancel }) {
    const [validFrom, setValidFrom] = useState(moment(membership.valid_from) || null);
    const [validTo, setValidTo] = useState(moment(membership.valid_to) || null);
    const [isPaymentDeferred, setIsPaymentDeferred] = useState(membership.is_payment_deferred || false);

    return (
        <>
            <div>Choose the dates when membership will be valid</div>
            <Row gutter={[25, 25]}>
                <Col md={8} span={24}>
                    <div style={{ marginTop: 15 }}>Membership valid from:</div>
                    <DatePicker style={{ width: "100%" }} onChange={setValidFrom} value={validFrom} format={shortDateFormat} />
                </Col>
                <Col md={8} span={24}>
                    <div style={{ marginTop: 15 }}>Membership valid to:</div>
                    <DatePicker style={{ width: "100%" }} onChange={setValidTo} value={validTo} format={shortDateFormat} />
                </Col>
                <Col md={8} span={24}>
                    <div style={{ marginTop: 15 }}>Corporate will pay for this membership</div>
                    <Checkbox onChange={e => setIsPaymentDeferred(e.target.checked)} checked={isPaymentDeferred} />
                </Col>
            </Row>
            <Row justify="end">
                <Button type="default" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={() => onSubmit(membership.membership_id, validFrom.format(shortDateFormat), validTo.format(shortDateFormat), isPaymentDeferred)}
                    style={{
                        marginLeft: 15
                    }}
                >
                    Save
                </Button>
            </Row>
        </>
    )
}
