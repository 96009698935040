import React from 'react';
import {
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { Button, Card, Modal, Spin, Tag, Tooltip } from 'antd';

import { api } from '../api';
import moment from 'moment';
import JoinRequestModal from './JoinRequestModal';
import CustomTable from './CustomTable';
import { formatShortDate, renderDate } from '../utils';

export class CorporationsRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            action: '',
            id: null,
            loading: false,
            paymentModalVisible: false,
        };
    }
    columns = [
        {
            title: 'First name ',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last name ',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Email address',
            dataIndex: 'personal_email',
            key: 'personal_email',
        },
        {
            title: 'Status',
            dataIndex: 'valid_to',
            key: 'valid_to',
            render: endOfMembership => {
                const isActive = this.isMembershipActive(endOfMembership);
                return (
                    <>
                        {isActive ? 
                            <Tooltip title={`Membership valid to: ${formatShortDate(endOfMembership)}`}>
                                <Tag color='#237804'>Active</Tag>
                            </Tooltip>
                            : <Tag color='#ff4d4f'>Inactive</Tag>
                        }
                    </>
                )
            }
        },
        {
            title: 'Request Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDate,
        },
        {
            title: 'Action',
            key: 'id',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={ e => {
                            e.preventDefault();
                            this.props.getEstimatedPrice(record.user_id).then(() => {
                                this.setState({paymentModalVisible: true});
                                this.props.handleChangeRecord(record);
                                this.props.fetchUserMembershipStatus(record.user_id)          
                            })
                                            

                        }}

                        style={{ paddingLeft: 0 }}
                    >
                        <CheckOutlined /> Approve
                    </Button>
                    <Button
                        type="link"
                        onClick={e => confirm(record,this.handleAction,e)}
                        style={{ paddingLeft: 0 }}
                    >
                        <CloseOutlined /> Reject
                    </Button>
                </span>
            ),
        },
    ];

    componentDidMount() {
        this.fetchData();
    }
    handlePaymentModalVisibility = state => {
        this.setState({
            paymentModalVisible: state
        })
        this.props.changeExistingMemberModalVisibility(false);
    }

    handleAction = (e, record, actionType) => {
        e.preventDefault();
        let actionResult = actionType === 'approve' ? true : false;
        this.setState({ action: actionResult, id: record.request_id || record.id }, () =>
            this.patchRequest()
        );
    }; 

    patchRequest = () => {
        const { id, action } = this.state;
        api.patch(`/corporationJoinRequest/${id}?action=${action}`)
            .then(() =>
                this.setState({ id: null, action: '', loading: true }, () =>
                    this.fetchData()
                )
            )
            .then(() => this.props.handleRefresh());
    };
    fetchData = () => {
        this.setState({ loading:true })

        api
            .get(`/corporationJoinRequest/corporations/${this.props.corporationId}`)
            .then(result => result.data)
            .then(result => {
                const filteredData = result.data.filter(d => d.approval_status === null);

                this.setState({ data: filteredData, loading: false });
            });
    };

    isMembershipActive = (endOfMembership) => {
        return endOfMembership && moment(endOfMembership).isAfter(moment());
    }

    render() {

        const { avaliableSlots, triggerModal, recordMembershipStatus, record, estimatedCost } = this.props;
        const { data, loading,paymentModalVisible } = this.state;
        return (
            <Spin spinning={loading}>
                <JoinRequestModal visible={paymentModalVisible || triggerModal} 
                              record={record} 
                              handlePaymentModalVisibility={this.handlePaymentModalVisibility}
                              footer={null}
                              recordMembershipStatus={recordMembershipStatus}
                              estimatedCost={estimatedCost}
                              avaliableSlots={avaliableSlots}
                              handleAction={this.handleAction}
                              {...this.props}
                />

                {data && data.length > 0 && (
                    <>
                        <Card
                            className="custom-card"
                            title={
                                <div className="custom-card-header blue-underline">
                                    Corporate join requests
                                </div>
                            }
                        >
                            <CustomTable
                                columns={this.columns}
                                dataSource={data}
                                loading={loading}
                                rowKey="id"
                                pagination={{ pageSize: 10 }}
                            />
                        </Card>
                    </>
                )}
            </Spin>
        );
    }
}


function confirm(record, action, onClickEvent) {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `${record.first_name} ${record.last_name} sent a request to join your corporate. Are you sure that you want to reject this member?`,
      okText: 'Confirm rejection',
      cancelText: 'Cancel',
      onOk:()=> action(onClickEvent, record, 'reject')
    });
  }