import React from 'react';
import { Row, Col, Input, Button, Form } from 'antd';

export class SetupCorporateForm extends React.Component {
    render() {
        return (
            <Form onFinish={this.props.handleClickContinue}>          
                <Row justify="center" style={{ padding: 25 }}>
                    <Col span={24} className="setup-text">
                        Setup your Corporate account by providing the first and last name of the person in charge of the account.
                    </Col>
                </Row>
                <Row justify="center" className="margin-top-20">
                    <Col md={8} span={24}>
                        <Form.Item
                            name="firstName"
                            rules={
                                [{ required: true, message: "First name is required" }]
                            }
                            className="setup-name"
                        >
                            <Input 
                                placeholder="First name" 
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col md={8} span={24}>
                        <Form.Item
                            name="lastName"
                            rules={
                                [{ required: true, message: "Last name is required" }]
                            }
                            className="setup-name"
                        >
                            <Input 
                                placeholder="Last name" 
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col md={8} span={24} style={{ textAlign: "center", marginTop: 16 }}>
                        <Button type="primary" className="submit-button" htmlType="submit" size="large" block style={{ maxWidth: 150 }}>
                            Continue
                        </Button> 
                    </Col>
                </Row>
            </Form>
        )
    }
}