import React from "react";
import BoardSection from "../../components/BoardSection";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { Draggable, Container } from "react-smooth-dnd";
import "../../styles/BoardsView.css";
import {
  Spin,
  Button,
  DatePicker,
  Layout,
  Card,
  Popconfirm,
  Modal,
  Input,
  Col,
} from "antd";
import { api } from "../../api";
import CustomTable from "../../components/CustomTable";
import { PlusCircleOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Content } = Layout;

export class YampBoard extends React.PureComponent {
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, role) => (
        <Popconfirm
          onConfirm={() => this.deleteRole(role.id)}
          title={`Are you sure want to delete role "${role.name}"`}
        >
          <Button style={{ paddingLeft: 0 }} type="link">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  constructor(props) {
    super(props);

    this.refsCollection = {};

    this.state = {
      id: "",
      loading: true,
      sections: [],
      date: [],
      options: [],
      titles: [],
      roles: [],
      displayRoleModal: false,
      modalVisible: false,
      newRoleName: "",
      idBoard: "",
    };
  }
  roleColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, role) => (
        <Popconfirm
          onConfirm={() => this.deleteRole(role.id)}
          title={`Are you sure want to delete role "${role.name}"`}
        >
          <Button style={{ paddingLeft: 0 }} type="link">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  componentDidMount() {
    const self = this;
    window.scrollTo(0, 0);
    async function createBoardIfNotExists() {
      const requestBoards = await api.get(`/boards-yawp`);
      const isBoardArrayEmpty = requestBoards.data.boards.length === 0;
      if (isBoardArrayEmpty) {
        let id = uuid();
        let board = {
          id: id,
          startDate: null,
          endDate: null,
          hierarchy: [],
        };
        const requestCreateBoardsYawp = await api.post(`/boards-yawp`, {
          board,
        });
        let section = {
          id: uuid(),
          title: "",
          displayPhotos: false,
          members: [],
          children: [],
        };
        self.setState({
          sections: [...self.state.sections, section],
        });
      } else {
        const boardRegistered = requestBoards.data.boards[0];
        const idBoardRegistered = boardRegistered.id;
        const requestBoard = await api.get(`/boards-yawp/${idBoardRegistered}`);
        if (!requestBoard.data.board.hierarchy.length) {
          let section = {
            id: uuid(),
            title: "",
            displayPhotos: false,
            members: [],
            children: [],
          };
          self.setState({
            sections: [...self.state.sections, section],
          });
        } else {
          self.setState({
            sections: requestBoard.data.board.hierarchy
          });
        }
        self.setState({
          idBoard: idBoardRegistered,
        });
      }
      self.setState({ loading: false });
    }
    createBoardIfNotExists();
    this.props.changeTitle("YAWP Board");
    this.fetchBoardTitles();
    this.fetchMembers();
    this.fetchBoardRoles();
  }
  fetchBoardTitles = () => {
    api
      .get("/boards/titles")
      .then((response) => this.setState({ titles: response.data.titles }));
  };

  fetchMembers() {
    api
      .get("/members")
      .then((res) => this.setState({ options: res.data.members }));
  }

  fetchBoardRoles = () => {
    api.get("/boards_roles").then((response) => {
      this.setState({ roles: response.data });
    });
  };
  changeModalVisibility = (visibility) => {
    this.setState({
      modalVisible: visibility,
    });
  };
  displayRoleModal() {
    this.setState({
      displayRoleModal: true,
      newRoleName: "",
    });
  }
  addRole = () => {
    const { newRoleName } = this.state;

    if (newRoleName !== "") {
      api.post("/boards_roles", { role: newRoleName }).then(() => {
        this.fetchBoardRoles();
        this.setState({
          newRoleName: "",
          displayRoleModal: false,
        });
      });
    }
  };
  render() {
    return (
      <>
        <Content className="content">
          <Spin spinning={this.state.loading}>
            <Card
              className="custom-card"
              title={
                <div className="custom-card-header blue-underline">
                  YAWP Steering Committee Members
                </div>
              }
              extra={
                <Col style={{ display: "flex" }} className="row">
                  <Button
                    onClick={() => this.changeModalVisibility(true)}
                    type="link"
                    style={{
                      marginTop: -10,
                      marginRight: 10,
                    }}
                  >
                    Manage roles
                  </Button>
                  <Button
                    style={{
                      marginTop: -10,
                    }}
                    type="primary"
                    className="save"
                    onClick={() => this.saveBoard()}
                  >
                    Save board
                  </Button>
                </Col>
              }
            >
            </Card>
            <Container
              onDrop={(result) => this.reorderChildrens(result)}
              dragHandleSelector=".drag-handler-section"
            >
              {this.state.sections ? (
                this.state.sections.map((section, id) => (
                  <Draggable key={section.id}>
                    <BoardSection
                      data={section}
                      level={0}
                      handleDelete={() => this.removeSection(section.id)}
                      ref={(instance) => {
                        this.refsCollection[id] = instance;
                      }}
                      options={this.state.options}
                      boardsTitles={this.state.titles.filter(
                        (title) => title.type === "MAIN TITLE"
                      )}
                      sectionsTitles={this.state.titles.filter(
                        (title) => title.type === "COMMITTEE"
                      )}
                      roles={this.state.roles}
                      isYamp={true}
                    />
                  </Draggable>
                ))
              ) : (
                <></>
              )}
            </Container>
            {/* <Button
              size="large"
              type="dashed"
              block
              onClick={() => this.addSection()}
            >
              + Add section
            </Button> */}
          </Spin>
          <Modal
            visible={this.state.modalVisible}
            bodyStyle={{ padding: 0, borderBottom: "unset" }}
            closable={false}
            footer={null}
            width={600}
          >
            <Card
              title="Manage board roles"
              extra={
                <Button type="primary" onClick={() => this.displayRoleModal()}>
                  <PlusCircleOutlined />
                  Add role
                </Button>
              }
              onCancel={() => this.changeModalVisibility(false)}
            >
              <CustomTable
                columns={this.roleColumns}
                dataSource={this.state.roles}
                pagination={{ pageSize: 10 }}
              />
              <Button
                style={{ display: "block", marginLeft: "auto", marginTop: 25 }}
                type="primary"
                onClick={() => this.changeModalVisibility(false)}
              >
                OK
              </Button>
              <Modal
                visible={this.state.displayRoleModal}
                onOk={() => this.addRole()}
                onCancel={() => this.setState({ displayRoleModal: false })}
                title="Add role"
                width={450}
              >
                Role name
                <Input
                  value={this.state.newRoleName}
                  onChange={(e) => {
                    this.setState({
                      newRoleName: e.target.value,
                    });
                  }}
                />
              </Modal>
            </Card>
          </Modal>
          <Modal
            visible={this.state.displayRoleModal}
            onOk={() => this.addRole()}
            onCancel={() => this.setState({ displayRoleModal: false })}
            title="Add role"
            width={450}
          >
            Role name
            <Input
              value={this.state.newRoleName}
              onChange={(e) => {
                this.setState({
                  newRoleName: e.target.value,
                });
              }}
            />
          </Modal>
        </Content>
      </>
    );
  }

  addSection() {
    let section = {
      id: uuid(),
      title: "",
      displayPhotos: false,
      members: [],
      children: [],
    };
    this.setState({
      sections: [...this.state.sections, section],
    });
  }

  removeSection(id) {
    this.setState((state) => {
      const sections = state.sections.filter((section) => section.id !== id);
      return { sections };
    });
  }

  async saveBoard() {
    let data = [];

    for (var ref in this.refsCollection) {
      if (this.refsCollection[ref]) {
        data[ref] = this.refsCollection[ref].saveData();
      }
    }

    data = data.filter((x) => x !== null);
    this.setState({
      loading: true,
    });
    const requestUpdateBoard = await api.patch(
      `/boards-yawp/${this.state.idBoard}`,
      {
        hierarchy: data,
        startDate: moment(this.state.date[0]).isValid()
          ? moment(this.state.date[0]).format()
          : null,
        endDate: moment(this.state.date[1]).isValid()
          ? moment(this.state.date[1]).format()
          : null,
      }
    );
    this.setState({
      loading: false,
    });
  }

  handleChangeDate(date) {
    this.setState({ date });
  }

  reorderChildrens(result) {
    const { removedIndex, addedIndex } = result;
    const { sections } = this.state;

    const deletedItem = sections[removedIndex];

    this.setState((prevState) => {
      const sections = prevState.sections.filter(
        (_, index) => index !== removedIndex
      );
      sections.splice(addedIndex, 0, deletedItem);

      return { sections };
    });
  }
}
