import React from 'react';
import { Layout, Spin, Modal, Input, Form } from 'antd';
import { api } from '../api';
import { DocumentsList } from '../components/DocumentsList';
import '../styles/DocumentsAdminView.css';
import { withUser } from '../components/UserContext';
import Filters from '../components/utils/Filters';
import Filter from '../components/utils/Filter';
import buildQueryStringFromFilters from '../utils/buildQueryStringFromFilters';

const { Content } = Layout;

const defaultFilters = {
    category: "all"
}

export class DocumentsViewViewInner extends React.Component {
    state = {
        isLoading: true,
        documents: [],
        categories: [],
        selectedCategoryId: 'all',
        selectedCategory: {},
        uploading: false,
        editedId: null,
        editedCategory: {},
        filters: defaultFilters,
        totalCount: 0,
        page: 1
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Documents');
        this.readCategoryFromParams();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params && prevProps.match.params && this.props.match.params.id !== prevProps.match.params.id) {
            this.readCategoryFromParams();
        }
    }

    readCategoryFromParams = () => {
        this.setState(prevState => {
            const category = this.props.match.params && this.props.match.params.id;
            prevState.filters.category = category !== 'all' ? parseInt(category) : 'all';
            return prevState;
        }, () => this.fetchData())
    }

    fetchData = async () => {
        const { filters, page } = this.state;
        this.setState({ isLoading: true });

        const query = buildQueryStringFromFilters(filters)

        const [categories, documents] = await Promise.all([
            api.get(`/documents/categories`),
            api.get(`/documents${query}&page=${page}`),
        ]);

        const { data } = documents;

        this.setState({
            documents: data.documents,
            totalCount: data.total_count,
            categories: categories.data,
            isLoading: false,
            uploading: false,
        });
    };

    handleCategoryChange = categoryId => {
        this.setState({ selectedCategory: categoryId }, this.fetchData);
    };

    handleCategoryCreate = name => {
        api.post(`/documents/categories`, { name }).then(() => {
            this.fetchData();
        });
    };

    handleCategoryDelete = categoryId => {
        api.delete(`/documents/categories/${categoryId}`)
            .then(() => {
                this.setState({ selectedCategory: 'all' }, () => {
                    this.fetchData();
                });
            })
            .catch(() =>
                Modal.warning({
                    title: "Deleting error",
                    content: "Unable to delete category. Make sure all documents are moved to different category or deleted."
                })
            );
    };

    handleCategoryRename = name => {
        api.patch(`/documents/categories`, {
            id: this.state.selectedCategory,
            name,
        }).then(() => this.fetchData());
    };

    handleDocumentUpload = async (subject, comments, categoryId, date, file, author) => {
        try {
            this.setState({ uploading: true });
            const formData = new FormData();
            formData.append('document', file);

            const uploadResp = await api.post(`/documents/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            await api.post(`/documents`, {
                original_filename: file.name,
                location: uploadResp.data.data.location,
                subject,
                comments,
                document_date: date,
                category_id: categoryId,
                author
            });
            this.fetchData();
        } catch (error) {
            console.log('Upload error: ', error);
        }
    };

    handleDocumentEdit = async (id, subject, comments, categoryId, date, filesList, author) => {
        this.setState({ uploading: true });
        let uploadResp;

        if(filesList.length > 0) {
            const formData = new FormData();
            formData.append('document', filesList[0]);

            uploadResp = await api.post(`/documents/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }

        api.patch(`/documents`, {
            id,
            subject,
            comments,
            category_id: categoryId,
            document_date: date,
            location: uploadResp && uploadResp.data && uploadResp.data.data,
            author
        }).then(() => this.fetchData());
    };

    handleDocumentDownload = id => {
        api.get(`/documents/${id}`)
            .then(({ data }) => {
                window.open(data.location, '_blank');
            });
    };

    handleDocumentDelete = id => {
        api.delete(`/documents/${id}`)
            .then(() => {
                this.setState({ selectedCategoryId: 'all' }, () => {
                    this.fetchData();
                });
            })
            .catch(error => console.log(error));
    };

    submitForm(values) {
        const { editedId } = this.state;

        if(editedId) {
            api
                .patch(`/documents/categories/${editedId}`, {
                    ...values
                })
                .then(() => {
                    this.setState({ 
                        displayModal: false, 
                        selectedCategory: {
                            ...values
                        }
                    })
                    this.fetchData();
                })
        } else {
            api
                .post(`/documents/categories`, {
                    ...values
                })
                .then(() => {
                    this.setState({ displayModal: false })
                    this.fetchData();
                })
        }
    }

    selectCategory(categoryId) {
        const { categories } = this.state;

        this.setState({
            selectedCategoryId: categoryId,
            selectedCategory: categories.find(category => category.id === categoryId) || {}
        }, () => {
            this.fetchData()
        })
    }

    addCategory() {
        this.setState({
            displayModal: true,
            editedId: null
        })
    }

    closeModal() {
        this.setState({
            displayModal: false,
            editedCategory: {}
        })
    }

    editSelectedCategory() {
        const { selectedCategoryId, categories } = this.state;

        this.setState({
            editedId: selectedCategoryId,
            editedCategory: categories.find(category => category.id === selectedCategoryId),
            displayModal: true
        })
    }

    deleteSelectedCategory() {
        const { selectedCategoryId } = this.state;

        api
            .delete(`/documents/categories/${selectedCategoryId}`)
            .then(() => {
                this.setState({
                    selectedCategoryId: 'all'
                })
                this.fetchData();
            })
    }

    clearFilters() {
        this.setState({
            filters: defaultFilters
        }, () => {
            this.fetchData();
        })
    }

    updateFilters(filter, value) {
        this.setState(prevState => {
            return {
                filters: {
                    ...prevState.filters,
                    [filter]: value
                }
            }
        })
    }

    submitFilters() {
        this.setState({
            page: 1
        }, () => {
            this.fetchData()
        })
    }

    changePage(page) {
        this.setState({
            page
        }, () => {
            this.fetchData()
        })
    }

    render() {
        const {
            isLoading,
            documents,
            categories,
            uploading,
            displayModal,
            editedId,
            editedCategory,
            filters,
            totalCount,
            page
        } = this.state;

        return (
            <>
                <Modal
                    visible={displayModal}
                    title={editedId ? "Edit category" : "Add category"}
                    onCancel={() => this.closeModal()}
                    okButtonProps={{ form: "category-form", key: 'submit', htmlType: 'submit' }}
                    destroyOnClose={true}
                >
                    <Form
                        id="category-form"
                        onFinish={values => this.submitForm(values)}
                        initialValues={{
                            name: editedCategory.name,
                            description: editedCategory.description
                        }}
                    >
                        <p style={{ marginBottom: 0 }}>Name</p>
                        <Form.Item
                            name="name"
                            rules={[
                                {required: true, message: "Category name is required"}
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <p className="margin-top-10" style={{ marginBottom: 0 }}>Description</p>
                        <Form.Item
                            name="description"
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
                <Content className="content">
                    <Spin spinning={isLoading} size="large">
                        <Filters
                            onClearFilters={() => this.clearFilters()}
                            onFinish={() => this.submitFilters()}
                        >
                            <Filter
                                title="Category"
                                type="select"
                                defaultValue="all"
                                value={filters.category}
                                options={[
                                    {id: "all", name: "All"},
                                    ...categories
                                ]}
                                optionProperty={{
                                    key: "id",
                                    value: "id",
                                    display: "name"
                                }}
                                onChange={categoryID => this.updateFilters("category", categoryID)}
                            />
                        </Filters>
                        <DocumentsList
                            documents={documents}
                            categories={categories}
                            onDocumentUpload={this.handleDocumentUpload}
                            onDocumentEdit={this.handleDocumentEdit}
                            onDocumentDownload={this.handleDocumentDownload}
                            onDocumentDelete={this.handleDocumentDelete}
                            uploading={uploading}
                            fetchData={() => this.fetchData()}
                            page={page}
                            totalCount={totalCount}
                            onChangePage={page => this.changePage(page)}
                        />
                    </Spin>
                </Content>
            </>
        );
    }
}

export const DocumentsView = withUser(DocumentsViewViewInner);
