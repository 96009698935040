import React from 'react'

export default function EnteredFields({ fieldsList }) {
    return (
        <div>
            <div className="user-details element">
                <p>Organization name</p>
                {fieldsList.organization_name}
            </div>
            <div className="user-details element">
                <p>Country</p>
                {fieldsList.country}
            </div>
            <div className="user-details element">
                <p>City</p>
                {fieldsList.city}
            </div>
            <div className="user-details element">
                <p>Additional fields</p>
                {fieldsList.additional_fields && fieldsList.additional_fields.map((field, key) => 
                    <div key={key} className="list">
                        <div>
                            <span>{field.label}</span>
                        </div>
                        <div>
                            <span>{field.value}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
