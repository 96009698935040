import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Button, Input, Card, Select } from "antd";
import { countryCodes } from "../constants/countries";
import "../styles/ProfileView.css";

const { Option, OptGroup } = Select;

class CorporationDataFormInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = e => {
        e.preventDefault();
        const { form, onSubmit } = this.props;

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { corporation, form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form method="post" layout="inline" onSubmit={this.handleSubmit}>
                <Card
                    className="custom-card"
                    title={
                        <div className="blue-underline no-bold">Address</div>
                    }
                >
                    <Row className="u--spacer-bottom-1x">
                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Firm/Organisation
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_organisation", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_organisation,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Email
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_email", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_email,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Website
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_website", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_website,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="u--spacer-bottom-1x">
                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Street address
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_street", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_street,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                APT/Suite #
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_apt", {
                                    initialValue:
                                        corporation && corporation.address_apt,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Postal code
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_postal", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_postal,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} span={24}>
                            <span className="profile-address__label">City</span>
                            <Form.Item>
                                {getFieldDecorator("address_city", {
                                    initialValue:
                                        corporation && corporation.address_city,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                State
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_state", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_state,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Country
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_country", {
                                    initialValue:
                                        corporation &&
                                        corporation.address_country,
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Country"
                                        className="profile-address__input"
                                        style={{ width: 258 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryCodes.map(c => (
                                            <Option key={c.name} value={c.name}>{c.name}</Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div className="profile-phone">
                    <Card
                        className="custom-card"
                        title={
                            <div className="blue-underline no-bold">
                                Work phone
                            </div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator(
                                        "work_phone_country_code",
                                        {
                                            initialValue:
                                                corporation &&
                                                corporation.work_phone_country_code,
                                        }
                                    )(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            optionFilterProp="children"
                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup key={c.name} label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("work_phone_area_code", {
                                        initialValue:
                                            corporation &&
                                            corporation.work_phone_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("work_phone_number", {
                                    initialValue:
                                        corporation &&
                                        corporation.work_phone_number,
                                })(<Input placeholder="707-2577" />)}
                            </Form.Item>
                        </div>
                    </Card>

                    <Card
                        className="custom-card"
                        title={
                            <div className="blue-underline no-bold">
                                Work fax
                            </div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator(
                                        "work_fax_country_code",
                                        {
                                            initialValue:
                                                corporation &&
                                                corporation.work_fax_country_code,
                                        }
                                    )(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            optionFilterProp="children"
                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup key={c.name} label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("work_fax_area_code", {
                                        initialValue:
                                            corporation &&
                                            corporation.work_fax_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("work_fax_number", {
                                    initialValue:
                                        corporation &&
                                        corporation.work_fax_number,
                                })(<Input placeholder="707-2577" />)}
                            </Form.Item>
                        </div>
                    </Card>

                    <Card
                        className="custom-card"
                        title={
                            <div className="blue-underline no-bold">Mobile</div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("mobile_country_code", {
                                        initialValue:
                                            corporation &&
                                            corporation.mobile_country_code,
                                    })(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            optionFilterProp="children"
                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup key={c.name} label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("mobile_area_code", {
                                        initialValue:
                                            corporation &&
                                            corporation.mobile_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("mobile_number", {
                                    initialValue:
                                        corporation &&
                                        corporation.mobile_number,
                                })(<Input placeholder="707-2577" />)}
                            </Form.Item>
                        </div>
                    </Card>
                </div>

                <div className="profile-full-width-button u--spacer-bottom-1x  u--spacer-top-1x">
                    <Button
                        type="primary"
                        ghost
                        size="large"
                        onClick={this.handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            </Form>
        );
    }
}

export const CorporationDataForm = Form.create({ name: "corporationData" })(
    CorporationDataFormInner
);
