import React from 'react';
import BoardSection from '../../components/BoardSection';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Draggable, Container } from 'react-smooth-dnd';
import '../../styles/BoardsView.css';
import { Spin, Button, DatePicker, Layout, Card, Popconfirm } from 'antd';
import { api } from '../../api';
import { shortDateFormat } from '../../utils';

const { RangePicker } = DatePicker;
const { Content } = Layout;

export class BoardView extends React.PureComponent {
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, role) => (
                <Popconfirm onConfirm={() => this.deleteRole(role.id)} title={`Are you sure want to delete role "${role.name}"`}>
                    <Button style={{ paddingLeft: 0 }} type="link">Delete</Button>
                </Popconfirm>
            )
        }
    ]

    constructor(props) {
        super(props);

        this.refsCollection = {}

        this.state = {
            id: '',
            loading: true,
            sections: [],
            date: [],
            options: [],
            titles: [],
            roles: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Board');

        api.get(`/boards/${this.props.match.params.id}`).then(response => {
            this.setState(
                {
                    sections: response.data.board
                        ? response.data.board.hierarchy
                        : [],
                    date: [
                        response.data.board.start_date,
                        response.data.board.end_date,
                    ],
                },
                () => {
                    this.setState({
                        loading: false,
                    });
                }
            );
        });

        this.fetchBoardTitles();
        this.fetchMembers();
        this.fetchBoardRoles();
    }

    fetchBoardTitles = () => {
        api
            .get('/boards/titles')
            .then(response => this.setState({ titles: response.data.titles }));
    }

    fetchMembers() {
        api
            .get('/members')
            .then(res => this.setState({ options: res.data.members }));
    }

    fetchBoardRoles = () => {
        api
            .get('/boards_roles')
            .then(response => this.setState({ roles: response.data }))
    }

    render() {
        return (
            <>
                <Content className="content">
                    <Spin spinning={this.state.loading}>
                        <Card
                            className="custom-card"
                            title={
                                <div className="custom-card-header blue-underline">
                                    Board
                                </div>
                            }
                            extra={
                                <Button
                                    style={{
                                        marginTop: -10
                                    }}
                                    type="primary"
                                    className="save"
                                    onClick={() => this.saveBoard()}
                                >
                                    Save board
                                </Button>
                            }
                        >
                            <div style={{ marginBottom: 5 }}>Start and End Date</div>
                            <RangePicker
                                className="calendar"
                                onChange={(e, date) =>
                                    this.handleChangeDate(date)
                                }
                                value={
                                    this.state.date[0] && this.state.date[1]
                                        ? [
                                            moment(this.state.date[0]),
                                            moment(this.state.date[1]),
                                            shortDateFormat,
                                        ]
                                        : []
                                }
                                placeholder={["Start Date", "End Date"]}
                                format={shortDateFormat}
                            />
                        </Card>
                        <Container onDrop={result => this.reorderChildrens(result)} dragHandleSelector=".drag-handler-section">
                            {this.state.sections.map((section, id) => (
                                <Draggable key={section.id}>
                                    <BoardSection
                                        data={section}
                                        level={0}
                                        handleDelete={() => this.removeSection(section.id)}
                                        ref={instance => {
                                            this.refsCollection[id] = instance;
                                        }}
                                        options={this.state.options}
                                        boardsTitles={this.state.titles.filter(title => title.type === "MAIN TITLE")}
                                        sectionsTitles={this.state.titles.filter(title => title.type === "COMMITTEE")}
                                        roles={this.state.roles}
                                        isYamp={false}
                                    />
                                </Draggable>
                            ))}
                        </Container>
                        <Button
                            size="large"
                            type="dashed"
                            block
                            onClick={() => this.addSection()}
                        >
                            + Add section
                        </Button>
                    </Spin>
                </Content>
            </>
        );
    }

    addSection() {
        this.setState(state => {
            let sections = state.sections.concat({
                id: uuid(),
                title: '',
                displayPhotos: false,
                members: [],
                children: []
            })

            return { sections }
        });
    }

    removeSection(id) {
        this.setState(state => {
            const sections = state.sections.filter(
                section => section.id !== id
            );
            return { sections };
        });
    }

    saveBoard() {
        let data = [];

        for (var ref in this.refsCollection) {
            if (this.refsCollection[ref]) {
                data[ref] = this.refsCollection[ref].saveData();
            }
        }

        data = data.filter(x => x !== null);

        this.setState({
            loading: true,
        }, () => {
            api.patch(`/boards/${this.props.match.params.id}`, {
                hierarchy: data,
                startDate: moment(this.state.date[0]).isValid()
                    ? moment(this.state.date[0]).format()
                    : null,
                endDate: moment(this.state.date[1]).isValid()
                    ? moment(this.state.date[1]).format()
                    : null,
            }).then(() => {
                this.setState({
                    loading: false,
                });
            });
        });
    }

    handleChangeDate(date) {
        this.setState({ date });
    }

    reorderChildrens(result) {
        const { removedIndex, addedIndex } = result;
        const { sections } = this.state;

        const deletedItem = sections[removedIndex];

        this.setState(prevState => {
            const sections = prevState.sections.filter((_, index) => index !== removedIndex);
            sections.splice(addedIndex, 0, deletedItem)

            return { sections }
        })
    }
}
