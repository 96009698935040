import React from "react";
import { Input, Select, Col, Row } from "antd";
import "../../../styles/EventsView.css";

const { Option } = Select;


export function CouponsContent({currency, coupon:{name,amount,type} }) {
    return (
        <>
            <p>{name}</p>
            Discount: {amount}{type === "percentage" ? "%" : ""}
        </>
    );
}

export function CouponsModal({ editedField, onUpdate, currency }) {
 
    return (
        <>
            <p style={{ marginBottom: "5px" }}>Name</p>
            <Input
                value={editedField.name}
                onChange={e => onUpdate("name", e.target.value)}
            />
            <p className="margin-top-20" style={{ marginBottom: "5px" }}>
                Discount
            </p>
            <Row gutter={[48, 16]}>
                <Col span={8}>
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Coupon type"
                        value={editedField.type}
                        onChange={e => {onUpdate("type", e); }}
                    >
                        <Option value="amount">Amount</Option>
                        <Option value="percentage">Percentage</Option>
                    </Select>
                </Col>
                <Col span={11}>
                    <Input
                        type="number"
                        suffix={editedField.type === "percentage" ? "%" : ""}
                        placeholder="Value"
                        value={editedField.amount}
                        onChange={e => onUpdate("amount", e.target.value)}
                    />
                </Col>

                <Col span={5}>
                </Col>
            </Row>
        </>
    );
}