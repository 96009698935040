import React, { useState, useEffect } from 'react';
import { api } from "../api";
import { roles as allRoles } from '../utils/userRoles';

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
    const [first_name, setFirstName] = useState(null);
    const [last_name, setLastName] = useState(null);
    const [id, setId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [img, setImg] = useState(null);
    const [email, setEmail] = useState(null);
    const [corporationId, setCorporationId] = useState(null);
    const [corporationName, setCorporationName] = useState(null);
    const [membershipStatus, setMembershipStatus] = useState(null);
    const [isContactPerson, setIsContactPerson] = useState(null);
    const [hasMemberProfile, setHasMemberProfile] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        api
            .get("/user")
            .then(response => {
                if(response.data) {
                    login(response.data);
                }
                setLoading(false);
            })
    }, [])


    const login = (user) => {
        setId(user.id);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setRoles(user.roles);
        setImg(user.img);
        setEmail(user.email);
        setCorporationId(user.corporationId);
        setCorporationName(user.companyName);
        setMembershipStatus(user.membershipStatus);
        setIsContactPerson(user.is_corporate_representative);
        setHasMemberProfile(user.hasMemberProfile);
    }

    const logout = () => {
        api
            .post("/logout")
            .then(response => {
                if(response.data.logout) {
                    setId(null);
                    setFirstName(null);
                    setLastName(null);
                    setRoles([]);
                    setImg(null);
                    setEmail(null);
                    setCorporationId(null);
                    setCorporationName(null);
                    setMembershipStatus(null);
                    setIsContactPerson(null);
                    setHasMemberProfile(null);
                }
            })
    };

    const changeName = (firstName, lastName) => {
        setFirstName(firstName);
        setLastName(lastName);
    };

    const changeMembership = (membershipStatus) => {
        setMembershipStatus(membershipStatus);
    };

    const changeEmail = (email) => {
        setEmail(email);
    };
    
    const changeImage = (image) => {
        setImg(image);
    }
    
    const isAdmin = () => roles.indexOf(allRoles.ADMIN) > -1;
    const isMember = () => roles.indexOf(allRoles.INDIVIDUAL_MEMBER) > -1;
    const isBoard = () => roles.indexOf(allRoles.BOARD_MEMBER) > -1;

    return (
        <UserContext.Provider
            value={{
                id,
                first_name,
                last_name,
                roles,
                email,
                img,
                corporationId,
                corporationName,
                membershipStatus,
                isLoading,
                isContactPerson,
                hasMemberProfile,
                login,
                logout,
                changeMembership,
                changeName,
                changeEmail,
                changeImage,
                isAdmin,
                isMember,
                isBoard
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const withUser = Component => props => (
    <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
    </UserContext.Consumer>
);
