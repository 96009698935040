import React from 'react';
import { Table } from 'antd';

export default function CustomTable({ dataSource, columns, rowKey, pagination, style, rowSelection, onChangePaginationPublication }) {
    return (
        <>
            <div className="table-mobile" style={style}>
                {dataSource && Array.isArray(dataSource) && dataSource.map((data, index) => (
                    <div className="table-row" key={index}>
                        {columns && Array.isArray(columns) && columns.map((column, index) => (
                            <div className="table-column" key={index}>
                                <b style={{ fontWeight: 600 }}>{column.title}</b>:
                                <div className="table-data">
                                    {column.render
                                        ? column.render(data[column.key], data)
                                        : data[column.key]
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="table-desktop">
                <Table
                    scroll={{ x: 'max-content' }}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={rowKey}
                    pagination={pagination}
                    style={style}
                    rowSelection={rowSelection}
                    onChange={(e, f) =>
                        onChangePaginationPublication && 
                        onChangePaginationPublication(e, f)
                    }
                />
            </div>
        </>
    )
}
