import React from 'react'
import { Row, Col } from 'antd'

import Membership from './Membership'
import { membershipType } from '../../utils/membershipType'

import individual from '../../img/individual.png'
import corproate from '../../img/corporate.png'

export function MembershipType({ onChange, selectedMembership }) {
    return (
        <div className="select-membership-type">
            <h1>Select membership type</h1>
            <Row gutter={[16,16]}>
                <Col md={12} span={24}>
                    <Membership
                        onClick={() => onChange(membershipType.INDIVIDUAL)}
                        isSelected={selectedMembership === membershipType.INDIVIDUAL}
                        icon={individual}
                        title="Individual member"
                        description={`You are creating an individual membership. You have the option to pay for your membership or to request to join an existing ArbitralWomen Corporate Membership if your firm subscribed one`}
                    />
                </Col>
                <Col md={12} span={24}>
                    <Membership
                        onClick={() => onChange(membershipType.CORPORATE_REPRESENTATIVE)}
                        isSelected={selectedMembership === membershipType.CORPORATE_REPRESENTATIVE}
                        icon={corproate}
                        title="Corporate representative"
                        description={`You are creating an account for your firm to subscribe an ArbitralWomen Corporate Membership. This account will enable you to add memberships for your firm’s members. The Corporate Membership offers a discounted rate of 650 Euros for 5 members and 135 Euros for each additional member irrespective of where members are based, and without any limitation of number of members.`}
                    />
                </Col>
            </Row>
        </div>
    )
}
