import React from "react";
import _ from "lodash";
import { Statistic, Col, Row, Button, Card, Space } from "antd";
import moment from "moment";
import { api } from '../api';
import { shortDateFormat } from '../utils/formatShortDate'

const SubscriptionDetailsCard = ({
  subscription,
  handleChange,
  user,
  fetchSubscription }) => {

  const cancelSubscription = e => {
    e.preventDefault();
    handleChange(true, "loading")
    api.patch(`/payments/subscription/cancel/${subscription.subscription_id}`, { userId: user.id })
      .then(() => {
        fetchSubscription()
        handleChange(false, "loading")
      });
  };

  const withdrawSubscription = e => {
    e.preventDefault();
    handleChange(true, "loading")
    api.delete(`/payments/subscription/${subscription.subscription_id}`)
      .then(() => {
        fetchSubscription()
        handleChange(false, "loading")
      });

  }

  const approve = e => {
    e.preventDefault();
    handleChange(true, "loading");
    const { links } = subscription;
    if (!_.isEmpty(links))
      window.location.replace(`${links[0].href}`);

  }

  const handleStatus = status => {

    const text = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    if (status === 'ACTIVE') {
      return { color: "#52C41A", text: text }
    }
    else if (status === 'APPROVAL_PENDING') {
      return { color: "", text: "Approval Needed" }
    }
    else if (status === 'CANCEL') {
      return { color: "red", text: text };
    }
    else {
      return { color: "", text: text };
    }
  }

  return (
    <>

      {!_.isEmpty(subscription) && (
        <Card
          title={
            <div
              className="custom-card-header blue-underline"
              style={{ textAlign: "left" }}
            >
              Subscription
            </div>
          }
          extra={
            <div className="membership-actions">
              <Space direction="vertical">
                {subscription.status === "ACTIVE" && (
                  <Button type="primary" onClick={e => cancelSubscription(e)}>
                    Cancel subscription
                  </Button>
                )}
                {subscription.status === "CANCEL" && (
                  <Button type="link" onClick={e => withdrawSubscription(e)}>
                    Clear
                  </Button>
                )}
                {subscription && subscription.status === "APPROVAL_PENDING" && (
                  <Space>
                    <Button type="primary" onClick={e => approve(e)}>
                      Approve
                    </Button>
                    <Button type="link" onClick={e => withdrawSubscription(e)}>
                      Withdraw request
                    </Button>
                  </Space>

                )}
              </Space>
            </div>
          }
          headStyle={{ border: 'none' }}
        >
          <Row gutter={16} style={{ textAlign: 'left' }}>
            <Col span={6}>
              <Statistic
                title="Start Date:"
                value={moment(subscription.start_time)
                  .utc()
                  .local()
                  .format(shortDateFormat)}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="Next billing date"
                value={
                  (subscription.billing_info &&
                    moment(subscription.billing_info.next_billing_time)
                      .utc()
                      .local()
                      .format(shortDateFormat)) ||
                  "Not set"
                }
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Status"
                // valueStyle={{color: subscription.status === "ACTIVE" ? '#52C41A' : '#EB1A1A'}}
                valueStyle={{ color: handleStatus(subscription.status).color }}
                value={handleStatus(subscription.status).text} />
            </Col>
            <Col span={6}>
              <Statistic title="Subscribing slots" value={subscription.quantity} />
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};
export default SubscriptionDetailsCard;
