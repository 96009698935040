import React from "react";
import {
    Button,
    Modal,
    Input,
    DatePicker,
    Select,
    Col,
    Space,
    Row,
    Popconfirm,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import moment from "moment";
import { api } from "../../api";
import CustomTable from "../CustomTable";
import { formatShortDate, renderDate, shortDateFormat } from "../../utils";


const { RangePicker } = DatePicker;
const { Option } = Select;

const membersTypes = {
    APPROVED: "approved",
    NOT_APPROVED: "not-approved",
};

export class MembershipDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            isLoading: true,
            description: "",
            requestCorporationId: null,
            record: [],
            validFrom: null,
            validTo: null,
            value: null,
            purchaseName: "",
            memberProfileDetails: null,
        };

        this.membershipColumns = [
            {
                title: "Membership Type",
                dataIndex: "corporation_id",
                key: "corporation_id",
                render: (_, row) => (
                    <div>
                        {row.corporation_id ? (
                            <Space direction={"vertical"}>
                                <div>{row.name}</div>
                                <div>
                                    {row.renewaldate
                                        ? `Renewal date:  ${formatShortDate(row.renewaldate)}`
                                        : "Not set"}
                                </div>
                            </Space>
                        ) : (
                            `Individual`
                        )}
                    </div>
                ),
            },
            {
                title: "Valid From",
                dataIndex: "valid_from",
                key: "valid_from",
                render: renderDate
            },
            {
                title: "Valid To",
                dataIndex: "valid_to",
                key: "valid_to",
                render: renderDate
            },
            {
                title: "Amount",
                dataIndex: "amount_to_pay",
                key: "amount_to_pay",
                render: amount => amount && `€${amount}`
            },
            {
                title: "Comment",
                dataIndex: "purchase_name",
                key: "purchase_name",
            },
            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (_, row) => (
                    <>
                        {/* //todo is_payment_defferd -> payment success   by joining payments table */}
                        {row.is_payment_deferred && !row.paid && (
                            <>
                                <button
                                    className="button__link"
                                    onClick={e => this.confirmGift(e, row)}
                                >
                                    Gift
                                </button>
                                <br />
                            </>
                        )}

                        <Popconfirm
                            onConfirm={e => this.deleteMembership(e, row.id)}
                            title="Do you want to delete membership"
                        >
                            <button className="button__link">
                                Delete
                            </button>
                        </Popconfirm>
                    </>
                ),
            },
        ];
    }

    giftMembership = (e, row) => {
        api.patch(`/membership/${row.id}/gift`).then(() => {
            this.props.fetchMembers(membersTypes.APPROVED);
            this.closeModal(e);
        });
    };

    deleteMembership = (e, id) => {
        api.delete(`/membership/${id}`).then(() => {
            this.props.fetchMembers(membersTypes.APPROVED);
            this.closeModal(e);
        });
    };

    createMembership = e => {
        const {
            userId,
            requestCorporationId,
            validFrom,
            validTo,
            purchaseName,
            value,
        } = this.state;

        api.post("/membership", {
            userId: userId,
            corporationId: requestCorporationId,
            validFrom: validFrom,
            validTo: validTo,
            purchaseName: purchaseName,
            value: value
        }).then(() => {
            this.props.fetchMembers(membersTypes.APPROVED);
            this.closeModal(e);
        });
    };

    closeModal = (e) => {
        this.setState({
            userId: null,
            description: "",
            value: null,
            validFrom: null,
            validTo: null,
            purchaseName: "",
        });
        this.props.closeModal(e);
    };

    confirmGift(e, membershipDetails) {
        e.preventDefault();
        const { validFrom, validTo } = membershipDetails;
        const { renewaldate, name } = this.state.memberProfileDetails;
        Modal.confirm({
            title: "You are about to pay for deferred membership",
            icon: <ExclamationCircleOutlined />,
            content: `The membership is valid from ${moment(validFrom).format(
                shortDateFormat
            )} to 
          ${moment(validTo).format(shortDateFormat)}. 
            The ${name} Company renewal date is ${moment(renewaldate).format(
                shortDateFormat
            )}.
                You will gift this membership which for ${Math.abs(
                moment(validTo).diff(validFrom, "M")
            )} month(s)`,
            okText: "Confirm",
            onOk: () => this.giftMembership(e, membershipDetails),
            cancelText: "Cancel",
        });
    }

    canAdminCreateMembership = () => {
        const { validTo, validFrom, purchaseName, value } = this.state;
        return validFrom && validTo && value && purchaseName && true;
    };

    fetchMembershipDetails = async (userId) => {
        const res = await Promise.all([
            api.get(`/users/${userId}/memberships`),
            api.get(`/corporations/user/${userId}`),
        ]);

        this.setState({
            userId: userId,
            membershipDetails:
                res && res[0] && res[0].data && res[0].data.memberships,
            memberProfileDetails:
                res && res[1] && res[1].data && res[1].data.corporation,
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.userId !== prevProps.userId || this.props.isVisible !== prevProps.isVisible) {
            if (this.props.isVisible) {
                this.fetchMembershipDetails(this.props.userId);
            }
        }
    }
    render() {
        const {
            purchaseName,
            memberProfileDetails,
            value
        } = this.state;

        if (!this.props.isVisible)
            return null;

        return (
            <Modal
                title="Membership Details"
                visible={this.props.isVisible}
                width={1000}
                footer={null}
                onCancel={this.closeModal}
            >
                <CustomTable
                    dataSource={this.state.membershipDetails}
                    columns={this.membershipColumns}
                    rowKey="id"
                    pagination={{ pageSize: 50 }}
                    footer={null}
                />
                <div>
                    <p style={{ fontWeight: 500, fontSize: "16px" }}>Create membership</p>
                    <>
                        <Row gutter={[24, 16]}>
                            <Col md={12} span={24}>
                                <RangePicker
                                    style={{ width: "100%" }}
                                    onChange={e => {
                                        this.setState({
                                            validFrom: moment(e[0]).format(shortDateFormat),
                                            validTo: moment(e[1]).format(shortDateFormat)
                                        });
                                    }}
                                    format={shortDateFormat}
                                />
                            </Col>
                            <Col md={12} span={24}>
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Membership Type"
                                    onChange={e =>
                                        this.setState({
                                            requestCorporationId: e,
                                        })
                                    }
                                >
                                    <Option value={null}>Individual</Option>
                                    {memberProfileDetails && (
                                        <Option value={memberProfileDetails.id}>
                                            Corporate ({memberProfileDetails.name})
                                        </Option>
                                    )}
                                </Select>
                            </Col>
                        </Row>

                        <Row gutter={[24, 16]}>
                            <Col md={12} span={24}>
                                <Input
                                    placeholder="Amount"
                                    prefix="€"
                                    value={value}
                                    onChange={e =>
                                        this.setState({
                                            value: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                            <Col md={12} span={24}>
                                <Input
                                    placeholder="Payment Comment"
                                    value={purchaseName}
                                    onChange={e =>
                                        this.setState({
                                            purchaseName:
                                                e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                    </>
                    <div
                        className="confirm-buttons"
                        style={{
                            paddingTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={e => this.createMembership(e)}
                            disabled={!this.canAdminCreateMembership()}
                        >Confirm</Button>
                        <Button type="link" onClick={this.closeModal}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
