import React from "react";
import { Button, Modal, Input, Tooltip, Select, Popconfirm, Upload, Form, Card, DatePicker, Pagination } from "antd";
import moment from "moment";
import { shortDateFormat } from '../utils/formatShortDate'
import "../styles/DocumentsAdminView.css";
import { PlusCircleOutlined, UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { roles } from "../utils/userRoles";
import CategoryDetails from "./CategoryDetails";
import { api } from "../api";
import { withUser } from "./UserContext";
import CustomTable from "./CustomTable";
import { renderDate } from "../utils";

const { Option } = Select;

class DocumentsListInner extends React.PureComponent {
    state = {
        isNewFileFormModalVisible: false,
        newFileComment: null,
        newFileSubject: null,
        newFileCategory: null,
        newFileDate: null,
        isEditFileFormModalVisible: false,
        editedFileId: null,
        editedFileComment: null,
        editedFileSubject: null,
        editedFileCategoryId: null,
        editedFileDate: null,
        newFileError: null,
        newFileUploadError: null,
        editFileError: null,
        fileList: [],
        displayCategories: false,
        displaySetupCategoryModal: false,
        editedCategory: {},
        editedCategoryId: null,
        selectedFile: null,
        author: "",
        members: []
    };

    columns = [
        {
            title: "Document Date",
            dataIndex: "document_date",
            key: "document_date",
            render: renderDate
        },
        {
            title: "Author",
            dataIndex: "author",
            key: "author"
        },
        {
            title: "Title",
            dataIndex: "subject",
            key: "subject",
            ellipsis: true,
            render: (_, row) => (
                <Tooltip placement="topLeft" title={row.subject}>
                    <div className="documents-list__ellipsis">
                        {row.subject}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (_, document) => {
                const category = this.props.categories.find(
                    category => category.id === document.category_id
                );
                return category ? category.name : "";
            },
        },
        {
            title: "Abstract",
            dataIndex: "comments",
            key: "comments",
            render: (_, row) => (
                <Tooltip placement="topLeft" title={row.comments}>
                    <div className="documents-list__ellipsis">
                        {row.comments}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: "Upload Date",
            dataIndex: "upload_time",
            key: "upload_time",
            render: renderDate
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, row) => (
                <div className="documents-action">
                    <Button
                        type="link"
                        className="u--spacer-right-1x"
                        onClick={() => this.props.onDocumentDownload(row.id)}
                    >
                        View
                    </Button>
                    {this.props.user.isAdmin() && (
                        <>
                            <Button
                                type="link"
                                className="u--spacer-right-1x"
                                onClick={() =>
                                    this.openEditFileModal(
                                        row.id,
                                        row.comments,
                                        row.subject,
                                        row.category_id,
                                        row.document_date,
                                        row.original_filename,
                                        row.author
                                    )
                                }
                            >
                                Edit
                            </Button>
                            <Popconfirm
                                title="Are you sure you want to delete this document?"
                                onConfirm={() =>
                                    this.props.onDocumentDelete(row.id)
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link">Delete</Button>
                            </Popconfirm>
                        </>
                    )}
                </div>
            ),
        },
    ];

    categoriesColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Access granted to",
            dataIndex: "access_type",
            key: "access_type",
            render: (_, category) =>
                category &&
                category.access_granted_for &&
                category.access_granted_for
                    .map(access => {
                        if (access === roles.INDIVIDUAL_MEMBER) {
                            return "Member";
                        }
                        if (access === roles.BOARD_MEMBER) {
                            return "Board member";
                        }
                        return "";
                    })
                    .join(", "),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, category) => (
                <>
                    <Button
                        type="link"
                        style={{ paddingLeft: 0 }}
                        onClick={() =>
                            this.setCategoryDetails(category, category.id)
                        }
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        placement="top"
                        title={`Do you want to delete category "${category.name}"`}
                        onConfirm={() => this.deleteCategory(category.id)}
                    >
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </>
            ),
        },
    ];

    componentDidMount() {
        api
            .get("/members")
            .then(response => this.setState({ members: response.data.members }))
    }

    openEditFileModal = (id, comment, subject, categoryId, date, fileName, author) => {
        this.setState({
            isEditFileFormModalVisible: true,
            editedFileId: id,
            editedFileComment: comment,
            editedFileSubject: subject,
            editedFileCategoryId: categoryId,
            editedFileDate: date && moment(date),
            fileList: [],
            selectedFile: fileName,
            author
        });
    };

    handleSubmitNewFile = async () => {
        const {
            newFileComment,
            newFileSubject,
            newFileCategory,
            fileList,
            newFileDate,
            author
        } = this.state;

        if (fileList.length === 0) {
            this.setState({ newFileUploadError: "Please select file" });
        }

        if (!newFileCategory) {
            this.setState({ newFileError: "Please select category" });
        }

        if (newFileCategory && fileList.length > 0) {
            await this.props.onDocumentUpload(
                newFileSubject,
                newFileComment,
                newFileCategory,
                newFileDate,
                fileList[0],
                author
            );
            this.toggleNewFileModal();
        }
    };

    handleSubmitEditFile = async () => {
        const {
            editedFileId,
            editedFileComment,
            editedFileSubject,
            editedFileCategoryId,
            editedFileDate,
            fileList,
            author
        } = this.state;

        if (editedFileCategoryId) {
            await this.props.onDocumentEdit(
                editedFileId,
                editedFileSubject,
                editedFileComment,
                editedFileCategoryId,
                editedFileDate,
                fileList,
                author
            );
            this.toggleEditFileModal();
        } else {
            this.setState({ editFileError: "Please select category" });
        }
    };

    toggleNewFileModal = () => {
        this.setState(prevState => ({
            isNewFileFormModalVisible: !prevState.isNewFileFormModalVisible,
            newFileComment: null,
            newFileSubject: null,
            newFileCategory: null,
            newFileError: null,
            newFileDate: null,
            newUploadFileError: null,
            fileList: [],
            author: ""
        }));
    };

    toggleEditFileModal = () => {
        this.setState(prevState => ({
            isEditFileFormModalVisible: !prevState.isEditFileFormModalVisible,
            editedFileId: null,
            editedFileComment: null,
            editedFileSubject: null,
            editedFileCategoryId: null,
            editFileError: null,
            editedFileDate: null,
            fileList: [],
            selectedFile: null
        }));
    };

    setCategoriesModal(state) {
        this.setState({
            displayCategories: state,
        });
    }

    setCategoryDetails(category, categoryId) {
        this.setState({
            editedCategory: category,
            editedCategoryId: categoryId,
            displaySetupCategoryModal: true,
        });
    }

    submitCategoryForm(values) {
        const { editedCategoryId } = this.state;
        const { fetchData } = this.props;

        if (editedCategoryId) {
            api.patch(`/documents/categories/${editedCategoryId}`, {
                ...values,
            }).then(() => {
                this.setState({
                    displaySetupCategoryModal: false,
                });
                fetchData();
            });
        } else {
            api.post(`/documents/categories`, {
                ...values,
            }).then(() => {
                this.setState({
                    displaySetupCategoryModal: false,
                });
                fetchData();
            });
        }
    }

    deleteCategory(categoryId) {
        const { fetchData } = this.props;

        api.delete(`/documents/categories/${categoryId}`)
            .then(() => {
                fetchData();
            })
            .catch(error => {
                if (error.response) {
                    Modal.warning({
                        content: error.response.data.message
                    })
                }
            });
    }

    render() {
        const {
            isNewFileFormModalVisible,
            newFileComment,
            newFileSubject,
            isEditFileFormModalVisible,
            editedFileComment,
            editedFileSubject,
            editedFileDate,
            editedFileCategoryId,
            newFileError,
            newFileUploadError,
            editFileError,
            newFileDate,
            fileList,
            displayCategories,
            displaySetupCategoryModal,
            editedCategory,
            editedCategoryId,
            selectedFile,
            author
        } = this.state;

        const {
            uploading,
            categories,
            page,
            onChangePage,
            totalCount,
            user
        } = this.props;

        return (
            <>
                {isNewFileFormModalVisible && (
                    <Modal
                        title="Add file"
                        visible={isNewFileFormModalVisible}
                        onOk={this.handleSubmitNewFile}
                        onCancel={this.toggleNewFileModal}
                        destroyOnClose={true}
                    >
                        <div className="u--spacer-bottom-1x">
                            <Form.Item
                                validateStatus={
                                    newFileUploadError ? "error" : null
                                }
                                help={
                                    newFileUploadError ? newFileUploadError : ""
                                }
                            >
                                <Upload
                                    onRemove={() => {
                                        this.setState({ fileList: [] });
                                    }}
                                    beforeUpload={file => {
                                        this.setState({ fileList: [file] });
                                        return false;
                                    }}
                                    fileList={fileList}
                                >
                                    <Button disabled={uploading}>
                                        {uploading ? (
                                            <>
                                                <LoadingOutlined /> Uploading
                                            </>
                                        ) : (
                                            <>
                                                <UploadOutlined /> Select file
                                            </>
                                        )}
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </div>
                        <div
                            className="u--spacer-bottom-1x"
                            style={{ marginTop: -20 }}
                        >
                            <div>Document Date</div>
                            <DatePicker
                                defaultValue={newFileDate}
                                onChange={date =>
                                    this.setState({
                                        newFileDate: date.format(shortDateFormat),
                                    })
                                }
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Select Date"
                                format={shortDateFormat}
                            />
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Author
                            <Select
                                style={{ width: "100%" }}
                                defaultValue={author}
                                onChange={e =>
                                    this.setState({ author: e })
                                }
                                showSearch
                            >
                                <Select.Option value={`${user.first_name} ${user.last_name}`}>
                                    {user.first_name} {user.last_name}
                                </Select.Option>
                                {this.state.members.map(member =>
                                    <Select.Option key={member.id} value={`${member.first_name} ${member.last_name}`}>
                                        {member.first_name} {member.last_name}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Title
                            <Input
                                defaultValue={newFileSubject}
                                onChange={e =>
                                    this.setState({
                                        newFileSubject: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Category
                            <Form.Item
                                validateStatus={newFileError ? "error" : null}
                                help={newFileError ? newFileError : ""}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    onChange={id =>
                                        this.setState({ newFileCategory: id })
                                    }
                                >
                                    {this.props.categories.map(c => (
                                        <Option key={c.id} value={c.id}>
                                            {c.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Abstract
                            <Input.TextArea
                                defaultValue={newFileComment}
                                onChange={e =>
                                    this.setState({
                                        newFileComment: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </Modal>
                )}

                {isEditFileFormModalVisible && (
                    <Modal
                        title="Edit file"
                        visible={isEditFileFormModalVisible}
                        onOk={this.handleSubmitEditFile}
                        onCancel={this.toggleEditFileModal}
                    >
                        <Form.Item
                            validateStatus={
                                newFileUploadError ? "error" : null
                            }
                            help={
                                newFileUploadError ? newFileUploadError : ""
                            }
                        >
                            <div style={{ marginBottom: "15px" }}>Current file: {selectedFile}</div>
                            <Upload
                                onRemove={() => {
                                    this.setState({ fileList: [] });
                                }}
                                beforeUpload={file => {
                                    this.setState({ fileList: [file] });
                                    return false;
                                }}
                                fileList={fileList}
                            >
                                <Button disabled={uploading}>
                                    {uploading ? (
                                        <>
                                            <LoadingOutlined /> Uploading
                                        </>
                                    ) : (
                                        <>
                                            <UploadOutlined /> Select file
                                        </>
                                    )}
                                </Button>
                            </Upload>
                        </Form.Item>
                        <div
                            className="u--spacer-bottom-1x"
                        >
                            <div>Document Date</div>
                            <DatePicker
                                defaultValue={
                                    editedFileDate && moment(editedFileDate)
                                }
                                onChange={date =>
                                    this.setState({
                                        editedFileDate: date && date.format(
                                            shortDateFormat
                                        ),
                                    })
                                }
                                style={{
                                    width: "100%",
                                }}
                                placeholder="Select Date"
                                format={shortDateFormat}
                            />
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Author
                            <Select
                                style={{ width: "100%" }}
                                defaultValue={author}
                                onChange={e =>
                                    this.setState({ author: e })
                                }
                                showSearch
                            >
                                <Select.Option value={`${user.first_name} ${user.last_name}`}>
                                    {user.first_name} {user.last_name}
                                </Select.Option>
                                {this.state.members.map(member =>
                                    <Select.Option key={member.id} value={`${member.first_name} ${member.last_name}`}>
                                        {member.first_name} {member.last_name}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Title
                            <Input
                                defaultValue={editedFileSubject}
                                onChange={e =>
                                    this.setState({
                                        editedFileSubject: e.target.value,
                                    })
                                }
                            />
                        </div>

                        <div className="u--spacer-bottom-1x">
                            Category
                            <Form.Item
                                validateStatus={editFileError ? "error" : null}
                                help={editFileError ? editFileError : ""}
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    onChange={id =>
                                        this.setState({
                                            editedFileCategoryId: id,
                                        })
                                    }
                                    defaultValue={editedFileCategoryId}
                                >
                                    {this.props.categories.map(c => (
                                        <Option key={c.id} value={c.id}>
                                            {c.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div
                            className="u--spacer-bottom-1x"
                            style={{ marginTop: -20 }}
                        >
                            Abstract
                            <Input.TextArea
                                defaultValue={editedFileComment}
                                onChange={e =>
                                    this.setState({
                                        editedFileComment: e.target.value,
                                    })
                                }
                            />
                        </div>


                    </Modal>
                )}

                {displaySetupCategoryModal && (
                    <Modal
                        visible={displaySetupCategoryModal}
                        onCancel={() =>
                            this.setState({ displaySetupCategoryModal: false })
                        }
                        destroyOnClose={true}
                        title={
                            editedCategoryId ? "Edit category" : "Add category"
                        }
                        okButtonProps={{
                            form: "category-details",
                            htmlType: "submit",
                            key: "submit",
                        }}
                    >
                        <CategoryDetails
                            editedCategory={editedCategory}
                            onSubmit={this.submitCategoryForm.bind(this)}
                        />
                    </Modal>
                )}

                {user.isAdmin() && (
                    <Modal
                        visible={displayCategories}
                        onCancel={() => this.setCategoriesModal(false)}
                        footer={
                            <Button
                                type="primary"
                                onClick={() => this.setCategoriesModal(false)}
                            >
                                Close
                            </Button>
                        }
                        width={800}
                        closable={false}
                        bodyStyle={{
                            padding: 0,
                        }}
                    >
                        <Card
                            title="Categories"
                            className="custom-card"
                            bordered={false}
                            extra={
                                <>
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            this.setCategoryDetails({}, null)
                                        }
                                    >
                                        <PlusCircleOutlined /> Add category
                                    </Button>
                                </>
                            }
                            headStyle={{
                                paddingTop: 5,
                            }}
                        >
                            <CustomTable
                                dataSource={categories}
                                columns={this.categoriesColumns}
                                rowKey="id"
                                pagination={{ pageSize: 10 }}
                            />
                        </Card>
                    </Modal>
                )}

                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            Documents
                        </div>
                    }
                    extra={
                        user.isAdmin() && (
                            <>
                                <Button
                                    type="link"
                                    onClick={() =>
                                        this.setCategoriesModal(true)
                                    }
                                >
                                    Manage categories
                                </Button>
                                <Button
                                    icon={<PlusCircleOutlined />}
                                    type="primary"
                                    onClick={this.toggleNewFileModal}
                                >
                                    Upload File
                                </Button>
                            </>
                        )
                    }
                >
                    <CustomTable
                        className="documents"
                        dataSource={this.props.documents}
                        columns={this.columns.filter(column => column.title !== "Author" || user.isAdmin())}
                        rowKey="id"
                        pagination={false}
                    />
                    <Pagination
                        pageSize={10}
                        current={page}
                        total={totalCount}
                        onChange={onChangePage}
                        showSizeChanger={false}
                    />
                </Card>
            </>
        );
    }
}

export const DocumentsList = withUser(DocumentsListInner)