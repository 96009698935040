import React from 'react'
import { Modal, Button } from 'antd'
import moment from 'moment'
import PaymentDetails from './participants/PaymentDetails';
import EnteredFields from './participants/EnteredFields';
import { InputSuffix } from '../../utils/currencySuffix';
import CustomTable from '../CustomTable';
import { longDateFormat } from '../../utils';

export default class ParticipantsList extends React.Component {
    columns = [
        {
            title: 'Participant',
            dataIndex: 'participant',
            key: 'participant',
            render: (_, participant) => <>{participant.title && `${participant.title}.`} {participant.first_name} {participant.last_name}</>
        },
        {
            title: 'Email address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Registration date',
            dataIndex: 'registered_at',
            key: 'registered_at',
            render: (_, participant) => moment(participant.registered_at).format(longDateFormat)
        },
        {
            title: 'Paid',
            dataIndex: 'amount_to_pay',
            key: 'amount_to_pay',
            render: (_, participant) => `${InputSuffix("amount", this.props.event.currency)}${participant.amount_to_pay || 0}`
        },
        {
            title: "Action",
            dataIndex: 'action',
            key: 'action',
            render: (_, participant) => (
                <>
                    <Button style={{ paddingLeft: 0, display: "block" }} type="link" onClick={() => this.displayPaymentDetails(participant)}>View payment details</Button>
                    <Button style={{ paddingLeft: 0 }} type="link" onClick={() => this.displayTypedFields(participant)}>Display entered fields</Button>
                </>
            )
        }
    ]

    constructor(props) {
        super(props);

        this.state = {
            paymentDetails: null,
            fieldsList: null,
            displayModal: false
        }
    }

    displayPaymentDetails(participant) {
        this.setState({
            paymentDetails: participant,
            fieldsList: null,
            displayModal: true
        })
    }

    displayTypedFields(participant) {
        this.setState({
            fieldsList: participant,
            paymentDetails: null,
            displayModal: true
        })
    }

    closeModal() {
        this.setState({
            displayModal: false
        })
    }

    render() {
        const { participants } = this.props;
        const { paymentDetails, fieldsList, displayModal } = this.state;

        const { first_name, last_name } = paymentDetails || fieldsList || {};

        return (
            <>
                <Modal
                    visible={displayModal}
                    title={
                        `${first_name} ${last_name} ${paymentDetails ? " - payment details" : ""} ${fieldsList ? " - entered fields" : ""}`
                    }
                    onCancel={() => this.closeModal()}
                    onOk={() => this.closeModal()}
                    footer={<Button type="primary" onClick={() => this.closeModal()}>Close</Button>}
                >
                    {paymentDetails && <PaymentDetails currency={this.props.event.currency} paymentDetails={paymentDetails} />}
                    {fieldsList && <EnteredFields fieldsList={fieldsList} />}
                </Modal>
                <CustomTable
                    rowKey="id"
                    columns={this.columns}
                    dataSource={participants}
                    pagination={{ pageSize: 10 }}
                />
            </>
        )
    }
}
