import React, { useEffect } from 'react';
import { ArticlesTable } from '../components/ArticlesTable';

export default function ArticlesView(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
        props.changeTitle('Articles');
    }, []);

    return (
        <>
            <ArticlesTable isAdmin={false} />
        </>
    );
}
