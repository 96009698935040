export const EVENT_PRICE_TYPES = {
    REGULAR_MEMBER: 1,
    REGULAR_NON_MEMBER: 2,
    EARLY_BIRD_MEMBER: 3,
    EARLY_BIRD_NON_MEMBER: 4,
};

export const EVENT_PRICES = {
    REGULAR_MEMBER: 100,
    REGULAR_NON_MEMBER: 200,
    EARLY_BIRD_MEMBER: 50,
    EARLY_BIRD_NON_MEMBER: 100,
}

export const FIELDS_TYPE = {
    TEXTBOX: 1,
    CHECKBOX: 2,
    DROPDOWN: 3,
    MULTI_SELECT_LIST: 4,
    DATEPICKER: 5,
};

export const EVENT_TYPE = {
    ARBITRALWOMEN_FREE: 3,
    ARBITRALWOMEN_PAID: 2,
    YAWP_FREE: 5,
    YAWP_PAID: 6,
    NON_ARBITRALWOMEN_FREE: 1,
    // NON_ARBITRALWOMEN_PAID: 7,
    NON_YAWP_FREE: 8,
    // NON_YAWP_PAID: 9,
    // SPONSOR_ONLY: 4,
};

export const EVENT_FORM = {
    IN_PERSON: 1,
    VIRTUAL: 2,
    HYBRID: 3,
};
