import React from 'react';
import { Tabs, Spin, Layout, Modal } from 'antd';

import { ProfileComments } from '../components/ProfileComments';
import { ProfileDataForm } from '../components/ProfileDataForm';
import { ProfileData } from '../components/ProfileData';
import { withUser } from '../components/UserContext';

import { api } from '../api';

import '../styles/ProfileView.css';
import '../styles/index.css';

const { Content } = Layout;

export class ProfileViewInner extends React.PureComponent {
    state = {
        isProfileDataLoading: true,
        profileData: {},
        isCommentsLoading: true,
        comments: null,
        isMoreComments: false,
        newCommentsAfterClickingLoadButton: 3,
        commentsToShow: 3,
    };

    handleApproveMember = () => {
        console.log('Aprove member');
    };

    handleCVUpload = url => {
        this.setState(prevState => ({
            profileData: { ...prevState.profileData, cv: url }
        }));
    };

    handleImgUpload = url => {
        this.setState(prevState => ({
            profileData: { ...prevState.profileData, img: url }
        }));
    };

    handleEditData = values => {
        const { img, cv } = this.state.profileData;
        const { user } = this.props;
        
        if((cv && img) || user.isAdmin()) {
            this.setState({ isProfileDataLoading: true });
            const { id, first_name, last_name, birthdate } = this.state.profileData;

            api.patch('/members', {
                ...values,
                user: this.props.user,
                first_name: first_name,
                last_name: last_name,
                birthdate: birthdate,
                cv,
                img,
                id: id,
            }).then(() => {
                api
                .patch('/members/notification', { status: true })
                .then(() => {
                    Modal.success({
                        title: "Profile has been saved"
                    })

                    if(!this.props.user.isAdmin()) {
                        this.updateUserAccount();
                    }

                    this.fetchProfile();  
                })
            });
        } else {
            if(!cv && !user.isAdmin()) {
                Modal.warning({
                    title: "Saving error",
                    content: "CV is required"
                })
            }
            if(!img && !user.isAdmin()) {
                Modal.warning({
                    title: "Saving error",
                    content: "Profile image is required"
                })
            }
        }
    };

    fetchProfile() {
        const memberId = this.props.match.params.id || this.props.user.id;

        api.get(`/members/${memberId}`).then(resposne => {
            this.setState({
                profileData: resposne.data.data,
                isProfileDataLoading: false,
            });
        });
    }

    handleAddComment = comment => {
        const { id } = this.props.match.params;

        const commentInfo = {
            content: comment,
            memberId: id,
        };

        api.post('/comments', commentInfo)
            .then(() => this.getComments(id))
            .catch(error => console.log(error));
    };

    updateProfil(property, value) {
        this.setState({
            profileData: {
                ...this.state.profileData,
                [property]: value,
            },
        });
    }

    updateUserAccount() {
        const { first_name, last_name, img } = this.state.profileData;
        const { user } = this.props;

        if(!user.isAdmin()) {
            user.changeName(first_name, last_name);
            user.changeImage(img);
        }
    }

    handleDeleteComment = id => {
        api.delete(`/comments/${id}`)
            .then(res => {
                if (res.status === 200) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            comments: prevState.comments.filter(
                                comment => comment.id !== id
                            ),
                        };
                    });
                }
            })
            .catch(error => console.log(error));
    };

    handleEditComment = (id, comment) => {
        const memberId = this.props.match.params.id || this.props.user.id;

        api.patch(`/comments/${id}`, { comment }).then(() => 
            this.getComments(memberId)
        );
    };

    loadMoreComments = () => {
        const toShow = this.state.commentsToShow;
        const len = this.state.comments.length;
        const onePortion = this.state.newCommentsAfterClickingLoadButton;

        this.setState({
            isMoreComments: len > toShow + onePortion,
            commentsToShow: len > toShow + onePortion ? toShow + onePortion : len,
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Profile');
        const memberId = this.props.match.params.id || this.props.user.id;

        if (memberId) {
            api.get(`/members/${memberId}`).then(result =>
                this.setState(
                    {
                        profileData: result.data.data,
                        isProfileDataLoading: false,
                    },
                    () => {
                        const { profileData } = this.state;

                        if (!profileData || profileData.first_name === undefined) {
                            this.setState({
                                profileData: {
                                    ...profileData,
                                    first_name: this.props.user.first_name,
                                    last_name: this.props.user.last_name,
                                },
                            });
                        }
                    }
                )
            );

            if (this.props.user.isAdmin()) {
                this.getComments(memberId);
            }
        } else {
            this.setState({
                isProfileDataLoading: false,
            });
        }
    }

    getComments = memberId => {
        api.get(`/comments/${memberId}`).then(result => {
            this.setState(
                {
                    comments: result.data,
                    commentsToShow: 3,
                },
                () => {
                    const toShow = this.state.commentsToShow;
                    const len = result.data.length;
                    this.setState({
                        isMoreComments: len > toShow,
                        commentsToShow: len < toShow ? len : toShow,
                    });
                }
            );
        });
    };

    render() {
        const { user } = this.props;
        const { isProfileDataLoading, profileData } = this.state;

        return (
            <>
                <Tabs
                    defaultActiveKey="1"
                    onChange={this.callback}
                    size="large"
                    animated={false}
                    tabBarStyle={{ margin: 0 }}
                    className="profile"
                >
                    <div className="profile__container">
                        <Spin spinning={isProfileDataLoading}>
                            <Content
                                className="content"
                                style={{ maxWidth: 930 }}
                            >
                                <ProfileData
                                    updateProfil={(property, value) =>
                                        this.updateProfil(property, value)
                                    }
                                    profile={profileData || {}}
                                    onApprove={this.handleApproveMember}
                                    onCVUpload={this.handleCVUpload}
                                    onImgUpload={this.handleImgUpload}
                                />
                                <ProfileDataForm
                                    onSubmit={this.handleEditData}
                                    profile={profileData || {}}
                                />
                            </Content>
                        </Spin>
                        {user.isAdmin() && this.props.match.params.id && (
                            <>
                                <Content
                                    className="content"
                                    style={{ maxWidth: 930 }}
                                >
                                    <ProfileComments
                                        comments={
                                            !this.state.comments ||
                                            this.state.comments.slice(
                                                0,
                                                this.state.commentsToShow
                                            )
                                        }
                                        isMoreComments={
                                            this.state.isMoreComments
                                        }
                                        onSubmit={this.handleAddComment}
                                        onDelete={this.handleDeleteComment}
                                        onEdit={this.handleEditComment}
                                        loadMoreComments={
                                            this.loadMoreComments
                                        }
                                    />
                                </Content>
                            </>
                        )}
                    </div>
                </Tabs>
            </>
        );
    }
}

export const ProfileView = withUser(ProfileViewInner);
