import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import {
    DollarOutlined,
    HomeOutlined,
    LayoutOutlined,
    ReadOutlined,
    TeamOutlined,
    UserOutlined,
    AuditOutlined,
    BarChartOutlined,
    LockOutlined
} from "@ant-design/icons";

import { Menu } from "antd";
import { api } from "../../api";

export const MenuAll = props => {
    let location = useLocation();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = () => {
        api.get(`/documents/categories`).then(res => setCategories(res.data));
    };

    return (
        <Menu
            selectedKeys={[location.pathname]}
            defaultOpenKeys={["/documents"]}
            style={{ fontWeight: "600" }}
            mode='inline'
            onClick={props.onClick}>
            <Menu.Item key='/'>
                <Link to='/'>
                    <BarChartOutlined />
                    <span className='nav-text'>Dashboard</span>
                </Link>
            </Menu.Item>
            <Menu.Item key='/account'>
                <Link to={`/account`}>
                    <LockOutlined />
                    <span className='nav-text'>Account settings</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/profile">
                <Link to={`/profile`}>
                    <UserOutlined />
                    <span className="nav-text">Profile</span>
                </Link>
            </Menu.Item>
            {props.user.corporationId && (
                <Menu.Item key="/my-corporation">
                    <Link to="/my-corporation">
                        <HomeOutlined />
                        <span className="nav-text">My corporate</span>
                    </Link>
                </Menu.Item>
            )}
            <Menu.Item key='/payments'>
                <Link to='/payments'>
                    <DollarOutlined />
                    <span className='nav-text'>Payments</span>
                </Link>
            </Menu.Item>
            <Menu.Item key='/articles'>
                <Link to='/articles'>
                    <ReadOutlined />
                    <span className='nav-text'>Articles</span>
                </Link>
            </Menu.Item>
            <Menu.Item key='/events'>
                <Link to='/events'>
                    <TeamOutlined />
                    <span className='nav-text'>Events</span>
                </Link>
            </Menu.Item>
            {(props.user.isAdmin() || props.user.isBoard()) &&
            <Menu.ItemGroup key="admin" title="Administration">
                {props.user.isAdmin() && <Menu.Item key='/members'>
                    <Link to='/members'>
                        <UserOutlined />
                        <span className='nav-text'>Members</span>
                    </Link>
                </Menu.Item>}
                {props.user.isAdmin() && <Menu.Item key='/corporations'>
                    <Link to='/corporations'>
                        <HomeOutlined />
                        <span className='nav-text'>Corporates</span>
                    </Link>
                </Menu.Item>}
                {props.user.isAdmin() && <Menu.Item key='/users'>
                    <Link to='/users'>
                        <UserOutlined />
                        <span className='nav-text'>System users</span>
                    </Link>
                </Menu.Item>}
                <Menu.Item key='/boards'>
                    <Link to='/boards'>
                        <LayoutOutlined />
                        <span className='nav-text'>Boards</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key='/yawp-board'>
                    <Link to='/yawp-board'>
                        <LayoutOutlined />
                        <span className='nav-text'>YAWP Board</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key='/audit'>
                    <Link to='/audit'>
                        <AuditOutlined />
                        <span className='nav-text'>Audit</span>
                    </Link>
                </Menu.Item>
            </Menu.ItemGroup>}
            <Menu.ItemGroup key="documents" title="Documents">
                <Menu.Item>
                    <Link to={`/documents/all`}>All</Link>
                </Menu.Item>
                {categories &&
                    categories.map(category => (
                        <Menu.Item key={category.id}>
                            <Link to={`/documents/${category.id}`}>{category.name}</Link>
                        </Menu.Item>
                    ))}
            </Menu.ItemGroup>
        </Menu>
    );
};
