import React from 'react';

import { Col, Row, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined, DragOutlined } from '@ant-design/icons'

export default function EventListElement({ children, onUpdate, onDelete, draggable = false, label }) {
    return (
        <div>
            <Row className="additional-field">
                <Col span={18}>
                    {children}
                </Col>
                <Col span={6} style={{ textAlign: "right" }}>
                    {draggable && <DragOutlined className="additional-field-button drag-handler"/>}
                    <EditOutlined onClick={onUpdate} className="additional-field-button"/>
                    <Popconfirm placement="top" title={`Do you want to delete "${label}"?`} onConfirm={onDelete}>
                        <DeleteOutlined className="additional-field-button"/>
                    </Popconfirm>
                </Col>
            </Row>
        </div>
    )
}
