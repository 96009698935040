import React from 'react'
import { Tag } from 'antd'

export default function PaymentStatus({status}) {
    switch (status) {
        case "success": return <Tag color="#237804">Successful</Tag>;
        case "UNCLAIMED": return <Tag color="#ffa940">Unclaimed</Tag>;
        case "COMPLETED": return <Tag color="#237804">Completed</Tag>;
        case "cancel": return <Tag color="#ffa940">Cancelled</Tag>;
        default: return <Tag color="#8c8c8c">Initiated</Tag>;
    }
}
