import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Button, Input, Card, Select, Checkbox } from "antd";
import { countryCodes } from "../constants/countries";
import "../styles/ProfileView.css";
import {
    type_of_practitioner,
    legal_expertise,
    practice_areas,
    nations,
    countries,
    languages,
    proficiencyTypes,
} from "../utils/dictionaries";
import { arrayUnique } from "../utils/arrayUnique";

const { TextArea } = Input;
const { OptGroup, Option } = Select;
class ProfileDataFormInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            languages_details: props.profile.languages_details
                ? props.profile.languages_details
                : [],
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        const { languages_details } = this.state;
        const { form, onSubmit } = this.props;

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onSubmit({ ...values, languages_details });
            }
        });
    };

    handleSpeakingProficiencyChange = (value, languageCode) => {
        this.setState(prevState => {
            const prevLanguageStateIndex = prevState.languages_details.findIndex(
                l => l.language_code === languageCode
            );
            if (prevLanguageStateIndex > -1) {
                const prevLanguageState =
                    prevState.languages_details[prevLanguageStateIndex];
                const langObj = {
                    language_code: languageCode,
                    speaking_proficiency: value,
                    professional_proficiency: prevLanguageState
                        ? prevLanguageState.professional_proficiency
                        : [],
                };

                return {
                    languages_details: [
                        ...prevState.languages_details.slice(
                            0,
                            prevLanguageStateIndex
                        ),
                        langObj,
                        ...prevState.languages_details.slice(
                            prevLanguageStateIndex + 1
                        ),
                    ],
                };
            } else {
                const langObj = {
                    language_code: languageCode,
                    speaking_proficiency: value,
                    professional_proficiency: [],
                };
                return {
                    languages_details: [
                        ...prevState.languages_details,
                        langObj,
                    ],
                };
            }
        });
    };

    handleProfessionalProficiencyChange = (value, languageCode) => {
        this.setState(prevState => {
            const prevLanguageStateIndex = prevState.languages_details.findIndex(
                l => l.language_code === languageCode
            );
            if (prevLanguageStateIndex > -1) {
                const prevLanguageState =
                    prevState.languages_details[prevLanguageStateIndex];
                const langObj = {
                    language_code: languageCode,
                    speaking_proficiency: prevLanguageState.speaking_proficiency
                        ? prevLanguageState.speaking_proficiency
                        : undefined,
                    professional_proficiency: [...value],
                };

                return {
                    languages_details: [
                        ...prevState.languages_details.slice(
                            0,
                            prevLanguageStateIndex
                        ),
                        langObj,
                        ...prevState.languages_details.slice(
                            prevLanguageStateIndex + 1
                        ),
                    ],
                };
            } else {
                const langObj = {
                    language_code: languageCode,
                    speaking_proficiency: value,
                    professional_proficiency: [...value],
                };
                return {
                    languages_details: [
                        ...prevState.languages_details,
                        langObj,
                    ],
                };
            }
        });
    };

    componentDidUpdate(prevProps) {
        if(this.props.profile.id !== prevProps.profile.id) {
            this.setState({
                languages_details: this.props.profile.languages_details
            })
        }
    }

    handleLanguageChange = values => {
        this.setState(prevState => {
            const { languages_details } = prevState;
            const newLanguagesDetails = [];

            for (let i = 0; i < languages_details ? languages_details.length : 0; i++) {
                if (values.includes(languages_details[i].language_code)) {
                    newLanguagesDetails.push(languages_details[i]);
                }
            }

            return { languages_details: newLanguagesDetails };
        });
    };

    render() {
        const { languages_details } = this.state;
        const { profile, form } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const selectedLanguesForm = getFieldValue("language")
            ? getFieldValue("language")
            : [];

        const selectedLanguagesFromDetails = languages_details 
            ? languages_details.reduce((acc, curr) => [...acc, curr.language_code], [])
            : []

        const selectedLanguages = arrayUnique([...selectedLanguagesFromDetails, ...selectedLanguesForm]).sort();

        return (
            <Form method="post" layout="inline" onSubmit={this.handleSubmit}>
                <Row>
                    <Col md={12} span={24}>
                        <div className="profile-opinions__box u--secondary-background">
                            <span>
                                Who recommended you or how did you learn about
                                ArbitralWomen?
                            </span>
                            <br />
                            <Form.Item>
                                {getFieldDecorator("who_recommended", {
                                    initialValue: profile.who_recommended,
                                })(
                                    <TextArea
                                        rows={4}
                                        style={{ width: 360, marginTop: 25 }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={12} span={24}>
                        <div className="profile-opinions__box u--secondary-background">
                            <span>
                                Why would you like to join ArbitralWomen or any
                                comments you wish to share?
                            </span>
                            <br />
                            <Form.Item>
                                {getFieldDecorator("why_join", {
                                    initialValue: profile.why_join,
                                })(
                                    <TextArea
                                        rows={4}
                                        style={{ width: 360, marginTop: 25 }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Card
                    className="custom-card"
                    title={
                        <div className="no-bold blue-underline">Address</div>
                    }
                >
                    <Row className="u--spacer-bottom-1x">
                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Firm/Organisation
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_organisation", {
                                    initialValue: profile.address_organisation,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Email
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_email", {
                                    initialValue: profile.address_email,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Website
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_website", {
                                    initialValue: profile.address_website,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="u--spacer-bottom-1x">
                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Street address
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_street", {
                                    initialValue: profile.address_street,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                APT/Suite #
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_apt", {
                                    initialValue: profile.address_apt,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Postal code
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_postal", {
                                    initialValue: profile.address_postal,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} span={24}>
                            <span className="profile-address__label">City</span>
                            <Form.Item>
                                {getFieldDecorator("address_city", {
                                    initialValue: profile.address_city,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                State
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_state", {
                                    initialValue: profile.address_state,
                                })(
                                    <Input className="profile-address__input" />
                                )}
                            </Form.Item>
                        </Col>

                        <Col md={8} span={24}>
                            <span className="profile-address__label">
                                Country
                            </span>
                            <Form.Item>
                                {getFieldDecorator("address_country", {
                                    initialValue: profile.address_country,
                                })(
                                    <Select
                                        allowClear
                                        showSearch
                                        placeholder="Country"
                                        className="profile-address__input"
                                        style={{ width: 258 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countryCodes.map(c => (
                                            <Option value={c.name}>
                                                {c.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div className="profile-phone">
                    <Card
                        className="custom-card"
                        title={
                            <div className="no-bold blue-underline">
                                Professional phone
                            </div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator(
                                        "work_phone_country_code",
                                        {
                                            initialValue:
                                                profile.work_phone_country_code ? profile.work_phone_country_code : undefined,
                                        }
                                    )(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            optionFilterProp="children"
                                            allowClear

                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("work_phone_area_code", {
                                        initialValue:
                                            profile.work_phone_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("work_phone_number", {
                                    initialValue: profile.work_phone_number
                                })(<Input placeholder="707 2577" />)}
                            </Form.Item>
                        </div>
                    </Card>

                    <Card
                        className="custom-card"
                        title={
                            <div className="no-bold blue-underline">
                                Personal phone
                            </div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator(
                                        "work_fax_country_code",
                                        {
                                            initialValue:
                                                profile.work_fax_country_code ? profile.work_fax_country_code : undefined,
                                        }
                                    )(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("work_fax_area_code", {
                                        initialValue:
                                            profile.work_fax_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("work_fax_number", {
                                    initialValue: profile.work_fax_number
                                })(<Input placeholder="707 2577" />)}
                            </Form.Item>
                        </div>
                    </Card>

                    <Card
                        className="custom-card"
                        title={
                            <div className="no-bold blue-underline">Mobile</div>
                        }
                    >
                        <div className="profile-phone__inputs_group u--spacer-bottom-1x">
                            <div>
                                <span className="profile-phone__label">
                                    Country code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("mobile_country_code", {
                                        initialValue:
                                            profile.mobile_country_code ? profile.mobile_country_code : undefined,
                                    })(
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="+1"
                                            value={null}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {countryCodes.map(c => (
                                                <OptGroup label={c.name}>
                                                    <Option value={c.dial_code}>
                                                        {c.dial_code}
                                                    </Option>
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>

                            <div>
                                <span className="profile-phone__label">
                                    Area code
                                </span>
                                <Form.Item>
                                    {getFieldDecorator("mobile_area_code", {
                                        initialValue: profile.mobile_area_code,
                                    })(
                                        <Input
                                            placeholder="202"
                                            className="profile-phone__group_input"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        <div>
                            <Form.Item>
                                {getFieldDecorator("mobile_number", {
                                    initialValue: profile.mobile_number
                                })(<Input placeholder="707 2577" />)}
                            </Form.Item>
                        </div>
                    </Card>
                </div>

                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            Professional Information
                        </div>
                    }
                >
                    <div className="professional-info__row  u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Type of Practitioner
                            <Form.Item>
                                {getFieldDecorator("profession", {
                                    initialValue: profile.profession ? profile.profession : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                    >
                                        {type_of_practitioner.map(
                                            (practitioner, index) => (
                                                <Option
                                                    key={index}
                                                    value={practitioner}
                                                >
                                                    {practitioner}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                        <div className="professional-info__container">
                            Practice Areas
                            <Form.Item>
                                {getFieldDecorator("practice_areas", {
                                    initialValue: profile.practice_areas ? profile.practice_areas : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                    >
                                        {practice_areas.map(
                                            (area, index) => (
                                                <Option
                                                    key={index}
                                                    value={area}
                                                >
                                                    {area}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    </div>

                    <div className="professional-info__row  u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Legal Expertise
                            <Form.Item>
                                {getFieldDecorator("legal_expertise", {
                                    initialValue: profile.legal_expertise ? profile.legal_expertise : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                    >
                                        {legal_expertise.map(
                                            (expertise, index) => (
                                                <Option
                                                    key={index}
                                                    value={expertise}
                                                >
                                                    {expertise}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    </div>

                    <div className="professional-info__row u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Publications
                            <Form.Item>
                                {getFieldDecorator("publications", {
                                    initialValue: profile.publications,
                                })(
                                    <TextArea
                                        rows={3}
                                        className="professional-info__input"
                                        size="large"
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div className="professional-info__container">
                            Bar admission
                            <Form.Item>
                                {getFieldDecorator("bar_admission", {
                                    initialValue: profile.bar_admission,
                                })(
                                    <TextArea
                                        rows={3}
                                        className="professional-info__input"
                                        size="large"
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>

                    <div className="professional-info__row u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Education
                            <Form.Item>
                                {getFieldDecorator("education", {
                                    initialValue: profile.education,
                                })(
                                    <TextArea
                                        rows={3}
                                        className="professional-info__input"
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div className="professional-info__container">
                            Teaching
                            <Form.Item>
                                {getFieldDecorator("teaching", {
                                    initialValue: profile.teaching,
                                })(
                                    <TextArea
                                        rows={3}
                                        className="professional-info__input"
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>

                    <div className="professional-info__row u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Other information
                            <Form.Item>
                                {getFieldDecorator("other_information", {
                                    initialValue: profile.other_information,
                                })(
                                    <TextArea
                                        className="professional-info__input"
                                        rows={3}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div className="professional-info__container">
                            Other experience
                            <Form.Item>
                                {getFieldDecorator("other_experience", {
                                    initialValue: profile.other_experience,
                                })(
                                    <TextArea
                                        rows={3}
                                        className="professional-info__input"
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                </Card>

                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            Regional Information
                        </div>
                    }
                >
                    <div className="professional-info__row u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Nationality/Nationalities <i>(You may select several items)</i>
                            <Form.Item>
                                {getFieldDecorator("nationality", {
                                    initialValue: profile.nationality ? profile.nationality.filter(x => x) : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                    >
                                        {nations.map((nation, index) => (
                                            <Option key={index} value={nation}>
                                                {nation}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>

                        <div className="professional-info__container">
                            Country/Countries of residence <i>(You may select several items)</i>
                            <Form.Item>
                                {getFieldDecorator("residence", {
                                    initialValue: profile.residence ? profile.residence.filter(x => x) : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {countries.map(country => (
                                            <Option
                                                key={country.code}
                                                value={country.name}
                                            >
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    </div>

                    <div className="professional-info__row u--spacer-bottom-1x">
                        <div className="professional-info__container">
                            Jurisdiction/s <i>(You may select several items)</i>
                            <Form.Item>
                                {getFieldDecorator("jurisdiction", {
                                    initialValue: profile.jurisdiction ? profile.jurisdiction.filter(x => x) : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                    >
                                        {countries.map((country, index) => (
                                            <Option
                                                key={index}
                                                value={country.name}
                                            >
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>

                        <div className="professional-info__container">
                            Language/s <i>(You may select several items)</i>
                            <Form.Item>
                                {getFieldDecorator("language", {
                                    initialValue: profile.language ? profile.language.filter(x => x) : [],
                                })(
                                    <Select
                                        mode="multiple"
                                        className="professional-info__input"
                                        placeholder="Please select"
                                        onChange={this.handleLanguageChange}
                                    >
                                        {languages.map((language, index) => (
                                            <Option
                                                key={index}
                                                value={language.name}
                                            >
                                                {language.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                    </div>
                </Card>

                {selectedLanguages && selectedLanguages.map(languageCode => {
                    const languageDetails = profile.languages_details 
                        ? profile.languages_details.filter(details => details.language_code === languageCode)[0] 
                        : [];

                    const selectedLanguageName = languages 
                        ? languages.filter(l => l.name === languageCode)[0] 
                        : [];

                    return (
                        selectedLanguageName && (
                            <Card
                                key={languageCode}
                                className="custom-card"
                                title={
                                    <div className="no-bold blue-underline">
                                        {selectedLanguageName.name}
                                    </div>
                                }
                            >
                                <div className="professional-info__row u--spacer-bottom-1x">
                                    <div className="professional-info__container">
                                        Speaking Proficiency
                                        <Select
                                            className="professional-info__input"
                                            placeholder="Please select"
                                            defaultValue={
                                                languageDetails &&
                                                languageDetails.speaking_proficiency
                                            }
                                            onChange={value =>
                                                this.handleSpeakingProficiencyChange(
                                                    value,
                                                    languageCode
                                                )
                                            }
                                        >
                                            <Option value="Conversational">
                                                Conversational
                                            </Option>
                                            <Option value="Proficient">
                                                Proficient
                                            </Option>
                                            <Option value="Native Fluency">
                                                Native Fluency
                                            </Option>
                                        </Select>
                                    </div>
                                </div>

                                <div className="professional-info__container">
                                    Professional Proficiency
                                    <br />
                                    <span className="u--secondary-text">
                                        Please select how you are able to use
                                        this language in Dispute Resolution).
                                    </span>
                                    <Checkbox.Group
                                        onChange={value =>
                                            this.handleProfessionalProficiencyChange(
                                                value,
                                                languageCode
                                            )
                                        }
                                        defaultValue={
                                            languageDetails &&
                                            languageDetails.professional_proficiency
                                        }
                                    >
                                        {proficiencyTypes.map(type => (
                                            <React.Fragment key={type}>
                                                <Checkbox value={type}>
                                                    {type}
                                                </Checkbox>
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </Checkbox.Group>
                                </div>
                            </Card>
                        )
                    );
                })}

                <div className="profile-full-width-button u--spacer-bottom-1x  u--spacer-top-1x">
                    <Button
                        type="primary"
                        ghost
                        size="large"
                        onClick={this.handleSubmit}
                    >
                        Save changes
                    </Button>
                </div>
            </Form>
        );
    }
}

export const ProfileDataForm = Form.create({ name: "profileData" })(
    ProfileDataFormInner
);
