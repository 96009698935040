import React from 'react';

import { Modal } from 'antd';

import EventListElement from './EventListElement';
import EventListAddButton from './EventListAddButton';

import { v4 as uuid } from 'uuid';

import { CouponsContent, CouponsModal } from "./common/Coupons";

const defaultField = {
    type:"amount",
    name:"",
    
}

export default class Coupons extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            displayModal: false,
            editedFieldId: null,
            editedField: defaultField
        }
    }

    addNewField= () => {
        this.setState({
            displayModal: true,
            editedFieldId: null,
            editedField: {
                ...defaultField,
                id: uuid()
            }
        })
    }
    
    editField = field => {
        this.setState({
            displayModal: true,
            editedFieldId: field.id,
            editedField: field
        })
    }

    updateField = (property, value) => {
        const { editedField } = this.state;

        this.setState({
            editedField: {
                ...editedField,
                [property]: value
            }
        })
    }

    submitFieldForm = () => {
        const { onAddField, onUpdateField } = this.props;
        const { editedFieldId, editedField } = this.state;

        if(editedFieldId) {
            onUpdateField(editedField, editedFieldId);
        } else {
            onAddField(editedField);
        }

        this.setState({ displayModal: false })
    }

    render() {
        const { coupons, onDeleteField,currency } = this.props;
        const { displayModal, editedField, editedFieldId } = this.state;

        return (
            <div>
                <Modal
                    visible={displayModal}
                    width={600}
                    onCancel={() => this.setState({ displayModal: false })}
                    onOk={() => this.submitFieldForm()}
                    title={editedFieldId ? "Edit coupon" : "Add coupon"}
                >
                   <CouponsModal
                       editedField={editedField}
                       currency={currency}
                       onUpdate={this.updateField}
                   />
                </Modal>
                {coupons.map(coupon => 
                    <EventListElement
                        key={coupon.id}
                        onDelete={() => onDeleteField(coupon.id)}
                        onUpdate={() => this.editField(coupon)}
                        label={coupon.name}
                    >
                        <CouponsContent coupon={coupon} currency={currency}/>
                    </EventListElement>
                )}
                <EventListAddButton onClick={() => this.addNewField()} title="Add coupon"/>
            </div>
        );
    }
}
