import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function UserSelect(props) {
    const [members, setMembers] = useState([]);
    const [info, setInfo] = useState({
        notFoundContent: 'Enter at least 3 characters',
    });

    const fetchMembers = name => {
        if (name.length > 2) {
            let options = props.options.filter(
                user => user.first_name 
                    && user.last_name 
                    && (user.first_name.indexOf(name) !== -1 || user.last_name.includes(name) !== -1)
            );

            setMembers(options);
            setInfo({ notFoundContent: 'Not found'})
        } else {
            setMembers([]);
            setInfo({ notFoundContent: 'Enter at least 3 characters' })
        }
    };

    const changeMember = id => {
        props.changeMember(members.find(x => x.id === id));
    };

    return (
        <div className="member">
            <Select
                value={props.member && (props.member.first_name || props.member.last_name) 
                    ? `${props.member.first_name} ${props.member.last_name}` 
                    : undefined
                }
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={e => changeMember(e)}
                onSearch={e => fetchMembers(e)}
                {...info}
            >
                {members.map(member => (
                    <Option value={member.id} key={member.id}>
                        {member.first_name} {member.last_name}
                    </Option>
                ))}
            </Select>
        </div>
    );
}
