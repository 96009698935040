import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Button, Input, Row, Col } from 'antd';

import '../styles/ProfileView.css';
import { 
    validatePassword, 
    isPasswordLongEnough, 
    passwordContainsAtLeastOneDigit,
    passwordContainsUppercaseLetter
} from '../utils/validatePassword';

class ProfileResetPasswordFormInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        const { form, onSubmit } = this.props;

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you entered are inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (validatePassword(value)) {
            callback();
        } else if (!isPasswordLongEnough(value)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsAtLeastOneDigit(value)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        } else if (!passwordContainsUppercaseLetter(value)) {
            callback("Your password must be of minimum 10 characters with at least 1 digit and 1 uppercase letter");
        }
        
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Card
                className="custom-card"
                title={
                    <div className="custom-card-header blue-underline">
                        Reset password
                    </div>
                }
            >
                <div>
                    <Form
                        method="post"
                        onSubmit={this.handleSubmit}
                    >
                        <Row gutter={[8,0]}>
                            <Col md={6} span={24}>
                                <Form.Item>
                                    {getFieldDecorator('oldPassword', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please input your old password',
                                            },
                                        ],
                                    })(
                                        <Input.Password
                                            placeholder="Old password"
                                            className="profile-reset-pwd-input"
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col md={6} span={24}>
                                <Form.Item style={{overflowWrap: 'break-word'}}>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            {
                                                validator: this.validateToNextPassword,
                                            },
                                        ],
                                    })(
                                        <Input.Password
                                            placeholder="New password"
                                            className="profile-reset-pwd-input"
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col md={6} span={24}>
                                <Form.Item style={{overflowWrap: 'break-word'}}>
                                    {getFieldDecorator('confirm', {
                                        rules: [
                                            {
                                                required: true,
                                                message:
                                                    'Please confirm your password!',
                                            },
                                            {
                                                validator: this.compareToFirstPassword,
                                            },
                                        ],
                                    })(
                                        <Input.Password
                                            onBlur={this.handleConfirmBlur}
                                            placeholder="Confirm password"
                                            className="profile-reset-pwd-input"
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                            <Col md={6} span={24}>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={this.handleSubmit}
                                    className="password-reset-button"
                                >
                                    Reset password
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
        );
    }
}

export const ProfileResetPasswordForm = Form.create({ name: 'changePassword' })(
    ProfileResetPasswordFormInner
);
