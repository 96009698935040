import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Card, Modal, Row, Col } from 'antd';
import * as moment from 'moment';

import '../styles/ProfileView.css';
import { withUser } from './UserContext';
import { formatShortDate } from '../utils';

const { TextArea } = Input;

class ProfileCommentsInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            editedComment: '',
            editedCommentId: null,
            isModalVisible: false,
        };
    }

    handleChange = e => {
        this.setState({ comment: e.target.value });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { comment } = this.state;

        if (comment) {
            this.props.onSubmit(comment);
            this.setState({
                comment: '',
            });
        }
    };

    handleDelete = id => {
        this.props.onDelete(id);
    };

    loadMoreComments = () => {
        this.props.loadMoreComments();
    };

    openModal = (id, comment) => {
        this.setState({
            editedComment: comment,
            editedCommentId: id,
            isModalVisible: true,
        });
    };
    closeModal = () => {
        this.setState({
            isModalVisible: false,
            editedComment: '',
            editedCommentId: null,
        });
    };

    render() {
        return (
            <>
                <Row>
                    <Col md={11}>
                        <Card
                            className="custom-card"
                            title={
                                <div className="blue-underline no-bold">Comments</div>
                            }
                            extra={
                                formatShortDate(moment())
                            }
                        >
                            <TextArea
                                rows={6}
                                value={this.state.comment}
                                onChange={this.handleChange}
                            />
                            <Button
                                type="primary"
                                ghost
                                size="large"
                                className="profile-comments__save u--spacer-top-1x"
                                onClick={this.handleSubmit}
                                style={{ padding: 0 }}
                            >
                                Save comment
                            </Button>
                        </Card>
                    </Col>
                    <Col md={1}/>
                    <Col md={12}>
                        <div className="profile-comments__list" style={{ width: "100%"}}>
                            {!this.props.comments.map ||
                                this.props.comments.map(comment => (
                                    <Card
                                        key={comment.id}
                                        title={
                                            <div className="no-bold">{formatShortDate(comment.date_added)}</div>
                                        }
                                        headStyle={{
                                            fontSize: '14px',
                                            color: '#808080',
                                        }}
                                        bodyStyle={{
                                            fontSize: '17px',
                                            color: '#000000',
                                        }}
                                        className="custom-card"
                                        extra={
                                            <>
                                                <Button
                                                    className="profile-comments__save"
                                                    onClick={() =>
                                                        this.openModal(
                                                            comment.id,
                                                            comment.content
                                                        )
                                                    }
                                                >
                                                    <EditOutlined />
                                                </Button>
                                                <Button
                                                    className="profile-comments__save"
                                                    onClick={() =>
                                                        this.handleDelete(comment.id)
                                                    }
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            </>
                                        }
                                    >
                                        {comment.content}
                                    </Card>
                                ))}

                            {!this.props.isMoreComments || (
                                <div className="profile-comments__load-more">
                                    <Button
                                        type="primary"
                                        ghost
                                        size="large"
                                        onClick={this.loadMoreComments}
                                    >
                                        Load more
                                    </Button>
                                </div>
                            )}

                            {this.state.isModalVisible && (
                                <Modal
                                    title="Edit Comment"
                                    visible={this.state.isModalVisible}
                                    onOk={() => {
                                        this.props.onEdit(
                                            this.state.editedCommentId,
                                            this.state.editedComment
                                        );
                                        this.closeModal();
                                    }}
                                    onCancel={this.closeModal}
                                >
                                    <TextArea
                                        value={this.state.editedComment}
                                        rows={4}
                                        className="u--spacer-top-1x"
                                        onChange={e =>
                                            this.setState({
                                                editedComment: e.target.value,
                                            })
                                        }
                                    />
                                </Modal>
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export const ProfileComments = withUser(ProfileCommentsInner);