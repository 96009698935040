import React, {useState} from "react";
import { Button, Modal, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import CorporationPayment from "./CorporationPayment";
import CorporationComments from "./CorporationComments";
import CustomTable from "../CustomTable";
import {
    CorporationMembersModal,
    MembershipsTable
} from '../corporation';

import { renderDate } from '../../utils';
import { CorporationsMemberView } from "../../views/CorporationsMemberView";

const CorporationList = props => {

    const menu = (record) => (
        <Menu>
            <Menu.Item>
                <Button style={{ paddingLeft: 0, display: "block" }} onClick={() => displayCorpotateDetails(record)} type="link">Edit corporate</Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ paddingLeft: 0, display: "block" }} onClick={e => handleModal(e,record)} type="link">View members</Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ paddingLeft: 0, display: "block" }} onClick={() => displayPayments(record)} type="link">View payments</Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{ paddingLeft: 0, display: "block" }} onClick={() => displayComments(record)} type="link">View comments</Button>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "Corporate name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Representative",
            dataIndex: "representatives",
            key: "representatives",
            render: all => (
                <div>
                    {all && all.map(r => (
                        <div key={r.email}>
                            {r.first_name} {r.last_name}
                            <br />
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "representatives",
            key: "representatives",
            render: all => (
                <div>
                    {all && all.map(r => (
                        <div key={r.email}>
                            {r.email}
                            <br />
                        </div>
                    ))}{" "}
                </div>
            ),
        },
        {
            title: "Members",
            dataIndex: "members_quantity",
            key: "members_quantity"
        },
        {
            title: "Registration date",
            dataIndex: "establish_date",
            key: "establish_date",
            render: renderDate
        },
        {
            title: "Renewal date",
            dataIndex: "renewaldate",
            key: "renewaldate",
            render: renderDate
        },
        {
            title: '',
            render: (_, record) => {
                return (
                    <Dropdown overlay={menu(record)}>
                        <a className="ant-dropdown-link" style={{whiteSpace: 'nowrap'}} onClick={e => e.preventDefault()}>
                            Actions <DownOutlined />
                        </a>
                    </Dropdown>
                );
            }
        }
    ];

    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState([]);
    const [corporation, setCorporation] = useState({});
    const [paymentModal, setPaymentModal] = useState(false);
    const [commentsModal, setCommentsModal] = useState(false);
    const [affiliatesModal, setAffiliatesModal] = useState(false);
    const [membershipsModal, setMembershipsModal] = useState(false);
    const [corporationEditModal, setCorporationEditModal] = useState(false);


    const handleModal = (e, record) => {
        e.preventDefault();
        setVisible(true)
        setRecord(record)
    }

    const displayPayments = (corporation) => {
        setPaymentModal(true)
        setCorporation(corporation)
    }

    const displayComments = (corporation) => {
        setCommentsModal(true);
        setCorporation(corporation)
    }

    const displayCorpotateDetails = (corporation) => {
        setCorporationEditModal(true);
        setCorporation(corporation);
    }

    return (
        <>
            <CustomTable
                columns={columns}
                dataSource={props.data}
                rowKey={"id"}
                locale={{ emptyText: " " }}
                pagination={false}
                loading={props.loading}
            />
            <CorporationMembersModal 
                visible={visible} 
                setVisible={setVisible}
                record={record}
            />
            <Modal
                visible={commentsModal}
                title="Comments"
                destroyOnClose={true}
                onCancel={() => setCommentsModal(false)}
                footer={<Button type="primary" onClick={() => setCommentsModal(false)}>Ok</Button>}
                width={1100}>
                <CorporationComments corporation={corporation} />
            </Modal>
            <Modal
                visible={paymentModal}
                destroyOnClose={true}
                title="Payments"
                onOk={() => setPaymentModal(false)}
                onCancel={() => setPaymentModal(false)}
                cancelButtonProps={{ style: { display: "none" }}}
                width={1100}
            >
                <CorporationPayment corporation={corporation}/>
            </Modal>
            <Modal
                visible={membershipsModal}
                title="Memberships"
                destroyOnClose={true}
                onCancel={() => setMembershipsModal(false)}
                footer={<Button type="primary" onClick={() => setMembershipsModal(false)}>Ok</Button>}
                width="95%">
                <MembershipsTable user={props.user} showTableOnly corporationId={corporation.id} />
            </Modal>
            <Modal
                visible={corporationEditModal}
                title="Edit corporate"
                style={{ top: 20 }}
                destroyOnClose={true}
                onCancel={() => setCorporationEditModal(false)}
                footer={<Button type="primary" onClick={() => setCorporationEditModal(false)}>Ok</Button>}
                width="95%">
                <CorporationsMemberView
                    changeTitle={() => {}}
                    corporationId={corporation.id}
                />
            </Modal>
        </>
    );
};

export default CorporationList;
