import React from 'react';
import { Layout, Pagination, Card } from 'antd';
import CorporationList from '../../components/admin/CorporationList';
import { api } from '../../api';
import Filter from '../../components/utils/Filter';
import Filters from '../../components/utils/Filters';
import { persist, retrieve } from '../../components/utils/filtersPersitor';

import buildQueryStringFromFilters from '../../utils/buildQueryStringFromFilters';

const { Content } = Layout;

export class CorporationsAdminView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: false,
            filters: {},
            page: 1,
            totalCount: 0,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Corporates');
        const {filters, page} = retrieve('CorporatesAdminView') || this.state;
        this.setState({filters, page}, () => {
            this.fetchCorporations();
        });
    }

    fetchCorporations = () => {
        const { filters, page } = this.state;

        const params = buildQueryStringFromFilters(filters);

        this.setState(
            {
                loading: true,
            },
            () => {
                api.get(`/corporations${params}&page=${page}`).then(
                    response => {
                        this.setState({
                            data: response.data.corporations,
                            totalCount: response.data.total_count,
                            loading: false,
                        });
                    }
                );
            }
        );
    };

    changeFilter(property, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                [property]: value,
            },
        });
    }

    clearFilters() {
        this.setState({ filters: {} }, () => {
            this.persistFilters();
            this.fetchCorporations();
        });
    }

    changePage(page) {
        this.setState({ page }, () => {
            this.persistFilters();
            this.fetchCorporations();
        });
    }

    submitSearch() {
        this.setState({ page: 1 }, () => {
            this.persistFilters();
            this.fetchCorporations();
        });
    }

    persistFilters() {
        const { filters, page } = this.state
        persist('CorporatesAdminView', {filters, page});
    }

    render() {
        const { data, loading, filters, page, totalCount } = this.state;

        return (
            <>
                <Content className="content">
                    <Filters onClearFilters={() => this.clearFilters()} onFinish={() => this.submitSearch()}>
                        <Filter
                            value={filters.name}
                            placeholder="Name"
                            title="Name"
                            onChange={e => this.changeFilter('name', e)}
                        />
                        <Filter
                            value={filters.representative}
                            placeholder="Representative"
                            title="Representative"
                            onChange={e =>
                                this.changeFilter('representative', e)
                            }
                        />
                    </Filters>
                    <Card
                        className="custom-card"
                        title={
                            <div className="custom-card-header blue-underline">
                                Corporates
                            </div>
                        }
                    >
                        <CorporationList
                            loading={loading}
                            handleDelete={this.handleDelete}
                            data={data}
                        />
                        <Pagination
                            total={totalCount}
                            current={page}
                            onChange={page => this.changePage(page)}
                            pageSize={50}
                            showSizeChanger={false}
                        />
                    </Card>
                </Content>
            </>
        );
    }
}
