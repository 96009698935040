import React from 'react';
import { Layout, Pagination, Card, Space } from 'antd';

import { MembersApproval } from '../../components/MembersApproval';
import { MembersList } from '../../components/admin';
import { api } from '../../api';
import Filters from '../../components/utils/Filters';
import Filter from '../../components/utils/Filter';
import buildQueryStringFromFilters from '../../utils/buildQueryStringFromFilters';
import { persist, retrieve } from '../../components/utils/filtersPersitor';
import { countries } from '../../utils/dictionaries';

const { Content } = Layout;

const members = {
    APPROVED: 'approved',
    NOT_APPROVED: 'not-approved',
};

const pageSize = 50;
const summaryColors = {
    Suspended: '#C78400',
    Expired: '#E80003',
    Active: '#00874E',
    Rejected: '#ff7a45'
};

export class MembersAdminView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            approvedMembers: [],
            notApprovedMembers: [],
            corporations: [],
            isLoadingApprovedMembers: true,
            isLoadingNotApprovedMembers: false,
            summary: [],
            filters: {},
            totalCount: 0,
            page: 1,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Members');
        const {filters, page} = retrieve('MembersAdminView') || this.state;
        this.setState({filters, page}, () => {
            this.fetchMembers(members.APPROVED);
            this.fetchMembers(members.NOT_APPROVED);
            this.fetchCorporations();
            this.fetchSummary();
        });
    }

    fetchCorporations = () => {
        api.get("/corporations?limit=0")
        .then(res => res.data)
        .then(res => this.setState({corporations: res.corporations}));
    }

    fetchSummary = () => {
        api.get("/members/summary")
        .then(res => res.data)
        .then(res => this.setState({summary: res.summary}));
    }

    fetchMembers = memberStatus => {
        const { filters, page } = this.state;

        this.setState({
            [memberStatus === members.APPROVED
                ? 'isLoadingApprovedMembers'
                : 'isLoadingNotApprovedMembers']: true,
        });

        const queryString = buildQueryStringFromFilters(filters);

        api.get(`/members/status/${memberStatus}${queryString}&page=${page}&limit=${pageSize}`).then(
            response => {
                this.setState({
                    [memberStatus === members.APPROVED
                        ? 'approvedMembers'
                        : 'notApprovedMembers']: response.data.members,
                    [memberStatus === members.APPROVED
                        ? 'isLoadingApprovedMembers'
                        : 'isLoadingNotApprovedMembers']: false,
                });

                if (memberStatus === members.APPROVED) {
                    this.setState({
                        totalCount: response.data.total_count,
                    });
                }
            }
        );
    };

    changeFilter(property, value) {
        this.setState({
            filters: {
                ...this.state.filters,
                [property]: value,
            },
        });
    }

    clearFilters() {
        this.setState({ filters: {} }, () => {
            this.persistFilters();
            this.fetchMembers(members.APPROVED);
        });
    }

    changePage(page) {
        this.setState({ page }, () => {
            this.persistFilters();
            this.fetchMembers(members.APPROVED);
        });
    }

    submitSearch() {
        this.setState({ page: 1 }, () => {
            this.persistFilters();
            this.fetchMembers(members.APPROVED); 
        });
    }

    persistFilters() {
        const { filters, page } = this.state
        persist('MembersAdminView', {filters, page});
    }

    render() {
        const {
            approvedMembers,
            notApprovedMembers,
            isLoadingApprovedMembers,
            isLoadingNotApprovedMembers,
            filters,
            page,
            corporations,
            totalCount,
            summary
        } = this.state;

        return (
            <>
                <Content className="content">
                    <MembersApproval
                        members={notApprovedMembers}
                        isLoading={isLoadingNotApprovedMembers}
                        fetchMembers={() => {
                            this.fetchMembers(members.NOT_APPROVED);
                            this.fetchMembers(members.APPROVED);
                        }}
                    />

                    <Filters onClearFilters={() => this.clearFilters()} onFinish={() => this.submitSearch()}>
                        <Filter
                            title="Status"
                            placeholder="Status"
                            value={filters.status}
                            onChange={e => this.changeFilter('status', e)}
                            type="select"
                            options={['All', 'Active', 'Expired', 'Rejected', 'Suspended']}
                            defaultValue="All"
                        />
                        <Filter
                            title="Name"
                            placeholder="Name"
                            value={filters.name}
                            onChange={e => this.changeFilter('name', e)}
                        />
                        <Filter
                            title="Email"
                            placeholder="Email"
                            value={filters.email}
                            onChange={e => this.changeFilter('email', e)}
                        />
                        <Filter
                            title="Country"
                            placeholder="Country"
                            value={filters.country}
                            onChange={e => this.changeFilter('country', e)}
                            type="select"
                            options={countries}
                            optionProperty={{
                                key: 'code',
                                value: 'name',
                                display: 'name',
                            }}
                            defaultValue="All"
                        />
                        <Filter
                            title="Corporate"
                            placeholder="Corporate"
                            options={corporations}
                            value={filters.corporation_name}
                            optionProperty={{key:"id",value:"name",display: 'name'}}
                            type="select"
                            onChange={e => {this.changeFilter("corporation_name", e)}}
                            defaultValue="All"
                        />
                    </Filters>

                    <Card
                        className="custom-card"
                        title={
                            <div className="custom-card-header blue-underline">
                                Approved members
                            </div>
                        }
                        extra={
                            <Space>
                                {summary.map(s => <span key={s.status} style={{ color: summaryColors[s.status] }}>{s.status}: {s.count}</span>)}
                            </Space>
                        }
                    >
                        <MembersList
                            members={approvedMembers}
                            corporations={corporations}
                            isLoading={isLoadingApprovedMembers}
                            fetchMembers={this.fetchMembers}
                        />
                        <Pagination
                            total={totalCount}
                            current={page}
                            onChange={page => this.changePage(page)}
                            pageSize={pageSize}
                            showSizeChanger={false}
                        />
                    </Card>
                </Content>
            </>
        );
    }
}
