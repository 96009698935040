import React from 'react';
import '../styles/InvitationsView.css';
import { Result, Button, Spin, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { api } from '../api';
import { withUser } from '../components/UserContext';

class InvitationViewInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            accepted: null,
            invitationId: this.props.match.params.invitationId || null,
            corporation: null,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        api.get(`/invitations/${this.state.invitationId}`).then(result => {
            const invitation = result.data.data;

            this.setState({
                accepted: invitation ? invitation.is_accepted : null,
                corporation: invitation ? invitation.corporation_name : null,
                isLoading: false,
            });
        });
    }

    otherAction() {
        this.props.user.logout();
    }

    render() {
        if (this.state.accepted) {
            return (
                <div className="invitation-container">
                    <Result
                        status="warning"
                        title="You have already joined to the corporate"
                    />
                </div>
            )
        }

        return (
            <Spin spinning={this.state.isLoading}>
                <div className="invitation-container">
                    {this.state.accepted === null &&
                        this.state.isLoading === false && (
                            <Result
                                title="Invitation has been deleted"
                                status="error"
                            />
                        )}
                    {this.state.accepted !== null && (
                            <Result
                                title={
                                    !this.state.accepted
                                        ? `You have been invited to join ${this.state.corporation}`
                                        : 'You have already joined to the corporate'
                                }
                                status={!this.state.accepted ? 'info' : 'warning'}
                                extra={
                                    !this.state.accepted && (
                                        <>
                                            <Row gutter={24} justify="space-around">
                                                <Col span={8}>
                                                    If you <strong>already have an account</strong> in ArbitralWomen.org, click here to join the corporate account.
                                                </Col>
                                                <Col span={8}>
                                                    If you are a <strong>new user</strong>, click here to create an account and complete your profile.
                                                </Col>
                                            </Row>
                                            <Row gutter={24} justify="space-around" style={{marginTop: '20px'}}>
                                                <Col span={8}>
                                                    <Button type="primary">
                                                        <Link
                                                            to={`/login?invitation=${this.state.invitationId}`}
                                                        >
                                                            Login to existing account
                                                   </Link>
                                                    </Button>
                                                </Col>
                                                <Col span={8}>
                                                    <Button type="primary">
                                                        <Link
                                                            to={`/register?invitation=${this.state.invitationId}`}
                                                        >
                                                            Create new account
                                                   </Link>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                }
                            />
                    )
                    }
                </div>
            </Spin>
        );
    }
}

export const InvitationView = withUser(InvitationViewInner);
