import React, { Component } from "react";
import ReactQuill from "react-quill";
import { Row, Col, Select, Form, Input, InputNumber, DatePicker, Upload, Button, Modal } from "antd";

import "react-quill/dist/quill.snow.css";

import { longDateFormat } from "../../utils"
import { countries } from "../../utils/dictionaries";
import { toolbar } from "../../utils/quill-toolbar";
import { EVENT_PRICES, EVENT_FORM, EVENT_TYPE } from '../../constants/enums';
import { api } from "../../api";
import { LoadingOutlined } from "@ant-design/icons";
import { parseEventTypeIdToEventTypeName, isEventTypeIdPaid, parseEventFormIdToEventFormName } from "../../utils/event";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export default class EventDetailsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            isUploadingFlyer: false
        }
    }

    componentDidMount() {
        api
            .get("/members/status/approved?limit=0")
            .then(res => res.data)
            .then(res => this.setState({ members: res.members }))
    }

    handleUploadFlyer(file) {
        const isPdf = file.type === "application/pdf";
        const isLt30M = file.size / 1024 / 1024 < 30;

        if (!isPdf) {
            Modal.warning({
                title: "Setup error",
                content: "You can only upload PDF file!"
            })
        }

        if (!isLt30M) {
            Modal.warning({
                title: "Setup error",
                content: "CV must smaller than 30MB!"
            })
        }

        if (isPdf && isLt30M) {
            this.setState({ isUploadingFlyer: true });

            const formData = new FormData();
            formData.append("flyer", file);

            api
                .post(`/events/flyer/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(response => {
                    this.props.onUpdateFlyer(response.data.location);
                    this.setState({ isUploadingFlyer: false })
                })
        }

        return false;
    }

    render() {
        const { selectedEventType, selectedEventForm, isBeingEdited } = this.props;
        const { isUploadingFlyer } = this.state;
        return (
            <>
                <Row>
                    <Col span={10}>
                        <div>Event Type</div>
                        <Form.Item
                            name="eventtypeid"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Select>
                                {Object.values(EVENT_TYPE).map(value =>
                                    <Option value={value}>
                                        {parseEventTypeIdToEventTypeName(value)}
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <div>Event form</div>
                        <Form.Item
                            name="eventformid"
                        >
                            <Select>
                                {Object.values(EVENT_FORM).map(value =>
                                    <Option value={value}>
                                        {parseEventFormIdToEventFormName(value)}
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10} offset={1}>
                        <div>Location</div>
                        <Form.Item name="location">
                            <Input
                                disabled={(selectedEventForm === EVENT_FORM.VIRTUAL ? true : false)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <div>Country</div>
                        <Form.Item name="country">
                            <Select
                                allowClear={true}
                                showSearch
                                filterOption={(input, option) =>
                                    option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={(selectedEventForm === EVENT_FORM.VIRTUAL ? true : false)}
                            >
                                {countries.map(country => (
                                    <Option key={country.code} value={country.name}>
                                        {country.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <div>Event Title</div>
                        <Form.Item
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={10}>
                        <div>Start Date/Hour ~ End Date/Hour</div>
                        <Form.Item
                            name="range_date"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <RangePicker format={longDateFormat} showTime />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div>Time Zone</div>
                        <Form.Item
                            name="time_zone"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <TextArea rows={1} placeholder="Time Zone" />

                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-between">
                    <Col span={24}>
                        <div>ArbitralWoman Speakers</div>
                        <Form.Item
                            name="arbitralwomenparticipation"
                        >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Please select"
                            >
                                {this.state.members.map(m => (
                                    <Option key={m.id} value={`${m.first_name} ${m.last_name}`}>
                                        {m.first_name} {m.last_name}
                                    </Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="space-between">
                    <>
                        <Col span={14}>
                            <div>Registration Link</div>
                            <Form.Item name="registration_link">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={9} offset={1}>
                            <div>Registration Email</div>
                            <Form.Item name="registration_email">
                                <Input />
                            </Form.Item>
                        </Col>

                    </>
                </Row>

                <Row>
                    <Col span={10}>
                        <Form.Item
                            name="flyer"
                        >
                            Flyer
                            <Input onChange={e => this.props.onUpdateFlyer(e.target.value)} value={this.props.flyer} style={{ width: "100%" }} type="text" />
                        </Form.Item>
                    </Col>
                    <Col span={14} style={{ display: "flex" }}>
                        <Upload
                            beforeUpload={file => this.handleUploadFlyer(file)}
                            fileList={null}
                        >
                            <Button type="ghost" style={{ marginTop: 21 }}>{isUploadingFlyer ? <LoadingOutlined /> : null} Upload Flyer</Button>
                        </Upload>
                        <a href={this.props.flyer} target="_blank" rel="noopener noreferrer">
                            <Button type="ghost" style={{ marginTop: 21 }}>View Flyer</Button>
                        </a>
                        <Button type="ghost" style={{ marginTop: 21 }} onClick={() => this.props.onDeleteFlyer()}>Delete Flyer</Button>
                    </Col>
                </Row>

                <Row gutter={12} style={{ display: "flex", alignItems: "flex-end" }}>

                    <Col span={4}>
                        Currency
                        <Form.Item
                            name={"currency"}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Select currency"
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            >
                                <Option value="USD">USD</Option>
                                <Option value="EUR">EUR</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        Member price
                        <Form.Item
                            name={['price', 'member']}
                            initialValue={!isBeingEdited ? EVENT_PRICES.REGULAR_MEMBER : null}
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        Non member price
                        <Form.Item
                            name={['price', 'non_member']}
                            initialValue={!isBeingEdited ? EVENT_PRICES.REGULAR_NON_MEMBER : null}
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            />
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={12} style={{ display: "flex", alignItems: "flex-end" }}>
                    <Col span={7}>
                        Member price before early bird
                        <Form.Item
                            name={['price', 'member_early_date']}
                            initialValue={!isBeingEdited ? EVENT_PRICES.EARLY_BIRD_MEMBER : null}
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        Non member price before early bird
                        <Form.Item
                            name={['price', 'non_member_early_date']}
                            initialValue={!isBeingEdited ? EVENT_PRICES.EARLY_BIRD_NON_MEMBER : null}
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <div>Early Bird Date</div>
                        <Form.Item name="early_bird_date">
                            <DatePicker
                                placeholder="Early bird date"
                                style={{ width: "100%" }}
                                format={longDateFormat}
                                showTime
                                disabled={!isEventTypeIdPaid(selectedEventType)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <div>Abstract (to be displayed on the front-end)</div>
                        <Form.Item name="description" initialValue="">
                            <ReactQuill theme="snow" modules={toolbar} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <div>Notes (only for the admin, will not be displayed on the front-end)</div>
                        <Form.Item name="notes" initialValue="">
                            <ReactQuill theme="snow" modules={toolbar} />
                        </Form.Item>
                    </Col>
                </Row>

            </>
        );
    }
}
