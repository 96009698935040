import React from 'react';
import {
    Timeline,
    DatePicker,
    Layout,
    Card,
    Spin,
} from 'antd';
import moment from 'moment';
import { shortDateFormat } from '../../utils';
import { api } from '../../api';
import Filter from '../../components/utils/Filter';
import Filters from '../../components/utils/Filters';
import { withUser } from '../../components/UserContext';
import { detailedDateFormat } from '../../utils';

const { RangePicker } = DatePicker;
const { Content } = Layout;

class AuditViewInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            loading: false,
            filterStartDate: moment().subtract(1, 'months'),
            filterEndDate: moment(),
            filterEventName: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('Audit');
        this.fetchEvents();
    }

    fetchEvents = () => {
        this.setState({
            loading: true
        }, () => {
            const s_date = this.state.filterStartDate.startOf('day').format(shortDateFormat);
            const e_date = this.state.filterEndDate.endOf('day').format(shortDateFormat);

            api
                .get(`audit?start=${s_date}&end=${e_date}`)
                .then(resp =>
                    this.setState({
                        events: resp.data.events,
                        loading: false
                    })
                )
                .catch(error => {
                    console.log(error);
                });
        })
    };

    handleInputChange = e => {
        this.setState({
            filterEventName: e,
        });
    };

    handleRangeChange = dates => {
        this.setState({
            filterStartDate:
                dates != null ? dates[0] : moment().subtract(1, 'months'),
            filterEndDate: dates != null ? dates[1] : moment(),
        });
    };

    eventFilterFunction = event => {
        const details = event.details.toLowerCase();
        const filterDetails = this.state.filterEventName.toLowerCase();
        return details.indexOf(filterDetails) >= 0;
    };

    clearFilters() {
        this.setState(
            {
                filterStartDate: moment().subtract(1, 'months'),
                filterEndDate: moment(),
                filterEventName: '',
            },
            () => {
                this.fetchEvents();
            }
        );
    }

    render() {
        return (
            <>
                <Content className="content">
                    <Filters onClearFilters={() => this.clearFilters()} onFinish={this.fetchEvents}>
                        <div className="filter" style={{ maxWidth: '250px' }}>
                            <div className="filter-title">Audit Date Range</div>
                            <RangePicker
                                value={[
                                    this.state.filterStartDate,
                                    this.state.filterEndDate,
                                ]}
                                onCalendarChange={this.handleRangeChange}
                                className="hide-mobile"
                                format={shortDateFormat}
                            />
                            <div className="display-mobile">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <DatePicker
                                        value={this.state.filterStartDate ? moment(this.state.filterStartDate) : null}
                                        onChange={date => this.setState({
                                            filterStartDate: moment(date).startOf('day')
                                        })}
                                        format={shortDateFormat}
                                    />
                                    <DatePicker
                                        value={this.state.filterEndDate ? moment(this.state.filterEndDate) : null}
                                        onChange={date => this.setState({
                                            filterEndDate: moment(date).startOf('day')
                                        })}
                                        format={shortDateFormat}
                                    />
                                </div>
                            </div>
                        </div>
                        <Filter
                            placeholder="Name"
                            title="Name"
                            value={this.state.filterEventName}
                            onChange={this.handleInputChange}
                        />
                    </Filters>
                    <Spin spinning={this.state.loading}>
                        <Card
                            className="custom-card"
                            title={
                                <div className="custom-card-header blue-underline">
                                    Timeline
                                </div>
                            }
                        >
                            <Timeline
                                style={{ minHeight: 75 }}
                            >
                                {this.state.events
                                    .filter(this.eventFilterFunction)
                                    .map(event => (
                                        <Timeline.Item key={event.id}>
                                            <b>
                                                {moment(event.date).format(detailedDateFormat)}
                                            </b>
                                            <p style={{ marginTop: 10, color: '#000000' }}>
                                                {event.details}
                                            </p>
                                        </Timeline.Item>
                                    ))}
                            </Timeline>
                        </Card>
                    </Spin>
                </Content>
            </>
        );
    }
}

export const AuditView = withUser(AuditViewInner);