export const addArrayElement = (array, element) => {
    return array.concat(element)
}

export const removeArrayElement = (array, id) => {
    return array.filter(e => e.id !== id)
}

export const updateArrayElement = (array, element, id) => {
    return array.map(e => {
        if(e.id === id) {
            return element
        } else {
            return e
        }
    })
}