import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Select, Input, Checkbox, DatePicker, Card, Collapse, List, Statistic, Tag, Modal } from 'antd';
import moment from 'moment';
import { shortDateFormat, longDateFormat } from '../utils';
import { FIELDS_TYPE } from '../constants/enums';
import { withUser } from './UserContext';
import { Link } from 'react-router-dom';
import { WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { InputSuffix } from "../utils/currencySuffix"
import logo from "../logo.svg";
import '../styles/RegisterUserView.css';

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

class RegisterUserFormInner extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hideOrganizationField: false,
            hasUserDismissedModal: false,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        const { onSubmit, form } = this.props;

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (this.state.hideOrganizationField) {
                    values.organization_name = null;
                }
                onSubmit(values);
            }
        });
    };

    onChange = e => {
        this.setState({ hideOrganizationField: e.target.checked });
    };

    getAdditionalField(obj) {
        const parsedOptions = JSON.parse(obj.options);

        switch (obj.field_type_id) {
            case FIELDS_TYPE.TEXTBOX:
                return <Input className="additional_input_field" />;
            case FIELDS_TYPE.DATEPICKER:
                return <DatePicker className="additional_input_field" format={shortDateFormat} />;
            case FIELDS_TYPE.CHECKBOX:
                return (
                    <Checkbox.Group
                        options={parsedOptions}
                        className="additional_input_field"
                    />
                );
            case FIELDS_TYPE.DROPDOWN:
                const selectOptions = parsedOptions.map(element => (
                    <Option key={element.label} value={element.label}>
                        {element.label}
                    </Option>
                ));

                return (
                    <Select className="additional_input_field">
                        {selectOptions}
                    </Select>
                );
            case FIELDS_TYPE.MULTI_SELECT_LIST:
                const multiSelectOptions = parsedOptions.map(element => (
                    <Option key={element.label} value={element.label}>
                        {element.label}
                    </Option>
                ));

                return (
                    <Select mode="multiple" className="additional_input_field">
                        {multiSelectOptions}
                    </Select>
                );
            default:
                break;
        }
    }

    formatShortDateTime(dateObj) {
        return dateObj ? moment(dateObj).format(longDateFormat) : '';
    }

    formatShortDate(dateObj) {
        return dateObj ? moment(dateObj).format(shortDateFormat) : '';
    }

    formatLogos(logos) {
        const obj = JSON.parse(logos);
        const items = obj.map((x, index) => {
            return (
                <Col span={4} key={index}>
                    <img src={x.url} alt="logo" className="logo_img"></img>
                </Col>
            );
        });

        return items;
    }

    changeSponsorship = (sponsorshipObj) => {
        this.setState({
            choosenSponsorship: this.state.choosenSponsorship !== sponsorshipObj ? sponsorshipObj : null
        })
    }

    dismissModal = () => {
        this.setState({
            hasUserDismissedModal: true
        })
    }


    isUserBenefittingFromBeingAWMember = () => {
        const { user = {} } = this.props;
        return user.id && user.membershipStatus && !user.membershipStatus.is_suspended &&
            user.membershipStatus.expires_at && moment(user.membershipStatus.expires_at).diff(moment().startOf('day')) >= 0;
    }

    render() {
        const { form, additionalFields, choosenSponsorship, choosenAddons, sponsorships, addons, event, eventPriceData, baseCost } = this.props;
        const { getFieldDecorator } = form;
        const { user = {} } = this.props;

        const addFields =
            additionalFields.length > 0
                ? additionalFields.map(x => {
                    return (
                        <Col span={8} key={x.id}>
                            <span>{x.label}</span>
                            <Form.Item>
                                {getFieldDecorator(x.id.toString())(
                                    this.getAdditionalField(x)
                                )}
                            </Form.Item>
                        </Col>
                    );
                })
                : '';

        const logos =
            event.logo_images && event.logo_images.length > 0
                ? this.formatLogos(event.logo_images)
                : '';

        const activeOptionPriceStyle = { backgroundColor: '#FAFAFA', padding: 10 };
        const inactiveOptionPriceStyle = { padding: 10 };

        const imgContainerStyle = {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "26px"
        }

        const imgStyle = {
            height: 50,
            cursor: "pointer"
        }

        return (
            <>
                <div className="logo-container" style={imgContainerStyle}>
                    <img alt="ArbitralWomen logo" onClick={() => window.history.back()} style={imgStyle} src={logo} />
                </div>

                <Modal
                    title="Information"
                    visible={!user.id && !this.state.hasUserDismissedModal}
                    closable={false}
                    maskClosable={false}
                    footer={null}
                >
                    <Row>
                        <p>
                            Are you an ArbitralWomen member? If yes, please
                            log in to benefit from member discounts!
                        </p>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <Link
                                to={{
                                    pathname: '/login',
                                    eventRedirectUrl: `/event/${event.id}/${event.name}`
                                }}
                            >
                                <Button type="primary">Login</Button>
                            </Link>
                        </Col>
                        <Col>
                            <Button type="link" onClick={this.dismissModal}>Continue as guest</Button>
                        </Col>
                    </Row>


                </Modal>
                <Form method="post" layout="inline" onSubmit={this.handleSubmit}>
                    <Card className="custom-card">
                        {event.banner_img && (
                            <div>
                                <img
                                    src={event.banner_img}
                                    alt="Banner"
                                    className="banner_img"
                                />
                            </div>
                        )}
                        <Row>{logos}</Row>
                        <div className="custom-card-header event_name">
                            <h1>{event.name}</h1>
                        </div>
                        <Row>
                            <Col>
                                <span className="label">Location: </span>
                                <span>{event.location}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="label">Start date: </span>
                                <span>{this.formatShortDateTime(event.startdate)}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className="label">End date: </span>
                                <span>{this.formatShortDateTime(event.enddate)}</span>
                            </Col>
                        </Row>
                        {
                            event.time_zone &&
                            <Col>
                                <span className="label">Time Zone: </span>
                                <span>{event.time_zone}</span>
                            </Col>
                        }
                        <Row>
                            <Col>
                                <span className="label">
                                    ArbitralWomen Speakers:{' '}
                                </span>
                                <span>{event.arbitralwomenparticipation &&
                                    Array.isArray(event.arbitralwomenparticipation) && event.arbitralwomenparticipation.join(', ')}</span>
                            </Col>
                        </Row>
                        <Row className="event_description">
                            <Col>
                                <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
                            </Col>
                        </Row>
                        {event.eventtypeid === 1 && event.registration_email &&
                            <Row>
                                Registration email: &nbsp; <b>{event.registration_email}</b>
                            </Row>
                        }
                        {event.eventtypeid === 5 && event.registration_email &&
                            <Row>
                                Registration email: &nbsp; <b>{event.registration_email}</b>
                            </Row>
                        }
                        {event.eventtypeid === 1 &&
                            <Button type="primary" style={{ marginTop: 25 }}>
                                {event.registration_link &&
                                    <a href={event.registration_link}>Click to open Non-ArbitralWomen registration page</a>
                                }
                            </Button>
                        }
                        {event.eventtypeid === 5 &&
                            <Button type="primary" style={{ marginTop: 25 }}>
                                {event.registration_link &&
                                    <a href={event.registration_link}>Click to open YAWP Event registration page</a>
                                }
                            </Button>
                        }
                    </Card>
                    {event.eventtypeid !== 1 || event.eventtypeid !== 5 &&
                        <>
                            {event.eventtypeid === 2 &&
                                <Card
                                    className="custom-card"
                                    title={
                                        <div className="custom-card-header blue-underline">Pricing</div>
                                    }
                                >
                                    {user.isMember() && user.membershipStatus && user.membershipStatus.is_suspended === true &&
                                        <Tag style={{ padding: 5, paddingLeft: 10, marginBottom: 20, display: 'block' }} color="red"><WarningOutlined /> Your account is suspended, you pay as Non-Member</Tag>
                                    }
                                    {user.isMember() && user.membershipStatus && ((!user.membershipStatus.expires_at) || (moment(user.membershipStatus.expires_at).diff(moment().startOf('day')) < 0)) &&
                                        <Tag style={{ padding: 5, paddingLeft: 10, marginBottom: 20, display: 'block' }} color="red"><WarningOutlined /> Your membership has expired, you pay as Non-Member</Tag>
                                    }
                                    <Row>
                                        {eventPriceData.early_bird_end_date &&
                                            <Col span={12}>
                                                <Statistic title="Early bird until" value={this.formatShortDateTime(eventPriceData.early_bird_end_date)} />
                                            </Col>
                                        }
                                        <Col span={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <Statistic title="" value={"Regular pricing"} />
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 35 }} gutter={[8, 8]}>
                                        {eventPriceData.early_bird_end_date &&
                                            <>
                                                <Col span={6}>
                                                    <Statistic
                                                        title="Member"
                                                        style={
                                                            this.props.isEarlyBird() && baseCost === eventPriceData.early_bird_price_member
                                                                ? activeOptionPriceStyle
                                                                : inactiveOptionPriceStyle
                                                        }
                                                        value={`${InputSuffix("amount", event.currency)}` + (eventPriceData.early_bird_price_member || 0)}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <Statistic
                                                        title="Non-Member"
                                                        style={
                                                            this.props.isEarlyBird() && baseCost === eventPriceData.early_bird_price_non_member
                                                                ? activeOptionPriceStyle
                                                                : inactiveOptionPriceStyle
                                                        }
                                                        value={`${InputSuffix("amount", event.currency)}` + (eventPriceData.early_bird_price_non_member || 0)}

                                                    />
                                                </Col>
                                            </>
                                        }
                                        <Col span={6}>
                                            <Statistic
                                                title="Member"
                                                style={
                                                    !this.props.isEarlyBird() && baseCost === eventPriceData.regular_price_member
                                                        ? activeOptionPriceStyle
                                                        : inactiveOptionPriceStyle
                                                }
                                                value={`${InputSuffix("amount", event.currency)}` + (eventPriceData.regular_price_member || 0)}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <Statistic
                                                title="Non-Member"
                                                style={
                                                    !this.props.isEarlyBird() && baseCost === eventPriceData.regular_price_non_member
                                                        ? activeOptionPriceStyle
                                                        : inactiveOptionPriceStyle
                                                }
                                                value={`${InputSuffix("amount", event.currency)}` + (eventPriceData.regular_price_non_member || 0)}
                                            />
                                        </Col>
                                    </Row>
                                    {!user.id &&
                                        <>
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <InfoCircleOutlined style={{ color: '#40A9FF' }} />
                                                </Col>
                                                <Col style={{ marginLeft: 10 }}>
                                                    You are not logged in, so you are not receiving any member discount.
                                                </Col>
                                                <Col style={{ marginLeft: 10 }}>
                                                    <Link
                                                        to={{
                                                            pathname: '/login',
                                                            eventRedirectUrl: `/event/${event.id}/${event.name}`
                                                        }}
                                                    >
                                                        Log in or register to receive benefits
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </>

                                    }

                                    {this.isUserBenefittingFromBeingAWMember() &&
                                        <Row style={{ marginTop: 20 }}>
                                            <Col><InfoCircleOutlined style={{ color: '#40A9FF' }} /></Col>
                                            <Col style={{ marginLeft: 10 }}>You are logged in, so you are benefiting from being an AW member.</Col>
                                        </Row>
                                    }

                                </Card>
                            }
                            <Card
                                className="custom-card"
                                title={
                                    <div className="custom-card-header blue-underline">Attender details</div>
                                }
                            >
                                <Form.Item label="Title: ">
                                    {getFieldDecorator('title')(
                                        <Select size="small" className="select_field">
                                            <Option value="Mr">Mr</Option>
                                            <Option value="Ms">Ms</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Row className="u--spacer-bottom-1x u--spacer-top-1x">
                                    <Col span={8}>
                                        <span>First name</span>
                                        <Form.Item>
                                            {getFieldDecorator('first_name', {
                                                initialValue: user.first_name,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" disabled={user.id ? true : false} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <span>Last name</span>
                                        <Form.Item>
                                            {getFieldDecorator('last_name', {
                                                initialValue: user.last_name,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" disabled={user.id ? true : false} />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <span>Email</span>
                                        <Form.Item>
                                            {getFieldDecorator('email', {
                                                initialValue: user.email,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" disabled={user.id ? true : false} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="u--spacer-bottom-1x">
                                    <Col span={8}>
                                        <span>Firm/Organisation</span>
                                        <Form.Item
                                            style={
                                                this.state.hideOrganizationField
                                                    ? { display: 'none' }
                                                    : ''
                                            }
                                        >
                                            {getFieldDecorator('organization_name', {
                                                hidden: this.state.hideOrganizationField,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" />)}
                                        </Form.Item>
                                        <div>
                                            <Checkbox
                                                onChange={this.onChange}
                                                checked={this.state.hideOrganizationField}
                                                className="input_field"
                                            >
                                                None
                                            </Checkbox>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <span>Country</span>
                                        <Form.Item>
                                            {getFieldDecorator('country', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <span>City</span>
                                        <Form.Item>
                                            {getFieldDecorator('city', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ],
                                            })(<Input className="input_field" />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            {addFields && (
                                <Card
                                    className="custom-card"
                                    title={
                                        <div className="custom-card-header blue-underline">Additional fields</div>
                                    }
                                >
                                    <Row className="u--spacer-bottom-1x">{addFields}</Row>
                                </Card>
                            )}
                            <Card
                                className="custom-card"
                                title={<div className="custom-card-header blue-underline">Sponsorship</div>}
                            >
                                <Collapse defaultActiveKey={[]} style={{ backgroundColor: 'inherit' }}>
                                    <Panel header="Do you have a discount coupon?">
                                        <Row>
                                            <Col span={8}>
                                                <Input placeholder='Enter coupon code' value={this.props.couponCode} onChange={e => this.props.onUpdateCouponCode(e.target.value)} />
                                            </Col>
                                            <Col span={4} offset={1}>
                                                <Button onClick={() => this.props.applyCouponCode()} type="primary">Apply</Button>
                                            </Col>
                                        </Row>
                                    </Panel>
                                    {sponsorships && sponsorships.length > 0 &&
                                        <Panel header="Do you want to sponsor the event?">
                                            <List>
                                                {sponsorships && sponsorships.map((s, key) =>
                                                    <List.Item
                                                        key={key}
                                                        className={s === choosenSponsorship ? "choosen-sponsorship" : "sponsorship-item"}
                                                        onClick={() => this.props.onChangeSponsorship(s)}
                                                    >
                                                        <Row justify="space-between" style={{ width: '100%' }}>
                                                            <Col span={8}>
                                                                <Row>Sponsorship name:&nbsp;<b>{s.name}</b></Row>
                                                                <Row>Sponsorship price:&nbsp;<b>{s.price}$</b></Row>
                                                            </Col>
                                                            <Col span={16}>
                                                                <Row>
                                                                    <b>Description:&nbsp;</b> {s.description}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            </List>
                                            {choosenSponsorship && getFieldDecorator('sponsorship_info')(
                                                <TextArea style={{ marginTop: 10 }} rows={3} placeholder="Provide additional info" />)
                                            }
                                        </Panel>
                                    }
                                    {addons && addons.length > 0 &&
                                        <Panel header="Do you want to buy an addon?">
                                            <List>
                                                {addons && addons.map((a, key) => {
                                                    return (
                                                        <List.Item key={key}>
                                                            <Checkbox checked={choosenAddons.indexOf(a) >= 0} onChange={(e) => this.props.onChangeAddons(e, a)}>
                                                            </Checkbox>
                                                            <Row justify="space-between" style={{ width: '100%' }}>
                                                                <Col span={8} offset={1}>
                                                                    <Row>Addon name:&nbsp;<b>{a.name}</b></Row>
                                                                    <Row>Addon price:&nbsp;<b>{a.price}$</b></Row>
                                                                </Col>
                                                                <Col span={15}>
                                                                    <Row>
                                                                        <b>Description:&nbsp;</b> {a.description}
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    )
                                                }

                                                )}
                                            </List>
                                        </Panel>
                                    }
                                </Collapse>
                            </Card>
                            <Card
                                className="custom-card"
                                title={
                                    <div className="custom-card-header blue-underline">Summary</div>
                                }
                            >
                                <Row>
                                    <Col span={6}>
                                        <Statistic title="Total cost" value={this.props.totalCost < 0 ? `${InputSuffix("amount", event.currency)}0` : `${InputSuffix("amount", event.currency)}${this.props.totalCost}`} />
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="Base cost" value={`${InputSuffix("amount", event.currency)}${this.props.baseCost}`} />
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="Sponsorship" value={choosenSponsorship ? choosenSponsorship.name : '-'} />
                                    </Col>
                                    {this.props.appliedCoupons.length > 0 &&
                                        <Col span={6}>
                                            <Statistic title="Coupons" value={this.props.appliedCoupons.join(', ')} />
                                        </Col>
                                    }
                                </Row>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={this.handleSubmit}
                                    style={{ marginTop: 35 }}
                                >
                                    {this.props.totalCost <= 0
                                        ? "Register to the event"
                                        : `Pay ${InputSuffix("amount", event.currency)}${this.props.totalCost} and register to the event`
                                    }
                                </Button>
                            </Card>
                        </>
                    }
                </Form>
            </>
        );
    }
}

export const RegisterUserForm = withUser(Form.create({ name: 'registerUser' })(
    RegisterUserFormInner
));
