import React, { useState } from 'react';
import { Button, Form, Input, Row, Col, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { api } from '../../api';

import mailIcon from "../../img/mail.png";
import star from "../../img/star.png";
import { withUser } from '../UserContext';

function SuccesfulRegistrationInner({ email, isFromInvitation, createdUser, user }) {
    const [showReenterEmailForm, setShowReenterEmailForm] = useState(false);
    const [oldEmail, setOldEmail] = useState(email);
    const [newEmail, setNewEmail] = useState(email);

    const fields = [
        {
            name: ['personalEmail'],
            value: newEmail,
        }
    ]
    const formStyles = {
        display: showReenterEmailForm ? 'block' : 'none'
    };

    const changeEmail = (form) => {
        if (form.personalEmail !== oldEmail) {
            const data = {
                newEmail: form.personalEmail,
                oldEmail: oldEmail
            }
            api.post('/register/new-email', data)
                .then(() => {
                    setOldEmail(form.personalEmail);
                    setNewEmail(form.personalEmail);
                    setShowReenterEmailForm(false);
                    Modal.success({
                        content: `Confirmation email has been sent on email ${form.personalEmail}`
                    })
                })
                .catch(error => Modal.warning({ content: error.response.data.message }))
        }
        else {
            Modal.warning({
                content: "You entered the same email as before!"
            })
        }
    }

    return (
        <>
            <div className="wrapped-content message succesfull-registration">
                {isFromInvitation
                    ?
                    <>
                            <img src={star} width={72} alt=""/>
                            <h2>Successful registered</h2>
                            <p>You have been successfully registered to the system</p>
                            <Button type="link" onClick={() => {
                                if(createdUser) {
                                    user && user.login(createdUser);
                                    window.location.replace("/#/");
                                }
                            }}>
                                Continue
                            </Button>
                        </>
                    :
                    <>
                            <img src={mailIcon} width={72} alt=""/>
                            <h2>Check your email</h2>
                            <p>We have sent a message to <b>{oldEmail}</b> with a link to confirm your email.</p>
                            <h3>Did you get an email?</h3>
                            <p>If you do not see an email from us within a few minutes, please verify whether:</p>
                            <ul style={{textAlign: 'left'}}>
                                <li>The email is in your spam box.</li>
                                <li>The email address you entered had a typo.</li>
                                <li>You registered with another email address.</li>
                                <li>The firewall of your firm is blocking delivery of our message.</li>
                            </ul>
                            <Button type="link" onClick={() => setShowReenterEmailForm(true)}>
                                Re-enter your email and try again
                            </Button>
                            
                            <Form style={formStyles} fields={fields} onFinish={changeEmail}>
                                <Row justify="space-between" align="middle" style={{marginTop: 20}} gutter={[16,16]}>
                                    <Col md={12} span={24}>
                                        <Form.Item
                                            name="personalEmail"
                                            style={{margin: 0}}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Email is required',
                                                },
                                                {
                                                    type: 'email',
                                                    message:
                                                        'Please enter a valid email address',
                                                },
                                            ]}
                                        >
                                            <Input 
                                                placeholder='Email' 
                                                prefix={
                                                    <MailOutlined
                                                        style={{
                                                            color: 'rgba(0,0,0,.25)',
                                                        }}
                                                    />
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={10} span={24}>
                                        <Form.Item style={{margin: 0}}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Re-send email confirmation
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
            </div>
        </>
    )
}

export const SuccesfulRegistration = withUser(SuccesfulRegistrationInner)