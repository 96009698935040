import React from "react";
import {
    Layout,
    Spin,
    Card,
    Button,
    Modal,
    DatePicker,
    Popconfirm,
    Tag,
    Tooltip
} from "antd";
import { api } from "../../api";
import "../../styles/EventsView.css";
import moment from "moment";
import ParticipantsList from "../../components/events/ParticipantsList";
import Filters from "../../components/utils/Filters";
import Filter from "../../components/utils/Filter";
import buildQueryStringFromFilters from "../../utils/buildQueryStringFromFilters";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import EventsModalContent from "../../components/events/EventsModalContent";

import { countries } from "../../utils/dictionaries";
import CustomTable from "../../components/CustomTable";
import { EventsApprovation } from "./EventsApprovation";
import { parseEventTypeIdToEventTypeName, allEventTypes } from "../../utils/event";
import { shortDateFormat, longDateFormat } from '../../utils/formatShortDate'

const { Content } = Layout;
const { RangePicker } = DatePicker;

export class EventsAdminView extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            events: null,
            event: {},
            participants: [],
            displayParticipants: false,
            loadingParticipants: false,
            filters: {},
            page: 1,
            totalCount: 0,
            displayEventsModal: false,
            editedId: null,
            locations: [],
            exportingCSV: false,
        };

        const { user } = this.props;

        this.myRef = React.createRef();
        this.columns = [
            {
                title: "Event",
                dataIndex: "name",
                key: "name",
                render: (_, event) => (
                    <>
                        <Tooltip
                            title={event.name}
                            block>
                            <p><b>Title: </b>{event.name ? event.name.substring(0, event.name.length > 75 ? 75 : event.name.length) : ""}{event.name.length > 75 ? "..." : ""}</p>
                        </Tooltip>
                        <p><b>Type: </b>{event.eventtype}</p>
                        <p><b>From </b>{moment(event.startdate).format(longDateFormat)} <b>to</b> {moment(event.enddate).format(longDateFormat)}</p>
                        <p><b>Registrations:</b> {event.registered_users}</p>

                        {event.location &&
                            <Tooltip title={event.location}>
                                <p><b>Location: </b>{event.location ? event.location.substring(0, event.location.length > 75 ? 75 : event.location.length) : ""}{event.location.length > 75 ? "..." : ""}</p>
                            </Tooltip>
                        }

                        {Array.isArray(event.arbitralwomenparticipation) &&
                            <p><b>AW Speakers: </b>
                                {event.arbitralwomenparticipation.map((participant, index) => (
                                    <span key={participant}>{participant}{(index + 1) % 2 === 0 ? <br /> : (index + 1) < event.arbitralwomenparticipation.length ? ", " : ""}</span>
                                ))}
                            </p>
                        }

                        {event.registration_link &&
                            <Tooltip title={event.registration_link}>
                                <p><b>Registration: </b><a href={event.registration_link} target="_blank" rel="noopener noreferrer">{event.registration_link ? event.registration_link.substring(0, event.registration_link.length > 75 ? 75 : event.registration_link.length) : ""}{event.registration_link.length > 75 ? "..." : ""}</a></p>
                            </Tooltip>
                        }

                        {event.registration_email &&
                            <Tooltip title={event.registration_email}>
                                <p><b>Registration Email: </b>{event.registration_email ? event.registration_email.substring(0, event.registration_email.length > 75 ? 75 : event.registration_email.length) : ""}{event.registration_email.length > 75 ? "..." : ""}</p>
                            </Tooltip>
                        }
                    </>
                )
            },
            {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (_, event) => (
                    <>
                        {(user.isAdmin() || event.status != 'rejected') &&
                            <Button
                                type="link"
                                style={{ display: "block" }}
                                onClick={() => this.openEditEventModal(event.id)}
                                className="hide-mobile"
                            >
                                Edit
                            </Button>
                        }
                        {event.eventtype !== "Non-ArbitralWomen Event" && event.eventtype !== "YAWP Event" && (
                            <Button
                                type="link"
                                style={{ display: "block" }}
                                onClick={() =>
                                    this.viewParticipants(event.id, event)
                                }
                            >
                                Participants
                            </Button>
                        )}
                        {event.flyer_url &&
                            <a target="_blank" rel="noopener noreferrer" href={event.flyer_url} style={{ fontSize: 15 }}>
                                <Button
                                    type="link"
                                    style={{ display: "block" }}
                                >
                                    View flyer
                                </Button>
                            </a>
                        }
                        <Popconfirm
                            title={`Are you sure want to delete event "${event.name}"?`}
                            onConfirm={() => this.deleteEvent(event.id)}
                        >
                            <Button
                                type="link"
                                block
                                style={{ textAlign: "left" }}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </>
                ),
            },
            {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (_, { status }) => {
                    if (status === 'pending') {
                        return <Tag color="#ffa940">Pending</Tag>
                    }
                    if (status === 'approved') {
                        return <Tag color="#237804">Approved</Tag>
                    }
                    if (status === 'rejected') {
                        return <Tag color="#ff4d4f">Rejected</Tag>
                    }
                }
            },
        ];
    }

    deleteEvent = (id) => {
        api.delete(`/events/${id}`).then(response => {
            if (response.data.success) {
                this.refreshData();
            }
        });
    }

    viewParticipants(eventId, event) {
        this.setState(
            {
                displayParticipants: true,
                event,
                participants: [],
                loadingParticipants: true,
            },
            () => {
                api.get(`/events/${eventId}/participants`).then(response => {
                    this.setState({
                        participants: response.data.participants,
                        loadingParticipants: false,
                    });
                });
            }
        );
    }

    openEditEventModal = (id) => {
        this.setState({
            displayEventsModal: true,
            editedId: id,
        });
    }

    openAddEventModal() {
        this.setState({
            displayEventsModal: true,
            editedId: null,
        });
    }

    closeEventModal() {
        this.setState({
            displayEventsModal: false,
            editedId: null,
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle("Events");
        this.refreshData();

        api.get("/events/locations").then(response => {
            this.setState({
                locations: response.data.locations,
            });
        });
    }

    refreshData = () => {
        const { filters, page } = this.state;
        const queryString = buildQueryStringFromFilters(filters);

        api.get(`/events${queryString}&page=${page}`).then(result => {
            const data = result.data.events;

            const results = data.map(function (row) {
                return {
                    key: row.id,
                    id: row.id,
                    currency: row.currency,
                    name: row.name,
                    location: row.location,
                    startdate: row.startdate
                        ? moment(row.startdate).format(longDateFormat)
                        : row.startdate,
                    enddate: row.enddate
                        ? moment(row.enddate).format(longDateFormat)
                        : row.enddate,
                    arbitralwomenparticipation: row.arbitralwomenparticipation,
                    registered_users: row.registered_users,
                    eventtype: parseEventTypeIdToEventTypeName(
                        row.eventtypeid
                    ),
                    registration_email: row.registration_email,
                    registration_link: row.registration_link,
                    flyer_url: row.flyer_url,
                    status: row.status
                };
            });

            this.setState({
                data: results,
                totalCount: result.data.total_count,
            });
        });

    }

    closeParticipantsModal() {
        this.setState({
            displayParticipants: false,
        });
    }

    clearFilters() {
        this.setState(
            {
                filters: {},
                page: 1,
            },
            () => {
                this.refreshData();
            }
        );
    }

    updateFilter(filter, value) {
        const { filters } = this.state;

        this.setState({
            filters: {
                ...filters,
                [filter]: value,
            },
        });
    }

    changeEventsDateRange(range) {
        const { filters } = this.state;

        this.setState({
            filters: {
                ...filters,
                startDate: range && range[0] ? range[0].format(shortDateFormat) : null,
                endDate: range && range[1] ? range[1].format(shortDateFormat) : null,
            },
        });
    }

    changePage(page) {
        this.setState(
            {
                page,
            },
            () => {
                this.refreshData();
                window.scrollTo(0, 0);
            }
        );
    }

    sumbitFilters() {
        this.setState(
            {
                page: 1,
            },
            () => {
                this.refreshData();
            }
        );
    }

    exportCSV(eventId) {
        const { exportingCSV } = this.state;

        if (!exportingCSV) {
            this.setState(
                {
                    exportingCSV: true,
                },
                () => {
                    api.get(`/events/${eventId}/participants/csv`)
                        .then(response => {
                            window.location.replace(response.data.file_url);
                            this.setState({
                                exportingCSV: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                exportingCSV: false,
                            });
                        });
                }
            );
        }
    }

    render() {
        const {
            participants,
            event,
            displayParticipants,
            loadingParticipants,
            filters,
            displayEventsModal,
            editedId,
            locations,
            exportingCSV,
            data
        } = this.state;
        const { user } = this.props;

        return (
            <div>
                {displayEventsModal && (
                    <EventsModalContent
                        user={user}
                        editedId={editedId}
                        closeEventModal={() => this.closeEventModal()}
                        refreshData={() => {
                            this.refreshData()
                            if (user.isAdmin()) {
                                this.myRef.current.getEvents()
                            }
                        }}
                    />
                )}
                <Modal
                    visible={displayParticipants}
                    onCancel={() => this.closeParticipantsModal()}
                    closable={false}
                    footer={
                        <Button
                            type="primary"
                            onClick={() => this.closeParticipantsModal()}
                        >
                            Close
                        </Button>
                    }
                    width={1200}
                    bodyStyle={{
                        padding: 0,
                    }}
                >
                    <Spin spinning={loadingParticipants}>
                        <Card
                            className="custom-card modal-no-padding"
                            bordered={false}
                            title={`List of participants for ${event.name}`}
                            extra={
                                <Button
                                    type="primary"
                                    title={`List of participants for ${event.name}`}
                                    onClick={() => this.exportCSV(event.id)}
                                >
                                    {exportingCSV && <LoadingOutlined />} Export list as CSV
                                </Button>
                            }
                            headStyle={{
                                borderBottom: "1px solid #f0f0f0",
                            }}
                        >
                            <ParticipantsList event={event} participants={participants} />
                        </Card>
                    </Spin>
                </Modal>
                {user.isAdmin() && <EventsApprovation editEvent={this.openEditEventModal} fetchEvents={this.refreshData} events={data} ref={this.myRef} />}
                <Content className="content">
                    <Filters onClearFilters={() => this.clearFilters()} onFinish={() => this.sumbitFilters()}>
                        <Filter
                            value={filters.name}
                            title="Title"
                            onChange={e => this.updateFilter('name', e)}
                            placeholder="Title"
                        />
                        <Filter
                            value={filters.location}
                            title="Location"
                            onChange={e => this.updateFilter("location", e)}
                            placeholder="Location"
                            type="select"
                            options={[...countries, ...locations]}
                            optionProperty={{
                                key: "code",
                                value: "code",
                                display: "name",
                            }}
                        />
                        <Filter
                            value={filters.status}
                            title="Approval"
                            onChange={e => this.updateFilter("status", e)}
                            placeholder="Status"
                            type="select"
                            options={
                                [{ type: "approved", label: "Approved" },
                                { type: "rejected", label: "Rejected" }]
                            }
                            optionProperty={{
                                key: "type",
                                value: "type",
                                display: "label",
                            }}
                        />
                        <Filter
                            value={filters.participation}
                            title="AW Speakers"
                            onChange={e =>
                                this.updateFilter("participation", e)
                            }
                            placeholder="Member"
                        />
                        <div className="filter">
                            <div className="filter-title">Date Range</div>
                            <RangePicker
                                value={filters.startDate && filters.endDate
                                    ? [moment(filters.startDate), moment(filters.endDate)]
                                    : []
                                }
                                onChange={range => this.changeEventsDateRange(range)}
                                className="hide-mobile"
                                placeholder={["Start", "End"]}
                                format={shortDateFormat}
                            />
                            <div className="display-mobile">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <DatePicker
                                        value={filters.startDate ? moment(filters.startDate) : null}
                                        onChange={date => this.setState(prevState => {
                                            return {
                                                filters: {
                                                    ...prevState.filters,
                                                    startDate: date && moment(date).startOf('day').format(shortDateFormat)
                                                }
                                            }
                                        })}
                                        format={shortDateFormat}
                                    />
                                    <DatePicker
                                        value={filters.endDate ? moment(filters.endDate) : null}
                                        onChange={date => this.setState(prevState => {
                                            return {
                                                filters: {
                                                    ...prevState.filters,
                                                    endDate: date && moment(date).endOf('day').format(shortDateFormat)
                                                }
                                            }
                                        })}
                                        format={shortDateFormat}
                                    />
                                </div>
                            </div>
                        </div>
                        <Filter
                            title="Type"
                            type="select"
                            placeholder="Type"
                            options={allEventTypes()}
                            optionProperty={{
                                key: "type",
                                value: "type",
                                display: "label",
                            }}
                            defaultValue="All"
                            value={this.state.filters["type"]}
                            onChange={e => this.updateFilter("type", e)}
                        />
                    </Filters>
                    <Spin spinning={!this.state.data} size="large">
                        <Card
                            className="custom-card"
                            title={
                                <div className="custom-card-header blue-underline">
                                    Events
                                </div>
                            }
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => this.openAddEventModal()}
                                    className="hide-mobile"
                                >
                                    <PlusCircleOutlined /> Add event
                                </Button>
                            }
                        >
                            <CustomTable
                                dataSource={this.state.data}
                                columns={this.columns}
                                rowKey="key"
                                pagination={{ pageSize: 20 }}
                            />
                        </Card>
                    </Spin>
                </Content>
            </div >
        );
    }
}
