import React from 'react';
import moment from 'moment';
import { Button, Card, Popconfirm, Spin } from 'antd';
import CustomTable from '../CustomTable';
import { api } from '../../api';
import { longDateFormat } from '../../utils/formatShortDate'

export class InvitationsTable extends React.Component {

    state = {
        invitations: []
    };

    invitationColumns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Sent date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: date => date && moment(date).format(longDateFormat)
        },
        {
            title: "Action",
            render: (_, invitation) =>
                <Popconfirm
                    title={`Do you want to delete invitation for ${invitation.email}`}
                    onConfirm={() => this.handleDeleteInvitation(invitation.id)}
                >
                    <Button style={{ paddingLeft: 0 }} type="link">Delete</Button>
                </Popconfirm>
        }
    ]

    handleDeleteInvitation(id) {
        const { corporationId } = this.props;

        this.setState({ isLoading: true });
        api
            .delete(`/corporations/${corporationId}/invitations/${id}`)
            .then(() => this.fetchInvitations())
    }

    fetchInvitations() {
        const { corporationId } = this.props;
        this.setState({ isLoading: true });
        api.get(`/corporations/${corporationId}/invitations`)
            .then(result => result.data)
            .then(({ invitations }) => this.setState({ invitations, isLoading: false }))
    }

    componentDidMount() {
        this.fetchInvitations();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reloadKey !== this.props.reloadKey) {
            this.fetchInvitations();
        }
    }

    render() {
        const {
            invitations,
            isLoading
        } = this.state;

        const {
            showTableOnly,
            showWhenEmpty
        } = this.props;

        if (invitations.length === 0 && !showWhenEmpty)
            return null;

        const table = (
            <CustomTable
                dataSource={invitations}
                columns={this.invitationColumns}
                rowKey='id'
                pagination={{ pageSize: 10 }}
            />
        );

        return showTableOnly ? table : (
            <Card
                className='custom-card'
                title={<div className='custom-card-header blue-underline'>Invitations sent</div>}
            >
                <Spin spinning={isLoading}>
                    {table}
                </Spin>
            </Card>
        );
    }
}