import React from 'react';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  MailOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { Modal, Input, Button, Tag, Tooltip, Card, Row, Col, Radio } from 'antd'
import { api } from '../api';
import '../styles/CorporationMembers.css';
import _ from "lodash";
import CustomTable from './CustomTable';
import { DetachMember } from './corporation';
import {
  renderDate,
  validateEmail
} from '../utils';

export class CorporationMembers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false,
      addNewMemberModalVisible: false,
      isPurchaseModalVisible: false,
      id: null,
      name: '',
      email: '',
      count: this.props.membersPayment.count || 0,
      isLoadingEstimatedCost: false,
      estimatedCost: 0,
      newSlots: 0,
      newMemberData: {
        firstName: '',
        lastName: '',
        email: '',
        type: 'member'
      },
      amountToPayForNewMembers: 0,
      reloadKey: Math.random(),
      selectedMembers: []
    }
  }
  columns = [
    {
      title: 'Member name',
      dataIndex: 'user_name',
      key: 'user_name'
    },
    {
      title: 'Email',
      dataIndex: 'member_email',
      key: 'member_email',
      render: (_, row) => (
        <a href={`mailto:${row.member_email}`}>{row.member_email}</a>
      )
    },
    {
      title: 'Expiry date',
      dataIndex: 'valid_to',
      key: 'valid_to',
      render: renderDate
    },
    {
      render: (_, row) => (
        <Tooltip
          title={row.user_id ? 'User exists' : (row.type == 'associate' ? 'Waiting for a member to register' : 'Invitation sent')}
          placement='left'
        >
          {row.user_id ? <CheckCircleOutlined /> : (row.type == 'associate' ? <ClockCircleOutlined /> : <MailOutlined />)}
        </Tooltip>
      )
    },
    {
      title: 'Status',
      dataIndex: 'valid_to',
      key: 'valid_to',
      render: (_, row) => {
        if (!row.valid_to) {
          return <Tag color='#8c8c8c'>Inactive</Tag>
        }

        if (!row.membership_is_valid) {
          return <Tag color='#ff4d4f'>Expired</Tag>
        }

        if (row.is_payment_deferred) {
          return <Tag color='#ffa940'>Active (deferred payment)</Tag>
        }

        return <Tag color='#237804'>Active</Tag>;
      }
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, row) => {
        var firstLetter = row.type.substr(0, 1);
        return firstLetter.toUpperCase() + row.type.substr(1);
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => <DetachMember member={row} onDelete={this.props.onDelete} />
    }
  ]

  handleAddNewMember = async () => {
    const { newMemberData } = this.state;

    if (!this.isRowFilled(newMemberData)) {
      Modal.warning({
        content: "You have to fill all the fields about the new member!"
      });
      return;
    }

    const user = await this.getUserFromFormIfExist(newMemberData);
    if (this.doesUserExist(user) && newMemberData.type === "member") {
      this.userExistsActions(user);
    } else {
      this.userNotExistsActions(user);
    }

  }

  userExistsActions = (user) => {
    if (!this.isUserCorpRepresentative(user) && !this.isUserAdmin(user)) {
      this.setState({
        addNewMemberModalVisible: false
      }, () => this.props.prepareModalForExistingUser(user))
    } else {
      Modal.warning({
        title: "Setup error",
        content: "User exists, but she is not an individual member, so you cannot add her to the corporation!"
      })
    }
  }

  userNotExistsActions = () => {
    const { newMemberData } = this.state;
    const fullName = newMemberData.firstName + ' ' + newMemberData.lastName;
    this.props.onAdd(fullName, newMemberData.email, newMemberData.type);
    this.closeAddNewMemberModal();
  }

  getUserFromFormIfExist = async (memberData) => {
    const user = await api.get(`/users/by-email/${memberData.email}`);
    return user.data;
  }

  doesUserExist = (user) => {
    return Boolean(user && user.id);
  }

  isUserCorpRepresentative(user) {
    return Boolean(user && user.is_company_representative);
  }

  isUserAdmin(user) {
    return user && user.roles.indexOf('admin') > -1;
  }

  getEstimatedPrice = async slots => {
    const { corporationId } = this.props;
    await api
      .get(`/payments/estimated-payment/${corporationId}/${slots || 0}`)
      .then(res => {
        this.setState({
          slots: slots,
          loading: false,
          estimatedCost: res.data.price,
        });
      });
  };

  handlePayNow = e => {
    e.preventDefault();
    const { subscription } = this.props;

    if (subscription && subscription.status === "ACTIVE") {
      this.reviseSubscription(e);
    }
    else {

      this.handleReqularPayment(e);

    }
  }

  handleReqularPayment = e => {

    const { user: { id }, availableMemberships = 0, members, corporationId } = this.props;
    const { newSlots = 0 } = this.state;
    const inactiveMembers = members.filter(member => member.valid_from === null);
    const amountOfInactiveMembers = (!_.isEmpty(inactiveMembers) && inactiveMembers.length) || 0;
    const amount = (newSlots + amountOfInactiveMembers - availableMemberships);
    if (Number.isInteger(availableMemberships) && Number.isInteger(newSlots)) {
      api.post(`/payments/new-order`, {
        userId: id,
        type: 'corporate',
        numberOfMembers: amount,
        corporation_id: corporationId,
      }).then(res => {
        const { id } = res.data.data;
        this.setState({ isLoading: true, isModalVisible: false });
        window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
      });
    }
  }

  reviseSubscription = e => {

    this.setState({
      loading: true,
    });

    const { availableMemberships = 0, members, subscription } = this.props;
    const { newSlots = 0 } = this.state;
    const inactiveMembers = members.filter(member => member.valid_from === null);
    const amountOfInactiveMembers = (!_.isEmpty(inactiveMembers) && inactiveMembers.length) || 0;
    const amount = (newSlots + amountOfInactiveMembers - availableMemberships);
    api.patch(`/payments/subscription/${subscription.subscription_id}/revise`,
      { subscriptionId: subscription.subscription_id, newSlots: amount, user: this.props.user, })
      .then(res => res.data)
      .then(res => {

        this.setState({ loading: false });
        window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${res.data.id}`);
      })
  }

  closeModal = (e, modal) => {
    this.setState({
      [modal]: false,
      name: '',
      email: ''
    })
  }

  closeAddNewMemberModal = () => {
    this.setState({
      addNewMemberModalVisible: false,
      newMemberData: {
        firstName: '',
        lastName: '',
        email: '',
        type: 'member'
      },
      reloadKey: Math.random()
    });
  }

  handlePurchaseSubmit = e => {
    this.props.handleTabChange(3, e)
    this.props.handleSlotsButtonPurchase(this.state.count)
    this.setState({ isPurchaseModalVisible: false })
  }

  handlePurchaseModal = () => {
    this.setState(prevState => ({
      isPurchaseModalVisible: !prevState.isPurchaseModalVisible
    }))
  }

  handleChange = (e, property) => {
    this.setState({ [property]: e.target.value })
  }

  handleEdit() {
    const { id, name, email } = this.state

    this.props.onEdit(id, name, email)
    this.setState({
      isModalVisible: false,
      name: '',
      email: ''
    })
  }

  handleDefer = e => {
    e.preventDefault();
    this.setState({
      addNewMemberModalVisible: false,
      loading: true,
    });

    const { user: { id, corporationId }, availableMemberships = 0, members, subscription } = this.props;
    const { newSlots = 0 } = this.state;
    const inactiveMembers = members.filter(member => member.valid_from === null);
    const amountOfInactiveMembers = (!_.isEmpty(inactiveMembers) && inactiveMembers.length) || 0;
    const amount = (newSlots + amountOfInactiveMembers - availableMemberships);
    api.patch(`/payments/subscription/${subscription.subscription_id}/defer`,
      { userId: id, corporationId: corporationId, subscriptionId: subscription.subscription_id, newSlots: amount, user: this.props.user })
      .then(res => res.data)
      .then(res => {
        this.props.fetchCorporation()
        this.setState({
          loading: false,
        });

      })
  }

  isRowFilled = (member) => {
    return member.firstName !== '' &&
      member.lastName !== '' &&
      validateEmail(member.email);
  }

  changeNewMemberInput = (value, field) => {
    this.setState(prevState => {
      prevState.newMemberData = {
        ...prevState.newMemberData,
        [field]: value
      }
      return prevState;
    })
  }

  openAddNewMemberModal = () => {
    this.setState({ addNewMemberModalVisible: true });
  }

  render() {
    const {
      isModalVisible,
      addNewMemberModalVisible,
      name,
      email,
      newMemberData
    } = this.state
    const { members } = this.props

    return (
      <>
        {isModalVisible && (
          <Modal
            title='Edit member data'
            visible={isModalVisible}
            onOk={() => this.handleEdit()}
            onCancel={e => this.closeModal(e, 'isModalVisible')}
          >
            Name
            <Input
              defaultValue={name}
              onChange={e => this.handleChange(e, "name")}
              className='u--spacer-bottom-1x'
            />
            Email
            <Input
              defaultValue={email}
              onChange={e => this.handleChange(e, "email")}
              className='u--spacer-bottom-1x'
            />
          </Modal>
        )}

        <Modal
          visible={addNewMemberModalVisible}
          onCancel={this.closeAddNewMemberModal}
          closable={false}
          className={'wide-modal'}
          title="Add a new member"
          footer={[
            <Button key="cancel" onClick={() => this.setState({ addNewMemberModalVisible: false })}>Cancel</Button>,
            <Button key="add" type="primary" onClick={this.handleAddNewMember}>Add</Button>
          ]}
        >
          <Row gutter={8}>
            <Col md={8} span={24}>
              <Input
                placeholder="First name"
                value={newMemberData.firstName}
                onChange={(e) => this.changeNewMemberInput(e.target.value, 'firstName')}
                style={{ margin: 10 }}
              />
            </Col>
            <Col md={8} span={24}>
              <Input
                placeholder="Last name"
                value={newMemberData.lastName}
                onChange={(e) => this.changeNewMemberInput(e.target.value, 'lastName')}
                style={{ margin: 10 }}
              />
            </Col>
            <Col md={8} span={24}>
              <Input
                placeholder="Email"
                value={newMemberData.email}
                onChange={(e) => this.changeNewMemberInput(e.target.value, 'email')}
                style={{ margin: 10 }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={24} span={24}>
              <Radio.Group onChange={(e) => this.changeNewMemberInput(e.target.value, 'type')} value={newMemberData.type}>
                <Radio value={'member'}>Add as a member</Radio>
                <Radio value={'associate'}>Add as an affiliate</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Modal>

        <Card
          className='custom-card'
          title={
            <div className='custom-card-header blue-underline'>
              Corporate members
            </div>
          }
          extra={
            <Button
              type="primary"
              disabled={false}
              onClick={this.openAddNewMemberModal}
            >
              <PlusCircleOutlined /> Add a new member
            </Button>
          }
        >
          <CustomTable
            dataSource={members}
            columns={this.columns}
            rowKey='id'
            pagination={false}
            rowSelection={{
              type: 'checkbox',
              onChange: (_, selectedRows) => {
                this.props.onChange(selectedRows.map(member => member.user_id))
              },
            }}
          />
        </Card>
      </>
    )
  }
}