import React from 'react';
import moment from 'moment';
import { shortDateFormat, formatShortDate } from '../utils/formatShortDate'
import {
    Spin,
    Button,
    Input,
    Modal,
    Card,
    Statistic,
    Row,
    Col,
    Tooltip,
    Form,
    DatePicker
} from 'antd';
import { withUser } from './UserContext';
import { api } from '../api';
import { PlusCircleOutlined } from '@ant-design/icons';
import PaymentsTable from "./PaymentsTable";
import SubscriptionPaymentsTable from "./SubscriptionPaymentsTable"
import _ from "lodash";

import "../styles/PaymentMemberHistory.css";

class PaymentMemberHistoryInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            payments: null,
            prolongation: null,
            isModalVisible: false,
            isEditModalVisible: false,
            numberOfMembers: 5,
            numberOfMembersToEdit: null,
            orderId: null,
            expirationDate: null,
            subscriptions: [],
            subscriptionPayments: [],
            corporateMember: null,
            test: false
        };
    }

    addPaymentForm = React.createRef();

    handleNumberOfMembersChange = e => {
        const value = parseInt(e.target.value, 10);

        this.setState({ numberOfMembers: value });
    };

    handleNumberOfMembersToEditChange = e => {
        const value = parseInt(e.target.value, 10);

        this.setState({ numberOfMembersToEdit: value });
    };

    toggleModal = () => {
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible,
        }));
    };

    toggleEditModal = () => {
        this.setState(prevState => ({
            isEditModalVisible: !prevState.isEditModalVisible,
        }));
    };

    fetchData = async () => {
        this.setState({ isLoading: true });

        const paymentsUrl = this.props.corporationId
            ? `/corporations/${this.props.corporationId}/payments`
            : `/payments/get-by-user/${this.props.user.id}`;

        const result = await Promise.all([
            api.get(paymentsUrl),
            api.get(`/corporations/user/${this.props.user.id}`),
            api.get('/corporation-associates')
        ]);

        this.setState({
            payments: this.props.corporationId ? result && result[0] && result[0].data.payments : result && result[0].data.data,
            corporateMember: result && result[1].data && result[1].data.corporation,
            isAssociate: result && result[2].data && result[2].data.length > 0,
            isLoading: false
        });
    }

    fetchSubscriptions = async () => {
        this.setState({ isLoading: true })

        const resp = await Promise.all([
            api.get(`/payments/subscription/${this.props.user.id}`),
            api.get(`/payments/subscription-payments/${this.props.user.id}`)
        ]);

        this.setState({
            subscriptions: resp && resp[0] && resp[0].data.subscriptions,
            subscriptionPayments: resp && (resp[1] && resp[1].data && resp[1].data.subscriptionPayments),
            isLoading: false
        })

    }

    fetchIndividualMembershipData() {
        if (!this.props.corporationId) {
            api.get('/statistics/individual/expiration-date').then(result => {
                this.setState({
                    expirationDate: result.data.data.expiration_date
                        ? moment(result.data.data.expiration_date).format(shortDateFormat)
                        : null,
                });
            });
        }
    }

    showErrorModal = () => {
        Modal.error({
            title: "PayPal payment error",
            content: "An error occured during payment. Please try again later. If the problem persists please contact our IT support at awadministrator@arbitralwomen.org."
        });
    };

    subscribe = () => {
        this.setState({ isLoading: true })
        api.post(`/payments/subscribe`, {
            userId: this.props.user.id,
            type: 'individual',
            test: this.state.test
        }).then(
            res => {
                this.setState({ isLoading: true });
                window.location.replace(`${res.data.link}`);
            }
        ).catch(() => {
            this.setState({ isLoading: false });
            this.showErrorModal();
        });
    }

    addManualPayment = () => {
        this.setState({ isAddPaymentModalVisible: true });
    }

    submitAddManualPayment = (values) => {
        this.setState({ isLoading: true });

        api.post(`/payments`, {
            createTime: values.date.format(shortDateFormat),
            amountToPay: values.amount,
            purchaseName: values.comment,
            corporationId: this.props.corporationId
        }).then(() => {
            this.fetchData();
            this.setState({ isAddPaymentModalVisible: false, isLoading: false });
        });
    }

    deletePayment = (row) => {
        api.delete(`/payments/${row.id}`).then(() => {
            this.fetchData();
        });
    }

    placeNewOrder = () => {
        api.post(`/payments/new-order`, {
            userId: this.props.user.id,
            type: 'individual',
        }).then(res => {
            const { id } = res.data.data;

            this.setState({ isLoading: true });
            window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
        });
    }

    handleInvidualSubscription = () => {
        api.post(`/payments/`, {
            userId: this.props.user.id,
            type: 'individual',
        }).then(res => {
            const { id } = res.data.data;

            this.setState({ isLoading: true });
            window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
        });
    };

    changeNumberOfMembers = (id, numberOfMembers) => {
        this.setState({
            numberOfMembersToEdit: numberOfMembers,
            isEditModalVisible: true,
            orderId: id,
        });
    };

    placeRenewalOrder = () => {
        const { prolongation } = this.state;
        if (prolongation) {
            api.post(`/payments/prolongate`, {
                userId: this.props.user.id,
                corporation_id: this.props.corporationId,
                totalSlots: prolongation.totalSlots,
                missingSlots: prolongation.missingSlots,
                selectedMembers: this.props.selectedMembers
            }).then(res => {
                const { id } = res.data.data;

                this.setState({ isLoading: true, isModalVisible: false });
                window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
            });
        }
    }

    placeMissingOrder = () => {
        api.post(`/payments/missing-memberships`, {
            userId: this.props.user.id,
            corporation_id: this.props.corporationId,
            selectedMembers: this.props.selectedMembers
        }).then(res => {
            const { id } = res.data.data;
            this.setState({ isLoading: true, isModalVisible: false });
            window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
        });
    }

    placeNewCorporateOrder = () => {
        if (Number.isInteger(this.state.numberOfMembers)) {
            api.post(`/payments/new-order`, {
                userId: this.props.user.id,
                type: 'corporate',
                numberOfMembers: this.state.numberOfMembers,
                corporation_id: this.props.corporationId,
            }).then(res => {
                if (!res.data.data) {
                    window.location.reload();
                    return;
                }

                const { id } = res.data.data;
                this.setState({ isLoading: true, isModalVisible: false });
                window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${id}`);
            });
        } else {
            Modal.warning({
                content: "Incorrect value."
            })
        }
    }

    editCorporateOrder = () => {
        this.setState({ isEditModalVisible: false });
        const { orderId, numberOfMembersToEdit } = this.state;
        if (Number.isInteger(this.state.numberOfMembers)) {
            api.patch(`/payments/edit-order`, {
                orderId,
                numberOfMembers: numberOfMembersToEdit,
            }).then(() => {
                this.setState({
                    isLoading: true,
                    orderId: null,
                    numberOfMembersToEdit: null,
                    isEditModalVisible: false,
                });
                this.fetchData();
            });
        } else {
            Modal.warning({
                content: "Incorrect value"
            })
        }
    }

    handlePaymentRequest = () => {
        const {
            isPurchaseRequested,
            membersPaymentCount,
            clearPurchaseRequest,
            prolongation,
            missing
        } = this.props;

        if (isPurchaseRequested && membersPaymentCount !== 0) {
            this.setState({ numberOfMembers: membersPaymentCount }, () =>
                this.placeNewCorporateOrder()
            );
            clearPurchaseRequest();
        } else if (isPurchaseRequested && prolongation) {
            this.setState({ prolongation: prolongation }, () =>
                this.placeRenewalOrder()
            );
            clearPurchaseRequest();
        } else if (isPurchaseRequested && missing) {
            this.placeMissingOrder();
            clearPurchaseRequest();
        }
    }

    cancelSubscription = e => {
        e.preventDefault();
        this.setState({ isLoading: true })
        const { subscriptions } = this.state;

        api
            .patch(`/payments/subscription/cancel/${subscriptions.subscription_id}`, { userId: this.props.user.id })
            .then(() => this.setState({ subscriptions: [] }, () => this.fetchSubscriptions()));
    }

    componentDidMount() {
        this.fetchData();
        this.fetchIndividualMembershipData();
        this.fetchSubscriptions();
    }

    componentDidUpdate() {
        if (this.props.isPurchaseRequested || this.props.prolongation) {
            this.handlePaymentRequest();
        }
    }

    render() {
        const { user } = this.props;
        const { isContactPerson } = user;
        const {
            isLoading,
            payments,
            isModalVisible,
            isEditModalVisible,
            numberOfMembers,
            numberOfMembersToEdit,
            expirationDate,
            subscriptions,
            subscriptionPayments,
            corporateMember,
            isAssociate,
            isAddPaymentModalVisible
        } = this.state;

        const { corporationId } = this.props;

        return (
            <Spin
                spinning={isLoading}
                size="large"
                tip="We're redirecting you to PayPal"
            >
                {isModalVisible && (
                    <Modal
                        title="Number of members"
                        visible={isModalVisible}
                        onOk={this.placeNewCorporateOrder}
                        onCancel={this.toggleModal}
                    >
                        Please enter number of members you would like to buy
                        membership for.
                        <Input
                            defaultValue={numberOfMembers}
                            onChange={this.handleNumberOfMembersChange}
                        />
                    </Modal>
                )}

                {isEditModalVisible && (
                    <Modal
                        title="New number of members"
                        visible={isEditModalVisible}
                        onOk={this.editCorporateOrder}
                        onCancel={this.toggleEditModal}
                    >
                        Please enter new number of members you would like to buy
                        membership for.
                        <Input
                            defaultValue={numberOfMembersToEdit}
                            onChange={this.handleNumberOfMembersToEditChange}
                        />
                    </Modal>
                )}

                {isAddPaymentModalVisible && (
                    <Modal
                        title="Add manual payment"
                        visible={isAddPaymentModalVisible}
                        onOk={() => this.addPaymentForm.current.submit()}
                        onCancel={() => this.setState({ isAddPaymentModalVisible: false })}
                    >
                        <Form layout="vertical" ref={this.addPaymentForm} onFinish={this.submitAddManualPayment}>
                            <Form.Item label="Payment date" name="date">
                                <DatePicker format={shortDateFormat} />
                            </Form.Item>
                            <Form.Item label="Amount" name="amount">
                                <Input prefix="€" />
                            </Form.Item>
                            <Form.Item label="Payment comment" name="comment">
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                )}

                {(user.isMember() || user.isBoard()) && !corporationId && (
                    <div className="membership-details">
                        <Card
                            title={
                                <div className="custom-card-header blue-underline">
                                    Membership
                                </div>
                            }
                            className="custom-card"
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic
                                        title={
                                            <div style={{ color: '#000000' }}>Expiration Date:</div>
                                        }
                                        value={formatShortDate(expirationDate) || 'No membership purchased'}
                                    />
                                </Col>
                            </Row>
                        </Card>

                    </div>

                )}

                <div style={{ marginBottom: 40 }}>
                    {!_.isEmpty(subscriptions) && false &&
                        <Card
                            title={
                                <div className="custom-card-header blue-underline">
                                    Subscription
                                </div>
                            }
                            extra={
                                <div className="membership-actions u--push-right">
                                    {subscriptions.status === "ACTIVE" &&
                                        <Button type="primary" onClick={e => this.cancelSubscription(e)}>
                                            Cancel subscription
                                        </Button>}
                                </div>
                            }
                            headStyle={{ border: 'none' }}
                        >
                            <Row>
                                <Col md={6} span={12}>
                                    <Statistic
                                        title="Start Date:"
                                        value={moment(subscriptions.start_time).utc().local().format(shortDateFormat)}
                                    />
                                </Col>
                                <Col md={6} span={12}>
                                    <Statistic
                                        title="Next billing date"
                                        value={
                                            subscriptions.billing_info &&
                                            (moment(subscriptions.billing_info.next_billing_time)
                                                .utc()
                                                .local()
                                                .format(shortDateFormat) || "Waiting for PayPal Callback")}
                                    />
                                </Col>
                                <Col md={6} span={12}>
                                    <Statistic
                                        title="Status"
                                        valueStyle={{ color: subscriptions.status === "ACTIVE" ? '#52C41A' : '#EB1A1A' }}
                                        value={subscriptions.status && subscriptions.status.charAt(0).toUpperCase() + subscriptions.status.slice(1).toLowerCase()}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    }
                </div>
                <Card
                    title={
                        <div className="custom-card-header blue-underline">
                            All payments
                        </div>
                    }
                    className="custom-card"
                    extra={
                        <>
                            {(user.isMember() || user.isBoard()) &&
                                !isContactPerson && (_.isEmpty(subscriptions) || subscriptions.status !== "ACTIVE") && (
                                    <>
                                        <Tooltip
                                            placement="top"
                                            title="Membership is for one year, however the renewal date will be pushed forward to the 1st of the following month (unless purchased on the 1st of the month)"
                                        >
                                            <Button style={{ margin: 5 }} type="primary" onClick={this.placeNewOrder}>
                                                <PlusCircleOutlined /> Purchase annual membership for {corporateMember || isAssociate ? `€135` : `€150`}
                                            </Button>
                                        </Tooltip>

                                        {!corporateMember && <Button style={{ margin: 5 }} type="primary" onClick={this.subscribe}>
                                            <PlusCircleOutlined /> Pay with Auto-Pay
                                        </Button>}



                                    </>
                                )}

                            {user.isAdmin() && <Button style={{ margin: 5 }} type="primary" onClick={this.addManualPayment}>
                                <PlusCircleOutlined /> Add manual payment
                            </Button>}
                        </>
                    }
                >
                    <PaymentsTable dataSource={payments} user={user} rowKey="id" onDeletePayment={this.deletePayment} />
                </Card>
                {!_.isEmpty(subscriptionPayments) &&
                    <Card
                        title={
                            <div className="custom-card-header blue-underline">
                                Subscription payments
                            </div>
                        }
                        className="custom-card"
                    >
                        <SubscriptionPaymentsTable api={process.env.REACT_APP_PAYMENT_URL} dataSource={subscriptionPayments} rowKey="id" />
                    </Card>
                }
            </Spin>
        );
    }
}

export const PaymentMemberHistory = withUser(PaymentMemberHistoryInner);
