import React from 'react';
import { Button, Spin, Result } from 'antd';
import { api } from '../api';
import { Link } from 'react-router-dom';

import '../styles/EmailConfirmationView.css';

import starIcon from '../img/star.png';
import { withUser } from '../components/UserContext';

class EmailConfirmationViewInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: null,
            loading: true,
            user: {},
            userInfo: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        api
            .patch(`/confirm-email/${this.props.match.params.token}`)
            .then(response => {   
                if(response.data.userInfo) {
                    this.setState({
                        userInfo: response.data.userInfo
                    })
                }
                this.setState({
                    loading: false,
                    status: response.data.updated
                })
            })
    }

    login() {
        const { userInfo } = this.state;
        const { user, history } = this.props;

        user.login(userInfo);

        history.push('/');
    }

    render() {
        const { status, loading } = this.state;

        return (
            <Spin spinning={loading}>
                {!loading &&
                    <div style={{ minWidth: 200, minHeight: 200}}>
                        {status === true && 
                            <div className="wrapped-content message email-confirmation">
                                <img src={starIcon} alt=""/>
                                <h2>You have confirmed your email address</h2>
                                <Button type="primary" className="submit-button" size="large" onClick={() => this.login()} block>
                                    Continue
                                </Button>
                            </div>
                        }
                        {status === false && 
                            <div className="wrapped-content message email-confirmation">
                                <Result
                                    style={{ paddingTop: 0 }}
                                    title={<span style={{ fontWeight: 500 }}>Cannot confirm this email address</span>}
                                    status="error"
                                />
                                <Button type="primary" className="submit-button" size="large" block>
                                    <Link to="/login">
                                        Back to login page
                                    </Link>
                                </Button>
                            </div>
                        }
                    </div>
                }
            </Spin>
        );
    }
}

export const EmailConfirmationView = withUser(EmailConfirmationViewInner)