import React from 'react'

export default function Membership({ icon, title, description, onClick, isSelected }) {
    return (
        <div className={`membership-type ${isSelected ? "selected" : ""}`}
            onClick={onClick}
        >
            <img src={icon} alt={title}/>
            <h2>{title}</h2>
            <p>{description}</p>
        </div>
    )
}
