import React, { useState, useEffect } from "react";
import { Result, Button, Spin } from 'antd';
import queryString from 'query-string';
import { api } from '../api';
import { Link } from 'react-router-dom';

export const EventsAfterPaymentView = (props) => {
    const params = queryString.parse(props.location.search)

    const [email, setEmail] = useState(null);
    const [eventName, setEventName] = useState(null);
    const [success, setSuccess] = useState(params.success === 'true');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        
        if (params.ticket) {
            api.get(`registeredUsers/${params.ticket}`)
                .then(res => {
                    setLoading(false);
                    setEmail(res.data && res.data.data && res.data.data.email)
                    setEventName(res.data && res.data.data && res.data.data.name)
                    setSuccess(res.data && res.data.success)
                })
        }
        else {
            setLoading(false);
            setSuccess(false);
        }
        
    }, [params.ticket])

    return (
        <Spin spinning={loading}>
            <Result
                status={success ? 'success' : 'error'}
                title={success ? `You have been registered to event ${eventName}!` : 'You have not been registered!'}
                subTitle={(success && email && params.ticket) && 
                    <div>
                        Confirmation email has been sent to email {email} <br />
                        Your ticket is: {params.ticket}
                    </div>}
                extra={[
                    <Link key="main-page-link" to='/'>
                        <Button type="primary" key="console">
                            Go to main page
                        </Button>
                    </Link>
                ]}
            />
        </Spin>
    )
}
