import React from 'react';
import moment from 'moment';

export const shortNoDayDateFormat = "MMM YYYY";
export const shortDateFormat = "DD MMM YYYY";
export const longDateFormat = "DD MMM YYYY HH:mm";
export const detailedDateFormat = "DD MMM YYYY HH:mm:ss";

export const formatShortNoDayDate = date => date && moment(date).format(shortNoDayDateFormat);
export const formatShortDate = date => date && moment(date).format(shortDateFormat);
export const formatLongDate = date => date && moment(date).format(longDateFormat);
export const formatDetailedDate = date => date && moment(date).format(detailedDateFormat);
export const renderDate = date => <span style={{ whiteSpace: 'nowrap' }}>{formatShortDate(date)}</span>;