import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, DatePicker, Form, Row, Col } from 'antd';

import { withUser } from '../components/UserContext';

import { api } from '../api';
import ErrorMessage from '../components/ErrorMessage';
import SuccessMessage from '../components/SuccessMessage';
import SupportMessage from '../components/utils/SupportMessage';
import { shortDateFormat } from '../utils';

export class LogInViewInner extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            inProgress: false,
            notConfirmedEmail: false,
            incorectCredentials: false,
            isFromInvitation: false,
            fields: [],
            invitationId: null,
            lastEnteredEmail: null,
            resendEmailSended: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.location.search.indexOf('invitation') !== -1) {
            const invitationId =
                this.props.location.search.split('invitation=')[1] || null;

            this.setState({
                invitationId,
                fields: [{ name: ['invitationId'], value: invitationId }],
            });
        }
    }

    shouldUserBeRedirectedToInitialView = userInfo => {
        return this.props.user.corporationId && userInfo && (!userInfo.corporationRenewalDate || !userInfo.corporationApprovalStatus);
    }

    handleLogin = userCredentials => {
        const { email, password } = userCredentials;

        this.setState({
            inProgress: true,
            lastEnteredEmail: email
        }, () => {
            api.post('/login', {
                email: email,
                password: password,
                currentDate: this.state.currentDate,
                invitationId: this.state.invitationId,
            })
                .then(response => response.data)
                .then(response => {
                    if (response.success) {
                        this.props.user.login(response.userInfo);
                    } else {
                        this.setState({ inProgress: false });
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.setState({
                            inProgress: false,
                            incorectCredentials: error.response.data
                                .isIncorectCredentials
                                ? true
                                : false,
                            notConfirmedEmail: error.response.data
                                .isNotEmailConfirmed
                                ? true
                                : false,
                        });
                    }
                });
        }
        );
    };

    handleSubmit = e => {
        const { invitationId } = this.state;

        if (this.state.invitationId) {
            api.get(`/invitations/${invitationId}`)
                .then(res => res.data)
                .then(res => {
                    this.setState({
                        isFromInvitation: true
                    });
                })
                .then(() => {
                    this.handleLogin(e);
                });
        } else {
            this.handleLogin(e);
        }
    };

    resendEmailActivationLink() {
        api.post('/users/resend-email-confirmation', {
            email: this.state.lastEnteredEmail
        })
            .then(response => {
                this.setState({
                    resendEmailSended: response.data.success
                })
            })
    }

    render() {
        const { user, location } = this.props;

        if (user.id && location.eventRedirectUrl) {
            return <Redirect to={location.eventRedirectUrl} />;
        }
        if (user.id) {
            return <Redirect to="/" />;
        }

        return (
            <div className="wrapped-content">
                <h1>Log in</h1>
                <p style={{ marginBottom: "1.5em" }}>Not yet an ArbitralWomen member? <Link style={{ display: "block" }} to="/register">Create an account</Link></p>
                {this.state.resendEmailSended &&
                    <SuccessMessage
                        style={{ marginBottom: 25 }}
                        message="Confirmation email has been resend"
                    />
                }
                {this.state.notConfirmedEmail && (
                    <ErrorMessage
                        style={{ marginBottom: 25 }}
                        message={
                            <>
                                An account with this username already exists but the email has not yet been confirmed.
                                A message from <b>noreply@arbitralwomen.org</b> may be in your email box or your spam box.{" "}
                                <Button onClick={() => this.resendEmailActivationLink()} type="link">Click here to receive again the activation email.</Button>
                            </>
                        }
                    />
                )}
                {this.state.incorectCredentials && (
                    <ErrorMessage
                        style={{ marginBottom: 25 }}
                        message="Invalid email or password"
                    />
                )}


                <Form
                    onFinish={this.handleSubmit}
                    fields={this.state.fields}
                >
                    <Row justify="center" gutter={[8, 8]}>
                        <Col md={12} span={24}>
                            <Form.Item
                                name="invitationId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                    {
                                        type: 'email',
                                        message:
                                            'Please enter correct email address',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <UserOutlined
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    }
                                    placeholder="Email"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[8, 8]}>
                        <Col md={12} span={24}>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is required',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined
                                            style={{ color: 'rgba(0,0,0,.25)' }}
                                        />
                                    }
                                    type="password"
                                    placeholder="Password"
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.props.location.search.indexOf('test=1') >
                        -1 && (
                            <Form.Item>
                                <DatePicker
                                    placeholder="Override current date"
                                    style={{ width: '100%' }}
                                    onChange={(date, dateString) => {
                                        this.setState({
                                            currentDate: dateString,
                                        });
                                    }}
                                    format={shortDateFormat}
                                />
                            </Form.Item>
                        )}
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={this.state.inProgress}
                        style={{ marginBottom: 20, maxWidth: 150 }}
                        size="large"
                        className="submit-button"
                    >
                        Log-in
                    </Button>
                    {this.state.invitationId ? (
                        <Link
                            style={{ display: "block", marginBottom: 20 }}
                            to={`/invitations/${this.state.invitationId}`}
                        >
                            Back to invitation
                        </Link>
                    ) : (
                        <>
                            <Link
                                style={{ display: "block", marginBottom: 20 }}
                                to="/reset-password"
                            >
                                Forgot password? Click here to reset password
                            </Link>
                        </>
                    )}
                    <Row justify="center" gutter={[8, 8]}>
                        <Col md={16} span={16}>
                            <SupportMessage />
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export const LogInView = withRouter(withUser(LogInViewInner));
