import React from 'react';
import 'antd/dist/antd.css';

import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Layout, Affix, Modal } from 'antd';

import LoginViewComponent from './views/LoginViewComponent';
import PageLoading from './components/PageLoading';
import ArticlesView from './views/ArticlesView';
import MobileMenu from './components/menus/MobileMenu';

import {
    ArticlesAdminView,
    AuditView,
    BoardView,
    BoardsView,
    CorporationsAdminView,
    MembersAdminView,
    SystemUsersView,
    PaymentsAdminView,
    EventsAdminView
} from './views/admin';
import { ProfileView } from './views/ProfileView';
import { CustomPageHeader } from './components/CustomPageHeader';
import { RegisterUserView } from './views/RegisterUserView';
import { AccountView } from './views/AccountView';
import { MembershipView } from './views/MembershipView';
import { CorporationsMemberView } from './views/CorporationsMemberView';
import { DashboardView } from './views/DashboardView';
import { InvitationView } from './views/InvitationView';
import { DocumentsView } from './views/DocumentsView';
import { EventsAfterPaymentView } from './views/EventsAfterPaymentView';
import {
    IndividualSetupView,
    InitializeCorporateView
} from './views/registration';
import { EmailUpdateView } from './views/EmailUpdateView';
import { Menu } from './components/menus';

import { api } from './api';
import { withUser } from './components/UserContext';

import './styles/App.css';
import { YampBoard } from './views/admin/YampBoard';

const { Header, Content, Sider } = Layout;

class AppInner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            redirectToIndividualSetup: false,
            redirectToCorporateSetup: false,
            title: '',
            refreshingUserData: false,
            displayMobileMenu: false
        };
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentDidMount() {
        const { user } = this.props;

        user && user.id &&
            api
                .post(`users/${this.props.user.id}/membership-status`)
                .then(res => {
                    const { membershipStatus } = res.data.data;

                    this.props.user.changeMembership(membershipStatus);
                });

        const params = window.location.href.split('?');

        if (params[1] === 'payment_completed') {
            Modal.success({
                title: "Payment completed",
                content: 'You have successfully purchased membership'
            })
        }

        if (params[1] === 'payment_canceled') {
            Modal.warning({
                title: 'Payment canceled',
                content: 'Your payment was canceled'
            })
        }
    }


    componentDidUpdate(prevProps) {
        const { user } = this.props;

        if (user && user.id && (user.id !== prevProps.user.id)) {
            this.setState({
                refreshingUserData: true
            }, () => {
                api
                    .get('users/initialize-info')
                    .then(res => {
                        this.setState({
                            redirectToIndividualSetup: this.shouldRedirectToIndividualSetup(res.data),
                            redirectToCorporateSetup: this.shouldRedirectToCorporateSetup(res.data),
                            refreshingUserData: false
                        })
                    })
            })
        }
    }

    shouldRedirectToIndividualSetup(userInfo) {
        const { user } = this.props;

        if (user.isMember() && !user.corporationId) {
            const { cv, img, first_name, last_name, isApproved } = userInfo;

            return ((!cv || !img || !first_name || !last_name) && !isApproved) ? true : false;
        }

        return false;
    }

    shouldRedirectToCorporateSetup(userInfo) {
        const { user } = this.props;

        if (user.isMember() && user.corporationId) {
            return !userInfo.corporationRenewalDate || window.location.href.includes('/setup/corporate');
        }

        return false;
    }

    changeTitle = newTitle => {
        this.setState({
            title: newTitle,
        });
    }

    setMobileMenu(state) {
        this.setState({
            displayMobileMenu: state
        })
    }

    render() {
        const { user } = this.props;

        const isAdmin = user.isAdmin();
        const isMember = user.isMember();
        const isBoard = user.isBoard();

        const { redirectToIndividualSetup, redirectToCorporateSetup, refreshingUserData } = this.state;
        const { id, corporationId } = user;

        if (refreshingUserData || user.isLoading) {
            return (
                <PageLoading />
            )
        }

        if (user.id && (redirectToIndividualSetup || redirectToCorporateSetup)) {
            return (
                <Router>
                    {redirectToCorporateSetup && user.corporationId &&
                        <Route path="/" render={props => <InitializeCorporateView {...props} />}></Route>
                    }
                    {redirectToIndividualSetup &&
                        <Route path="/" render={props => <IndividualSetupView {...props} />}></Route>
                    }
                </Router>
            )
        }

        const { displayMobileMenu } = this.state;

        return (
            <Router>
                {!id &&
                    !window.location.href.includes('/reset-password/') &&
                    !window.location.href.includes('/invitations/') &&
                    (!window.location.href.includes('/event/') || window.location.href.includes('/events')) &&
                    !window.location.href.includes('/confirm-email/') &&
                    !window.location.href.includes('/confirm-email-request/')
                    && <Redirect to="/login" />}

                {displayMobileMenu &&
                    <MobileMenu
                        hideMobileMenu={() => this.setMobileMenu(false)}
                    />
                }

                <Switch>
                    <Route path="/login" component={LoginViewComponent} />
                    <Route path="/register" component={LoginViewComponent} />
                    <Route exact path="/reset-password" component={LoginViewComponent} />
                    <Route path="/reset-password/:token" component={LoginViewComponent} />
                    <Route path="/invitations/:invitationId?" component={LoginViewComponent} />
                    <Route
                        path="/invitations/:invitationId?"
                        component={InvitationView}
                    />
                    <Route path="/confirm-email/:token" component={LoginViewComponent}
                    />
                    <Route
                        path="/confirm-email-request/:token?"
                        component={EmailUpdateView}
                    />
                    <Route
                        path="/event/:eventId/:name"
                        component={RegisterUserView}
                    />
                    <Route
                        path="/event/after-payment"
                        component={EventsAfterPaymentView}
                    />
                    <Route path="*">
                        <Layout theme="light">

                            <Affix offsetTop={0} style={{ height: '64px' }}>
                                <Header className="header">
                                    <CustomPageHeader
                                        title={this.state.title}
                                        displayMobileMenu={() => this.setMobileMenu(true)}
                                    />
                                </Header>
                            </Affix>
                            <Layout className="layout">
                                <Affix
                                    offsetTop={64}
                                    style={{
                                        backgroundColor: '#FFFFFF'
                                    }}
                                >
                                    <div className="left-sider">
                                        <Sider
                                            collapsible
                                            theme="light"
                                            breakpoint="lg"
                                            onCollapse={this.toggleCollapsed}
                                            collapsed={this.state.collapsed}
                                            width={230}
                                            style={{
                                                overflow: 'auto',
                                                height: 'calc(100vh - 110px)',
                                            }}
                                        >
                                            <Menu />
                                        </Sider>
                                    </div>
                                </Affix>
                                <Content>
                                    <Switch>
                                        <Route
                                            path="/documents/:id"
                                            render={props => <DocumentsView {...props} changeTitle={this.changeTitle} />}
                                        />
                                        <Route path="/members"
                                            render={props => (
                                                <MembersAdminView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/profile"
                                            render={props => (
                                                <ProfileView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        {(isMember || isBoard) && (
                                            <Route
                                                path="/profile/:id"
                                                render={props => (
                                                    <Redirect to={'/profile'} />
                                                )}
                                            />
                                        )}
                                        {corporationId && (
                                            <Route
                                                path="/my-corporation"
                                                render={props => (
                                                    <CorporationsMemberView
                                                        {...props}
                                                        user={user}
                                                        changeTitle={
                                                            this.changeTitle
                                                        }
                                                    />
                                                )}
                                            />
                                        )}

                                        <Route path="/events"
                                            render={props => (
                                                <EventsAdminView
                                                    user={user}
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route path="/articles"
                                            render={props => (
                                                <ArticlesAdminView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route path="/corporations"
                                            render={props => (
                                                <CorporationsAdminView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        {isAdmin && (
                                            <Route
                                                path="/payments"
                                                render={props => (
                                                    <PaymentsAdminView
                                                        {...props}
                                                        changeTitle={
                                                            this.changeTitle
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                        <Route
                                            path="/register_user/:eventId?"
                                            render={props => (
                                                <RegisterUserView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/boards"
                                            render={props => (
                                                <BoardsView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/yawp-board"
                                            render={props => (
                                                <YampBoard
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route path="/boards/:id"
                                            render={props => (
                                                <BoardView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route path="/audit"
                                            render={props => (
                                                <AuditView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/users"
                                            render={props => (
                                                <SystemUsersView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />

                                        <Route path="/profile/:id"
                                            render={props => (
                                                <ProfileView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />


                                        <Route path="/payments">
                                            <Content className="content">
                                                <MembershipView
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            </Content>
                                        </Route>

                                        <Route path="/account">
                                            <Content className="content">
                                                <AccountView
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            </Content>
                                        </Route>

                                        <Route path="/articles">
                                            <ArticlesView
                                                changeTitle={this.changeTitle}
                                            />
                                        </Route>

                                        <Route
                                            path="*"
                                            render={props => (
                                                <DashboardView
                                                    {...props}
                                                    changeTitle={
                                                        this.changeTitle
                                                    }
                                                />
                                            )}
                                        />

                                        <Route path="/">
                                            <Redirect to="/members" />
                                        </Route>
                                    </Switch>
                                </Content>
                            </Layout>
                        </Layout>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export const App = withUser(AppInner);
