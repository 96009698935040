import React from 'react'
import { InputSuffix } from '../../../utils/currencySuffix'

export default function PaymentDetails({ paymentDetails, currency }) {
    return (
        <div>
            <div className="user-details element">
                <p>Applied coupons</p>
                {paymentDetails.applied_coupons && paymentDetails.applied_coupons.map((coupon, key) => 
                    <span key={key}>{coupon.name} ({coupon.type === "amount" && InputSuffix(coupon.type, currency)}{coupon.amount}{coupon.type === "percentage" && InputSuffix(coupon.type, currency)}){key < paymentDetails.applied_coupons.length - 1 && ", "}</span>
                )}
            </div>
            <div className="user-details element">
                <p>Sponsorship</p>
                {paymentDetails.sponsorships && paymentDetails.sponsorships.map((sponsorship, key) => 
                    <div key={key}>
                        <div>
                            <span>{sponsorship.name} ({InputSuffix("amount", currency)}{sponsorship.price})</span>
                        </div>
                        <div>
                            <span>{sponsorship.user_info}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="user-details element">
                <p>Addons</p>
                {paymentDetails.addons && paymentDetails.addons.map((addon, key) => 
                    <div key={key} className="list">
                        <div>
                            <span>{addon.name} ({InputSuffix("amount", currency)}{addon.price})</span>
                        </div>
                        <div>
                            <span>{addon.user_info}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="user-details element">
                <p>Summary</p>
                <b>{InputSuffix("amount", currency)}{paymentDetails.amount_to_pay || 0}</b>
            </div>
        </div>
    )
}
