import React from 'react';
import { Link } from 'react-router-dom';
import {
    Spin,
    Button,
    Modal,
    Input,
    Checkbox,
    Card,
    Empty,
    Space
} from 'antd';
import { api } from '../api';
import { CheckCircleOutlined, CloseCircleOutlined, UserOutlined } from '@ant-design/icons';
import CustomTable from './CustomTable';
import { renderDate } from '../utils';

const { TextArea } = Input;

export class MembersApproval extends React.Component {
    constructor() {
        super();
        this.state = {
            isModalVisible: false,
            comment: '',
            idsToApprove: [],
        };

        this.columns = [
            {
                title: 'Img',
                dataIndex: 'img',
                key: 'img',
                render: (_, row) =>
                    row.img ? (
                        <img
                            className="rounded-profile-image"
                            src={row.img}
                            width="32"
                            height="32"
                            alt="Profile"
                        />
                    ) : (
                        <UserOutlined
                            style={{
                                backgroundColor: '#CCC',
                                fontSize: 18,
                                padding: 8,
                                color: '#FFF',
                            }}
                        />
                    ),
            },
            {
                title: 'Status',
                dataIndex: 'checked',
                key: 'checked',
                render: (_, row) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Checkbox
                            onChange={e =>
                                this.handleChangeMemberStatus(row.id, e)
                            }
                        />
                    </div>
                ),
            },
            {
                title: 'First name',
                dataIndex: 'first_name',
                key: 'first_name',
            },
            {
                title: 'Last name',
                dataIndex: 'last_name',
                key: 'last_name',
            },
            {
                title: 'Email',
                dataIndex: 'personal_email',
                key: 'personal_email',
                render: (_, row) =>
                    row.personal_email ? (
                        <a href={`mailto:${row.personal_email}`}>
                            {row.personal_email}
                        </a>
                    ) : (
                        ''
                    ),
            },
            {
                title: 'Registration date',
                dataIndex: 'created_at',
                key: 'created_at',
                render: renderDate
            },
            {
                title: 'CV',
                dataIndex: 'cv',
                key: 'cv',
                render: (_, row) =>
                    row.cv ? <a href={`${row.cv}`}>Download</a> : null,
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (_, row) => <Link to={`/profile/${row.id}`}>Edit Profile</Link>,
            },
        ];
    }

    handleChangeMemberStatus = (memberId, e) => {
        let statusArray = this.state.idsToApprove;
        if (this.state.idsToApprove.indexOf(memberId) < 0) {
            statusArray.push(memberId);
        } else {
            statusArray = statusArray.filter(id => id !== memberId);
        }
        this.setState({
            idsToApprove: statusArray,
        });
    };

    approveMembers = () => {
        const { idsToApprove, comment, action } = this.state;
        this.toggleModal();

        if (idsToApprove.length > 0) {
            this.setState({ isLoading: true });

            api.post(`/members/${action}`, {
                ids_to_approve: idsToApprove,
                comment,
            })
                .then(() => {
                    this.props.fetchMembers();
                    this.setState({
                        idsToApprove: [],
                    });
                })
                .catch(error => console.log(error));
            this.props.fetchMembers();
        } else {
            Modal.warning({
                content: "You haven't selected any users"
            })
        }
    };

    toggleModal = (action) => {
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible,
            action
        }));
    };

    handleCommentChange = e => {
        this.setState({ comment: e.target.value });
    };

    render() {
        const { isModalVisible, idsToApprove, action } = this.state;
        return (
            <>
                {isModalVisible && (
                    <Modal
                        title="Comment"
                        visible={isModalVisible}
                        onOk={this.approveMembers}
                        onCancel={this.toggleModal}
                    >
                        Do you want to add a comment?
                        <br />
                        If you are {action === 'approve' ? 'approving' : 'rejecting'} multiple members, comment will be
                        added to all of them.
                        <TextArea
                            rows={4}
                            className="u--spacer-top-1x"
                            onChange={this.handleCommentChange}
                        />
                    </Modal>
                )}
                <Spin spinning={this.props.isLoading} size="large">
                    <Card
                        className="custom-card"
                        title={
                            <div className="custom-card-header blue-underline">
                                New member(s) pending approval
                            </div>
                        }
                        extra={
                            this.props.members && (
                                <Space>
                                    <Button
                                        disabled={idsToApprove.length === 0}
                                        type="primary"
                                        icon={<CheckCircleOutlined />}
                                        onClick={() => this.toggleModal('approve')}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        disabled={idsToApprove.length === 0}
                                        type="default"
                                        icon={<CloseCircleOutlined />}
                                        onClick={() => this.toggleModal('reject')}
                                    >
                                        Reject
                                    </Button>
                                </Space>
                            )
                        }
                    >
                        {this.props.members.length > 0 
                            ? 
                                <CustomTable
                                    dataSource={this.props.members}
                                    columns={this.columns}
                                    pagination={{ pageSize: 5 }}
                                    rowKey="id"
                                />
                            : 
                                <Empty />
                        }
                    </Card>
                </Spin>
            </>
        );
    }
}
