import React, { Component } from 'react';
import { Row, Col } from 'antd';

import '../styles/LoginView.css';
import logo from '../logo.svg';
import { LogInView } from './LogInView';
import { RegisterView } from './RegisterView';
import { InvitationView } from './InvitationView';
import { EmailConfirmationView } from './EmailConfirmationView';
import ResetPassword from './ResetPassword';

export default class LoginViewComponent extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { location } = this.props;

        let content;

        if(location.pathname === "/login") {
            content = <LogInView {...this.props}/>
        }
        
        if(location.pathname === "/register") {
            content = <RegisterView  {...this.props}/>
        }

        if(location.pathname.includes('/invitations/')) {
            content = <InvitationView {...this.props}/>
        }
        
        if(location.pathname.includes('/confirm-email/')) {
            content = <EmailConfirmationView {...this.props}/>
        }
        
        if(location.pathname.includes("/reset-password")) {
            content = <ResetPassword  {...this.props}/>
        }

        return (
            <Row className="login-page">
                <Col className="login-logo" md={8} span={0}>
                    <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
                        <a href="https://arbitralwomen.org">
                            <img src={logo} alt="ArbitralWomen logo"/>
                        </a>
                    </div>
                </Col>
                <Col md={16} span={24} className="login-content">
                    {content}
                </Col>
            </Row>
        )
    }
}
