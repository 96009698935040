import React from 'react';
import { Layout } from 'antd';
import { withUser } from '../components/UserContext';
import {
    AdminDashboard,
    CorporateMemberDashboard,
    IndividualMemberDashboard
} from '../components/dashboards';

const { Content } = Layout;

class DashboardViewInner extends React.PureComponent {
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        this.props.changeTitle('Dashboard');
    };

    render() {
        const { user } = this.props;
        const { corporationId } = user;

        return (
            <>
                <Content className="content u--text-center">
                    {(user.isMember() || user.isBoard()) && !user.isAdmin() && !corporationId && 
                        <IndividualMemberDashboard />
                    }
                    {(user.isMember() || user.isBoard()) && !user.isAdmin() && corporationId && 
                        <CorporateMemberDashboard {...this.props}/>
                    }
                    {user.isAdmin() && 
                        <AdminDashboard {...this.props} />
                    }
                </Content>
            </>
        );
    }
}

export const DashboardView = withUser(DashboardViewInner);
