import React from 'react';
import { Card, Button, Input, Spin, Form, Modal, Row, Col } from 'antd';

import { ProfileResetPasswordForm } from '../components/ProfileResetPasswordForm';
import { withUser } from '../components/UserContext';

import { api } from '../api';
import { validateEmail } from '../utils/validateEmail';
import CorporationJoinRequestCard from "../components/CorporationJoinRequestCard";

class AccountViewInner extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            newEmail: '',
            newRecoveryEmail: '',
            firstName: this.props.user.first_name || '',
            lastName: this.props.user.last_name || '',
            newFirstName: '',
            newLastName: '',
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.changeTitle('My Account');
    }

    handleResetPassword = e => {
        const { confirm, oldPassword, password } = e;

        api.patch('/users/password', {
            userId: this.props.user.id,
            oldPassword,
            password,
            confirm,
        })
            .then(response => {
                if (response.data.success) {
                    Modal.success({
                        content: response.data.message
                    })
                } else {
                    Modal.warning({
                        content: response.data.message
                    })
                }
            })
            .catch(error => {
                if (error.response && !error.response.data.success) {
                    Modal.warning({
                        content: error.response.data.message
                    })
                }
            });
    };

    handleUpdateEmail = (e, type) => {
        e.preventDefault();
        const { newEmail } = this.state;
        const {
            user: { id, first_name },
        } = this.props;

        if (validateEmail(newEmail)) {
            api.post(`/users/request-change-email`, {
                type,
                email: newEmail,
                userId: id,
                name: first_name,
            })
                .then(() => Modal.success({ content: 'Confirmation email has been sent' }))
                .catch(error => Modal.warning({ content: error.response.data.message }));
        } else {
            Modal.warning({ content: 'Invalid email' });
        }
    };

    handleUpdateName = () => {
        const { newFirstName, newLastName } = this.state;
        const { user: { id, changeName }} = this.props;

        if (newFirstName.length > 0 && newLastName.length > 0) {
            this.setState({ isLoading: true });

            api.post(`/users/change-name`, {
                user: this.props.user,
                firstName: newFirstName,
                lastName: newLastName,
                userId: id,
            })
            .then(res => {
                this.setState({
                    firstName: newFirstName,
                    lastName: newLastName,
                    isLoading: false,
                });
                const { firstName, lastName } = res.data;
                changeName(firstName, lastName);
            })
        } else {
            Modal.warning({
                title: "Setup error",
                content: "Please fill in first and last name fields."
            })
        }
    };

    handleChange = (e, field) => {
        this.setState({ [field]: e.target.value });
    };

    render() {
        const { user: { email, corporationId }} = this.props;

        const {
            isLoading,
            firstName,
            lastName,
            newFirstName,
            newLastName,
            newEmail,
        } = this.state;

        const { user } = this.props;

        return (
            <Spin spinning={isLoading}>
                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            General account information
                        </div>
                    }
                >
                    <h4 className="no-bold">
                        First name: <b>{firstName}</b>
                    </h4>
                    <h4 className="no-bold">
                        Last name: <b>{lastName}</b>
                    </h4>
                </Card>
                {!corporationId && !user.isContactPerson && <CorporationJoinRequestCard {...this.props}/>}
                <ProfileResetPasswordForm onSubmit={this.handleResetPassword} />
                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            Personal email address
                        </div>
                    }
                >
                    <h3 className="no-bold" style={{ marginBottom: 25 }}>
                        <span className="u--secondary-text">
                            Email address:
                        </span>{' '}
                        {email}
                    </h3>
                    <Form method="post">
                        <Row gutter={[8,8]}>
                            <Col md={6} span={24}>
                                <Form.Item>
                                    <Input
                                        placeholder="New email"
                                        className="profile-reset-pwd-input"
                                        onChange={e => this.handleChange(e, 'newEmail')}
                                        value={newEmail}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={6} span={24}>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={e => this.handleUpdateEmail(e, 'personal')}
                                >
                                    Update email
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Card
                    className="custom-card"
                    title={
                        <div className="custom-card-header blue-underline">
                            User information
                        </div>
                    }
                >
                    <h3 className="no-bold">
                        Change your first name and last name
                    </h3>
                    <Form
                        method="post"
                        onSubmit={this.handleUpdateName}
                    >
                        <Row gutter={[8,8]}>
                            <Col md={6} span={24}>
                                <Form.Item>
                                    <Input
                                    placeholder="First name"
                                    className="profile-reset-pwd-input"
                                    onChange={e =>
                                        this.handleChange(e, 'newFirstName')
                                    }
                                    value={newFirstName}
                                />
                            </Form.Item>
                            </Col>
                            <Col md={6} span={24}>
                                <Form.Item>
                                    <Input
                                        placeholder="Last name"
                                        className="profile-reset-pwd-input"
                                        onChange={e =>
                                            this.handleChange(e, 'newLastName')
                                        }
                                        value={newLastName}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={6} span={24}>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={this.handleUpdateName}
                                >
                                    Update
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        );
    }
}

export const AccountView = withUser(AccountViewInner);
