import React from 'react';
import CustomTable from './CustomTable';
import { renderDate } from '../utils';
import { Button, Popconfirm } from 'antd';
import PaymentStatus from './PaymentStatus';

const PaymentsTable = props => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'create_time',
            key: 'create_time',
            render: renderDate,
        },
        {
            title: 'Amount',
            dataIndex: 'amount_to_pay',
            key: 'amount_to_pay',
            render: (obj, row) =>
                row.amount_to_pay
                    ? `	€${parseFloat(row.amount_to_pay).toFixed(2)}`
                    : '',
        },
        {
            title: 'Order details',
            dataIndex: 'order_details',
            key: 'order_details',
            render: (obj, row) => {
                if(row.currency !== undefined) 
                    return "Event ticket"

                return row.purchase_name ? row.purchase_name : '';
            },
        },   
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
            render: (obj, row) => {
                return row.created_by ? row.created_by : 'System';
            },
        },
        {
            title: 'Payment status',
            dataIndex: 'paypal_status',
            key: 'paypal_status',
            render: status => <PaymentStatus status={status} />
        },
        {
            title: 'PayPal Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
        },
        {
            render: (_, row) => <>
                {(row.membership_type === 'Manual' && props.user.isAdmin()) && <Popconfirm onConfirm={() => props.onDeletePayment(row)} title="Do you want to delete this payment?">
                        <Button type="link">Delete</Button>
                    </Popconfirm>}
                </>
        }
    ];

    const { rowKey, dataSource } = props;
    return (
        <CustomTable
            dataSource={dataSource}
            columns={columns}
            rowKey={rowKey}
            pagination={{ pageSize: 10 }}
        />
    )
}
export default PaymentsTable;