import React from 'react';
import { Input, Select } from 'antd';

import '../../styles/Filters.css';

const { Option } = Select;

export default function Filter({
    title,
    value,
    style,
    placeholder,
    onChange,
    type = 'input',
    defaultValue,
    options = [],
    optionProperty = {},
}) {
    return (
        <div className="filter" style={style}>
            <div className="filter-title">{title}</div>
            {type === 'input' && (
                <Input
                    value={value}
                    placeholder={placeholder}
                    onChange={e => onChange(e.target.value)}
                    defaultValue={defaultValue}
                />
            )}
            {type === 'select' && (
                <Select
                    onChange={e => onChange(e)}
                    value={value || defaultValue}
                    showSearch
                    filterOption={(input, option) =>
                        option && option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={placeholder}
                >
                    {options.map(option => {
                        if (typeof option === 'string') {
                            return (
                                <Option key={option} value={option}>
                                    {option}
                                </Option>
                            );
                        }
                        if (typeof option === 'object') {
                            return (
                                <Option
                                    key={option[optionProperty.key]}
                                    value={option[optionProperty.value]}
                                >
                                    {option[optionProperty.display]}
                                </Option>
                            );
                        }
                        return <></>
                    })}
                </Select>
            )}
        </div>
    );
}
