import React, { Component } from 'react';
import { api } from '../../api';
import { Statistic, Row, Col, Card, Spin, Typography } from 'antd';
import { HomeOutlined, ReadOutlined, UserOutlined } from '@ant-design/icons';
import SupportMessage from '../utils/SupportMessage';

import { Link } from 'react-router-dom';

const { Title } = Typography;

export class AdminDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            allMembersCount: 0,
            notApprovedMembersCount: 0,
            articlesCount: 0,
            corporationsCount: 0,
            membersCount: 0,
            outstandingPayments: 0,
            individualMembers: 0,
            corporateMembers: 0
        };
    }
    componentDidMount() {
        api
            .get('/statistics/admin')
            .then(resp => this.setState({ ...resp.data.data, loading: false }))
    }

    render() {
        const {
            allMembersCount,
            notApprovedMembersCount,
            corporationsCount,
            outstandingPayments,
            articlesCount,
            loading,
            individualMembers,
            corporateMembers
        } = this.state;

        return (
            <Spin spinning={loading}>
                <Row>
                    <Title level={3}>Overview</Title>
                </Row>

                <Row gutter={16}>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>Profiles Awaiting Approval</div>}
                                style={{ textAlign: 'left' }}
                                value={notApprovedMembersCount}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>Outstanding Payments</div>}
                                style={{ textAlign: 'left' }}
                                value={outstandingPayments}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>All Members</div>}
                                style={{ textAlign: 'left' }}
                                value={allMembersCount}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>Individual Members</div>}
                                style={{ textAlign: 'left' }}
                                value={individualMembers}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>Corporate Members</div>}
                                style={{ textAlign: 'left' }}
                                value={corporateMembers}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{color: '#000000'}}>Corporates</div>}
                                style={{ textAlign: 'left' }}
                                value={corporationsCount}
                            />
                        </Card>
                    </Col>
                </Row>                   
                <Row style={{ marginTop: '30px' }}>
                    <Title level={3}>Quick Access</Title>
                </Row>
                <Row gutter={16}>
                    <Col md={6} span={24}>
                        <Link to="/members">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <Col span={4}><UserOutlined /></Col>
                                            <Col span={20}>
                                                <span className="nav-text">
                                                    <Row justify='space-between'>
                                                        <Col>Members</Col>
                                                        <Col>{allMembersCount}</Col>
                                                    </Row>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={6} span={24}>
                        <Link to="/corporations">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <Col span={4}><HomeOutlined /></Col>
                                            <Col span={20}>
                                                <span className="nav-text">
                                                    <Row justify='space-between'>
                                                        <Col>Corporates</Col>
                                                        <Col>{corporationsCount}</Col>
                                                    </Row>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={6} span={24}>
                        <Link to="/articles">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <Col span={4}><ReadOutlined /></Col>
                                            <Col span={20}>
                                                <span className="nav-text">
                                                    <Row justify='space-between'>
                                                        <Col>Articles</Col>
                                                        <Col>{articlesCount}</Col>
                                                    </Row>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row style={{textAlign: 'left'}}>
                    <Col>
                        <SupportMessage />
                    </Col>
                </Row>
            </Spin>
        );
    }
}
