import React, { Component } from 'react'
import { api } from '../../api'
import { Button, Popconfirm, Modal, Card, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { longDateFormat } from '../../utils/formatShortDate'
import CustomTable from '../CustomTable';


export default class CorporationComments extends Component {
    columns = [
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Added on',
            dataIndex: 'added_on',
            key: 'added_on',
            render: date => date && moment(date).format(longDateFormat)
        },
        {
            title: 'Action',
            render: (_, comment) =>
                <>
                    <Button type="link" onClick={() => this.editComment(comment)}>Edit</Button>
                    <Popconfirm title="Dou you want to delete comment?" onConfirm={() => this.deleteComment(comment)}>
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </>
        }
    ]

    constructor(props) {
        super(props)

        this.state = {
            comments: [],
            comment: "",
            displayModal: false,
            editedComment: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchComments();
    }

    fetchComments() {
        const { corporation } = this.props;

        this.setState({ isLoading: true })

        api
            .get(`/corporations/${corporation && corporation.id}/comments`)
            .then(response => this.setState({ comments: response.data.comments, isLoading: false }))
            .catch(() => this.setState({ isLoading: false }))
    }

    addComment() {
        this.setState({
            displayModal: true,
            editedComment: null,
            comment: ""
        })
    }

    deleteComment(comment) {
        const { corporation } = this.props;

        api
            .delete(`/corporations/${corporation.id}/comments/${comment.id}`)
            .then(() => {
                this.fetchComments();
                this.setState({ displayModal: false })
            })
    }

    editComment(comment) {
        this.setState({
            displayModal: true,
            editedComment: comment.id,
            comment: comment.comment
        })
    }

    saveOrAddComment() {
        const { comment, editedComment } = this.state;
        const { corporation } = this.props;

        if (editedComment) {
            api
                .patch(`/corporations/${corporation.id}/comments/${editedComment}`, { comment })
                .then(() => {
                    this.fetchComments();
                    this.setState({ displayModal: false })
                })
        } else {
            api
                .post(`/corporations/${corporation.id}/comments`, { comment, corporationId: corporation.id })
                .then(() => {
                    this.fetchComments();
                    this.setState({ displayModal: false })
                })
        }
    }

    render() {
        const { comments, comment, displayModal, editedComment } = this.state;

        return (
            <Card
                style={{ border: "none", margin: 0 }}
                headStyle={{ padding: "0px" }}
                bodyStyle={{ padding: "0px" }}
                extra={
                    <Button type="primary" onClick={() => this.addComment()}>
                        <PlusOutlined /> Add comment
                    </Button>
                }
            >
                <CustomTable
                    dataSource={comments}
                    columns={this.columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
                <Modal
                    visible={displayModal}
                    title={`${editedComment ? "Edit" : "Add"} comment`}
                    onCancel={() => this.setState({ displayModal: false })}
                    onOk={() => this.saveOrAddComment()}
                >
                    Comment
                    <Input.TextArea
                        value={comment}
                        rows={4}
                        onChange={e => this.setState({ comment: e.target.value })}
                    />
                </Modal>
            </Card>
        )
    }
}
