import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withUser } from './UserContext';

function MembershipStatusInner({ user, status, isCompanyRepresentative}) {
    const paymentsPageLink = (user.corporationId || user.isCompanyRepresentative) 
        ? "/corporations"
        : "/payments" 

    let memberStatus = !isCompanyRepresentative && (
        <Tag color="grey">Membership inactive</Tag>
    );

    const daysUntilExpired =
        status &&
        status.expires_at &&
        moment(status.expires_at).diff(
            moment().startOf('day'),
            'days'
        );

    if (status && status.expires_at && daysUntilExpired < 0) {
        memberStatus = <Tag color="red">Your Membership has expired -  <Link to={paymentsPageLink} className="membership-link">Purchase membership here</Link></Tag>;
    }

    if (status && status.expires_at && daysUntilExpired > 30) {
        memberStatus = <Tag color="green">Active membership</Tag>;
    }

    if (status && status.expires_at && daysUntilExpired <= 30 && daysUntilExpired > 0) {
        memberStatus = (
            <Tag color="red">
                Your membership expires in {daysUntilExpired} days -  <Link to={paymentsPageLink} className="membership-link">Purchase membership here</Link>
            </Tag>
        );
    }

    if (status && status.expires_at && daysUntilExpired === 0) {
        memberStatus = <Tag color="red">Your membership expires today -  <Link to={paymentsPageLink} className="membership-link">Purchase membership here</Link></Tag>;
    }

    return !user.isAdmin() 
        ? status && status.is_suspended 
            ? <Tag color="red">Your Membership is suspended - Please contact the admin to resolve this</Tag>
            : memberStatus
        : <></>
}

export const MembershipStatus = withUser(MembershipStatusInner)