import React, { useState, useEffect } from 'react';
import { Result, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { withUser } from '../components/UserContext';

import '../styles/EmailConfirmationView.css';
import { api } from '../api';

function EmailUpdateViewInner(props) {
    const [status, setStatus] = useState('');
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        
        const {
            user: { changeEmail },
        } = props;
        api.patch(`/request-change-email/${props.match.params.token}`, {
            user: props.user
        })
            .then(res => {
                if (res.data.updated) {
                    setStatus('success');
                    setTitle('Your email address has been changed!');
                    if (res.data.type === 'personal') {
                        changeEmail(res.data.email, res.data.newToken);
                    }
                } else {
                    setStatus('warning');
                    setTitle(
                        'You have already confirmed the email address change request'
                    );
                }
                setLoading(false);
            })
            .catch(() => {
                setStatus('error');
                setTitle('Your email address request has not been confirmed');
            });
    }, []);

    return (
        <Spin spinning={loading}>
            <div className="email-confirm">
                {!loading && (
                    <Result
                        status={status}
                        title={title}
                        extra={
                            <Button type="primary">
                                <Link to="/login">Back to login page</Link>
                            </Button>
                        }
                    />
                )}
            </div>
        </Spin>
    );
}

export const EmailUpdateView = withUser(EmailUpdateViewInner);
