import React from "react";
import { Modal, Button, Space} from "antd";
import { api } from '../api';
import moment from 'moment';
import _ from "lodash";
import "../styles/PaymentModal.css";
import { formatShortDate } from "../utils";

export default class JoinRequestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      visible: false,
      option : 0,
      title : "",
    };
  }

  componentDidUpdate(prevProps){
     if ((this.props.recordMembershipStatus !== prevProps.recordMembershipStatus || this.props.estimatedCost !== prevProps.estimatedCost)) {
        this.handleOption();
     }
  }
 
  handleOption = () => {
    let option = 0;
   
    const { renewalDate, recordMembershipStatus, avaliableSlots, estimatedCost } = this.props;
    const expires_at = (!_.isEmpty(recordMembershipStatus) && recordMembershipStatus.expires_at ) || moment();
    //const difference = moment(renewalDate).diff(expires_at, 'M');
    const costPerMonth = 135 / 12;
    const difference = Math.floor(estimatedCost / costPerMonth);

    if (recordMembershipStatus && !recordMembershipStatus.expires_at) {
        option = avaliableSlots > 0 ? 0 : 1;
    } else {
        option = difference >= 0 ? 2 : 3;
    }

    this.setState({ option:option })

    switch (option) {
        case 0:
            this.setState({title: "This is a new member. If you accept this member they will be assigned to one of your current free slots" });
            break;
        case 1:
            this.setState({title: "This is a new member. In order to accept this member you will have to purchase a new slot" });
            break;
        case 2:
            this.setState({title: estimatedCost !== 0 
                ?
                    <p>
                        This member already has an active membership <br/>
                        until {formatShortDate(expires_at)}.
                        Your corporate renewaldate is <br/>
                        {" "+formatShortDate(renewalDate)}. To accept this members 
                        you will  <br/> have to pay  the difference of {Math.abs(difference)} month(s) 
                        which is €{estimatedCost}
                    </p>
                : 
                    <p>
                        This member already has an active membership <br />
                        until {formatShortDate(expires_at)}.
                        Your corporate renewaldate is <br />
                        {" " + formatShortDate(renewalDate)}. 
                        You can add this member without payment.
                    </p>            
            })
            break;
        case 3:
            this.setState({title :
                <p>
                    This member already has an active membership <br/>
                    until {formatShortDate(expires_at)}.
                    Your corporate renewaldate is <br/>
                    {" "+formatShortDate(renewalDate)}. If you accept this members 
                    you will  <br/> receive a credit of {Math.abs(difference)} month(s) 
                    which is ${estimatedCost} <br/>
                    This credit will be applied on your upcoming renewal date
                </p> 
            });
            break;
        default:
            this.setState({title: "" });
            return "";
    }
  }

  handleCancel = e => {
    e.preventDefault();
    this.props.handlePaymentModalVisibility(false);
  };

  handleAccept = e => {
    e.preventDefault();
    const { record } = this.props;
    this.props.handleAction(e, record, 'approve');
    this.props.handlePaymentModalVisibility(false)  
  }
  
  handleDefer = e => {
    e.preventDefault();

    const { user: { id }, corporationId, subscription, record } = this.props;

    if (subscription) {
      api.patch(`/payments/subscription/${subscription.subscription_id}/defer`,
        {
          userId: id,
          corporationId: corporationId,
          subscriptionId: subscription.subscription_id,
          newSlots: 1,
          user: this.props.user,
          description: "Join request payment for missing membership period",
          type: 'join-request'
        })
        .then(res => res.data)
        .then(() => {
          this.props.handlePaymentModalVisibility(false)
          this.props.fetchCorporation()
          this.props.handleAction(e, record, 'approve');
          this.setState({
            loading: false,
          });
        })
    }
    else {
      api.post(`/payments/new-order`, {
        userId: id,
        type: 'join-request',
        description: "Join request payment for missing membership period",
        numberOfMembers: 1,
        corporation_id: corporationId,
        isDeffered: true,
        joinRequest: record,
      }).then(() => {
        this.props.handlePaymentModalVisibility(false);
        this.props.handleAction(e, record, 'approve');
      });
    }

  }
  handleCredit = async e => {
    e.preventDefault();

    const { user: { id }, corporationId, record, subscription } = this.props;
    const action = { type: 'join-request', data: record }
    if (subscription) {
      await api.patch(`/payments/subscription/${subscription.subscription_id}/defer`,
        {
          userId: id,
          corporationId: corporationId,
          subscriptionId: subscription.subscription_id,
          newSlots: 1,
          user: this.props.user,
          description: "Join request payment for missing membership period",
          type: 'join-request'
        });
    }

    await api.post(`/payments/credits/${corporationId}`, { action })
      .then(res => {
        this.props.handlePaymentModalVisibility(false);
        this.props.handleAction(e, record, 'approve');
      })

  }

    reviseSubscription = e => {
        e.preventDefault();
        const { subscription,record } = this.props;

        api.patch(`/payments/subscription/${subscription.subscription_id}/revise`,
            { subscriptionId: subscription.subscription_id,
              newSlots: 1,
              user: this.props.user,
              description:"Join request payment for missing membership period",
              type: 'join-request',
              isDeffered:true, 
            })
            .then(res => res.data)
            .then(res => {
                this.props.handleAction(e, record, 'approve');
                window.location.replace(`${process.env.REACT_APP_PAYMENT_URL}/${res.data.id}`);
            })
    }

  render() {
    const { title, option } = this.state;
    const { visible = false, footer = null, estimatedCost } = this.props;

    return (
        <Modal
          className="payment-modal"
          title={title}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={footer}
        >
          <div className="payment-modal-content" style={{display:"flex", flexDirection:"row"}}>
        
              {(option === 0 || option === 3 || estimatedCost === 0)  ? (
                <>
                    <Button 
                      className="payment-button"  
                      type="primary" 
                      onClick={(option === 0 || estimatedCost === 0) ? this.handleAccept: this.handleCredit}
                    >
                      Accept 
                    </Button>

                    <Button className="payment-button"  type="link" onClick={this.handleCancel} >
                      Cancel
                    </Button>
              
                </>
              ) : (
                <Space>           
                    <Button className="payment-button"  type="primary" onClick={this.handleDefer} >
                      Accept & Pay Later
                    </Button>
            
                    <Button className="payment-button"  type="link" onClick={this.handleCancel} >
                      Cancel
                    </Button>
                </Space>
              )}
 
          </div>
        </Modal>
    );
  }
}
