import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Card, Button, Modal, Input, Col, Row, DatePicker } from 'antd';

import '../styles/ProfileView.css';
import { formatShortNoDayDate, shortNoDayDateFormat } from '../utils';
import moment from 'moment';

export const CorporationData = props => {
    const { corporation } = props;
    const [newName, setNewName] = useState(corporation && corporation.name);
    const [newRenewalDate, setNewRenewalDate] = useState(corporation && corporation.renewaldate && moment(corporation.renewaldate).format(shortNoDayDateFormat));
    const [isModalVisible, setIsModalVisible] = useState(false);

    function toggleModal() {
        setIsModalVisible(!isModalVisible);
    }

    function editData() {
        if (newName !== '') {
            toggleModal();
            props.onSubmit(newName, newRenewalDate);
        } else {
            Modal.warn({
                content: "Please fill in the form"
            })
        }
    }

    return (
        <>
            {isModalVisible && (
                <Modal
                    title="Edit corporate data"
                    visible={isModalVisible}
                    onOk={editData}
                    onCancel={toggleModal}
                >
                    Name
                    <Input
                        defaultValue={corporation && corporation.name}
                        onChange={e => setNewName(e.target.value)}
                        className="u--spacer-bottom-1x"
                    />
                    Membership renewal date<br />
                    <DatePicker
                        picker="month"
                        defaultValue={corporation && corporation.renewaldate && moment(corporation.renewaldate)}
                        onChange={(e, date) => setNewRenewalDate('01 ' + date)}
                        className="u--spacer-bottom-1x"
                        format={shortNoDayDateFormat}
                    />
                </Modal>
            )}
            <Card
                className="custom-card"
                title={
                    <h1 className="custom-card-header blue-underline">
                        Your corporate
                    </h1>
                }
                extra={
                    props.canEdit && <Button type="primary" onClick={toggleModal} >
                        <EditOutlined /> Edit
                    </Button>
                }
            >
                <Row>
                    <Col md={6} span={24}>
                        <div>
                            <span className="u--secondary-text">
                                Corporate name
                            </span>
                            <h2>{corporation && corporation.name}</h2>
                        </div>
                    </Col>
                    <Col md={6} span={24}>
                        <div>
                            <span className="u--secondary-text">
                                Corporate created
                            </span>
                            <h2>{formatShortNoDayDate((corporation || {}).establish_date)}</h2>
                        </div>
                    </Col>
                    <Col md={6} span={24}>
                        <div>
                            <span className="u--secondary-text">
                                Membership renewal date
                            </span>
                            <h2>{formatShortNoDayDate((corporation || {}).renewaldate)}</h2>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
