import React from "react";
import { Link } from "react-router-dom";
import {
    UserOutlined,
    DownOutlined,
    LogoutOutlined,
    MenuOutlined,
    LeftOutlined
} from "@ant-design/icons";
import {
    PageHeader,
    Menu,
    Row,
    Col,
    Dropdown,
    Button,
    Tag,
    Avatar,
} from "antd";
import { withUser } from "./UserContext";
import { MembershipStatus } from "./MembershipStatus";

import logo from "../logo.svg";

class CustomPageHeaderInner extends React.PureComponent {
    render() {
        const { user, title, subTitle } = this.props;

        const headerMenu = (
            <Menu>
                <div className="mobile-user-profile">
                    <MembershipStatus
                        status={user.membershipStatus}
                        isCompanyRepresentative={user.isContactPerson}
                        key="membershipStatus"
                    />
                    <span className="user-name">
                        {user.first_name} {user.last_name}
                    </span>
                    {user.corporationName && !user.isContactPerson && 
                        <Tag key="companyMember">
                            Member of corporate {user.corporationName}
                        </Tag>
                    }
                    {user.corporationId && 
                        <Tag key="companyRepresentative">
                            Corporate representative
                        </Tag>
                    }
                </div>
                <Menu.Item key="logout">
                    <Button
                        style={{ width: "100%", textAlign: "left", padding: 0 }}
                        type="link"
                        onClick={() => {
                            user.logout();
                        }}
                    >
                        <LogoutOutlined /> Log out
                    </Button>
                </Menu.Item>
            </Menu>
        );

        const userIcon = user.img 
            ? <img alt="user-icon" src={`${user.img}`} />
            : <UserOutlined />

        return (
            <Row
                style={{
                    background: "#FFFFFF",
                    height: "100%",
                    paddingRight: "15px",
                }}
                align="middle"
            >
                <Col md={0} span={2} offset={1} style={{ fontSize: 18, color: "#0072DC"}}>
                    <MenuOutlined color="blue" onClick={this.props.displayMobileMenu}/>
                </Col>
                <Col flex="200px" md={8} span={0}>
                    <Link to="/">
                        <div className="logo">
                            <img src={logo} alt="ArbitralWomen" />
                        </div>
                    </Link>
                </Col>
                <Col offset={1} flex="auto">
                    <PageHeader
                        ghost={false}
                        className="page-header"
                        title={title}
                        subTitle={subTitle}
                        extra={[
                            <Button key="back" type="link" style={{ position: "relative", top: -3 }} className="hide-mobile">
                                <a href={`${process.env.REACT_APP_WORDPRESS_URL}`} target="_blank" rel="noopener noreferrer">
                                    <LeftOutlined /> Back to Frontend
                                </a>
                            </Button>,
                            <div className="user-profile" key="membership-status">
                                <MembershipStatus
                                    status={user.membershipStatus}
                                    isCompanyRepresentative={user.isContactPerson}
                                    key="membershipStatus"
                                />
                            </div>,
                            user.corporationName && !user.corporationId && (
                                <div className="user-profile" key="member-of-corporate">
                                    <Tag key="companyMember">
                                        Member of corporate {user.corporationName}
                                    </Tag>
                                </div>
                            ),
                            user.corporationId && (
                                <div className="user-profile" key="corporate-representative">
                                    <Tag key="companyRepresentative">
                                        Corporate representative of {user.corporationName}
                                    </Tag>
                                </div>
                            ),
                            <Dropdown overlay={headerMenu} key="dropdown" overlayStyle={{ width: 200 }}>
                                <button
                                    style={{ marginLeft: 10 }}
                                    className="ant-dropdown-link button__link"
                                >
                                    <strong className="user-profile">
                                        {user.first_name} {user.last_name}
                                    </strong>{" "}
                                    <DownOutlined />
                                    <Avatar
                                        shape="square"
                                        icon={userIcon}
                                        style={{ marginLeft: "10px" }}
                                    />
                                </button>
                            </Dropdown>,
                        ]}
                    />
                </Col>
            </Row>
        );
    }
}

export const CustomPageHeader = withUser(CustomPageHeaderInner);
