import React from 'react';
import { api } from '../../api';
import { Statistic, Card, Spin, Row, Col, Typography, Tag } from 'antd';
import { ReadOutlined, UserOutlined, InfoCircleOutlined, CloseOutlined, TeamOutlined } from '@ant-design/icons';

import moment from 'moment';
import { shortDateFormat } from '../../utils';
import { Link } from 'react-router-dom';
import { withUser } from '../UserContext';
import SupportMessage from '../utils/SupportMessage';

const { Title } = Typography;

class IndividualMemberDashboardInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: 0,
            expires_date: "",
            last_payment: "",
            approval_info: "",
            isMemberProfileCreated: false,
            isLoading: true,
            completed_profile: null
        };
    }

    componentDidMount = async () => {
        try {
            const resp = await api.get(`statistics/individual`);
            const memberResp = await api.get('/members/is-member');
            const { data: { completed_profile } } = await api.get('/members/member-status');

            this.setState({
                articles:
                    resp.data && resp.data.data && resp.data.data.articles,
                expires_date:
                    resp.data && resp.data.data && resp.data.data.expires_date,
                last_payment:
                    resp.data && resp.data.data && resp.data.data.last_payment,
                approval_info:
                    resp.data && resp.data.data && resp.data.data.approval_info,
                isMemberProfileCreated:
                    memberResp.data && memberResp.data.isMember,
                isLoading: false,
                completed_profile
            });
        } catch (error) {
            console.log(error);
        }
    };

    closeNotification() {
        api
            .patch('/members/notification', {
                status: true
            })
            .then(() => {
                this.setState({
                    completed_profile: true
                })
            })
    }

    render() {
        const {
            expires_date,
            last_payment,
            approval_info,
            articles,
            isMemberProfileCreated,
            isLoading,
            completed_profile
        } = this.state;

        const { membershipStatus } = this.props.user;

        if (!membershipStatus)
            return null;

        const expiration_date_info = expires_date
            ? moment(expires_date).format(shortDateFormat)
            : 'No expiration date';

        const last_payment_info = last_payment
            ? moment(last_payment.create_time).format(shortDateFormat)
            : 'No last payment date';

        const approval_text_info = approval_info
            ? 'Approved'
            : 'Awaiting approval';

        const approval_color = approval_info ? '#3f8600' : '#e81a1a';

        const expiration_date_different = moment(membershipStatus.expires_at).diff(moment().startOf('day'), 'days');

        const expiration_color = expiration_date_different < 31 ? "#e81a1a" : "#000000";

        const articles_number = articles ? articles[0].count : 'No data';

        const overviewStyle = !expires_date || !isMemberProfileCreated ? { marginTop: 30 } : {};
        return (
            <Spin spinning={isLoading}>
                {completed_profile === false &&
                    <Tag
                        color="blue"
                        className="dashboard-info"
                        style={{ marginBottom: 40 }}
                    >
                        <InfoCircleOutlined style={{ marginRight: 5 }} />
                        Welcome to the ArbitralWomen member dashboard! Kindly complete your profile
                        {' '}<Link className="profile-link" to={"/profile"}>here</Link>
                        <CloseOutlined
                            style={{ float: "right", color: "#1890FF", fontSize: 12, marginTop: 5 }}
                            title="Close notification"
                            onClick={() => this.closeNotification()}
                        />
                    </Tag>
                }
                {!expires_date && !isLoading &&
                    <Tag
                        color="red"
                        className="dashboard-info"
                    >
                        <InfoCircleOutlined style={{ marginRight: 5 }} />
                        Your membership is inactive. You can purchase membership in your
                        &nbsp;<Link to="/payments" style={{ color: '#39A9FF', textDecoration: 'underline' }}>membership section</Link>
                        &nbsp;or you can join to the corporate.
                    </Tag>
                }
                {expiration_date_different < 0 && !isLoading &&
                    <Tag
                        color="red"
                        className="dashboard-info"
                    >
                        <InfoCircleOutlined style={{ marginRight: 5 }} />
                        Your membership has expired. You can purchase membership in your
                        &nbsp;<Link to="/payments" style={{ color: '#39A9FF', textDecoration: 'underline' }}>membership section</Link>
                        &nbsp;or you can join to the corporate.
                    </Tag>
                }
                <Row style={overviewStyle}>
                    <Title level={3}>Overview</Title>
                </Row>
                <Row gutter={16}>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{ color: '#000000' }}>Expiry date</div>}
                                style={{ textAlign: 'left' }}
                                value={expiration_date_different < 0 ? `Expired on: ${expiration_date_info}` : expiration_date_info}
                                valueStyle={{ color: expiration_color }}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{ color: '#000000' }}>Last payment date</div>}
                                style={{ textAlign: 'left' }}
                                value={last_payment_info}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{ color: '#000000' }}>Approval status</div>}
                                style={{ textAlign: 'left' }}
                                value={approval_text_info}
                                valueStyle={{ color: approval_color }}
                            />
                        </Card>
                    </Col>
                    <Col md={6} span={12}>
                        <Card className="dashboard-info-card">
                            <Statistic
                                title={<div style={{ color: '#000000' }}>Your articles</div>}
                                style={{ textAlign: 'left' }}
                                value={articles_number}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Title level={3}>Quick Access</Title>
                </Row>
                <Row gutter={16}>
                    <Col md={6} span={12}>
                        <Link to="/profile">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <UserOutlined />
                                            <span className="nav-text">
                                                Profile
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={6} span={12}>
                        <Link to="/articles">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <ReadOutlined />
                                            <span className="nav-text">
                                                Articles
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={6} span={12}>
                        <Link to="/events">
                            <Card className="quick-access-card">
                                <Row justify="left">
                                    <Col span={24}>
                                        <Row
                                            justify="flex-start"
                                            align="middle"
                                        >
                                            <TeamOutlined />
                                            <span className="nav-text">
                                                Events
                                            </span>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                    <Col>
                        <SupportMessage />
                    </Col>
                </Row>
            </Spin>
        );
    }
}

export const IndividualMemberDashboard = withUser(IndividualMemberDashboardInner);